.dashboard-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px; }
  .dashboard-header .left-section {
    display: flex; }
    .dashboard-header .left-section .view-btn {
      height: 50px;
      margin-right: 20px;
      border: 1px solid #398CAF;
      border-radius: 8px;
      background-color: transparent;
      color: #398CAF; }
      .dashboard-header .left-section .view-btn.active {
        background-color: #398CAF;
        color: white; }
  .dashboard-header .right-section .inline {
    display: flex;
    margin-bottom: 20px;
    margin-top: 10px; }
    .dashboard-header .right-section .inline p {
      text-align: right;
      color: #BA4A00;
      margin-bottom: 0px;
      line-height: 32px; }
      .dashboard-header .right-section .inline p span {
        font-weight: 500; }
    .dashboard-header .right-section .inline .ant-picker {
      border: none;
      background-color: transparent; }
      .dashboard-header .right-section .inline .ant-picker input {
        color: #BA4A00;
        font-weight: 500;
        text-align: center; }
      .dashboard-header .right-section .inline .ant-picker .anticon {
        font-size: 20px; }
      .dashboard-header .right-section .inline .ant-picker .ant-picker-clear {
        background-color: #EAEDEE; }
  .dashboard-header .right-section .filters {
    display: flex;
    justify-content: flex-end; }
    .dashboard-header .right-section .filters .ant-btn {
      margin-right: 20px;
      height: 30px;
      color: #8B8D96;
      border: 1px solid #8B8D96;
      background-color: white;
      border-radius: 7px;
      font-weight: normal;
      display: flex;
      height: 38px;
      padding-top: 6px; }
      .dashboard-header .right-section .filters .ant-btn .anticon-down {
        margin-top: 4px; }
      .dashboard-header .right-section .filters .ant-btn.active {
        background-color: #D9EBF3;
        color: #398CAF;
        border: 1px solid #398CAF; }
        .dashboard-header .right-section .filters .ant-btn.active .anticon-close {
          color: white;
          background: #398CAF;
          min-width: 20px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 2px;
          margin-top: 2px; }
          .dashboard-header .right-section .filters .ant-btn.active .anticon-close svg {
            font-size: 10px;
            font-weight: bold; }

@media (max-width: 992px) and (min-width: 768px) {
  .dashboard-header {
    display: block !important; }
    .dashboard-header .filters {
      justify-content: flex-start !important; } }

@media (max-width: 768px) {
  .ant-picker-dropdown {
    max-width: 282px;
    margin: 20px; }
    .ant-picker-dropdown .ant-picker-panels {
      flex-wrap: wrap; }
  .section-selector.reseller.ant-radio-group.ant-radio-group-solid > label.ant-radio-button-wrapper {
    width: 100% !important;
    color: #BA4A00 !important;
    background-color: white !important; }
  .dashboard-header {
    display: block !important;
    margin-bottom: 0px; }
    .dashboard-header .filters .ant-btn {
      margin-right: 10px; }
    .dashboard-header .mobile-header {
      margin-bottom: 20px; }
      .dashboard-header .mobile-header .selectors {
        display: flex; }
        .dashboard-header .mobile-header .selectors .ant-picker {
          background-color: white !important;
          margin-left: 10px;
          padding: 14px; }
          .dashboard-header .mobile-header .selectors .ant-picker > *:not(.ant-picker-suffix) {
            display: none; }
          .dashboard-header .mobile-header .selectors .ant-picker svg {
            font-size: 20px;
            color: black; }
      .dashboard-header .mobile-header .date-display {
        display: flex;
        margin-top: 20px;
        color: #BA4A00; }
        .dashboard-header .mobile-header .date-display p {
          margin-bottom: 0px; }
        .dashboard-header .mobile-header .date-display .bold {
          text-align: right;
          font-weight: 600; } }

.selector .ant-popover-inner-content {
  max-height: 300px;
  overflow-y: scroll; }
  .selector .ant-popover-inner-content .ant-checkbox-wrapper {
    width: 100%;
    margin-bottom: 10px;
    font-weight: normal; }

.selector .checkbox-group {
  display: flex;
  flex-direction: column; }
  .selector .checkbox-group label {
    background-color: #F5F5F5;
    margin-right: 0px;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 5px;
    font-size: 12px; }
    .selector .checkbox-group label.ant-checkbox-wrapper {
      display: flex;
      margin-left: 0px;
      align-items: center; }

.rangepicker-footer p {
  color: #8B8D96;
  margin-bottom: 0px;
  line-height: 1.5;
  margin-top: 10px; }

.rangepicker-footer .ant-select {
  height: 37px;
  margin: 20px 0px;
  margin-right: 20px; }
  .rangepicker-footer .ant-select .ant-select-selector {
    height: 37px;
    padding-top: 2px;
    border-color: #8B8D96; }

.rangepicker-footer .controls {
  background-color: #EAEDEE;
  margin-left: -20px;
  width: calc(100% + 40px);
  margin-bottom: -1px;
  padding: 20px;
  display: flex;
  justify-content: flex-end; }
  .rangepicker-footer .controls button {
    margin-left: 20px; }
