.customers-table .customer-mobile {
  margin: 10px 20px;
  display: flex;
  width: calc(100% - 40px);
  align-items: center;
  border-radius: 8px;
  height: 80px;
  padding: 8px 15px;
  align-items: center; }
  .customers-table .customer-mobile:nth-of-type(even) {
    background-color: #F5F5F5; }
  .customers-table .customer-mobile div:first-of-type {
    height: 100%; }
  .customers-table .customer-mobile div p:first-of-type {
    font-size: 16px;
    color: #505157;
    margin: 0px; }
  .customers-table .customer-mobile div p:last-of-type {
    font-size: 14px;
    font-weight: 300;
    margin: 0px; }
  .customers-table .customer-mobile div:nth-of-type(2) {
    margin-left: auto; }
  .customers-table .customer-mobile > span {
    margin-left: 20px; }
  .customers-table .customer-mobile .date {
    max-width: 30%;
    min-width: 30%;
    padding-left: 10px; }
  .customers-table .customer-mobile .info {
    max-width: 60%;
    min-width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .customers-table .customer-mobile .info p {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden; }
  .customers-table .customer-mobile .anticon {
    max-width: 5%;
    min-width: 5%; }

@media (max-width: 768px) {
  .customers-table {
    padding-top: 178px; } }
