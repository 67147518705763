#add-lead-note {
  margin-bottom: 40px; }
  #add-lead-note label.title {
    color: #BA4A00;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 10px;
    display: block; }
  #add-lead-note .notes-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
    overflow: hidden; }
    #add-lead-note .notes-container img {
      position: absolute;
      right: 0; }
  #add-lead-note .note {
    color: #505157;
    width: 255px;
    padding: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    background-color: #fcf8f3;
    border-bottom: 5px solid #f6e9d9;
    border-radius: 6px;
    z-index: 200;
    max-height: 120px;
    overflow: scroll;
    overflow-x: auto; }
    #add-lead-note .note .title {
      font-size: 14px;
      color: #AB8E6A;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    #add-lead-note .note .timestamp {
      font-size: 12px;
      color: black;
      opacity: 0.3; }
    #add-lead-note .note .description {
      font-size: 12px;
      font-weight: 300;
      color: #505157; }
  #add-lead-note .placeholder-note {
    color: #505157;
    width: 255px;
    padding: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    background-color: #fcf8f3;
    border-bottom: 5px solid #f6e9d9;
    border-radius: 6px;
    font-weight: 500;
    font-size: 12px;
    font-style: italic;
    z-index: 3; }
  #add-lead-note .button-container {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    padding-left: 20px; }
    #add-lead-note .button-container p {
      margin-top: auto;
      margin-left: auto;
      color: #505157;
      font-weight: 500;
      margin-top: 10px; }
  #add-lead-note .ant-input-affix-wrapper {
    max-width: 390px;
    margin-right: 20px;
    border: none;
    border-radius: 8px;
    background-color: #f5f5f5; }
    #add-lead-note .ant-input-affix-wrapper input {
      height: 42px;
      font-size: 16px;
      color: #8B8D96;
      background-color: #f5f5f5; }
    #add-lead-note .ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
      box-shadow: none;
      border: 1px solid #1890FF;
      background-color: #f4f9ff; }
      #add-lead-note .ant-input-affix-wrapper.ant-input-affix-wrapper-focused input {
        background-color: transparent; }
  #add-lead-note .ant-input-prefix {
    margin-right: 20px;
    font-size: 16px;
    color: #8B8D96; }

@media (max-width: 768px) {
  #add-lead-note .search-container {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row; }
    #add-lead-note .search-container button {
      height: 50px;
      min-width: 50px; }
  #add-lead-note .note {
    width: 100%;
    padding: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    background-color: #fcf8f3;
    border-bottom: 5px solid #f6e9d9;
    border-radius: 6px;
    max-height: unset; }
    #add-lead-note .note .title {
      font-size: 16px;
      color: #AB8E6A;
      margin-bottom: 10px; }
    #add-lead-note .note .timestamp {
      font-size: 14px;
      color: #8B8D96;
      font-weight: 300;
      margin-bottom: 20px; }
    #add-lead-note .note .description {
      font-size: 14px;
      color: #505157;
      font-weight: 300;
      overflow: hidden;
      max-height: 122px; }
    #add-lead-note .note .divider {
      margin-top: 15px;
      margin-bottom: 15px;
      height: 1px;
      color: #BFBFBF; }
    #add-lead-note .note .bottom-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between; }
    #add-lead-note .note .view-more-text {
      font-size: 16px;
      color: #505157;
      font-weight: 300; }
      #add-lead-note .note .view-more-text span {
        margin-left: 20px; }
    #add-lead-note .note .delete-text {
      font-size: 16px;
      color: #8B8D96;
      font-weight: 300;
      margin-right: 30px; }
    #add-lead-note .note .edit-text {
      font-size: 16px;
      color: #398CAF; }
    #add-lead-note .note.opened .description {
      max-height: unset; }
  #add-lead-note .notes-container {
    padding-left: 20px;
    padding-right: 20px; } }

@media (max-width: 1490px) {
  .notes-container .note:nth-last-of-type(3) {
    display: none; } }

@media (max-width: 1140px) {
  .notes-container .note:nth-last-of-type(2) {
    display: none; } }
