.import-customers .ant-modal-content {
  overflow: hidden; }

.import-customers.full-height .ant-drawer-content-wrapper {
  height: 100% !important; }

.import-customers h3 {
  font-weight: 500;
  font-size: 24px;
  color: #BA4A00; }

.import-customers label {
  font-weight: 500;
  font-size: 16px;
  color: #1B1B1B; }
  @media (max-width: 768px) {
    .import-customers label {
      font-size: 14px; } }

.import-customers .default-view .import-option .img-container {
  padding: 40px;
  background-color: #F5F5F5;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (max-width: 768px) {
    .import-customers .default-view .import-option .img-container {
      padding: 20px;
      margin-right: 20px; }
      .import-customers .default-view .import-option .img-container img {
        max-width: 75px;
        height: auto; } }

.import-customers .default-view .import-option.customers {
  padding-left: 0px;
  padding-right: 10px; }

.import-customers .default-view .import-option.images {
  padding-right: 0px;
  padding-left: 10px; }

.import-customers .default-view .import-option .ant-upload, .import-customers .default-view .import-option > button {
  width: 100%;
  margin-top: 20px;
  height: 50px; }
  .import-customers .default-view .import-option .ant-upload button, .import-customers .default-view .import-option > button button {
    width: 100%;
    height: 50px; }

.import-customers .default-view .import-option p {
  color: #8C8C8C;
  margin-top: 20px; }

.import-customers .default-view .import-option .inline {
  display: flex; }

@media (max-width: 768px) {
  .import-customers .default-view .import-option.customers {
    margin-bottom: 40px;
    padding-bottom: 40px;
    border-bottom: 1px solid #DADADA; } }

.import-customers .duplicates-view .head, .import-customers .after-import-view .head {
  display: flex; }
  .import-customers .duplicates-view .head img, .import-customers .after-import-view .head img {
    margin-right: 20px;
    margin-bottom: 20px; }
    @media (max-width: 768px) {
      .import-customers .duplicates-view .head img, .import-customers .after-import-view .head img {
        margin-bottom: 0px; } }
  .import-customers .duplicates-view .head h5, .import-customers .after-import-view .head h5 {
    color: #BA4A00;
    font-weight: 600;
    font-size: 14px; }
    @media (max-width: 768px) {
      .import-customers .duplicates-view .head h5, .import-customers .after-import-view .head h5 {
        line-height: 29px; } }
  .import-customers .duplicates-view .head p, .import-customers .after-import-view .head p {
    color: #8C8C8C; }
  @media (max-width: 768px) {
    .import-customers .duplicates-view .head, .import-customers .after-import-view .head {
      display: block;
      border-bottom: 1px solid #DADADA; }
      .import-customers .duplicates-view .head h5, .import-customers .after-import-view .head h5 {
        font-size: 24px;
        margin-top: 20px; } }

.import-customers .duplicates-view .body, .import-customers .after-import-view .body {
  margin-left: -24px;
  margin-right: -24px;
  margin-bottom: -24px;
  padding: 24px;
  background-color: #F5F7F7;
  height: 50vh;
  overflow-y: auto; }
  .import-customers .duplicates-view .body h4, .import-customers .after-import-view .body h4 {
    font-size: 16px;
    color: #BA4A00;
    font-weight: bold;
    text-align: center;
    width: 100%;
    margin-bottom: 20px;
    margin-top: -4px;
    text-transform: uppercase; }
  @media (max-width: 768px) {
    .import-customers .duplicates-view .body, .import-customers .after-import-view .body {
      background-color: white;
      height: auto;
      padding-top: 0px; } }
  .import-customers .duplicates-view .body .customer-duplicates, .import-customers .after-import-view .body .customer-duplicates {
    overflow: scroll;
    height: 100%; }
    .import-customers .duplicates-view .body .customer-duplicates .ant-table, .import-customers .after-import-view .body .customer-duplicates .ant-table {
      padding: 0px 10px 40px 10px; }
    .import-customers .duplicates-view .body .customer-duplicates table thead, .import-customers .after-import-view .body .customer-duplicates table thead {
      position: sticky;
      z-index: 999;
      background-color: white; }
    .import-customers .duplicates-view .body .customer-duplicates table thead > tr > th, .import-customers .after-import-view .body .customer-duplicates table thead > tr > th {
      background-color: white; }
    .import-customers .duplicates-view .body .customer-duplicates table thead tr, .import-customers .after-import-view .body .customer-duplicates table thead tr {
      box-shadow: none; }
    .import-customers .duplicates-view .body .customer-duplicates table tbody tr td:nth-last-child(2), .import-customers .after-import-view .body .customer-duplicates table tbody tr td:nth-last-child(2) {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px; }
    .import-customers .duplicates-view .body .customer-duplicates table tbody tr:nth-of-type(even) td:last-child, .import-customers .after-import-view .body .customer-duplicates table tbody tr:nth-of-type(even) td:last-child {
      border-top-right-radius: 14px;
      border-bottom-right-radius: 14px; }
    .import-customers .duplicates-view .body .customer-duplicates table tbody tr td:nth-child(2), .import-customers .after-import-view .body .customer-duplicates table tbody tr td:nth-child(2) {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px; }
    .import-customers .duplicates-view .body .customer-duplicates table tbody tr:nth-of-type(even) td:nth-child(1), .import-customers .after-import-view .body .customer-duplicates table tbody tr:nth-of-type(even) td:nth-child(1) {
      border-top-left-radius: 14px;
      border-bottom-left-radius: 14px; }
  .import-customers .duplicates-view .body .duplicate-items, .import-customers .after-import-view .body .duplicate-items {
    padding-bottom: 165px;
    height: calc(100vh - 156px);
    overflow: scroll; }
    .import-customers .duplicates-view .body .duplicate-items > .ant-checkbox-wrapper, .import-customers .after-import-view .body .duplicate-items > .ant-checkbox-wrapper {
      padding-left: 15px !important; }
    .import-customers .duplicates-view .body .duplicate-items .ant-checkbox-checked .ant-checkbox-inner::after, .import-customers .after-import-view .body .duplicate-items .ant-checkbox-checked .ant-checkbox-inner::after {
      zoom: 1.4; }
    .import-customers .duplicates-view .body .duplicate-items .ant-checkbox-disabled .ant-checkbox-inner, .import-customers .after-import-view .body .duplicate-items .ant-checkbox-disabled .ant-checkbox-inner {
      background-color: #BFBFBF;
      border: none; }
    .import-customers .duplicates-view .body .duplicate-items > .ant-checkbox-wrapper, .import-customers .after-import-view .body .duplicate-items > .ant-checkbox-wrapper {
      width: 100%;
      padding: 20px 20px 10px 34px; }
      .import-customers .duplicates-view .body .duplicate-items > .ant-checkbox-wrapper span, .import-customers .after-import-view .body .duplicate-items > .ant-checkbox-wrapper span {
        font-weight: 300;
        font-size: 16px; }
      .import-customers .duplicates-view .body .duplicate-items > .ant-checkbox-wrapper .ant-checkbox, .import-customers .after-import-view .body .duplicate-items > .ant-checkbox-wrapper .ant-checkbox {
        margin-right: 10px; }
    .import-customers .duplicates-view .body .duplicate-items .duplicate-mobile, .import-customers .after-import-view .body .duplicate-items .duplicate-mobile {
      margin: 10px 0px;
      display: flex;
      align-items: center;
      border-radius: 8px;
      height: 80px;
      padding: 8px 15px;
      align-items: center;
      justify-content: space-between; }
      .import-customers .duplicates-view .body .duplicate-items .duplicate-mobile.selected .left p, .import-customers .after-import-view .body .duplicate-items .duplicate-mobile.selected .left p {
        color: #1890FF; }
      .import-customers .duplicates-view .body .duplicate-items .duplicate-mobile p, .import-customers .after-import-view .body .duplicate-items .duplicate-mobile p {
        margin: 0px; }
      .import-customers .duplicates-view .body .duplicate-items .duplicate-mobile:nth-of-type(odd), .import-customers .after-import-view .body .duplicate-items .duplicate-mobile:nth-of-type(odd) {
        background-color: #F5F5F5; }
      .import-customers .duplicates-view .body .duplicate-items .duplicate-mobile div p:first-of-type, .import-customers .after-import-view .body .duplicate-items .duplicate-mobile div p:first-of-type {
        font-size: 16px;
        color: #505157;
        margin: 0px; }
      .import-customers .duplicates-view .body .duplicate-items .duplicate-mobile div p:last-of-type, .import-customers .after-import-view .body .duplicate-items .duplicate-mobile div p:last-of-type {
        font-size: 14px;
        font-weight: 300;
        margin: 0px; }
      .import-customers .duplicates-view .body .duplicate-items .duplicate-mobile > span, .import-customers .after-import-view .body .duplicate-items .duplicate-mobile > span {
        margin-left: 10px; }

.import-customers .missing-fields-view h5 {
  color: #BA4A00;
  font-weight: 600;
  font-size: 14px; }
  @media (max-width: 768px) {
    .import-customers .missing-fields-view h5 {
      line-height: 29px;
      font-size: 24px; } }

.import-customers .missing-fields-view p {
  color: #8C8C8C; }

.import-customers .duplicates-view .buttons, .import-customers .missing-fields-view .buttons {
  position: absolute;
  bottom: 0px;
  display: flex;
  height: 64px;
  background-color: #F5F7F7;
  width: 100%;
  padding-top: 12px;
  padding-right: 20px;
  width: calc(100% - 48px); }
  .import-customers .duplicates-view .buttons button, .import-customers .missing-fields-view .buttons button {
    height: 32px;
    margin-left: 10px; }
    .import-customers .duplicates-view .buttons button.secondary-medium-2, .import-customers .missing-fields-view .buttons button.secondary-medium-2 {
      margin-left: auto; }
  @media (max-width: 768px) {
    .import-customers .duplicates-view .buttons, .import-customers .missing-fields-view .buttons {
      display: block;
      background-color: white;
      height: auto;
      padding: 0px; }
      .import-customers .duplicates-view .buttons button, .import-customers .missing-fields-view .buttons button {
        width: 100%;
        height: 50px;
        margin-left: 0px;
        margin-bottom: 10px; } }

.import-customers .after-import-view .body {
  height: auto;
  min-height: 160px; }
  .import-customers .after-import-view .body > p {
    font-size: 14px;
    color: #505157;
    width: 100%; }
    .import-customers .after-import-view .body > p.bold {
      font-weight: 600;
      margin-bottom: 5px; }
    @media (max-width: 768px) {
      .import-customers .after-import-view .body > p {
        margin-top: 20px; } }
  .import-customers .after-import-view .body .ant-upload {
    border: none;
    background-color: white;
    height: auto;
    padding: 20px; }
    .import-customers .after-import-view .body .ant-upload .ant-upload-btn {
      padding: 0px;
      height: 212px;
      background-color: #F5F5F5; }
      .import-customers .after-import-view .body .ant-upload .ant-upload-btn svg {
        color: #8B8D96;
        margin-bottom: 20px; }
      .import-customers .after-import-view .body .ant-upload .ant-upload-btn p {
        color: #8B8D96;
        font-size: 14px; }
        .import-customers .after-import-view .body .ant-upload .ant-upload-btn p.underline {
          text-decoration: underline; }
  .import-customers .after-import-view .body .images-failed-dragger .ant-upload {
    padding: 10px; }
    .import-customers .after-import-view .body .images-failed-dragger .ant-upload p {
      font-size: 12px; }
    .import-customers .after-import-view .body .images-failed-dragger .ant-upload svg {
      font-size: 30px; }
  .import-customers .after-import-view .body .images-failed {
    padding-right: 10px; }
    .import-customers .after-import-view .body .images-failed > div {
      background-color: white;
      padding: 10px;
      border-radius: 4px;
      height: 100%;
      height: 233px;
      overflow-y: scroll; }
      .import-customers .after-import-view .body .images-failed > div p {
        color: #8B8D96;
        margin-bottom: 5px; }
      @media (max-width: 768px) {
        .import-customers .after-import-view .body .images-failed > div {
          background: #F5F5F5;
          max-height: calc(100vh - 505px);
          overflow: scroll; }
          .import-customers .after-import-view .body .images-failed > div p {
            font-size: 12px; } }
  .import-customers .after-import-view .body .buttons {
    width: 100%;
    margin-top: 20px; }
    .import-customers .after-import-view .body .buttons button {
      margin-left: auto;
      display: block; }
    @media (max-width: 768px) {
      .import-customers .after-import-view .body .buttons {
        position: absolute;
        bottom: 30px;
        width: calc(100% - 48px); }
        .import-customers .after-import-view .body .buttons button {
          width: 100%;
          height: 50px;
          margin-left: 0px; }
        .import-customers .after-import-view .body .buttons .ant-upload {
          width: 100%;
          padding: 0px; } }

.import-customers .after-import-view.upload-images .head {
  border-bottom: none; }
  .import-customers .after-import-view.upload-images .head p {
    text-align: center; }
    @media (max-width: 768px) {
      .import-customers .after-import-view.upload-images .head p {
        text-align: left;
        margin-top: 20px; } }

.import-customers .after-import-view.upload-images .body {
  border-radius: 5px; }
  @media (max-width: 768px) {
    .import-customers .after-import-view.upload-images .body div.ant-upload {
      padding: 0px;
      margin-top: 20px;
      width: calc(100vw - 48px); }
      .import-customers .after-import-view.upload-images .body div.ant-upload span.ant-upload {
        padding: 0px; }
      .import-customers .after-import-view.upload-images .body div.ant-upload button {
        height: 50px;
        margin: 0px;
        width: 100%; } }
