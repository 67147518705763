#lead-steps-bar .container {
  display: flex;
  flex-direction: row;
  margin-bottom: 40px; }
  #lead-steps-bar .container.disabled > div {
    cursor: not-allowed; }

#lead-steps-bar .step-first {
  display: flex;
  box-sizing: border-box;
  height: 40px;
  width: 155px;
  border-radius: 20px 0 0 20px;
  background-color: white;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  margin-right: 2px;
  width: 235px;
  color: #505157;
  font-size: 14px;
  cursor: pointer; }
  @media (max-width: 992px) {
    #lead-steps-bar .step-first {
      padding-left: 10px;
      padding-right: 0px;
      font-size: 12px; } }

#lead-steps-bar .step {
  display: flex;
  box-sizing: border-box;
  height: 40px;
  width: 155px;
  background-color: white;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  margin-right: 2px;
  width: 235px;
  color: #505157;
  font-size: 14px;
  cursor: pointer; }
  @media (max-width: 992px) {
    #lead-steps-bar .step {
      padding-left: 10px;
      padding-right: 0px;
      font-size: 12px; } }

#lead-steps-bar .step-last {
  display: flex;
  box-sizing: border-box;
  height: 40px;
  width: 235px;
  border-radius: 0 20px 20px 0;
  background-color: white;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  color: #505157;
  font-size: 14px;
  cursor: pointer; }
  @media (max-width: 992px) {
    #lead-steps-bar .step-last {
      padding-left: 10px;
      padding-right: 0px;
      font-size: 12px; } }

#lead-steps-bar .step-lost {
  display: flex;
  box-sizing: border-box;
  height: 40px;
  width: 235px;
  border-radius: 20px;
  background-color: white;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  color: #505157;
  margin-left: 20px;
  font-size: 14px;
  cursor: pointer; }
  @media (max-width: 992px) {
    #lead-steps-bar .step-lost {
      padding-left: 10px;
      padding-right: 0px;
      font-size: 12px; } }

#lead-steps-bar .completed {
  border: 1px solid #52C41A;
  background-color: #E4F3DB;
  color: #52C41A; }

#lead-steps-bar .icon {
  margin-left: 10px; }

#lead-steps-bar i:before {
  font-size: 12px; }
