#add-lead-order label.title {
  color: #BA4A00;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 10px;
  display: block; }

#add-lead-order .discount-rate {
  background-color: #BA4A00;
  font-weight: 600;
  border-radius: 4px;
  padding: 3px 10px;
  color: white;
  margin-right: 10px; }

#add-lead-order .section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px; }
  #add-lead-order .section-header .view-selector {
    display: flex; }
    #add-lead-order .section-header .view-selector p {
      margin-left: 20px;
      color: #8B8D96;
      font-weight: 500;
      cursor: pointer; }
      #add-lead-order .section-header .view-selector p.active {
        cursor: unset;
        color: black;
        border-bottom: 1px solid black; }

#add-lead-order .divider {
  margin-top: 10px;
  margin-left: -20px;
  width: 110%;
  height: 1px;
  background-color: #EAEAEA; }

#add-lead-order .order-container .content-container {
  min-height: 400px;
  display: flex;
  justify-content: center; }

#add-lead-order .order-container .empty-placeholder {
  position: relative;
  margin-top: 10%; }
  #add-lead-order .order-container .empty-placeholder .description {
    position: absolute;
    width: 300px;
    top: 130px;
    left: 70px;
    z-index: 100;
    color: "#8B8D96";
    font-size: 14; }

#add-lead-order .order-table {
  width: 100%; }

#add-lead-order .order-top-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; }
  #add-lead-order .order-top-container > button {
    margin-bottom: 10px; }
  #add-lead-order .order-top-container .ant-input-affix-wrapper {
    max-width: 390px;
    margin-right: 20px;
    border: none;
    border-radius: 8px;
    background-color: #f5f5f5; }
    #add-lead-order .order-top-container .ant-input-affix-wrapper input {
      height: 42px;
      font-size: 16px;
      color: #8B8D96;
      background-color: #f5f5f5; }
    #add-lead-order .order-top-container .ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
      box-shadow: none;
      border: 1px solid #1890FF;
      background-color: #f4f9ff; }
      #add-lead-order .order-top-container .ant-input-affix-wrapper.ant-input-affix-wrapper-focused input {
        background-color: transparent; }
  #add-lead-order .order-top-container .ant-input-prefix {
    margin-right: 20px;
    font-size: 16px;
    color: #8B8D96; }

#add-lead-order .footer {
  position: absolute;
  bottom: 0;
  height: 52px;
  width: 100%;
  z-index: 100;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0 0 8px 8px; }
  #add-lead-order .footer .total {
    margin-left: 20px;
    font-size: 16;
    color: #BA4A00;
    font-weight: 500; }
  #add-lead-order .footer .total-profit {
    margin-right: 20px;
    width: 150px; }
  #add-lead-order .footer .total-amount {
    width: 150px; }
  #add-lead-order .footer .total-right-container {
    display: flex;
    flex-direction: row;
    font-size: 16px;
    font-weight: 500;
    color: #505157; }
    #add-lead-order .footer .total-right-container span {
      font-size: 12px;
      color: #8B8D96; }

#add-lead-order .shadow {
  background-color: rgba(255, 255, 255, 0.41);
  box-shadow: 0 -6px 10px 0 rgba(0, 0, 0, 0.06), 0 -1px 18px 0 rgba(0, 0, 0, 0.06), 0 -3px 5px 0 rgba(0, 0, 0, 0.06); }

#add-lead-order .order-container {
  width: 100%; }
  #add-lead-order .order-container .ant-input-affix-wrapper {
    border: none;
    border-radius: 8px;
    background-color: #f5f5f5; }
    #add-lead-order .order-container .ant-input-affix-wrapper input {
      font-size: 14px;
      color: #8B8D96;
      background-color: transparent; }
    #add-lead-order .order-container .ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
      box-shadow: none;
      border: 1px solid #1890FF;
      background-color: transparent input;
        background-color-background-color: transparent; }
  #add-lead-order .order-container .ant-input-prefix {
    margin-right: 5px;
    font-size: 16px;
    color: #8B8D96; }

#add-lead-order .ant-table-tbody > tr > td {
  border-bottom: 0px; }

#add-lead-order .ant-table-thead > tr > th {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  text-align: left;
  background: transparent;
  border-bottom: 0px solid #f0f0f0;
  transition: background 0.3s ease; }

@media (max-width: 768px) {
  #add-lead-order .ant-table-tbody > tr > td {
    padding-top: 0;
    padding-bottom: 0; }
  #add-lead-order .footer {
    position: fixed;
    background-color: white;
    height: 76px;
    display: flex;
    flex-direction: column; }
    #add-lead-order .footer .row {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between; }
    #add-lead-order .footer .title-total {
      font-size: 14px;
      color: #BA4A00;
      font-weight: 500; }
    #add-lead-order .footer .title-profit {
      font-size: 14px;
      color: #8B8D96; }
    #add-lead-order .footer .total-value {
      font-size: 16px;
      font-weight: 500;
      color: #505157; }
    #add-lead-order .footer .profit-value {
      font-size: 16px;
      font-weight: 500;
      color: #505157; }
  #add-lead-order .mobile-content-container {
    display: flex;
    flex-direction: column;
    justify-content: center; }
    #add-lead-order .mobile-content-container .button {
      margin: 20px;
      margin-top: 0px; }
  #add-lead-order .mobile-table-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px; }
    #add-lead-order .mobile-table-row .title-text {
      color: #505157;
      font-size: 16px; }
    #add-lead-order .mobile-table-row .subtitle-text {
      color: #505157;
      font-size: 16px;
      font-weight: 300; }
      #add-lead-order .mobile-table-row .subtitle-text.discounted {
        color: #BA4A00; }
  #add-lead-order .background-white {
    background-color: white; } }
