.loading-placeholder {
  height: 100vh;
  width: 100vw; }

.anticon-loading svg {
  color: #BA4A00; }

.cost-breakdown {
  background-color: #EAEDEE;
  min-height: 100vh;
  overflow: auto; }
  .cost-breakdown .discount-rate {
    background-color: #BA4A00;
    font-weight: 600;
    border-radius: 4px;
    padding: 3px 10px;
    color: white;
    margin-right: 10px; }
  .cost-breakdown .container {
    width: 620px;
    margin: 0 auto;
    display: block;
    margin-top: 20px;
    border-radius: 8px;
    position: relative;
    margin-bottom: 20px; }
    .cost-breakdown .container .head {
      background-color: #505157;
      display: flex;
      padding: 20px;
      border-radius: 8px 8px 0px 0px;
      box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.08), 0 1px 18px 0 rgba(0, 0, 0, 0.1), 0 3px 5px 0 rgba(0, 0, 0, 0.1); }
      .cost-breakdown .container .head p {
        color: white;
        margin-bottom: 0px;
        font-weight: 300; }
        .cost-breakdown .container .head p.bold {
          font-weight: 500; }
      .cost-breakdown .container .head div:last-of-type {
        text-align: right;
        margin-left: auto; }
    .cost-breakdown .container .body {
      box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.08), 0 1px 18px 0 rgba(0, 0, 0, 0.1), 0 3px 5px 0 rgba(0, 0, 0, 0.1);
      padding: 20px;
      overflow: hidden;
      border-radius: 0px 0px 8px 8px;
      background-color: white; }
      .cost-breakdown .container .body h1 {
        font-size: 16px;
        margin-bottom: 40px; }
      .cost-breakdown .container .body .labels {
        display: inline-block;
        width: 100%;
        border-bottom: 1px solid #8B8D96;
        padding-bottom: 10px;
        margin-bottom: -10px; }
        .cost-breakdown .container .body .labels div {
          float: left;
          font-weight: 500; }
      .cost-breakdown .container .body .width-5 {
        width: 5%; }
      .cost-breakdown .container .body .width-15 {
        width: 15%; }
      .cost-breakdown .container .body .width-45 {
        width: 45%; }
      .cost-breakdown .container .body .width-20 {
        width: 20%; }
      .cost-breakdown .container .body .discounted-price {
        width: 90%;
        text-align: right;
        margin-top: 5px;
        padding-right: 13px;
        color: #BA4A00; }
      .cost-breakdown .container .body .strikethrough {
        text-decoration: line-through; }
      .cost-breakdown .container .body .product-item {
        width: 100%;
        cursor: pointer;
        overflow: hidden; }
        .cost-breakdown .container .body .product-item > div {
          float: left; }
        .cost-breakdown .container .body .product-item .image-container {
          width: 100%;
          margin-top: 20px; }
          .cost-breakdown .container .body .product-item .image-container img {
            width: 130px;
            height: auto;
            border-radius: 4px; }
        .cost-breakdown .container .body .product-item .product-details {
          width: 100%;
          display: inline-block;
          margin-top: 20px;
          border-bottom: 1px solid #EAEAEA;
          padding-bottom: 10px;
          cursor: pointer; }
          .cost-breakdown .container .body .product-item .product-details div {
            float: left;
            font-weight: 300; }
            .cost-breakdown .container .body .product-item .product-details div.gray {
              color: #BFBFBF; }
            .cost-breakdown .container .body .product-item .product-details div .width-5 {
              text-align: right; }
      .cost-breakdown .container .body .total {
        width: 100%;
        display: flex;
        border-top: 1px solid black;
        padding-top: 10px; }
        .cost-breakdown .container .body .total div {
          float: left;
          color: black;
          font-size: 16px;
          font-weight: 600;
          margin-left: -5px; }
    .cost-breakdown .container .options {
      position: absolute;
      right: -190px; }
      .cost-breakdown .container .options p {
        color: #505157;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 0px; }
      .cost-breakdown .container .options .ant-select {
        margin-bottom: 30px; }
      .cost-breakdown .container .options .ant-select-selector {
        padding-left: 0px;
        border: none;
        font-size: 16px;
        background-color: transparent; }
      .cost-breakdown .container .options .ant-select-arrow {
        color: #505157;
        top: 48%; }
      .cost-breakdown .container .options .share-buttons div {
        cursor: pointer;
        width: 100%;
        color: white;
        border-radius: 4px;
        margin-bottom: 5px;
        padding: 4px 8px; }
        .cost-breakdown .container .options .share-buttons div.whatsapp {
          background-color: #23CD64; }
        .cost-breakdown .container .options .share-buttons div.link {
          background-color: #505157; }
  .cost-breakdown.product-open .container {
    left: -520px; }
    .cost-breakdown.product-open .container .options {
      right: -720px; }
    .cost-breakdown.product-open .container .head, .cost-breakdown.product-open .container .body {
      opacity: .5; }
  .cost-breakdown .become-reseller {
    width: 176px;
    border-radius: 8px;
    box-shadow: 1px 3px 10px rgba(90, 48, 49, 0.1);
    background-color: white;
    padding: 10px;
    margin-top: 30px; }
    .cost-breakdown .become-reseller p {
      font-size: 14px !important;
      font-weight: 400 !important;
      color: #505157 !important;
      margin-bottom: 30px !important; }
    .cost-breakdown .become-reseller button {
      color: white;
      width: 100%; }

@media (max-width: 768px) {
  div.cost-breakdown {
    display: inherit; }
    div.cost-breakdown .container {
      width: 100%;
      padding: 20px;
      margin-top: 0px; }
    div.cost-breakdown .options {
      background-color: #EAEDEE;
      margin-bottom: 20px;
      position: relative !important;
      right: 0px !important;
      display: flex;
      justify-content: space-between; }
      div.cost-breakdown .options .ant-select {
        margin-bottom: 0px !important;
        margin-top: 6px; }
      div.cost-breakdown .options .share-button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 146px;
        height: 40px;
        background-color: #23CD64;
        border-radius: 4px;
        padding: 10px;
        color: white; }
        div.cost-breakdown .options .share-button p {
          color: white; }
    div.cost-breakdown .head {
      display: block !important; }
      div.cost-breakdown .head div:last-of-type {
        margin-top: 30px;
        text-align: left !important; }
    div.cost-breakdown .body {
      padding-bottom: 60px !important; }
      div.cost-breakdown .body h1 {
        margin-bottom: 30px !important; }
      div.cost-breakdown .body .product-item-mobile {
        cursor: pointer;
        display: flex;
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid #EAEAEA; }
        div.cost-breakdown .body .product-item-mobile img {
          width: 50px;
          height: 50px;
          object-fit: cover;
          border-radius: 4px;
          margin-right: 20px; }
        div.cost-breakdown .body .product-item-mobile .product-details {
          width: 100%; }
          div.cost-breakdown .body .product-item-mobile .product-details .inline {
            display: flex;
            justify-content: space-between; }
            div.cost-breakdown .body .product-item-mobile .product-details .inline .item-total {
              margin-left: auto;
              margin-right: 10px; }
      div.cost-breakdown .body .total {
        margin-top: -20px;
        justify-content: space-between; }
        div.cost-breakdown .body .total div {
          margin-left: 0px !important; }
    div.cost-breakdown.product-open .container {
      left: 0px !important; }
      div.cost-breakdown.product-open .container .head, div.cost-breakdown.product-open .container .body {
        display: none !important; } }

@media (min-width: 768px) and (max-width: 1035px) {
  .cost-breakdown .container {
    width: 537px !important;
    right: 96px; }
  .cost-breakdown.product-open .container {
    left: -620px !important; } }

@media (max-width: 768px) {
  .become-reseller {
    width: 100% !important;
    margin-bottom: 20px; }
  .cost-breakdown .container .body {
    margin-bottom: 50px; } }
