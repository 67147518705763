.invite-reseller h1 {
  font-weight: 600;
  font-size: 16px; }

.invite-reseller .ant-modal-content {
  border-radius: 20px;
  overflow: hidden; }
  .invite-reseller .ant-modal-content form {
    width: 295px;
    margin: 0 auto; }
  .invite-reseller .ant-modal-content p {
    margin-bottom: 30px; }
  .invite-reseller .ant-modal-content .primary-medium-2 {
    height: 82px;
    position: relative;
    left: -72px;
    bottom: -24px;
    width: 440px;
    font-size: 24px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px; }

.invite-reseller .ant-drawer-body .primary-medium-2 {
  width: 100%;
  height: 50px;
  color: white; }

.invite-reseller .ant-drawer-body p, .invite-reseller .ant-drawer-body h1 {
  padding: 0px 20px; }

.invite-reseller .ant-drawer-body p {
  margin-bottom: 30px; }
