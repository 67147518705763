.status-cell {
  display: flex;
  align-items: center; }
  .status-cell .status-dot {
    width: 10px;
    margin-right: 10px;
    height: 10px;
    border-radius: 50%; }
    .status-cell .status-dot.Active {
      background-color: #52C41A; }
    .status-cell .status-dot.Suspended {
      background-color: #FFC100; }
    .status-cell .status-dot.Pending {
      background-color: #1890FF; }

.table-btns {
  display: flex;
  justify-content: flex-end; }
  .table-btns button {
    height: 28px;
    margin-left: 10px; }

.ant-table-column-sort .ant-table-column-sorters:first-child {
  color: #BA4A00; }

.user-mobile {
  margin: 10px 20px;
  display: flex;
  width: calc(100% - 40px);
  align-items: center;
  border-radius: 8px;
  height: 80px;
  padding: 8px 15px; }
  .user-mobile:nth-of-type(even) {
    background-color: #F5F5F5; }
  .user-mobile div:first-of-type {
    height: 100%; }
  .user-mobile div p:first-of-type {
    font-size: 16px;
    color: #505157;
    margin: 0px; }
  .user-mobile div p:last-of-type {
    font-size: 14px;
    font-weight: 300;
    margin: 0px; }
  .user-mobile div:nth-of-type(2) {
    margin-left: auto; }
  .user-mobile > span {
    margin-left: 20px; }
  .user-mobile .joined {
    font-size: 16px !important;
    color: #505157; }
  .user-mobile .status {
    font-size: 14px;
    display: flex;
    align-items: center; }
    .user-mobile .status i {
      width: 14px;
      height: 14px;
      margin-left: 10px;
      border-radius: 50%;
      display: block; }
      .user-mobile .status i.Suspended {
        background-color: #FFC100; }
      .user-mobile .status i.Active {
        background-color: #52C41A; }
      .user-mobile .status i.Pending {
        background-color: #1890FF; }
