.ant-drawer-close {
  display: none; }

#add-products-to-order {
  margin: -24px; }
  #add-products-to-order button .flaticon-next {
    margin-left: 10px;
    margin-top: 3px; }
  #add-products-to-order .filter-selector {
    margin-right: auto;
    margin-left: 20px; }
  #add-products-to-order .header-container {
    height: 60px;
    background-color: #d7dbdd;
    display: flex;
    align-items: center;
    padding-left: 25px;
    padding-right: 25px;
    justify-content: space-between; }
    #add-products-to-order .header-container .title {
      font-size: 18px;
      font-weight: 600;
      color: #BA4A00;
      margin-left: 15px; }
    #add-products-to-order .header-container .icon {
      height: 40px;
      width: 40px;
      border-radius: 10px;
      background: linear-gradient(135deg, #BA4A00 0%, #490708 100%);
      display: flex;
      align-items: center;
      justify-content: center; }
    #add-products-to-order .header-container i:before {
      font-size: 20px;
      color: white; }
  #add-products-to-order .product-actions {
    display: flex; }
    #add-products-to-order .product-actions > div {
      width: 22px;
      height: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      border-radius: 4px;
      overflow: hidden;
      cursor: pointer;
      background-color: #505157; }
      #add-products-to-order .product-actions > div i:before {
        font-size: 11px;
        color: white; }
  #add-products-to-order .content {
    padding: 20px;
    height: 87vh;
    background-color: #EAEDEE; }
    #add-products-to-order .content .top-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 25px; }
    #add-products-to-order .content .selected {
      color: #1890ff; }
    #add-products-to-order .content .perpetual-product {
      color: #BA4A00 !important; }
  #add-products-to-order .ant-input-affix-wrapper {
    border: none;
    border-radius: 8px;
    background-color: #f5f5f5; }
    #add-products-to-order .ant-input-affix-wrapper input {
      font-size: 14px;
      color: #8B8D96;
      background-color: #f5f5f5; }
    #add-products-to-order .ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
      box-shadow: none;
      border: 1px solid #1890FF;
      background-color: #f4f9ff; }
      #add-products-to-order .ant-input-affix-wrapper.ant-input-affix-wrapper-focused input {
        background-color: transparent; }
  #add-products-to-order .ant-input-prefix {
    margin-right: 5px;
    font-size: 16px;
    color: #8B8D96; }
  #add-products-to-order .ant-table-tbody > tr > td {
    border-bottom: 0px; }
  #add-products-to-order .ant-table-cell {
    padding: 20px 16px; }
  #add-products-to-order .ant-table-thead > tr > th {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    text-align: left;
    background: transparent;
    border-bottom: 0px solid #f0f0f0;
    transition: background 0.3s ease; }
  #add-products-to-order .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #1890ff15;
    border-color: #1890ff; }
  #add-products-to-order .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: #1890ff; }

@media (max-width: 768px) {
  #add-products-to-order {
    background-color: #EAEDEE; }
    #add-products-to-order .order-table {
      margin-top: 10px; }
    #add-products-to-order thead {
      display: none; }
    #add-products-to-order .ant-table-wrapper {
      margin-top: 20px; }
    #add-products-to-order .ant-table-tbody > tr > td {
      padding: 8px 16px; }
    #add-products-to-order .ant-table-tbody > tr.ant-table-row:hover > td {
      background: none; }
    #add-products-to-order .header-container {
      display: flex;
      flex-direction: row;
      background-color: white; }
      #add-products-to-order .header-container .cancel-text {
        font-size: 16px;
        font-weight: 300;
        color: #8B8D96; }
      #add-products-to-order .header-container .add-text {
        font-size: 16px;
        font-weight: 300;
        color: #BA4A00; }
    #add-products-to-order .search-container {
      margin-left: 20px;
      margin-right: 20px;
      margin-top: 20px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: row;
      justify-content: space-between; }
      #add-products-to-order .search-container .filter {
        height: 50px;
        width: 50px;
        border-radius: 8px;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center; }
        #add-products-to-order .search-container .filter i:before {
          font-size: 20px;
          position: relative;
          top: 2px; }
    #add-products-to-order .mobile-table-row {
      display: flex;
      align-items: center;
      padding: 15px;
      border-radius: 8px;
      background-color: #f5f5f5; }
      #add-products-to-order .mobile-table-row .title-text {
        color: #505157;
        font-size: 16px; }
      #add-products-to-order .mobile-table-row .subtitle-text {
        color: #505157;
        font-size: 16px;
        font-weight: 300; }
      #add-products-to-order .mobile-table-row .selected {
        color: #1890ff;
        font-weight: 400; }
    #add-products-to-order .background-white {
      background-color: white; }
    #add-products-to-order .ant-input-affix-wrapper {
      border: none;
      border-radius: 8px;
      background-color: white; }
      #add-products-to-order .ant-input-affix-wrapper input {
        font-size: 14px;
        color: #8B8D96;
        background-color: white; }
      #add-products-to-order .ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
        box-shadow: none;
        border: 1px solid #1890FF;
        background-color: #f4f9ff; }
        #add-products-to-order .ant-input-affix-wrapper.ant-input-affix-wrapper-focused input {
          background-color: transparent; } }

.product-preview img {
  max-width: 100%;
  height: auto; }
