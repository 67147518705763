.reseller-overview .table-opportunities {
  display: flex; }
  .reseller-overview .table-opportunities div {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500; }
    .reseller-overview .table-opportunities div.total {
      font-weight: normal;
      margin-right: 0px; }
    .reseller-overview .table-opportunities div.open {
      background-color: #F0EBB8;
      color: #CCA324; }
    .reseller-overview .table-opportunities div.closed {
      background-color: #D2E3CA;
      color: #4DAE1E; }
    .reseller-overview .table-opportunities div.lost {
      background-color: #E9CFCF;
      color: #AD1819; }

.reseller-overview .table-btns {
  display: flex; }

.reseller-overview .table-status {
  display: flex;
  align-items: center; }
  .reseller-overview .table-status p {
    margin: 0px; }
  .reseller-overview .table-status div {
    margin-right: 5px;
    width: 14px;
    height: 14px;
    border-radius: 50%; }
  .reseller-overview .table-status.Active div {
    background-color: #52C41A; }
  .reseller-overview .table-status.Inactive div {
    background-color: #FFC100; }
  .reseller-overview .table-status.Pending div {
    background-color: #1890FF; }
  .reseller-overview .table-status.Retired div {
    background-color: #FB4953; }

@media (max-width: 768px) {
  .reseller-overview {
    margin-top: 165px; } }

.reseller-mobile {
  margin: 10px 20px;
  display: flex;
  width: calc(100% - 40px);
  align-items: center;
  border-radius: 8px;
  height: 80px;
  padding: 8px 15px;
  justify-content: space-between; }
  .reseller-mobile:nth-of-type(even) {
    background-color: #F5F5F5; }
  .reseller-mobile p {
    margin-bottom: 0px; }
    .reseller-mobile p.bold {
      font-weight: 500 !important; }
  .reseller-mobile .value {
    margin-left: auto; }
    .reseller-mobile .value p {
      text-align: right; }
      .reseller-mobile .value p span {
        font-weight: 300;
        margin-right: 10px; }
  .reseller-mobile > span {
    margin-left: 10px; }
