label {
  color: #BA4A00;
  font-weight: 500;
  display: block;
  margin-bottom: 5px;
  font-size: 12px;
  margin-bottom: 10px; }

.ant-form-item .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #DDEFFF;
  border-color: #1890FF !important; }
  .ant-form-item .ant-checkbox-checked .ant-checkbox-inner:after {
    border-color: #1890FF; }

.ant-form-item .ant-checkbox-wrapper {
  margin-top: -20px;
  font-size: 14px;
  font-weight: normal;
  color: #505157; }

.ant-form-item .ant-input-affix-wrapper {
  border-color: transparent;
  background-color: #F5F5F5; }
  .ant-form-item .ant-input-affix-wrapper .ant-input-prefix {
    color: #505157;
    font-size: 14px !important; }
  .ant-form-item .ant-input-affix-wrapper .ant-input:focus, .ant-form-item .ant-input-affix-wrapper .ant-input {
    background-color: transparent; }

.ant-form-item .ant-form-item-label {
  padding-bottom: 0px !important; }
  .ant-form-item .ant-form-item-label > label {
    width: 100%; }
  .ant-form-item .ant-form-item-label label {
    color: #505157;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0px; }
  .ant-form-item .ant-form-item-label .form-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%; }
  .ant-form-item .ant-form-item-label label label.addon {
    font-size: 10px;
    font-weight: 300;
    color: #8B8D96; }

.ant-form-item input, .ant-form-item textarea {
  height: 50px;
  color: #505157;
  border-color: transparent;
  background-color: #F5F5F5; }

.ant-form-item.no-label .ant-form-item-label {
  display: none; }

.ant-form-item-has-error .ant-input {
  background-color: #F5F5F5 !important; }

.ant-form-item-has-error .ant-input-affix-wrapper {
  background-color: #F5F5F5 !important; }

.ant-form-item-has-error .ant-picker {
  border: 1px solid #CA0000 !important; }

.ant-input:focus, .ant-input-affix-wrapper-focused {
  box-shadow: none !important;
  border: 1px solid #1890FF;
  background-color: #F4F7FB !important;
  border-color: #1890FF !important; }

@media (max-width: 768px) {
  .ant-form-item .ant-form-item-label label {
    font-size: 14px;
    margin-bottom: 5px; }
  .ant-form-item .addon {
    font-size: 12px !important; }
  .ant-form-item input {
    height: 50px; }
  .ant-checkbox-inner {
    width: 24px !important;
    height: 24px !important; } }

.ant-checkbox-group .ant-checkbox-inner {
  background-color: transparent; }

.ant-checkbox-group .ant-checkbox-checked .ant-checkbox-inner {
  border-color: #1890FF;
  background-color: #D4E6F6; }
  .ant-checkbox-group .ant-checkbox-checked .ant-checkbox-inner:after {
    border-color: #1890FF; }

.ant-checkbox-group span {
  font-size: 14px; }

.ant-form-item .ant-select .ant-select-selector {
  height: 50px !important;
  color: #505157 !important;
  border-color: transparent !important;
  background-color: #F5F5F5 !important; }
  .ant-form-item .ant-select .ant-select-selector .ant-select-selection-item {
    padding-top: 9px; }

.split-fields {
  display: flex;
  align-items: flex-end; }
  .split-fields .ant-form-item {
    width: 50%; }
  .split-fields .ant-form-item:first-of-type .ant-input-affix-wrapper {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px; }
  .split-fields .ant-form-item:first-of-type input {
    color: #8B8D96; }
  .split-fields .ant-form-item:last-of-type .ant-input-affix-wrapper {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px; }
  .split-fields .ant-input-affix-wrapper .ant-input-prefix {
    color: #8B8D96;
    font-size: 12px !important; }
  .split-fields label {
    white-space: pre-wrap; }

.inline-fields {
  display: flex;
  align-items: flex-end; }
  .inline-fields .ant-input-affix-wrapper:first-of-type {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px; }
    .inline-fields .ant-input-affix-wrapper:first-of-type input {
      color: #8B8D96;
      font-size: 12px !important; }
  .inline-fields .ant-input-affix-wrapper:last-of-type {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px; }
  .inline-fields .ant-input-affix-wrapper .ant-input-prefix, .inline-fields .ant-input-affix-wrapper .ant-input-suffix {
    color: #8B8D96;
    font-size: 12px !important; }
  .inline-fields .ant-input-prefix {
    margin-top: 2px; }
  .inline-fields label {
    white-space: pre-wrap; }
  .inline-fields .products-overview td .ant-input-affix-wrapper.ant-input-affix-wrapper-disabled, .inline-fields .ant-input-affix-wrapper-disabled .ant-input[disabled], .inline-fields .ant-input-affix-wrapper-disabled {
    background-color: #F5F5F5 !important; }

.field-description {
  font-weight: 300;
  color: #8B8D96;
  margin-top: -20px; }
  .field-description a {
    color: #1890FF;
    text-decoration: underline; }

.field-title {
  font-size: 14px;
  font-weight: 500;
  color: #505157;
  margin-bottom: 30px; }

.right-align input {
  text-align: right;
  -moz-appearance: textfield; }
  .right-align input::-webkit-outer-spin-button, .right-align input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; }

.ant-input-affix-wrapper-disabled .ant-input[disabled], .ant-input-affix-wrapper-disabled {
  background-color: #DADADA !important; }

.ant-form-item-explain.ant-form-item-explain-error {
  margin-bottom: 20px; }
