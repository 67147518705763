.ant-btn {
  height: 36px;
  border: none;
  font-size: 14px;
  font-weight: 500; }

button span {
  pointer-events: none; }

.ant-btn.active span {
  pointer-events: unset; }

.primary-medium-1 {
  background: white;
  color: #BA4A00;
  border: 1px solid #BA4A00;
  transition: none;
  transition: all .5s;
  display: flex;
  justify-content: center;
  align-items: center; }
  .primary-medium-1:hover {
    background-color: #BA4A00;
    border: 1px solid #BA4A00;
    color: white; }
  .primary-medium-1:focus {
    background-color: black;
    border: 1px solid black;
    color: white; }

.primary-medium-2 {
  background-color: #BA4A00;
  color: #E0C7A8; }
  .primary-medium-2:hover {
    background-color: #3F0506;
    color: white; }
  .primary-medium-2:focus {
    background-color: #1B1B1B;
    color: white; }

.primary-medium-3 {
  background-color: #398CAF;
  color: white;
  height: 50px; }
  .primary-medium-3:hover {
    background-color: #27617A;
    color: white; }
  .primary-medium-3:focus {
    background-color: #1B1B1B;
    color: white; }

.save {
  background-color: transparent;
  color: #1B1B1B;
  height: 32px;
  border: 1px solid #1B1B1B; }
  .save:hover {
    background-color: #BA4A00;
    color: white; }
  .save:focus {
    background-color: #1B1B1B;
    color: white; }

.secondary-medium-1 {
  background-color: #BFBFBF;
  color: #505157;
  height: 32px; }
  .secondary-medium-1:hover {
    background-color: #BA4A00;
    color: white; }
  .secondary-medium-1:focus {
    background-color: #1B1B1B;
    color: white; }

.secondary-medium-2 {
  background-color: #DADADA;
  color: #505157;
  height: 32px; }
  .secondary-medium-2:hover {
    background-color: #BA4A00;
    color: white; }
  .secondary-medium-2:focus {
    background-color: #1B1B1B;
    color: white; }

.secondary-slim-5 {
  background-color: #EAEAEA;
  color: #505157;
  padding-top: 0;
  height: 26px; }
  .secondary-slim-5:hover {
    background-color: #BA4A00;
    color: white; }
  .secondary-slim-5:focus {
    background-color: #1B1B1B;
    color: white; }

.secondary-medium-2, .confirm-delete .ant-btn {
  background-color: #DADADA;
  color: #505157; }
  .secondary-medium-2:hover, .confirm-delete .ant-btn:hover {
    background-color: #BA4A00;
    color: white; }
  .secondary-medium-2:focus, .confirm-delete .ant-btn:focus {
    background-color: #1B1B1B;
    color: white; }

table .secondary-medium-2 {
  height: 30px;
  margin-right: 10px; }
  table .secondary-medium-2.green {
    background-color: #52C41A;
    color: white; }
  table .secondary-medium-2.blue {
    background-color: #1890FF;
    color: white; }

.delete-button, .confirm-delete .ant-btn.ant-btn-primary {
  background: #FB4953;
  color: white;
  transition: none; }
  .delete-button:hover, .confirm-delete .ant-btn.ant-btn-primary:hover {
    background-color: #fb495370;
    color: white; }
  .delete-button:focus, .confirm-delete .ant-btn.ant-btn-primary:focus {
    background-color: #B9353D;
    color: white; }
