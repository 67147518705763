.product-item {
  padding: 12px; }
  .product-item > div {
    transition: all .5s;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: none; }
    .product-item > div .img-container {
      width: 100%;
      padding-top: 58%;
      background-size: cover !important;
      background-position: center !important; }
      .product-item > div .img-container img {
        width: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0; }
    .product-item > div .info {
      background-color: white;
      padding: 10px; }
      .product-item > div .info label {
        color: #505157;
        display: block;
        font-size: 12px; }
      .product-item > div .info h3 {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 0px; }
        .product-item > div .info h3 span {
          font-size: 14px; }
      .product-item > div .info h4 {
        font-size: 10px;
        color: #B6B8BF;
        text-decoration: line-through;
        min-height: 15px;
        margin-bottom: 10px; }
        .product-item > div .info h4 span {
          color: #4E505B;
          text-decoration: none; }
      .product-item > div .info .commission {
        height: 20px;
        width: 100%;
        background: #557672;
        border-radius: 3px;
        position: relative;
        padding-left: 33px;
        font-weight: 500;
        color: white;
        line-height: 1.45; }
        .product-item > div .info .commission img {
          position: absolute;
          left: -8px;
          top: -8px; }
        .product-item > div .info .commission .get {
          opacity: .5;
          margin-right: 10px; }
        .product-item > div .info .commission .smaller {
          font-size: 10px; }
    .product-item > div .mobile-share-btn {
      width: 30px;
      height: 30px;
      position: absolute;
      border-radius: 50%;
      background: #BA4A00;
      top: 20px;
      left: 20px; }
      .product-item > div .mobile-share-btn i:before {
        color: white;
        position: relative;
        top: 6px;
        left: 9px; }
    .product-item > div.hover {
      box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.08), 0px 1px 18px rgba(0, 0, 0, 0.1), 0px 3px 5px rgba(0, 0, 0, 0.101535); }
      .product-item > div.hover .img-container {
        filter: brightness(0.5);
        -webkit-filter: brightness(0.5);
        -moz-filter: brightness(0.5);
        -o-filter: brightness(0.5);
        -ms-filter: brightness(0.5); }
      .product-item > div.hover .share-btn {
        position: absolute;
        font-size: 14px;
        color: white;
        padding: 9px 11px;
        cursor: pointer;
        background-color: #BA4A00;
        border-radius: 100px;
        z-index: 1;
        margin: 0;
        top: 25%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        transition: all .5s; }
        .product-item > div.hover .share-btn i {
          margin-right: 5px; }
          .product-item > div.hover .share-btn i:before {
            position: relative;
            top: 3px; }
        .product-item > div.hover .share-btn:hover {
          background-color: #595959; }
  .product-item.promoted > div {
    border: 2px solid #BA4A00; }
    .product-item.promoted > div h3 {
      color: #BA4A00; }
