.auth-form {
  max-width: 610px; }
  .auth-form > h5 {
    color: black;
    font-size: 20px; }
    @media (max-width: 768px) {
      .auth-form > h5 {
        font-size: 18px; } }
    .auth-form > h5 span {
      font-weight: 600; }
  .auth-form > p {
    font-weight: 300;
    font-size: 16px; }
  .auth-form .ant-form-item .ant-input-affix-wrapper .ant-input-prefix {
    font-size: 20px !important; }
  .auth-form .forgot-pass {
    margin: 20px 0px 0px 20px;
    font-size: 18px;
    color: #BA4A00;
    font-weight: 400;
    cursor: pointer; }
  .auth-form #error.zh-error {
    font-size: 19px; }
  .auth-form form, .auth-form .form {
    overflow: visible !important;
    max-width: 448px;
    background: white;
    border-radius: 24px;
    overflow: hidden;
    box-shadow: 0px 1px 20px #888888;
    position: relative; }
    .auth-form form > div > h5, .auth-form .form > div > h5 {
      color: #BA4A00;
      font-size: 24px;
      margin: 20px;
      padding-top: 20px; }
      @media (max-width: 768px) {
        .auth-form form > div > h5, .auth-form .form > div > h5 {
          font-size: 18px; } }
    .auth-form form > div > p, .auth-form .form > div > p {
      margin: 20px;
      color: #8C8C8C;
      font-weight: 300; }
      @media (max-width: 768px) {
        .auth-form form > div > p, .auth-form .form > div > p {
          font-size: 16px; } }
    .auth-form form .ant-form-item, .auth-form .form .ant-form-item {
      padding: 15px 24px;
      margin-bottom: 0px; }
      .auth-form form .ant-form-item label, .auth-form .form .ant-form-item label {
        color: #BA4A00;
        font-weight: 500;
        margin-bottom: 10px;
        font-size: 16px; }
        @media (max-width: 768px) {
          .auth-form form .ant-form-item label, .auth-form .form .ant-form-item label {
            font-size: 14px; } }
      .auth-form form .ant-form-item input, .auth-form form .ant-form-item .ant-input-affix-wrapper, .auth-form .form .ant-form-item input, .auth-form .form .ant-form-item .ant-input-affix-wrapper {
        font-size: 20px;
        font-weight: 300;
        color: #595959;
        border: none;
        background: none;
        outline-width: 0;
        box-shadow: none; }
        @media (max-width: 768px) {
          .auth-form form .ant-form-item input, .auth-form form .ant-form-item .ant-input-affix-wrapper, .auth-form .form .ant-form-item input, .auth-form .form .ant-form-item .ant-input-affix-wrapper {
            font-size: 18px; } }
      .auth-form form .ant-form-item.ant-form-item-has-error input, .auth-form .form .ant-form-item.ant-form-item-has-error input {
        color: #FB4953;
        background-color: transparent !important; }
    .auth-form form .password-box, .auth-form .form .password-box {
      position: relative; }
      .auth-form form .password-box span, .auth-form .form .password-box span {
        position: absolute;
        right: 36px;
        top: 73px;
        color: #398CAF;
        font-weight: 300;
        cursor: pointer; }
        @media (max-width: 768px) {
          .auth-form form .password-box span, .auth-form .form .password-box span {
            top: 70px; } }
    .auth-form form .separator, .auth-form .form .separator {
      background: #DADADA;
      height: 1px;
      width: 100%; }
    .auth-form form button, .auth-form .form button {
      color: #E0C7A8;
      font-weight: 300;
      font-size: 24px;
      padding: 0px 24px;
      width: 100%;
      height: 80px;
      text-align: left;
      background-color: #BA4A00;
      border-radius: 0px;
      border-bottom-left-radius: 24px;
      border-bottom-right-radius: 24px; }
      .auth-form form button:hover, .auth-form .form button:hover {
        background-color: #410506;
        color: #E0C7A8; }
      .auth-form form button:focus, .auth-form .form button:focus {
        background-color: #1B1B1B;
        color: #E0C7A8; }
      .auth-form form button.with-terms, .auth-form .form button.with-terms {
        padding-top: 20px;
        height: 106px; }
        @media (max-width: 768px) {
          .auth-form form button.with-terms, .auth-form .form button.with-terms {
            padding-top: 42px; } }
      .auth-form form button .anticon, .auth-form .form button .anticon {
        float: right;
        margin-top: 5px; }
      .auth-form form button #terms-text, .auth-form .form button #terms-text {
        position: absolute;
        font-size: 12px;
        color: white;
        opacity: .5;
        top: 20px; }
        .auth-form form button #terms-text a, .auth-form .form button #terms-text a {
          color: white;
          text-decoration: underline; }
      @media (max-width: 768px) {
        .auth-form form button, .auth-form .form button {
          font-size: 20px;
          top: 12px;
          white-space: break-spaces; } }
    .auth-form form #error, .auth-form .form #error {
      background-color: #FB4953;
      font-weight: 500;
      font-size: 12px;
      color: white;
      padding: 0px 3px;
      margin-left: 10px;
      position: absolute;
      left: 15px;
      bottom: -90px;
      border-radius: 2px; }
      @media (max-width: 768px) {
        .auth-form form #error, .auth-form .form #error {
          font-size: 14px;
          margin-left: -10px;
          bottom: -50px; } }
    .auth-form form .info, .auth-form .form .info {
      color: #8C8C8C;
      font-weight: 300;
      font-size: 12px !important;
      margin-left: 24px;
      margin-bottom: 0px !important;
      margin-top: 0px !important; }
      .auth-form form .info.marginbottom, .auth-form .form .info.marginbottom {
        margin-bottom: 20px !important; }
      @media (max-width: 768px) {
        .auth-form form .info, .auth-form .form .info {
          margin-bottom: 0px; } }
    .auth-form form .ant-form-item-explain, .auth-form .form .ant-form-item-explain {
      font-size: 12px;
      font-weight: 500;
      text-transform: uppercase;
      margin-top: 10px;
      background-color: #FEEDEE;
      padding: 1px 6px;
      border-radius: 5px;
      min-height: 17px;
      color: #FB4953;
      width: fit-content; }
      @media (max-width: 768px) {
        .auth-form form .ant-form-item-explain, .auth-form .form .ant-form-item-explain {
          background-color: transparent; } }
  @media (max-width: 768px) {
    .auth-form {
      padding-bottom: 60px; } }
