.payout-modal .ant-modal-content {
  overflow: hidden; }

.payout-modal .buttons {
  display: flex;
  padding: 25px;
  background: #E6EBEC;
  margin-left: -24px;
  margin-bottom: -24px;
  width: calc(100% + 48px); }

.payout-modal .choose-payout .inline {
  display: flex; }
  .payout-modal .choose-payout .inline .commission-amount {
    width: 298px;
    height: 88px;
    border: 1px solid #505157;
    border-radius: 10px;
    padding: 13px 15px; }
    .payout-modal .choose-payout .inline .commission-amount p {
      color: #BA4A00;
      font-weight: 500;
      font-family: Poppins;
      margin-bottom: 0px; }
    .payout-modal .choose-payout .inline .commission-amount .amount {
      font-weight: 600;
      font-family: Poppins;
      color: black;
      font-size: 30px; }
      .payout-modal .choose-payout .inline .commission-amount .amount span {
        font-size: 20px; }
  .payout-modal .choose-payout .inline img {
    margin-left: 20px;
    width: 85px;
    height: auto;
    object-fit: cover; }

.payout-modal .choose-payout .text {
  font-family: Montserrat;
  font-weight: 300;
  font-size: 16px;
  color: #8C8C8C;
  margin-top: 20px; }

.payout-modal .choose-payout .buttons .button {
  flex: 1;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 15px;
  cursor: pointer; }
  .payout-modal .choose-payout .buttons .button label {
    font-family: Poppins;
    font-weight: 500;
    font-size: 10px;
    margin-bottom: 5px; }
  .payout-modal .choose-payout .buttons .button h3 {
    font-family: Poppins;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 5px; }
  .payout-modal .choose-payout .buttons .button p {
    margin-bottom: 0px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 0px; }
  .payout-modal .choose-payout .buttons .button.auto {
    margin-right: 10px;
    background-color: #BA4A00; }
    .payout-modal .choose-payout .buttons .button.auto label {
      color: white;
      opacity: .4; }
    .payout-modal .choose-payout .buttons .button.auto h3 {
      color: #F6E9D9; }
    .payout-modal .choose-payout .buttons .button.auto p {
      color: white; }
  .payout-modal .choose-payout .buttons .button.manual {
    margin-left: 10px;
    background-color: white; }
    .payout-modal .choose-payout .buttons .button.manual label {
      color: #8C8C8C; }
    .payout-modal .choose-payout .buttons .button.manual h3 {
      color: black; }
    .payout-modal .choose-payout .buttons .button.manual p {
      color: #BA4A00; }

@media (max-width: 768px) {
  .payout-modal .choose-payout img {
    display: none; }
  .payout-modal .choose-payout .text {
    font-size: 14px; }
  .payout-modal .choose-payout .buttons {
    display: block; }
    .payout-modal .choose-payout .buttons .button {
      margin-left: 0px !important;
      margin-right: 0px !important; }
      .payout-modal .choose-payout .buttons .button.auto {
        margin-bottom: 20px; } }

.payout-modal .bankdetails .back {
  color: #BA4A00;
  font-weight: 500;
  margin-bottom: 40px;
  cursor: pointer; }

.payout-modal .bankdetails h1 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0px; }

.payout-modal .bankdetails p {
  font-weight: 300;
  font-size: 16px;
  color: #8C8C8C; }

.payout-modal .bankdetails .account {
  padding-right: 10px; }

.payout-modal .bankdetails .accountolder {
  padding-left: 10px; }

.payout-modal .bankdetails .buttons button {
  margin-left: auto; }

.payout-modal .stripe-success .anticon-check-circle {
  margin: 0 auto;
  display: block;
  margin-bottom: 20px; }
  .payout-modal .stripe-success .anticon-check-circle svg {
    color: #89C763;
    font-size: 100px; }

.payout-modal .stripe-success p {
  font-weight: 300;
  font-size: 16px;
  color: #8C8C8C;
  text-align: center; }

.payout-modal .stripe-success button {
  height: 50px;
  border-radius: 8px;
  background: #DADADA;
  color: black;
  width: 100%; }

.no-x .ant-modal-close, .no-x .ant-drawer-close {
  display: none; }
