.notification {
  overflow: visible;
  border: 2px solid #BA4A00; }
  .notification img {
    width: 87px;
    margin-left: -15px;
    margin-top: -41px; }
  .notification .ant-notification-notice-description {
    margin-left: 90px;
    font-weight: 300;
    padding-right: 20px; }
    .notification .ant-notification-notice-description p {
      margin-bottom: 0px; }
      .notification .ant-notification-notice-description p span {
        font-weight: 500; }
  .notification .ant-notification-close-x {
    color: #BA4A00;
    margin-top: 3px;
    display: block; }
