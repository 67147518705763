.contact {
  position: absolute;
  right: 40px;
  bottom: 35px;
  color: white;
  font-size: 14px;
  font-weight: 500; }
  .contact > div {
    display: flex; }
  .contact .top div:first-of-type {
    background-color: #1B1B1B;
    padding: 5px 10px; }
  .contact .top .help {
    width: 149px; }
  .contact .top .whatsapp, .contact .top .wechat {
    height: 33px;
    width: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: white; }
  .contact .top .whatsapp {
    background-color: #23CD64 !important;
    margin-right: 4px; }
  .contact .top .wechat {
    background-color: #78AD2C; }
  .contact .bottom {
    background-color: #505157;
    width: 189px;
    align-items: center;
    justify-content: space-between;
    display: flex;
    padding: 5px 10px;
    margin-top: 5px;
    cursor: pointer;
    color: white; }
    .contact .bottom p {
      margin: 0px; }
  .contact div {
    border-radius: 4px; }
  .contact svg {
    font-size: 20px; }
  @media (max-width: 768px) {
    .contact {
      position: unset;
      right: unset;
      bottom: unset;
      margin-top: 40px;
      padding-bottom: 20px; }
      .contact.absolute {
        position: absolute;
        bottom: 20px; } }
