.contact-form {
  margin-top: 30px; }
  .contact-form .terms {
    margin-top: 40px;
    font-weight: 300;
    color: #505157;
    text-decoration: underline;
    cursor: pointer; }
  .contact-form .ant-form-item:last-of-type {
    border: 2px solid #BA4A00;
    background-color: #EADBDD;
    border-radius: 4px;
    padding: 20px; }
    .contact-form .ant-form-item:last-of-type .ant-checkbox-wrapper {
      margin-top: 0px; }
    .contact-form .ant-form-item:last-of-type .ant-form-item-label {
      display: none; }
    .contact-form .ant-form-item:last-of-type .ant-form-item-explain {
      margin-bottom: 0px; }
