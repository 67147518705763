.statusbar {
  display: flex; }
  .statusbar > div {
    height: 40px;
    width: 235px;
    background-color: white;
    border: 1px solid white;
    padding: 7px 0px 0px 20px;
    cursor: pointer; }
    .statusbar > div:first-of-type {
      margin-right: 5px;
      border-radius: 20px 0px 0px 20px; }
    .statusbar > div:last-of-type {
      border-radius: 0px 20px 20px 0px; }
    .statusbar > div.active {
      background-color: #D6EBFF;
      color: #1890FF;
      border-color: #1890FF; }
