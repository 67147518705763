.basic .earnings {
  display: flex;
  width: 100%; }
  .basic .earnings .earnings-info {
    margin-right: 40px; }

.basic .search {
  display: flex;
  align-items: center;
  margin-top: 40px; }
  .basic .search .ant-input-affix-wrapper {
    width: 465px;
    margin-right: 15px;
    border-radius: 10px;
    border-color: white; }
    .basic .search .ant-input-affix-wrapper input {
      font-size: 16px; }
    .basic .search .ant-input-affix-wrapper svg {
      font-size: 18px;
      color: #8C8C8C;
      margin-right: 10px; }
  .basic .search > div {
    background: #FFFFFF;
    border-radius: 10px;
    height: 50px;
    padding: 14px; }
    .basic .search > div .ant-checkbox-wrapper-checked .ant-checkbox-inner {
      background: rgba(24, 144, 255, 0.15);
      border-color: #1890FF; }
      .basic .search > div .ant-checkbox-wrapper-checked .ant-checkbox-inner::after {
        border-color: #1890FF; }
    .basic .search > div .ant-checkbox-wrapper-checked span {
      color: #1890FF; }

.basic .product-list {
  margin: 0px -12px;
  margin-top: 20px; }

@media screen and (max-width: 768px) {
  .basic {
    background-color: #EAEDEE !important;
    padding: 20px !important; }
    .basic .earnings {
      display: unset; }
    .basic .search .ant-input-affix-wrapper {
      width: 44px;
      transition: all .3s; }
      .basic .search .ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
        width: 100%; }
    .basic .search .ant-checkbox-wrapper.small > span:not(.ant-checkbox) {
      display: none; } }
