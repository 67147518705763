#promotions .ant-col-md-18 {
  padding-left: 20px; }

#promotions .promotion-details {
  height: calc(100vh - 231px);
  overflow: scroll; }

#promotions .promotion-id {
  margin-top: 20px;
  color: #BA4A00;
  height: 22px; }

#promotions .period {
  border-radius: 4px;
  display: flex;
  align-items: flex-end; }
  #promotions .period > div {
    width: 50%; }
  #promotions .period .ant-form-item-explain {
    display: none; }
  #promotions .period .ant-form-item-has-error .ant-picker {
    border: 1px solid #CA0000 !important; }
  #promotions .period .ant-form-item {
    margin-bottom: 0px; }
  #promotions .period .ant-picker {
    border: none;
    background-color: #F5F5F5;
    height: 50px;
    width: 100%; }
  #promotions .period .from-date .ant-picker {
    border-radius: 4px 0px 0px 4px; }
  #promotions .period .to-date .ant-picker {
    border-radius: 0px 4px 4px 0px; }
  #promotions .period .separator-container {
    background-color: #F5F5F5; }
    #promotions .period .separator-container .separator {
      width: 80%;
      height: 1px;
      background-color: #505157;
      margin: 0 auto;
      position: relative;
      top: -1px; }

#promotions .promotion-image {
  margin-top: 20px; }
  #promotions .promotion-image .labels {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px; }
    #promotions .promotion-image .labels p:first-of-type {
      font-size: 14px;
      font-weight: 500;
      margin: 0px;
      color: #505157; }
    #promotions .promotion-image .labels p:last-of-type {
      font-size: 10px;
      font-weight: 300;
      color: #8B8D96;
      margin: 0px; }
  #promotions .promotion-image .image-container-placeholder {
    width: 100%;
    height: 150px;
    background-color: #DADADA;
    border-radius: 4px;
    border: 10px solid #F5F5F5; }
    #promotions .promotion-image .image-container-placeholder img {
      object-fit: contain;
      height: 100%;
      margin: 0 auto;
      display: block;
      width: 100%; }
    #promotions .promotion-image .image-container-placeholder.has-image {
      border: none;
      background-color: white; }
  #promotions .promotion-image .ant-upload {
    width: 100%; }
    #promotions .promotion-image .ant-upload p {
      color: #8B8D96;
      font-weight: 500;
      margin-top: 10px;
      margin-bottom: 0px;
      cursor: pointer; }
    #promotions .promotion-image .ant-upload > div {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap; }

@media (max-width: 768px) {
  #promotions {
    padding-right: 0px; }
    #promotions .ant-col-md-18 {
      padding-left: 0px !important; }
    #promotions .ant-upload {
      margin-bottom: 20px; }
    #promotions .product-image .labels p:last-of-type {
      font-size: 12px; }
    #promotions .status-selector {
      background-color: #EAEDEE;
      padding: 20px;
      margin-top: -20px; }
      #promotions .status-selector .status {
        height: 14px;
        width: 14px;
        border-radius: 50%;
        margin-right: 20px; }
        #promotions .status-selector .status.Inactive {
          background-color: #FFC100; }
        #promotions .status-selector .status.Active {
          background-color: #52C41A; }
      #promotions .status-selector > div {
        background-color: white;
        height: 50px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        padding: 20px;
        justify-content: left;
        color: #505157;
        cursor: pointer; }
        #promotions .status-selector > div .anticon {
          margin-left: auto; }
        #promotions .status-selector > div svg {
          color: #8C8C8C; }
    #promotions .ant-radio-group.ant-radio-group-solid.section-selector {
      width: 100%;
      padding: 6px;
      background-color: white;
      border-radius: 8px;
      margin-top: 20px; }
      #promotions .ant-radio-group.ant-radio-group-solid.section-selector > label {
        width: 50%;
        height: 38px;
        padding-top: 4px;
        text-align: center;
        border: none;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
        border-color: transparent;
        border-width: 0px; }
        #promotions .ant-radio-group.ant-radio-group-solid.section-selector > label:before {
          display: none; }
        #promotions .ant-radio-group.ant-radio-group-solid.section-selector > label.ant-radio-button-wrapper-checked {
          background-color: #BA4A00 !important; }
    #promotions .period {
      display: block !important; }
      #promotions .period > div {
        width: 100%; }
      #promotions .period .from-date .ant-picker {
        border-radius: 4px 4px 0px 0px !important; }
      #promotions .period .to-date .ant-form-item-label {
        display: none; }
      #promotions .period .to-date .ant-picker {
        border-radius: 0px 0px 4px 4px !important; } }
