#add-customer .ant-tabs-nav::before {
  display: none; }

#add-customer .ant-tabs-nav .ant-tabs-tab {
  padding: 0px; }

#add-customer .ant-tabs-nav .ant-tabs-tab-btn {
  text-transform: uppercase;
  font-weight: 600;
  color: #8B8D96; }

#add-customer .ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: black; }

#add-customer .ant-tabs-nav .ant-tabs-ink-bar {
  background-color: black; }

@media (max-width: 768px) {
  #add-customer .ant-tabs-nav {
    display: none; } }

@media (max-width: 768px) {
  #add-customer .ant-tabs h5 {
    display: none; }
  #add-customer .ant-tabs #rc-tabs-0-panel-plot .ant-card-body > .ant-row > .ant-col {
    padding-left: 0px !important;
    padding-right: 0px !important; } }

#add-customer .select-tab-mobile {
  background: #EAEDEE;
  padding: 20px 20px 10px 20px;
  margin-top: -2px; }
  #add-customer .select-tab-mobile > div {
    background-color: white;
    height: 50px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 20px;
    justify-content: left;
    color: #505157;
    cursor: pointer; }
    #add-customer .select-tab-mobile > div .anticon {
      margin-left: auto; }
    #add-customer .select-tab-mobile > div svg {
      color: #8C8C8C; }

#add-customer h5 {
  text-transform: uppercase;
  color: #BA4A00;
  font-size: 12px;
  margin-top: 20px; }

#add-customer .separator {
  height: 1px;
  width: 100%;
  background: #BFBFBF;
  position: relative;
  bottom: 1px;
  margin: 20px 0px 30px 0px; }
  #add-customer .separator.family {
    margin-top: 20px; }
  @media (max-width: 768px) {
    #add-customer .separator {
      width: calc(100% - 40px);
      margin: -10px 20px 0px 20px; } }

#add-customer .customer-numbers input {
  height: 40px; }

#add-customer .customer-numbers .ant-col {
  padding-right: 20px; }

@media (max-width: 768px) {
  #add-customer .customer-numbers {
    margin-top: -20px !important;
    background: #EAEDEE;
    padding: 20px 0px;
    margin: 0px; }
    #add-customer .customer-numbers > .ant-col {
      padding-top: 0px !important;
      padding-bottom: 0px !important; }
      #add-customer .customer-numbers > .ant-col:last-of-type {
        margin-bottom: 10px; }
    #add-customer .customer-numbers .form-label label {
      margin-bottom: -6px;
      display: block; } }

#add-customer .ant-picker {
  border: none;
  background-color: #F5F5F5;
  height: 50px;
  width: 100%; }

#add-customer input {
  height: 50px; }

#add-customer .no-label {
  margin-top: -15px !important; }

#add-customer .ant-row {
  margin: 0px -10px; }
  @media (max-width: 768px) {
    #add-customer .ant-row {
      margin: 0px; } }
  #add-customer .ant-row .ant-col {
    padding: 10px; }

#add-customer .ant-card-body {
  padding: 10px 20px 30px 20px; }
  @media (max-width: 768px) {
    #add-customer .ant-card-body {
      padding: 10px 0px 10px 0px; } }

#add-customer .location-fields {
  display: flex;
  margin: 0px -5px; }
  #add-customer .location-fields > div {
    display: block;
    padding: 0px 5px; }
    #add-customer .location-fields > div .addon {
      display: none; }

#add-customer .ant-form-item-explain {
  display: none; }

#add-customer .family-member {
  position: relative;
  padding-bottom: 10px; }

#add-customer .remove {
  font-weight: 600;
  text-decoration: underline;
  color: #BA4A00;
  position: absolute;
  bottom: -30px;
  right: 10px;
  cursor: pointer; }
  @media (max-width: 768px) {
    #add-customer .remove {
      position: unset;
      text-align: right;
      margin-top: 10px; } }

#add-customer .add-family-btn {
  position: absolute;
  right: 70px;
  margin-top: -45px; }
  @media (max-width: 1030px) {
    #add-customer .add-family-btn {
      position: unset;
      right: unset;
      margin-top: unset;
      margin-bottom: 20px; } }
  @media (max-width: 768px) {
    #add-customer .add-family-btn {
      margin-top: 20px;
      width: 100%; } }

#add-customer .add-family-section {
  height: 120px;
  border-radius: 10px;
  background: #ba4a001a;
  display: flex;
  align-items: center;
  margin-top: 20px;
  justify-content: center; }
  @media (max-width: 768px) {
    #add-customer .add-family-section {
      width: calc(100% - 40px);
      margin: 20px 0px 20px 20px; } }

#add-customer .document-ids {
  display: flex;
  border-bottom: 1px solid #BFBFBF;
  margin-bottom: 20px;
  padding: 0px 20px 10px 20px;
  margin-left: -20px;
  width: calc(100% + 40px); }
  #add-customer .document-ids > .ant-row {
    margin-right: 20px; }
  @media (max-width: 768px) {
    #add-customer .document-ids {
      display: block;
      border-bottom: none; }
      #add-customer .document-ids > .ant-row {
        margin-right: 0px; } }

#add-customer .file-row {
  display: flex;
  margin-bottom: 20px; }
  #add-customer .file-row button {
    height: 64px;
    width: 64px;
    margin-left: 16px; }
    #add-customer .file-row button svg {
      font-size: 36px; }
  #add-customer .file-row .preview {
    display: flex;
    align-items: center;
    height: 64px;
    border-radius: 4px;
    background-color: #F5F5F5;
    overflow: hidden;
    width: 100%;
    flex: 1; }
    #add-customer .file-row .preview p {
      font-weight: 600;
      color: #505157;
      margin-bottom: 0px;
      margin-left: 20px; }
    #add-customer .file-row .preview .image {
      width: 64px;
      height: 64px;
      background-color: #8C8C8C;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative; }
      #add-customer .file-row .preview .image img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 1;
        transition: .5s ease; }
      #add-customer .file-row .preview .image .anticon-eye {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%); }
        #add-customer .file-row .preview .image .anticon-eye svg {
          font-size: 28px;
          color: white;
          transition: .5s ease;
          opacity: 0;
          transition: .5s ease; }
      #add-customer .file-row .preview .image .anticon-upload svg {
        font-size: 28px;
        color: #505157; }
  #add-customer .file-row.has-file .image {
    cursor: pointer; }
    #add-customer .file-row.has-file .image:hover {
      background-color: #ba4a0080; }
      #add-customer .file-row.has-file .image:hover img {
        opacity: 0.2; }
      #add-customer .file-row.has-file .image:hover .anticon-eye svg {
        opacity: 1; }
  @media (max-width: 768px) {
    #add-customer .file-row .preview {
      height: 55px; }
    #add-customer .file-row button {
      margin-left: 10px;
      height: 55px;
      width: 55px; }
      #add-customer .file-row button svg {
        font-size: 26px; }
    #add-customer .file-row .image {
      width: 55px !important;
      height: 55px !important;
      min-width: 55px; } }
  #add-customer .file-row:first-of-type {
    margin-top: 10px; }
  #add-customer .file-row.small {
    margin-bottom: 10px; }
    #add-customer .file-row.small .preview {
      height: 50px; }
      #add-customer .file-row.small .preview.active p {
        color: #BA4A00;
        text-decoration: underline;
        cursor: pointer; }
      #add-customer .file-row.small .preview.active img {
        margin-left: 10px; }
    #add-customer .file-row.small button {
      height: 50px;
      width: 50px; }
      #add-customer .file-row.small button svg {
        font-size: 26px;
        position: relative;
        right: 3px; }

#add-customer .ant-upload.dragger {
  border: none;
  background-color: white;
  height: auto;
  margin-bottom: 10px; }
  #add-customer .ant-upload.dragger h4 {
    color: #BA4A00;
    font-weight: bold;
    font-size: 16px;
    text-transform: uppercase; }
  #add-customer .ant-upload.dragger .ant-upload-btn {
    padding: 0px;
    height: 212px;
    background-color: #F5F5F5; }
    #add-customer .ant-upload.dragger .ant-upload-btn svg {
      color: #8B8D96;
      margin-bottom: 20px; }
    #add-customer .ant-upload.dragger .ant-upload-btn p {
      color: #8B8D96;
      font-size: 14px; }
      #add-customer .ant-upload.dragger .ant-upload-btn p.underline {
        text-decoration: underline; }

#add-customer .upload-more button, #add-customer .upload-more .ant-upload {
  width: 100%; }

@media (min-width: 768px) {
  #add-customer .header-bar {
    margin-bottom: 10px; } }
