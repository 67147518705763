.payment label.bold {
  margin-top: 20px;
  font-size: 16px;
  font-weight: 500;
  color: black; }

.payment label.normal-label {
  color: #505157;
  font-weight: 400;
  margin-top: 30px; }

.payment .explanation {
  margin-top: 60px;
  margin-bottom: 30px; }

.payment .ant-collapse-header {
  font-size: 16px;
  display: table; }
  .payment .ant-collapse-header .anticon-right {
    top: 19px !important; }

.payment .ant-collapse {
  margin-bottom: 40px; }

.payment .ant-collapse .option-header {
  background-color: #F5F5F5 !important; }
  .payment .ant-collapse .option-header.active {
    background-color: #F8E6D0 !important; }

.payment .ant-radio-group {
  width: 100%; }
  .payment .ant-radio-group .ant-radio-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    border: 2px solid #F5F5F5;
    background: #F5F5F5;
    border-radius: 4px;
    margin-bottom: 15px;
    border-radius: 4px;
    padding: 20px 25px;
    box-shadow: 1px 3px 4px rgba(131, 86, 86, 0.15); }
    .payment .ant-radio-group .ant-radio-wrapper .ant-radio-inner {
      width: 20px;
      height: 20px;
      border-color: #BA4A00; }
      .payment .ant-radio-group .ant-radio-wrapper .ant-radio-inner:after {
        top: 4px;
        left: 4px;
        width: 10px;
        height: 10px;
        background-color: #BA4A00; }
    .payment .ant-radio-group .ant-radio-wrapper span:nth-of-type(2) {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: flex-end;
      padding-left: 20px; }
      .payment .ant-radio-group .ant-radio-wrapper span:nth-of-type(2) p {
        margin: 0px;
        font-weight: 300; }
        .payment .ant-radio-group .ant-radio-wrapper span:nth-of-type(2) p.bolder {
          font-weight: 400; }
      .payment .ant-radio-group .ant-radio-wrapper span:nth-of-type(2) div:nth-of-type(2) p {
        text-align: right; }
    .payment .ant-radio-group .ant-radio-wrapper.ant-radio-wrapper-checked {
      background: #EADBDD;
      border: 2px solid #BA4A00; }

.payment .payment-options .option-header {
  color: #1B1B1B;
  cursor: pointer;
  line-height: initial;
  font-size: 14px;
  display: flex;
  padding: 10px 10px 10px 61px;
  align-items: center;
  border-radius: 4px 4px 0px 0px;
  background-color: #F8E6D0;
  border-bottom: none !important;
  box-shadow: 1px 3px 4px rgba(131, 86, 86, 0.15); }
  .payment .payment-options .option-header.active {
    border: 2px solid #BA4A00; }
  .payment .payment-options .option-header p {
    margin-bottom: 0px;
    margin-left: 10px; }

.payment .payment-options .ant-radio-wrapper {
  border-radius: 0px 0px 4px 4px;
  border-top: none !important; }
  .payment .payment-options .ant-radio-wrapper span:nth-of-type(2) {
    display: block; }
    .payment .payment-options .ant-radio-wrapper span:nth-of-type(2) p {
      text-align: left !important;
      white-space: break-spaces; }
      .payment .payment-options .ant-radio-wrapper span:nth-of-type(2) p.bolder {
        margin-top: 20px; }

.payment .summary {
  border-radius: 4px;
  padding: 20px;
  box-shadow: 1px 3px 4px rgba(131, 86, 86, 0.15);
  background-color: #F5F5F5;
  margin-bottom: 10px; }
  .payment .summary p {
    margin-bottom: 0px; }
    .payment .summary p.bolder {
      font-weight: 400; }

.payment .pay-btn {
  width: calc(100% + 40px);
  margin-left: -20px;
  position: relative;
  top: 20px;
  height: 60px;
  color: white; }

.payment .contact-details {
  width: 300px;
  margin: 0 auto; }

@media screen and (max-width: 768px) {
  .payment .ant-radio-group .ant-radio-wrapper span:nth-of-type(2) {
    display: block; }
    .payment .ant-radio-group .ant-radio-wrapper span:nth-of-type(2) p {
      text-align: left !important;
      margin-bottom: 5px;
      white-space: break-spaces; }
  .payment .pay-btn {
    top: unset;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    border-radius: 0px;
    margin-left: 0px; } }
