.go-advanced-modal .ant-modal-content {
  overflow: hidden;
  border-radius: 10px; }

.go-advanced-modal .ant-modal-close {
  display: none; }

.go-advanced-modal .ant-modal-body {
  overflow: hidden;
  padding: 0px; }
  .go-advanced-modal .ant-modal-body img {
    width: 100%; }
  .go-advanced-modal .ant-modal-body .padding {
    padding: 10px; }
    .go-advanced-modal .ant-modal-body .padding p {
      color: #505157;
      font-weight: 300;
      font-size: 16px;
      line-height: 22px; }
    .go-advanced-modal .ant-modal-body .padding button {
      width: 100%;
      height: 50px;
      margin-top: 10px; }
      .go-advanced-modal .ant-modal-body .padding button.learn {
        background-color: #BA4A00;
        color: white;
        margin-top: 0px; }
      .go-advanced-modal .ant-modal-body .padding button.goadvanced {
        background-color: #505157;
        color: white; }
      .go-advanced-modal .ant-modal-body .padding button.cancel {
        background-color: #DADADA;
        color: #505157; }
