#edit-product-mobile .header-container {
  display: flex;
  flex-direction: row;
  background-color: white;
  justify-content: space-between; }
  #edit-product-mobile .header-container .cancel-text {
    font-size: 16px;
    font-weight: 300;
    color: #8B8D96; }
  #edit-product-mobile .header-container .add-text {
    font-size: 16px;
    font-weight: 300;
    color: #BA4A00; }

#edit-product-mobile .divider {
  height: 1px;
  background-color: #BFBFBF;
  margin-top: 10px;
  margin-bottom: 10px; }

#edit-product-mobile .title {
  font-size: 16px;
  font-weight: 500;
  color: #505157;
  margin-bottom: 30px; }

#edit-product-mobile .subtitle {
  margin-top: 15px;
  margin-bottom: 5px;
  font-size: 14px;
  color: #505157; }

#edit-product-mobile .ant-input-affix-wrapper {
  border: none;
  border-radius: 8px;
  background-color: #f5f5f5; }
  #edit-product-mobile .ant-input-affix-wrapper input {
    font-size: 16px;
    color: #8B8D96;
    background-color: #f5f5f5; }
  #edit-product-mobile .ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
    box-shadow: none;
    border: 1px solid #1890FF;
    background-color: #f4f9ff; }
    #edit-product-mobile .ant-input-affix-wrapper.ant-input-affix-wrapper-focused input {
      background-color: transparent; }
