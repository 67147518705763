.order-details .ant-drawer-body {
  background-color: #EAEDEE; }
  .order-details .ant-drawer-body .header-container {
    height: 82px;
    background-color: #d7dbdd;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% + 48px);
    margin-left: -24px;
    padding: 0px 24px;
    margin-top: -24px; }
    .order-details .ant-drawer-body .header-container > div {
      display: flex;
      align-items: center;
      justify-content: center; }
    .order-details .ant-drawer-body .header-container .title, .order-details .ant-drawer-body .header-container .sub-title {
      font-size: 18px;
      font-weight: 600;
      color: #BA4A00;
      margin-left: 15px; }
    .order-details .ant-drawer-body .header-container .sub-title {
      font-weight: 300 !important; }
    .order-details .ant-drawer-body .header-container button {
      background-color: #BFBFBF;
      margin-left: auto; }
      .order-details .ant-drawer-body .header-container button:hover {
        background-color: #BA4A00; }
    .order-details .ant-drawer-body .header-container .icon {
      height: 40px;
      width: 40px;
      border-radius: 10px;
      background: linear-gradient(135deg, #BA4A00 0%, #490708 100%);
      display: flex;
      align-items: center;
      justify-content: center; }
    .order-details .ant-drawer-body .header-container i:before {
      font-size: 20px;
      color: white; }
  .order-details .ant-drawer-body .actions {
    position: relative;
    padding: 20px 0px;
    display: flex;
    align-items: center; }
    .order-details .ant-drawer-body .actions p {
      font-style: italic;
      position: relative;
      top: 8px; }
    .order-details .ant-drawer-body .actions .buttons {
      margin-left: auto;
      display: flex; }
      .order-details .ant-drawer-body .actions .buttons .forfeit-btn {
        background-color: #505157;
        color: white;
        margin-right: 20px; }
        .order-details .ant-drawer-body .actions .buttons .forfeit-btn:hover {
          background-color: #1B1B1B; }
      .order-details .ant-drawer-body .actions .buttons button {
        height: 40px;
        margin-left: 20px; }
        .order-details .ant-drawer-body .actions .buttons button i:before {
          font-size: 12px;
          margin-right: 10px; }
  .order-details .ant-drawer-body .clear {
    font-weight: 500;
    color: #398CAF;
    white-space: nowrap;
    margin-bottom: 0px;
    margin-right: 20px;
    cursor: pointer; }
  .order-details .ant-drawer-body table td .ant-btn {
    margin-left: auto;
    display: block; }
  .order-details .ant-drawer-body table td {
    border-radius: 0px !important; }
  .order-details .ant-drawer-body table .ant-btn[disabled] {
    background-color: #DADADA;
    color: #F5F5F5; }
  .order-details .ant-drawer-body table .ant-checkbox-disabled .ant-checkbox-inner {
    background-color: #BFBFBF;
    border-color: #BFBFBF !important; }
  .order-details .ant-drawer-body table .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #D7E9F9;
    border-color: #1890FF; }
    .order-details .ant-drawer-body table .ant-checkbox-checked .ant-checkbox-inner:after {
      border-color: #1890FF; }
  .order-details .ant-drawer-body table .ant-table-row-selected td {
    color: #1890FF; }
  .order-details .ant-drawer-body table .ant-table-row-selected p {
    color: #595959; }
  .order-details .ant-drawer-body .status-column {
    display: flex;
    align-items: center; }
    .order-details .ant-drawer-body .status-column p {
      margin: 0px; }
    .order-details .ant-drawer-body .status-column .dot {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      margin-right: 10px; }
      .order-details .ant-drawer-body .status-column .dot.Cleared {
        background-color: #52C41A; }
      .order-details .ant-drawer-body .status-column .dot.Due, .order-details .ant-drawer-body .status-column .dot.Paused {
        background-color: #FFC100; }
      .order-details .ant-drawer-body .status-column .dot.Late {
        background-color: #FB4953; }
      .order-details .ant-drawer-body .status-column .dot.Cancelled {
        background-color: #1B1B1B; }
  .order-details .ant-drawer-body thead .ant-table-selection {
    display: none; }

.filters-bar-instalments {
  width: 100vw;
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  background-color: white;
  z-index: 99; }
  .filters-bar-instalments .buttons {
    display: flex;
    padding: 20px; }
    .filters-bar-instalments .buttons p {
      margin: 0px;
      font-size: 16px;
      font-weight: 300; }
      .filters-bar-instalments .buttons p.cancel {
        color: #8B8D96; }
      .filters-bar-instalments .buttons p.forfeit {
        margin-left: auto;
        color: #505157; }
      .filters-bar-instalments .buttons p.fulfil {
        color: #BA4A00;
        margin-left: 20px; }
  .filters-bar-instalments .inline {
    padding: 20px;
    background-color: #EAEDEE;
    display: flex;
    width: 100%; }
    .filters-bar-instalments .inline:last-of-type {
      margin-bottom: 0px; }
    .filters-bar-instalments .inline button {
      height: 50px;
      min-width: 50px; }
      .filters-bar-instalments .inline button svg {
        font-size: 20px; }
    .filters-bar-instalments .inline .filters {
      min-width: 50px;
      min-height: 50px;
      background-color: white;
      border-radius: 8px;
      margin-left: 20px;
      display: flex;
      align-items: center;
      justify-content: center; }
      .filters-bar-instalments .inline .filters i:before {
        font-size: 20px;
        color: #505157;
        position: relative;
        top: 2px; }
  .filters-bar-instalments .instalment-items {
    height: calc(100vh - 156px);
    overflow: scroll; }
    .filters-bar-instalments .instalment-items .istalment-mobile {
      margin: 10px 20px;
      display: flex;
      width: calc(100% - 40px);
      align-items: center;
      border-radius: 8px;
      height: 80px;
      padding: 8px 15px;
      align-items: center; }
      .filters-bar-instalments .instalment-items .istalment-mobile p {
        margin: 0px; }
      .filters-bar-instalments .instalment-items .istalment-mobile .ant-checkbox-wrapper {
        margin-right: 20px; }
      .filters-bar-instalments .instalment-items .istalment-mobile .status {
        display: flex;
        align-items: center; }
        .filters-bar-instalments .instalment-items .istalment-mobile .status p {
          margin: 0px; }
        .filters-bar-instalments .instalment-items .istalment-mobile .status .dot {
          width: 12px;
          height: 12px;
          border-radius: 50%;
          margin-left: 10px; }
          .filters-bar-instalments .instalment-items .istalment-mobile .status .dot.Cleared {
            background-color: #52C41A; }
          .filters-bar-instalments .instalment-items .istalment-mobile .status .dot.Due, .filters-bar-instalments .instalment-items .istalment-mobile .status .dot.Paused {
            background-color: #FFC100; }
          .filters-bar-instalments .instalment-items .istalment-mobile .status .dot.Late {
            background-color: #FB4953; }
          .filters-bar-instalments .instalment-items .istalment-mobile .status .dot.Cancelled {
            background-color: #1B1B1B; }
      .filters-bar-instalments .instalment-items .istalment-mobile:nth-of-type(even) {
        background-color: #F5F5F5; }
      .filters-bar-instalments .instalment-items .istalment-mobile div p:first-of-type {
        font-size: 16px;
        color: #505157;
        margin: 0px; }
      .filters-bar-instalments .instalment-items .istalment-mobile div p:last-of-type {
        font-size: 14px;
        font-weight: 300;
        margin: 0px; }
      .filters-bar-instalments .instalment-items .istalment-mobile div:nth-of-type(2) {
        margin-left: auto; }
      .filters-bar-instalments .instalment-items .istalment-mobile > span {
        margin-left: 10px; }

.order-details-status {
  left: 22% !important;
  transform-origin: unset !important; }
