#signin {
  padding: 30px 93px;
  min-height: 100vh;
  margin: 0px;
  background-repeat: no-repeat !important;
  background-size: contain !important;
  border-radius: 0px !important;
  background-color: white !important;
  background-position: top right !important; }
  #signin > img {
    margin-bottom: 20px; }
  #signin .head img {
    width: 200px;
    margin-top: 40px; }
  @media (max-width: 768px) {
    #signin {
      padding: 20px;
      background-position: bottom !important;
      background-size: contain !important; }
      #signin .head {
        display: flex;
        justify-content: space-between;
        margin-bottom: 40px; }
        #signin .head img {
          width: 142px;
          height: 100%; }
        #signin .head .ant-select {
          font-size: 16px;
          margin-top: 4px; } }
