.opportunity-log {
  padding-left: 20px; }
  .opportunity-log .box {
    height: calc(100vh - 231px);
    padding: 0px; }
    .opportunity-log .box .button-container {
      padding: 20px; }
      .opportunity-log .box .button-container button {
        margin-left: auto;
        display: block;
        height: 30px; }
        .opportunity-log .box .button-container button.primary-medium-1 {
          width: 100%;
          height: 50px; }
  .opportunity-log thead th {
    background-color: white !important;
    padding: 11px 16px !important; }
  .opportunity-log thead tr {
    box-shadow: none; }
  .opportunity-log .table-status {
    display: flex;
    align-items: center; }
    .opportunity-log .table-status p {
      margin: 0px; }
    .opportunity-log .table-status div {
      margin-right: 5px;
      width: 14px;
      height: 14px;
      border-radius: 50%; }
    .opportunity-log .table-status.Closed div {
      background-color: #52C41A; }
    .opportunity-log .table-status.Open div {
      background-color: #FFC100; }
    .opportunity-log .table-status.Lost div {
      background-color: #FB4953; }
  .opportunity-log .styled-table tbody tr td:nth-child(2) {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px; }
  .opportunity-log .styled-table tbody tr td:nth-last-child(2) {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px; }
  .opportunity-log .lead-mobile {
    margin: 10px 20px;
    display: flex;
    width: calc(100% - 40px);
    align-items: center;
    border-radius: 8px;
    height: 80px;
    padding: 15px;
    align-items: center; }
    .opportunity-log .lead-mobile:nth-of-type(even) {
      background-color: #F5F5F5; }
    .opportunity-log .lead-mobile div:first-of-type {
      height: 100%; }
    .opportunity-log .lead-mobile div p:first-of-type {
      font-size: 16px;
      color: #505157;
      margin: 0px; }
    .opportunity-log .lead-mobile div p:last-of-type {
      font-size: 14px;
      font-weight: 300;
      margin: 0px; }
    .opportunity-log .lead-mobile div:nth-of-type(2) {
      margin-left: auto; }
    .opportunity-log .lead-mobile > span {
      margin-left: 20px; }
    .opportunity-log .lead-mobile .status {
      display: flex;
      align-items: center; }
      .opportunity-log .lead-mobile .status div {
        height: 14px;
        width: 14px;
        border-radius: 50%;
        margin-right: 0px;
        margin-left: 10px; }
      .opportunity-log .lead-mobile .status.Open div {
        background-color: #FFC100; }
      .opportunity-log .lead-mobile .status.Closed div {
        background-color: #52C41A; }
      .opportunity-log .lead-mobile .status.Lost div {
        background-color: #FB4953; }
    .opportunity-log .lead-mobile .profit {
      text-align: right;
      font-weight: 500; }
      .opportunity-log .lead-mobile .profit span {
        font-weight: 300; }

@media (max-width: 768px) {
  .opportunity-log {
    padding-left: 0px; } }
