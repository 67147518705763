#view-all-notes {
  margin: -24px; }
  #view-all-notes .header-container {
    height: 82px;
    background-color: #d7dbdd;
    display: flex;
    align-items: center;
    padding-left: 25px;
    padding-right: 25px;
    justify-content: space-between; }
    #view-all-notes .header-container .title {
      font-size: 18px;
      font-weight: 600;
      color: #BA4A00; }
    #view-all-notes .header-container .icon {
      height: 40px;
      width: 40px;
      border-radius: 10px;
      background: linear-gradient(135deg, #BA4A00 0%, #490708 100%);
      margin-right: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 3px; }
    #view-all-notes .header-container i:before {
      font-size: 20px;
      color: white; }
  #view-all-notes .content {
    padding: 20px;
    height: 87vh;
    background-color: #EAEDEE; }
    #view-all-notes .content .top-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 25px; }
  #view-all-notes .note {
    color: #505157;
    padding: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    background-color: #fcf8f3;
    border-bottom: 5px solid #f6e9d9;
    border-radius: 6px;
    z-index: 200; }
    #view-all-notes .note .top-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between; }
    #view-all-notes .note .title {
      font-size: 16px;
      color: #AB8E6A; }
    #view-all-notes .note .timestamp {
      font-size: 14px;
      color: black;
      opacity: 0.3; }
    #view-all-notes .note .description {
      font-size: 14px;
      font-weight: 300;
      color: #36373C; }
  #view-all-notes .ant-input-affix-wrapper {
    border: none;
    border-radius: 8px;
    background-color: #f5f5f5; }
    #view-all-notes .ant-input-affix-wrapper input {
      font-size: 14px;
      color: #8B8D96;
      background-color: #f5f5f5; }
    #view-all-notes .ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
      box-shadow: none;
      border: 1px solid #1890FF;
      background-color: #f4f9ff; }
      #view-all-notes .ant-input-affix-wrapper.ant-input-affix-wrapper-focused input {
        background-color: transparent; }
