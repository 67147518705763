.header {
  height: 56px;
  background-color: white;
  display: flex;
  align-items: center;
  padding: 0px 50px;
  position: fixed;
  width: 100%;
  z-index: 10; }
  .header > img {
    margin-right: 40px; }
  .header .ant-menu-horizontal {
    line-height: 46px !important;
    width: 100%; }
    .header .ant-menu-horizontal .ant-menu-overflowed-submenu .ant-menu-submenu-title {
      padding-right: 20px !important; }
    .header .ant-menu-horizontal .ant-menu-item {
      padding: 4px 20px;
      margin: 0px;
      font-weight: 500;
      color: #8C8C8C; }
      .header .ant-menu-horizontal .ant-menu-item:hover {
        background: linear-gradient(0deg, #a3151800 0%, #BA4A003b 100%); }
      .header .ant-menu-horizontal .ant-menu-item i:before {
        font-size: 20px;
        margin-right: 10px;
        position: relative;
        top: 3px;
        margin-left: 0px; }
    .header .ant-menu-horizontal .ant-menu-submenu {
      margin: 0px; }
      .header .ant-menu-horizontal .ant-menu-submenu .ant-menu-submenu-title {
        display: flex;
        padding: 0px 5px 0px 20px;
        align-items: flex-end;
        height: 55px;
        font-weight: 500;
        color: #8C8C8C; }
        .header .ant-menu-horizontal .ant-menu-submenu .ant-menu-submenu-title i {
          margin-right: 10px; }
          .header .ant-menu-horizontal .ant-menu-submenu .ant-menu-submenu-title i:before {
            font-size: 20px; }
        .header .ant-menu-horizontal .ant-menu-submenu .ant-menu-submenu-title > span > div {
          position: relative;
          bottom: 4px; }
        .header .ant-menu-horizontal .ant-menu-submenu .ant-menu-submenu-title:hover {
          background: linear-gradient(0deg, #a3151800 0%, #BA4A003b 100%); }
        .header .ant-menu-horizontal .ant-menu-submenu .ant-menu-submenu-title .anticon {
          margin-right: 0px; }
    .header .ant-menu-horizontal .ant-menu-item-selected {
      color: #BA4A00;
      border-bottom-color: #BA4A00; }
    .header .ant-menu-horizontal .ant-menu-item-active, .header .ant-menu-horizontal .ant-menu-item:hover, .header .ant-menu-horizontal .ant-menu-submenu-horizontal:hover {
      border-bottom-color: #BA4A00; }
  .header .right-section {
    margin-left: auto;
    height: 46px;
    display: flex;
    align-items: center; }
    .header .right-section > * {
      margin-left: 10px; }
    .header .right-section .select-language .ant-select-selector {
      text-transform: uppercase; }
    .header .right-section .anticon-question-circle svg {
      color: #BFBFBF;
      font-size: 44px;
      padding: 12px;
      margin: -12px; }
    .header .right-section .ant-select {
      font-size: 14px;
      color: #8B8D96;
      font-weight: 500; }
      .header .right-section .ant-select .ant-select-arrow svg {
        color: #8B8D96;
        font-weight: 500; }
      .header .right-section .ant-select .ant-select-selector, .header .right-section .ant-select .ant-select-focused {
        border-color: transparent !important;
        box-shadow: none !important; }
    .header .right-section .new-opportunity {
      display: flex;
      align-items: center;
      background-color: #BA4A00;
      justify-content: center;
      color: white;
      font-weight: 500;
      margin-right: 10px;
      cursor: pointer; }
      .header .right-section .new-opportunity svg {
        margin-right: 5px; }
  @media (max-width: 1300px) {
    .header .ant-menu-horizontal .ant-menu-item {
      padding: 4px 12px; }
    .header > img {
      margin-right: 20px; } }
  @media (max-width: 768px) {
    .header {
      padding: 0px 25px;
      height: 70px;
      border-bottom: 1px solid #EAEAEA; }
      .header > img {
        margin-left: 0px;
        margin-right: 15px; }
      .header > div:not(.mobile-menu-opened):not(.back) {
        padding: 0px 0px 0px 25px;
        height: 70px;
        width: 100% !important;
        display: flex;
        align-items: center;
        border-left: 1px solid #EAEAEA; }
        .header > div:not(.mobile-menu-opened):not(.back) > div * {
          line-height: 20px;
          margin: 0px; }
        .header > div:not(.mobile-menu-opened):not(.back) > div h5 {
          margin: 0px;
          color: #BA4A00;
          font-size: 14px;
          font-weight: 500; }
        .header > div:not(.mobile-menu-opened):not(.back) > div p {
          color: #505157; }
        .header > div:not(.mobile-menu-opened):not(.back) svg {
          font-size: 24px; }
        .header > div:not(.mobile-menu-opened):not(.back) .anticon-question-circle {
          color: #BFBFBF;
          margin-left: auto;
          margin-right: 10px; }
        .header > div:not(.mobile-menu-opened):not(.back) .anticon-menu {
          color: #8C8C8C;
          margin-left: 0px; } }
  .header .back svg {
    color: #BA4A00;
    font-size: 24px;
    margin-right: 18px;
    margin-top: 28px; }
  .header .mobile-menu-opened {
    background-color: white;
    position: absolute;
    width: 100vw;
    top: 0px;
    left: 0px;
    border-bottom-right-radius: 60px;
    box-shadow: -5px 8px 20px 0px #808080b8;
    z-index: 2; }
    .header .mobile-menu-opened .content {
      overflow: scroll;
      max-height: 100vh;
      padding: 15px 15px 30px 15px; }
    .header .mobile-menu-opened .menuitem i:before {
      margin-right: 10px; }
    .header .mobile-menu-opened .inline {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .header .mobile-menu-opened .inline * {
        font-size: 16px; }
      .header .mobile-menu-opened .inline p {
        line-height: 20px;
        margin: 0px; }
      .header .mobile-menu-opened .inline .ant-select {
        margin-bottom: -3px; }
      .header .mobile-menu-opened .inline:first-of-type {
        margin-bottom: 40px; }
      .header .mobile-menu-opened .inline img {
        width: 150px; }
      .header .mobile-menu-opened .inline .anticon-close svg {
        font-size: 24px;
        color: #1B1B1B; }
    .header .mobile-menu-opened .signout {
      color: #8B8D96;
      font-size: 16px;
      margin-bottom: 0px;
      margin-top: 70px;
      line-height: 30px;
      margin-bottom: 15px;
      display: table; }
    .header .mobile-menu-opened .menuitem {
      color: #8B8D96;
      font-size: 16px;
      line-height: 20px;
      display: table;
      padding: 10px;
      border-radius: 4px;
      margin-bottom: 20px; }
      .header .mobile-menu-opened .menuitem.active {
        background-color: #BA4A003d;
        color: #BA4A00; }
      .header .mobile-menu-opened .menuitem.indented {
        margin-left: 40px; }

.select-language-mobile .ant-select-selector {
  background-color: transparent !important;
  color: #505157;
  border: none !important; }

.select-language-mobile .ant-select-arrow {
  color: #505157;
  margin-top: -9px; }

.help-menu .ant-popover-inner {
  width: unset; }

.help-menu .ant-popover-inner-content {
  width: 240px; }
  .help-menu .ant-popover-inner-content div label {
    font-size: 12px;
    color: #505157;
    font-weight: 500; }
  .help-menu .ant-popover-inner-content div > div:not(.notice) {
    width: 100%;
    height: 36px;
    padding: 7px 10px;
    border-radius: 4px;
    color: white;
    margin-bottom: 5px;
    cursor: pointer; }
    .help-menu .ant-popover-inner-content div > div:not(.notice):nth-of-type(1) {
      background-color: #F5F5F5;
      color: #505157;
      margin-bottom: 20px; }
    .help-menu .ant-popover-inner-content div > div:not(.notice):nth-of-type(2) {
      background-color: #23CD64; }
    .help-menu .ant-popover-inner-content div > div:not(.notice):nth-of-type(3) {
      background-color: #78AD2C; }
    .help-menu .ant-popover-inner-content div > div:not(.notice):nth-of-type(4) {
      background-color: #505157;
      margin-bottom: 0px;
      margin-bottom: 20px; }
    .help-menu .ant-popover-inner-content div > div:not(.notice):nth-of-type(5) {
      background-color: #F5F5F5;
      color: #BA4A00; }
  .help-menu .ant-popover-inner-content div svg {
    font-size: 16px;
    margin-right: 5px; }
  .help-menu .ant-popover-inner-content div .notice {
    position: absolute;
    background: black;
    top: 363px;
    border-radius: 8px;
    padding: 10px;
    width: 241px;
    left: 0px; }
    .help-menu .ant-popover-inner-content div .notice p {
      color: white;
      margin: 0px;
      font-weight: 300;
      font-size: 14px; }
    .help-menu .ant-popover-inner-content div .notice button {
      width: 100%;
      height: 36px;
      color: white;
      background-color: #BA4A00;
      border-radius: 3px;
      margin-top: 20px; }

.ant-menu-submenu.ant-menu-submenu-popup {
  top: 57px !important;
  position: absolute; }
  .ant-menu-submenu.ant-menu-submenu-popup .submenu-opened {
    padding-left: 100px; }
    .ant-menu-submenu.ant-menu-submenu-popup .submenu-opened .submenu-item {
      padding: 20px; }
      .ant-menu-submenu.ant-menu-submenu-popup .submenu-opened .submenu-item > div {
        padding: 10px;
        cursor: pointer;
        margin-bottom: 10px; }
        .ant-menu-submenu.ant-menu-submenu-popup .submenu-opened .submenu-item > div:hover {
          background-color: #F5F5F5;
          border-radius: 10px; }
      .ant-menu-submenu.ant-menu-submenu-popup .submenu-opened .submenu-item h3 {
        font-size: 18px;
        color: #BA4A00;
        font-weight: 500; }
        .ant-menu-submenu.ant-menu-submenu-popup .submenu-opened .submenu-item h3 i:before {
          margin-right: 10px;
          position: relative;
          top: 2px; }
      .ant-menu-submenu.ant-menu-submenu-popup .submenu-opened .submenu-item p {
        line-height: 22px;
        color: #8C8C8C;
        font-weight: 300; }
      .ant-menu-submenu.ant-menu-submenu-popup .submenu-opened .submenu-item .submenu-link {
        color: #505157;
        cursor: pointer;
        margin-left: 10px;
        font-weight: 500; }
        .ant-menu-submenu.ant-menu-submenu-popup .submenu-opened .submenu-item .submenu-link i:before {
          position: relative;
          top: 2px;
          margin-left: 5px; }
  .ant-menu-submenu.ant-menu-submenu-popup .img-container {
    margin-left: auto;
    padding: 20px; }
    .ant-menu-submenu.ant-menu-submenu-popup .img-container img {
      position: relative;
      top: 60px;
      width: 100%;
      max-width: 250px;
      margin-left: auto;
      display: block; }

.contact-drawer .ant-drawer-body label {
  color: #505157;
  font-weight: 500;
  display: block;
  margin-bottom: 5px; }
  .contact-drawer .ant-drawer-body label:nth-of-type(2) {
    margin-top: 25px; }

.contact-drawer .ant-drawer-body .drawer-button {
  font-weight: 500; }
  .contact-drawer .ant-drawer-body .drawer-button svg {
    font-size: 18px;
    margin-right: 10px;
    position: relative;
    top: 2px; }
  .contact-drawer .ant-drawer-body .drawer-button:nth-of-type(2) {
    background-color: #23CD64;
    color: white; }
  .contact-drawer .ant-drawer-body .drawer-button:nth-of-type(3) {
    background-color: #78AD2C;
    color: white; }
  .contact-drawer .ant-drawer-body .drawer-button:nth-of-type(4) {
    background-color: #505157;
    color: white; }

.ant-menu-submenu li {
  display: flex;
  align-items: center;
  color: #8C8C8C;
  font-weight: 500; }
  .ant-menu-submenu li i {
    margin-right: 10px; }
  .ant-menu-submenu li:hover {
    color: #BA4A00;
    background: linear-gradient(0deg, #a3151800 0%, #BA4A003b 100%); }

.ant-menu-vertical.ant-menu-sub:not([class*='-active']) {
  overflow-y: hidden; }

.ant-menu-submenu .ant-menu-submenu-title {
  display: flex; }
  .ant-menu-submenu .ant-menu-submenu-title .anticon-caret-down {
    display: none; }
  .ant-menu-submenu .ant-menu-submenu-title .ant-menu-submenu-arrow {
    display: none; }

.ant-menu-submenu .ant-menu-submenu .ant-menu-submenu {
  width: 100vw;
  top: 0px !important; }
