.pay {
  height: 100vh;
  width: 100vw;
  background: #EAEDEE; }
  .pay .payment {
    width: 620px;
    margin: 0 auto;
    display: block;
    margin-top: 100px; }
    .pay .payment .head {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .pay .payment .head img {
        width: 140px; }
      .pay .payment .head .ant-select-selector {
        padding-left: 0px !important;
        border: none !important;
        font-size: 16px;
        background-color: transparent !important; }
      .pay .payment .head .ant-select-arrow {
        color: #505157;
        top: 48%; }
    .pay .payment .container {
      margin-top: 20px;
      background-color: white;
      box-shadow: 1px 3px 4px rgba(131, 86, 86, 0.15);
      padding: 20px 60px; }
      .pay .payment .container .icon {
        width: 43px;
        height: 43px;
        border-radius: 50%;
        background: #F5DDD9;
        margin: 0 auto;
        display: block;
        display: flex;
        align-items: center;
        justify-content: center; }
        .pay .payment .container .icon i {
          color: #BA4A00; }
          .pay .payment .container .icon i:before {
            line-height: 3; }
      .pay .payment .container h1 {
        color: #BA4A00;
        font-weight: 400;
        font-size: 20px;
        text-align: center;
        margin-top: 20px;
        margin-bottom: 20px; }
      .pay .payment .container p {
        color: #8B8D96;
        font-weight: 300;
        text-align: center; }
      .pay .payment .container .instalment-of {
        margin-top: 20px; }
      .pay .payment .container .pay-btn {
        width: 390px;
        height: 45px;
        color: white;
        margin: 0 auto;
        display: block;
        margin-bottom: 20px; }
    .pay .payment .contact {
      color: white;
      margin-top: 40px;
      position: unset; }
      .pay .payment .contact .row {
        display: flex;
        width: 393px;
        margin: 0 auto; }
        .pay .payment .contact .row .phone {
          cursor: pointer;
          background-color: #505157;
          padding: 8px 12px;
          border-radius: 4px;
          width: 268px; }
          .pay .payment .contact .row .phone span {
            margin-right: 50px; }
        .pay .payment .contact .row .whatsapp {
          cursor: pointer;
          margin-left: 20px;
          border-radius: 4px;
          background-color: #23CD64;
          width: 104px;
          text-align: center; }
          .pay .payment .contact .row .whatsapp svg {
            font-size: 24px;
            margin-top: 7px; }
      .pay .payment .contact .email {
        cursor: pointer;
        margin: 0 auto;
        background: #1890FF;
        height: 38px;
        padding: 8px 12px;
        text-align: center;
        margin-top: 20px;
        width: 393px;
        border-radius: 4px; }
  @media (max-width: 768px) {
    .pay .payment {
      width: 100%;
      padding: 20px;
      margin-top: 0px; }
      .pay .payment .try-again {
        position: fixed;
        bottom: 0px;
        left: 0px;
        width: 100% !important;
        border-radius: 0px; }
      .pay .payment .container {
        padding: 20px !important; }
      .pay .payment .contact .email {
        width: 100%; }
      .pay .payment .contact .row {
        width: 100%; }
        .pay .payment .contact .row .phone {
          width: unset;
          flex: 2; }
        .pay .payment .contact .row .whatsapp {
          width: unset;
          flex: 1; } }
