#account label.title {
  color: #BA4A00;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 10px;
  display: block; }

#account .box {
  overflow: hidden; }

#account .right {
  padding-left: 20px; }

#account .connect-stripe label {
  color: #505157;
  font-size: 14px;
  font-weight: 500; }

#account .connect-stripe p {
  color: #8B8D96; }

#account .connect-stripe button {
  white-space: initial;
  height: auto;
  color: white;
  min-height: 33px;
  width: 100%; }

#account img {
  position: absolute;
  right: -73px;
  margin-left: auto;
  width: 76%;
  bottom: -74px; }
  @media (max-width: 992px) {
    #account img {
      display: none; } }

#account .ant-form > .ant-row > .ant-col {
  padding: 10px; }

#account .resetpass .password-box {
  position: relative; }
  #account .resetpass .password-box span {
    position: absolute;
    right: 36px;
    color: #398CAF;
    font-weight: 300;
    cursor: pointer;
    top: 47px; }
    @media (max-width: 768px) {
      #account .resetpass .password-box span {
        top: 42px; } }

#account .resetpass p {
  color: #8B8D96;
  font-size: 14px;
  margin-top: -10px; }

#account .resetpass .buttons {
  display: flex;
  align-items: center;
  margin-top: 40px; }
  #account .resetpass .buttons button {
    color: white;
    height: 40px; }
  #account .resetpass .buttons p {
    margin-bottom: 0px;
    color: #BA4A00;
    margin-left: 20px;
    margin-top: 0px;
    cursor: pointer; }

@media (max-width: 768px) {
  #account label.title {
    display: none; }
  #account .buttons {
    display: initial !important; }
    #account .buttons button {
      width: 100%;
      height: 50px !important;
      margin-top: 20px; }
    #account .buttons p {
      margin-left: 0px !important;
      margin-top: 20px !important; }
  #account .right {
    padding-left: 0px; }
  #account .view-selector {
    background-color: #EAEDEE;
    padding: 20px;
    width: 100%; }
    #account .view-selector .ant-radio-group {
      width: 100%;
      padding: 6px;
      background-color: white;
      border-radius: 8px; }
      #account .view-selector .ant-radio-group > label {
        width: 50%;
        height: 38px;
        padding-top: 4px;
        text-align: center;
        border: none;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
        border-color: transparent;
        border-width: 0px; }
        #account .view-selector .ant-radio-group > label:before {
          display: none; }
        #account .view-selector .ant-radio-group > label.ant-radio-button-wrapper-checked {
          background-color: #BA4A00; }
  #account img {
    position: relative;
    margin: 0 auto;
    display: block;
    margin-top: 40px;
    bottom: 0px;
    right: 0px; } }

@media (min-width: 768px) {
  #account .right-side {
    position: relative;
    top: 93px; } }

.reset-pass-modal .ant-modal-content {
  border-radius: 24px; }
  .reset-pass-modal .ant-modal-content .password-box span {
    top: 73px !important; }
  .reset-pass-modal .ant-modal-content .ant-modal-body {
    padding: 0px; }
  .reset-pass-modal .ant-modal-content .ant-form-item-label > label::after {
    top: -5px; }
  .reset-pass-modal .ant-modal-content .ant-form-item-control {
    width: 100%; }
  .reset-pass-modal .ant-modal-content .ant-btn {
    height: 84px;
    padding-top: 0px; }

@media (max-width: 768px) {
  #account .view-selector {
    background-color: #EAEDEE; }
    #account .view-selector > div {
      background-color: white;
      height: 50px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      padding: 20px;
      justify-content: space-between;
      color: #505157;
      cursor: pointer; }
      #account .view-selector > div svg {
        color: #8C8C8C; }
  .account-drawer .ant-drawer-content-wrapper {
    height: auto !important; }
  .auth-form {
    padding-bottom: 0px !important; }
    .auth-form .password-box span {
      top: 73px !important; } }
