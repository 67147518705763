.filters-bar-payments {
  z-index: 1;
  display: flex;
  align-items: center;
  margin-bottom: 30px; }
  .filters-bar-payments .inline .ant-radio-group-solid {
    overflow: hidden; }
    .filters-bar-payments .inline .ant-radio-group-solid .ant-radio-button-wrapper span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden; }
  .filters-bar-payments .ant-input-affix-wrapper {
    max-width: 390px;
    margin-right: 20px;
    border: none;
    border-radius: 8px; }
    .filters-bar-payments .ant-input-affix-wrapper input {
      height: 42px;
      font-size: 16px;
      color: #8B8D96; }
    .filters-bar-payments .ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
      box-shadow: none;
      border: 1px solid #1890FF;
      background-color: #F4F9FF; }
      .filters-bar-payments .ant-input-affix-wrapper.ant-input-affix-wrapper-focused input {
        background-color: transparent; }
  .filters-bar-payments button {
    margin-left: auto; }
  .filters-bar-payments .clear {
    font-weight: 500;
    color: #398CAF;
    white-space: nowrap;
    margin-bottom: 0px;
    margin-right: 20px;
    cursor: pointer; }

@media (max-width: 768px) {
  .filters-bar-payments {
    display: block;
    padding: 20px;
    margin-bottom: 10px;
    background-color: #EAEDEE;
    position: fixed;
    width: 100%; }
    .filters-bar-payments .inline {
      display: flex;
      width: 100%;
      margin-bottom: 20px; }
      .filters-bar-payments .inline:last-of-type {
        margin-bottom: 0px; }
      .filters-bar-payments .inline button {
        height: 50px;
        min-width: 50px; }
        .filters-bar-payments .inline button svg {
          font-size: 20px; }
      .filters-bar-payments .inline .filters {
        min-width: 50px;
        min-height: 50px;
        background-color: white;
        border-radius: 8px;
        margin-left: 20px;
        display: flex;
        align-items: center;
        justify-content: center; }
        .filters-bar-payments .inline .filters i:before {
          font-size: 20px;
          color: #505157;
          position: relative;
          top: 2px; } }
