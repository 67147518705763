.filters-bar-promotions {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  flex-wrap: wrap; }
  .filters-bar-promotions > * {
    margin-bottom: 20px; }
  .filters-bar-promotions .primary-medium-3 i::before {
    font-size: 18px;
    position: relative;
    top: 2px; }
  .filters-bar-promotions .primary-medium-3 span {
    position: relative;
    bottom: 2px;
    margin-left: 10px; }
  .filters-bar-promotions .ant-input-affix-wrapper {
    margin-right: 20px;
    border-color: transparent;
    border-radius: 8px;
    width: 315px; }
    .filters-bar-promotions .ant-input-affix-wrapper input {
      height: 42px;
      font-size: 16px;
      color: #8B8D96; }
    .filters-bar-promotions .ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
      box-shadow: none;
      border: 1px solid #1890FF;
      background-color: #F4F9FF; }
      .filters-bar-promotions .ant-input-affix-wrapper.ant-input-affix-wrapper-focused input {
        background-color: transparent; }
  .filters-bar-promotions .primary-medium-3 {
    margin-right: 20px; }
  .filters-bar-promotions .primary-medium-1 {
    margin-left: auto; }
  .filters-bar-promotions .clear {
    font-weight: 500;
    color: #398CAF;
    cursor: pointer; }

@media (max-width: 768px) {
  .filters-bar-promotions {
    display: block;
    padding: 20px;
    margin-bottom: 10px;
    background-color: #EAEDEE;
    position: fixed;
    width: 100%; }
    .filters-bar-promotions .ant-input-affix-wrapper {
      width: inherit; }
    .filters-bar-promotions .section-selector {
      margin-top: 0px !important; }
    .filters-bar-promotions .inline {
      display: flex;
      width: 100%;
      margin-bottom: 20px; }
      .filters-bar-promotions .inline:last-of-type {
        margin-bottom: 0px; }
      .filters-bar-promotions .inline button {
        height: 50px;
        min-width: 50px; }
        .filters-bar-promotions .inline button svg {
          font-size: 20px; }
      .filters-bar-promotions .inline .filters {
        min-width: 50px;
        min-height: 50px;
        background-color: white;
        border-radius: 8px;
        margin-left: 20px;
        display: flex;
        align-items: center;
        justify-content: center; }
        .filters-bar-promotions .inline .filters i:before {
          font-size: 20px;
          color: #505157;
          position: relative;
          top: 3px; }
        .filters-bar-promotions .inline .filters.marketplace {
          margin-left: 0px; } }

.status-promotion {
  display: flex;
  align-items: center; }
  .status-promotion p {
    margin: 0px; }
  .status-promotion .dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 10px; }
    .status-promotion .dot.Active {
      background-color: #52C41A; }
    .status-promotion .dot.Inactive {
      background-color: #FFC100; }
