.promotions-overview .table-status {
  display: flex;
  align-items: center; }
  .promotions-overview .table-status p {
    margin: 0px; }
  .promotions-overview .table-status div {
    margin-right: 5px;
    width: 14px;
    height: 14px;
    border-radius: 50%; }
  .promotions-overview .table-status.Active div {
    background-color: #52C41A; }
  .promotions-overview .table-status.Inactive div {
    background-color: #FFC100; }

.promotions-overview .table-btns {
  display: flex; }

@media (max-width: 768px) {
  .promotions-overview {
    padding-top: 150px; } }

.promotion-mobile {
  margin: 10px 20px;
  display: flex;
  width: calc(100% - 40px);
  align-items: center;
  border-radius: 8px;
  height: 80px;
  padding: 8px 15px;
  justify-content: space-between; }
  .promotion-mobile:nth-of-type(even) {
    background-color: #F5F5F5; }
  .promotion-mobile p {
    margin-bottom: 0px; }
    .promotion-mobile p.bold {
      font-weight: 500 !important; }
  .promotion-mobile .value {
    margin-left: auto; }
    .promotion-mobile .value p {
      text-align: right; }
      .promotion-mobile .value p span {
        font-weight: 300;
        margin-right: 10px; }
  .promotion-mobile > span {
    margin-left: 10px; }
