.make-money-modal h1 {
  font-family: Poppins;
  font-weight: 700;
  color: #8C8C8C;
  font-size: 20px;
  margin-top: -10px;
  margin-bottom: 20px; }
  .make-money-modal h1 img {
    position: relative;
    bottom: 14px; }

.make-money-modal .container {
  display: flex; }
  .make-money-modal .container .product-example {
    width: 35%;
    position: relative; }
    .make-money-modal .container .product-example .product {
      border-radius: 8px;
      box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.08), 0px 1px 18px rgba(0, 0, 0, 0.1), 0px 3px 5px rgba(0, 0, 0, 0.101535);
      overflow: hidden; }
      .make-money-modal .container .product-example .product img {
        width: 100%;
        height: 113px;
        object-fit: cover; }
      .make-money-modal .container .product-example .product > div {
        padding: 12px 10px; }
        .make-money-modal .container .product-example .product > div .title {
          color: #505157;
          font-weight: 300;
          font-size: 12px;
          margin-bottom: 10px; }
        .make-money-modal .container .product-example .product > div .price {
          font-weight: 500;
          color: black;
          font-size: 18px;
          margin-bottom: 0px; }
          .make-money-modal .container .product-example .product > div .price span {
            font-size: 12px; }
    .make-money-modal .container .product-example > img {
      position: absolute;
      bottom: 50px;
      right: -37px; }
  .make-money-modal .container .amounts {
    width: 67%;
    padding-left: 20px;
    z-index: 1; }
    .make-money-modal .container .amounts h2 {
      font-family: Poppins;
      font-weight: 700;
      font-size: 16px;
      color: #BA4A00;
      line-height: 24px; }
    .make-money-modal .container .amounts .example {
      border: 2px solid #DEDEDE;
      border-radius: 8px;
      margin-bottom: 12px;
      padding: 15px;
      background-color: white; }
      .make-money-modal .container .amounts .example label {
        font-size: 12px;
        color: #505157;
        margin-bottom: 0px; }
      .make-money-modal .container .amounts .example p {
        color: black;
        font-weight: 600;
        margin-bottom: 0px; }
        .make-money-modal .container .amounts .example p.red {
          color: #BA4A00; }

@media (max-width: 768px) {
  .make-money-modal .ant-modal {
    top: 0px; }
    .make-money-modal .ant-modal .container {
      display: unset; }
      .make-money-modal .ant-modal .container h1 img {
        display: none; }
      .make-money-modal .ant-modal .container > div {
        width: 100%;
        padding: 0px; }
        .make-money-modal .ant-modal .container > div > img {
          display: none; }
      .make-money-modal .ant-modal .container h2 {
        margin-top: 20px; } }
