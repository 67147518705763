.mobile-drawer .ant-drawer-content-wrapper {
  height: auto !important; }

.mobile-drawer .head {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .mobile-drawer .head p:first-of-type {
    color: #505157; }
  .mobile-drawer .head p:last-of-type {
    color: #8B8D96;
    cursor: pointer; }

.mobile-drawer .drawer-button {
  width: 100%;
  color: #505157;
  background-color: #F5F5F5;
  padding: 16px 12px;
  margin-bottom: 10px;
  border-radius: 3px; }
  .mobile-drawer .drawer-button i:before {
    margin-right: 10px; }

.mobile-drawer .ant-drawer-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.mobile-drawer .inactive-status {
  height: 14px;
  width: 14px;
  border-radius: 50%;
  margin-right: 10px;
  background-color: #FFC100;
  display: inline-block;
  margin-bottom: -3px; }

.mobile-drawer .active-status {
  height: 14px;
  width: 14px;
  border-radius: 50%;
  margin-right: 10px;
  background-color: #52C41A;
  display: inline-block;
  margin-bottom: -3px; }

.mobile-drawer .notice {
  background: black;
  border-radius: 8px;
  padding: 10px;
  margin-top: 30px; }
  .mobile-drawer .notice p {
    color: white;
    margin: 0px;
    font-weight: 300;
    font-size: 14px; }

.mobile-drawer .language-selector {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px; }
  .mobile-drawer .language-selector div {
    width: 49%;
    color: #505157;
    font-size: 14px;
    height: 50px;
    background-color: #F5F5F5;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center; }

.mobile-drawer .getpaid {
  background-color: #BA4A00;
  color: white; }

.view-selector .drawer-button {
  background-color: #F5F5F5;
  color: #1B1B1B;
  height: 50px;
  display: flex;
  align-items: center; }
  .view-selector .drawer-button i:before {
    color: #1B1B1B;
    position: relative;
    top: 3px;
    font-size: 18px; }
  .view-selector .drawer-button.active {
    background-color: #398CAF;
    color: white; }
    .view-selector .drawer-button.active i:before {
      color: white; }
