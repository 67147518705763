.filters-drawer-promotions .ant-drawer-close {
  display: none; }

.filters-drawer-promotions .head {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #BFBFBF; }
  .filters-drawer-promotions .head p {
    font-size: 16px;
    color: #8B8D96; }

.filters-drawer-promotions p.blue {
  color: #398CAF; }

.filters-drawer-promotions .clear-all {
  font-size: 16px;
  margin-top: 20px;
  text-align: center; }

.filters-drawer-promotions .filter {
  box-shadow: 0 6px 20px 8px #00000026;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 20px; }
  .filters-drawer-promotions .filter .ant-checkbox-group {
    display: flex;
    flex-direction: column; }
    .filters-drawer-promotions .filter .ant-checkbox-group label {
      background-color: #F5F5F5;
      margin-right: 0px;
      padding: 10px;
      border-radius: 4px;
      margin-bottom: 5px;
      font-size: 14px;
      color: #505157; }
    .filters-drawer-promotions .filter .ant-checkbox-group .ant-checkbox-inner {
      height: 24px;
      width: 24px; }
    .filters-drawer-promotions .filter .ant-checkbox-group .ant-checkbox-checked .ant-checkbox-inner:after {
      margin-left: 2px; }
  .filters-drawer-promotions .filter .controls {
    margin-top: 5px;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between; }
    .filters-drawer-promotions .filter .controls p {
      margin: 0px;
      font-size: 14px; }
      .filters-drawer-promotions .filter .controls p:first-of-type {
        color: #505157;
        cursor: pointer; }
      .filters-drawer-promotions .filter .controls p:last-of-type {
        color: #8B8D96;
        font-weight: 300; }
      .filters-drawer-promotions .filter .controls p span {
        font-weight: 300; }
