.payments-table .value-column {
  display: flex; }
  .payments-table .value-column p {
    margin: 0px; }
  .payments-table .value-column span {
    margin-right: 10px;
    display: block;
    color: #8C8C8C; }

.payments-table .ordernumber-column {
  display: flex;
  align-items: center;
  cursor: pointer; }
  .payments-table .ordernumber-column p {
    margin: 0px 5px 0px 0px; }

.payments-table .ant-btn[disabled] {
  background-color: #DADADA;
  color: #F5F5F5; }

.payments-table .status-column {
  display: flex;
  align-items: center; }
  .payments-table .status-column p {
    margin: 0px; }
  .payments-table .status-column .dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 10px; }
    .payments-table .status-column .dot.Cleared {
      background-color: #52C41A; }
    .payments-table .status-column .dot.Due, .payments-table .status-column .dot.Paused {
      background-color: #FFC100; }
    .payments-table .status-column .dot.Late {
      background-color: #FB4953; }
    .payments-table .status-column .dot.Cancelled {
      background-color: #1B1B1B; }

@media (max-width: 768px) {
  .payments-table {
    padding-top: 161px; }
    .payments-table .payment-mobile {
      margin: 10px 20px;
      display: flex;
      width: calc(100% - 40px);
      align-items: center;
      border-radius: 8px;
      height: 80px;
      padding: 8px 15px;
      align-items: center; }
      .payments-table .payment-mobile > div {
        max-width: 60%; }
        .payments-table .payment-mobile > div .ordernumber {
          margin-top: 8px !important;
          display: flex;
          align-items: center;
          height: unset !important; }
          .payments-table .payment-mobile > div .ordernumber p {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: 500 !important;
            margin-right: 5px !important; }
        .payments-table .payment-mobile > div .value {
          font-size: 12px; }
      .payments-table .payment-mobile .status {
        display: flex;
        align-items: center; }
        .payments-table .payment-mobile .status p {
          margin: 0px; }
        .payments-table .payment-mobile .status .dot {
          width: 12px;
          height: 12px;
          border-radius: 50%;
          margin-left: 10px; }
          .payments-table .payment-mobile .status .dot.Cleared {
            background-color: #52C41A; }
          .payments-table .payment-mobile .status .dot.Due, .payments-table .payment-mobile .status .dot.Paused {
            background-color: #FFC100; }
          .payments-table .payment-mobile .status .dot.Late {
            background-color: #FB4953; }
          .payments-table .payment-mobile .status .dot.Cancelled {
            background-color: #1B1B1B; }
      .payments-table .payment-mobile:nth-of-type(even) {
        background-color: #F5F5F5; }
      .payments-table .payment-mobile div:first-of-type {
        height: 100%; }
      .payments-table .payment-mobile div p:first-of-type {
        font-size: 16px;
        color: #505157;
        margin: 0px; }
      .payments-table .payment-mobile div p:last-of-type {
        font-size: 14px;
        font-weight: 300;
        margin: 0px; }
      .payments-table .payment-mobile div:nth-of-type(2) {
        margin-left: auto; }
      .payments-table .payment-mobile > span {
        margin-left: 10px; } }
