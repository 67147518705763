.filters-bar {
  display: flex;
  align-items: center;
  margin-bottom: 30px; }
  .filters-bar .ant-input-affix-wrapper {
    max-width: 390px;
    margin-right: 20px;
    border: none;
    border-radius: 8px; }
    .filters-bar .ant-input-affix-wrapper input {
      height: 42px;
      font-size: 16px;
      color: #8B8D96; }
    .filters-bar .ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
      box-shadow: none;
      border: 1px solid #1890FF;
      background-color: #F4F9FF; }
      .filters-bar .ant-input-affix-wrapper.ant-input-affix-wrapper-focused input {
        background-color: transparent; }
  .filters-bar .ant-select, .filters-bar .ant-select:hover, .filters-bar .ant-select-focused {
    background-color: white;
    border-radius: 8px;
    font-size: 14px;
    color: #595959;
    margin-right: 20px; }
    .filters-bar .ant-select .ant-select-selector, .filters-bar .ant-select:hover .ant-select-selector, .filters-bar .ant-select-focused .ant-select-selector {
      height: 48px;
      border-color: transparent !important;
      box-shadow: none !important; }
    .filters-bar .ant-select .ant-select-selection-item, .filters-bar .ant-select:hover .ant-select-selection-item, .filters-bar .ant-select-focused .ant-select-selection-item {
      line-height: 48px; }
  .filters-bar > button {
    margin-left: auto; }
  .filters-bar .ant-input-prefix {
    margin-right: 20px;
    font-size: 16px;
    color: #8B8D96; }

@media (max-width: 768px) {
  .filters-bar {
    display: block;
    padding: 20px;
    margin-bottom: 10px;
    background-color: #EAEDEE; }
    .filters-bar .inline {
      display: flex;
      width: 100%;
      margin-bottom: 20px; }
      .filters-bar .inline:last-of-type {
        margin-bottom: 0px; }
      .filters-bar .inline button {
        height: 50px;
        min-width: 50px; }
      .filters-bar .inline .filters {
        min-width: 50px;
        min-height: 50px;
        background-color: white;
        border-radius: 8px;
        margin-left: 20px;
        display: flex;
        align-items: center;
        justify-content: center; }
        .filters-bar .inline .filters i:before {
          font-size: 20px;
          color: #505157; } }
