.status-drawer .head {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .status-drawer .head p:first-of-type {
    color: #505157; }
  .status-drawer .head p:last-of-type {
    color: #8B8D96;
    cursor: pointer; }

.status-drawer .drawer-button {
  width: 100%;
  color: #505157;
  background-color: #F5F5F5;
  padding: 16px 12px;
  margin-bottom: 10px;
  border-radius: 3px; }
  .status-drawer .drawer-button i:before {
    margin-right: 10px; }

.status-drawer .ant-drawer-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.status-drawer .mobile-status-selector {
  display: flex;
  align-items: center;
  justify-content: left; }
  .status-drawer .mobile-status-selector div {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    margin-right: 10px;
    margin-left: 10px; }
  .status-drawer .mobile-status-selector.Open div {
    background-color: #FFC100; }
  .status-drawer .mobile-status-selector.Closed div {
    background-color: #52C41A; }
  .status-drawer .mobile-status-selector.Lost div {
    background-color: #FB4953; }
