.ant-layout {
  background-color: white; }

section:not(.ant-layout) {
  padding: 30px 50px;
  background-color: #EAEDEE;
  border-radius: 23px;
  margin: 0px 20px;
  min-height: calc(100vh - 57px);
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  margin-top: 56px; }
  @media (max-width: 768px) {
    section:not(.ant-layout) {
      background-color: transparent;
      padding: 0px;
      border-radius: 0px;
      margin: 0px;
      width: 100%;
      margin-top: 70px; }
      section:not(.ant-layout)#users {
        padding: 0px; } }

.select-dropdown {
  width: 160px !important; }
  .select-dropdown .ant-select-item-option-content {
    color: #505157;
    font-size: 14px;
    background-color: #F5F5F5;
    padding: 3px 10px 0px 10px;
    border-radius: 4px;
    transition: all .5s; }
    .select-dropdown .ant-select-item-option-content:hover {
      color: white;
      background-color: #505157; }
  .select-dropdown .ant-select-item {
    padding: 2px 5px; }
  .select-dropdown .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: unset !important; }
  @media (max-width: 768px) {
    .select-dropdown .ant-select-item-option-content {
      font-size: 16px;
      height: 35px;
      padding-top: 7px; } }

.styled-menu {
  width: 200px;
  padding: 5px; }
  .styled-menu .ant-dropdown-menu-item {
    padding: 5px 10px;
    margin: 5px;
    border-radius: 4px;
    background-color: #F5F5F5;
    color: #505157;
    font-size: 14px; }
    .styled-menu .ant-dropdown-menu-item:hover {
      background-color: #505157;
      color: white; }

.styled-table {
  border-top-left-radius: 14px;
  border-top-right-radius: 14px; }
  .styled-table .ant-table {
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px; }
  .styled-table .ant-table-content {
    padding-bottom: 16px; }
    .styled-table .ant-table-content .ant-table-cell {
      border-bottom: none; }
  .styled-table .ant-table-column-sorters {
    padding: 4px 0px !important; }
  .styled-table thead tr th:first-of-type {
    border-top-left-radius: 14px !important; }
  .styled-table thead tr th:last-of-type {
    border-top-right-radius: 14px !important; }
  .styled-table thead th {
    color: #595959;
    font-size: 14px;
    padding: 16px 16px !important; }
  .styled-table thead tr {
    box-shadow: 1px 15px 20px 0px #88888869;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px; }
  .styled-table tbody td {
    font-size: 14px;
    color: #595959; }
  .styled-table .left-border, .styled-table .right-border {
    background-color: white;
    width: 10px; }
  .styled-table .ant-table-row-level-0 .left-border, .styled-table .ant-table-row-level-0 .right-border {
    background-color: transparent !important; }
  .styled-table td.ant-table-column-sort {
    background-color: unset; }
  .styled-table .ant-table-tbody > tr.ant-table-row:hover > td.left-border, .styled-table .ant-table-tbody > tr.ant-table-row:hover > td.right-border {
    background-color: transparent; }

.styled-table:not(.one-color) tbody tr:nth-of-type(even) td {
  background-color: #F5F5F5; }

.styled-table:not(.one-color) tbody tr:nth-of-type(odd) td {
  background-color: white; }

.styled-table:not(.one-color) tbody tr td:nth-child(2) {
  border-top-left-radius: 14px;
  border-bottom-left-radius: 14px; }

.styled-table:not(.one-color) tbody tr td:nth-last-child(2) {
  border-top-right-radius: 14px;
  border-bottom-right-radius: 14px; }

.styled-table:not(.one-color) tbody tr:last-of-type td:first-of-type {
  border-bottom-left-radius: 14px !important; }

.styled-table:not(.one-color) tbody tr:last-of-type td:last-of-type {
  border-bottom-right-radius: 14px !important; }

.box {
  padding: 20px;
  border-radius: 10px;
  background-color: white;
  position: relative; }

.ant-radio-group.ant-radio-group-solid.section-selector {
  width: 100%;
  padding: 6px;
  background-color: white;
  border-radius: 8px; }
  .ant-radio-group.ant-radio-group-solid.section-selector > label {
    width: 50%;
    height: 38px;
    padding-top: 4px;
    text-align: center;
    border: none;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    border-color: transparent;
    border-width: 0px; }
    .ant-radio-group.ant-radio-group-solid.section-selector > label:before {
      display: none; }
    .ant-radio-group.ant-radio-group-solid.section-selector > label.ant-radio-button-wrapper-checked {
      background-color: #BA4A00 !important; }

.ant-table-column-sorter-up.active, .ant-table-column-sorter-down.active, .ant-table-column-sort .ant-table-column-sorters:first-child {
  color: #BA4A00; }

.ant-input-affix-wrapper {
  height: 50px; }
  .ant-input-affix-wrapper input {
    height: 100%; }
  .ant-input-affix-wrapper .ant-input-prefix {
    font-size: 14px !important; }

.separator {
  background-color: #EAEAEA;
  width: 100%;
  height: 1px; }

.section-selector-container {
  background-color: #EAEDEE;
  padding: 20px;
  margin-top: -20px; }

.section-title {
  font-size: 12px !important;
  font-weight: 600; }

@media (max-width: 768px) {
  .section-title {
    display: none; } }

.fixed-height-table thead th {
  position: sticky !important;
  top: 0px;
  background-color: white;
  z-index: 10;
  box-shadow: 19px 10px 11px 2px #88888887; }

.fixed-height-table .ant-table {
  height: calc(100vh - 196px);
  overflow-y: scroll; }

.fixed-height-table.half-height .ant-table {
  height: calc(100vh - 600px);
  min-height: 380px; }

.fixed-height-table .ant-table-content {
  overflow: unset !important; }

.has-padding {
  padding: 10px; }

.range-popover {
  width: 300px; }
  .range-popover .ant-popover-inner {
    width: 100%; }
  .range-popover span > label {
    color: #505157;
    font-size: 12px;
    display: block;
    margin-bottom: 5px; }
  .range-popover .range {
    display: flex; }
    .range-popover .range .ant-input-affix-wrapper {
      border: none;
      background-color: #F5F5F5; }
      .range-popover .range .ant-input-affix-wrapper input {
        height: 42px;
        font-size: 14px;
        background-color: transparent; }
      .range-popover .range .ant-input-affix-wrapper .ant-input-prefix {
        color: #8B8D96; }
      .range-popover .range .ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
        border: none;
        box-shadow: none; }
    .range-popover .range .ant-input-affix-wrapper:first-of-type {
      border-bottom-right-radius: 0px;
      border-top-right-radius: 0px;
      border-right: 1px solid #505157; }
    .range-popover .range .ant-input-affix-wrapper:last-of-type {
      border-bottom-left-radius: 0px;
      border-top-left-radius: 0px; }

.ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
  display: none; }

.centered-tabs > .ant-tabs-nav::before, .centered-tabs > div > .ant-tabs-nav::before {
  border-bottom: 1px solid #BFBFBF;
  bottom: 10px !important; }

.centered-tabs .ant-tabs-nav-list {
  background-color: #EAEDEE; }

.centered-tabs .ant-tabs-tab {
  padding-bottom: 0px; }
  .centered-tabs .ant-tabs-tab:first-of-type {
    margin-left: 30px; }

.centered-tabs .ant-tabs-tab-btn {
  color: #8B8D96; }

.centered-tabs .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: black; }

.centered-tabs .ant-tabs-ink-bar {
  background: black; }

@media (max-width: 768px) {
  .centered-tabs {
    margin-top: -15px; }
    .centered-tabs > .ant-tabs-nav::before {
      display: none; }
    .centered-tabs .ant-tabs-nav {
      margin-bottom: 45px; }
    .centered-tabs .ant-tabs-nav-list {
      background-color: transparent; } }
