.commissions-table .money-row {
  display: flex; }
  .commissions-table .money-row span {
    margin-right: 10px;
    color: #8B8D96; }

.commissions-table .ordernumber-column {
  display: flex;
  align-items: center;
  cursor: pointer; }
  .commissions-table .ordernumber-column p {
    margin: 0px 5px 0px 0px; }
  .commissions-table .ordernumber-column svg {
    color: #8B8D96; }

.commissions-table .ant-btn[disabled] {
  background-color: #DADADA;
  color: #F5F5F5; }

.commissions-table .status-column {
  display: flex;
  align-items: center; }
  .commissions-table .status-column p {
    margin: 0px; }
  .commissions-table .status-column .dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 10px; }
    .commissions-table .status-column .dot.Cleared {
      background-color: #52C41A; }
    .commissions-table .status-column .dot.Due {
      background-color: #FFC100; }
    .commissions-table .status-column .dot.Late {
      background-color: #FB4953; }
    .commissions-table .status-column .dot.Cancelled {
      background-color: #1B1B1B; }

@media (max-width: 768px) {
  .commissions-table {
    padding-top: 170px; }
    .commissions-table .commission-mobile {
      width: 100%;
      margin: 10px 20px;
      display: flex;
      width: calc(100% - 40px);
      align-items: center;
      border-radius: 8px;
      height: 80px;
      padding: 8px 15px;
      align-items: center; }
      .commissions-table .commission-mobile .ordernumber {
        margin-top: 8px !important;
        display: flex;
        align-items: center;
        height: unset !important; }
        .commissions-table .commission-mobile .ordernumber p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: 500 !important;
          margin-right: 5px !important; }
      .commissions-table .commission-mobile .value {
        font-size: 12px; }
      .commissions-table .commission-mobile .status {
        display: flex;
        align-items: center; }
        .commissions-table .commission-mobile .status p {
          margin: 0px; }
        .commissions-table .commission-mobile .status .dot {
          width: 12px;
          height: 12px;
          border-radius: 50%;
          margin-left: 10px; }
          .commissions-table .commission-mobile .status .dot.Cleared {
            background-color: #52C41A; }
          .commissions-table .commission-mobile .status .dot.Due {
            background-color: #FFC100; }
          .commissions-table .commission-mobile .status .dot.Late {
            background-color: #FB4953; }
          .commissions-table .commission-mobile .status .dot.Cancelled {
            background-color: #1B1B1B; }
      .commissions-table .commission-mobile:nth-of-type(odd) {
        background-color: #F5F5F5; }
      .commissions-table .commission-mobile div:first-of-type {
        height: 100%; }
      .commissions-table .commission-mobile div p:first-of-type {
        font-size: 16px;
        color: #505157;
        margin: 0px;
        margin-top: 5px; }
      .commissions-table .commission-mobile div p:last-of-type {
        font-size: 14px;
        font-weight: 300;
        margin: 0px; }
      .commissions-table .commission-mobile div:nth-of-type(2) {
        margin-left: auto; }
      .commissions-table .commission-mobile > span {
        margin-left: 10px; } }
