.confirm-delete img {
  width: 190px; }

.confirm-delete h5 {
  color: #FB4953;
  font-size: 14px;
  font-weight: 500;
  margin: 20px 0px; }

.confirm-delete p {
  color: #8C8C8C;
  font-weight: 300; }
  .confirm-delete p span {
    color: #1B1B1B;
    font-weight: normal; }

.confirm-delete .ant-modal-body {
  padding: 0px; }
  .confirm-delete .ant-modal-body .ant-modal-confirm-body {
    padding: 20px; }

.confirm-delete .ant-modal-confirm-btns {
  width: 100%;
  padding: 25px;
  background: #E6EBEC;
  margin-top: 0px;
  text-align: right;
  display: flex;
  justify-content: flex-end; }

.confirm-delete button {
  margin-left: 20px; }

@media (max-width: 768px) {
  .confirm-delete {
    position: absolute;
    bottom: -32px;
    width: 100% !important;
    max-width: 100%;
    top: unset; }
    .confirm-delete .ant-modal-content {
      border-radius: 0px; }
    .confirm-delete .ant-modal-confirm-btns {
      display: flex;
      flex-direction: column; }
      .confirm-delete .ant-modal-confirm-btns button {
        height: 50px;
        margin-left: 0px; }
        .confirm-delete .ant-modal-confirm-btns button:first-of-type {
          margin-bottom: 15px; }
    .confirm-delete h5 {
      font-size: 16px; }
    .confirm-delete img {
      width: 70%; } }

.confirm-delete.instalment .ant-btn-primary {
  background-color: #BA4A00; }

.confirm-delete.instalment p {
  margin-top: -20px;
  margin-bottom: 0px; }
  .confirm-delete.instalment p span {
    font-weight: 500; }

.confirm-delete.commission p {
  margin-top: 20px; }

@media (min-width: 768px) {
  #payment-actions .confirm-delete {
    position: absolute;
    right: 20px;
    top: 160px; } }

#payment-actions .confirm-delete .ant-modal-confirm-btns {
  border-radius: 0px 0px 4px 4px; }
