.tutorials .ant-modal {
  width: 100vw;
  top: 0px;
  margin: 0px;
  max-width: 100vw;
  min-height: 100vh; }
  .tutorials .ant-modal .ant-modal-content {
    border-radius: 0px;
    min-height: 100vh;
    background: #ffffffe6;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px); }
    .tutorials .ant-modal .ant-modal-content .ant-modal-close {
      margin-top: 15px; }
      .tutorials .ant-modal .ant-modal-content .ant-modal-close svg {
        font-size: 24px; }
    .tutorials .ant-modal .ant-modal-content .inactive svg {
      color: #8C8C8C !important;
      cursor: unset !important; }
    .tutorials .ant-modal .ant-modal-content p {
      color: #505157;
      font-size: 16px;
      font-weight: 300; }
    .tutorials .ant-modal .ant-modal-content .head {
      display: flex;
      align-items: center;
      margin-bottom: 30px; }
      .tutorials .ant-modal .ant-modal-content .head .anticon-left-circle svg, .tutorials .ant-modal .ant-modal-content .head .anticon-right-circle svg {
        color: #BA4A00;
        font-size: 30px;
        margin-right: 10px;
        cursor: pointer; }
      .tutorials .ant-modal .ant-modal-content .head .ant-select-selector {
        border: none;
        background-color: transparent;
        color: #BA4A00;
        margin: 0px;
        font-size: 18px;
        font-weight: 500; }
      .tutorials .ant-modal .ant-modal-content .head .anticon-down {
        font-size: 18px;
        color: #BA4A00;
        position: relative;
        bottom: 5px; }
      .tutorials .ant-modal .ant-modal-content .head .ant-checkbox-wrapper {
        margin-left: auto;
        color: #8C8C8C;
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        margin-right: 70px; }
    .tutorials .ant-modal .ant-modal-content .ant-row .single-image {
      width: 100%;
      border-radius: 16px;
      margin-bottom: 20px;
      filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.15)); }
    .tutorials .ant-modal .ant-modal-content .ant-row .images {
      position: relative;
      margin-bottom: 20px;
      filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.15)); }
      .tutorials .ant-modal .ant-modal-content .ant-row .images .image {
        width: 100%;
        border-radius: 16px; }
      .tutorials .ant-modal .ant-modal-content .ant-row .images .arrow {
        position: absolute;
        width: 40px;
        height: 40px;
        background: rgba(0, 0, 0, 0.4);
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        cursor: pointer; }
        .tutorials .ant-modal .ant-modal-content .ant-row .images .arrow svg {
          color: white;
          font-size: 18px; }
        .tutorials .ant-modal .ant-modal-content .ant-row .images .arrow.left {
          left: 20px; }
        .tutorials .ant-modal .ant-modal-content .ant-row .images .arrow.right {
          right: 20px; }
      .tutorials .ant-modal .ant-modal-content .ant-row .images .previews {
        position: absolute;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        bottom: -25px; }
        .tutorials .ant-modal .ant-modal-content .ant-row .images .previews img {
          width: 60px;
          height: 60px;
          object-fit: cover;
          margin: 5px;
          filter: contrast(0.6); }
          .tutorials .ant-modal .ant-modal-content .ant-row .images .previews img.active {
            filter: contrast(0.2); }
    .tutorials .ant-modal .ant-modal-content .ant-row .text {
      padding-left: 20px; }
    .tutorials .ant-modal .ant-modal-content .ant-row .steps .steps-header {
      display: flex;
      align-self: center;
      margin-bottom: 30px; }
      .tutorials .ant-modal .ant-modal-content .ant-row .steps .steps-header h3 {
        color: #505157;
        font-weight: 500;
        font-size: 16px;
        margin-left: 10px; }
      .tutorials .ant-modal .ant-modal-content .ant-row .steps .steps-header .arrow {
        min-width: 30px;
        min-height: 30px;
        max-width: 30px;
        max-height: 30px;
        background: #505157;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-right: 10px; }
        .tutorials .ant-modal .ant-modal-content .ant-row .steps .steps-header .arrow svg {
          color: white;
          font-size: 18px; }
        .tutorials .ant-modal .ant-modal-content .ant-row .steps .steps-header .arrow.inactive {
          background-color: #8C8C8C; }
          .tutorials .ant-modal .ant-modal-content .ant-row .steps .steps-header .arrow.inactive svg {
            color: white !important; }

@media (max-width: 768px) {
  .tutorials .head {
    flex-direction: column;
    align-items: baseline !important; }
    .tutorials .head .ant-checkbox-wrapper {
      order: -1 !important;
      margin-left: 0px !important;
      margin-left: 0px !important;
      margin-bottom: 20px !important;
      display: block !important; }
      .tutorials .head .ant-checkbox-wrapper .ant-checkbox-inner {
        width: 18px !important;
        height: 18px !important;
        background-color: transparent; }
  .tutorials .steps-header {
    margin-bottom: 20px !important; }
  .tutorials .previews {
    bottom: -15px !important; }
    .tutorials .previews .preview-img {
      height: 30px !important;
      width: 30px !important; } }

.image-preview {
  width: 100% !important; }
  .image-preview .ant-modal-body {
    padding: 20px; }
    .image-preview .ant-modal-body img {
      width: 100%;
      border-radius: 8px; }
