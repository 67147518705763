.intro-modal .ant-modal-content {
  overflow: hidden; }

.intro-modal h1 {
  color: #BA4A00;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 40px; }

.intro-modal .steps {
  display: flex; }
  .intro-modal .steps .step {
    flex-grow: 1;
    flex-basis: 0;
    position: relative; }
    .intro-modal .steps .step .img-container {
      width: 100%;
      background: #EAEDEE;
      background-size: contain !important;
      background-repeat: no-repeat !important;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 186px;
      width: 186px;
      margin: 0 auto; }
      .intro-modal .steps .step .img-container img {
        width: 100%;
        height: 100%;
        object-fit: contain; }
    .intro-modal .steps .step.1 img {
      transform: scale(1.3); }
    .intro-modal .steps .step.2 img {
      height: 70%; }
    .intro-modal .steps .step.3 img {
      height: 80%; }
    .intro-modal .steps .step .text {
      display: flex;
      color: #687A80;
      font-weight: 800;
      font-size: 16px;
      align-items: center;
      justify-content: center;
      margin-top: 15px; }
      .intro-modal .steps .step .text .number {
        border: 3px solid #687A80;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px; }
      .intro-modal .steps .step .text p {
        margin: 0px; }
    .intro-modal .steps .step .arrow {
      position: absolute;
      right: -5%;
      top: 25%; }

.intro-modal .text-box {
  margin-top: 40px;
  background-color: #EFF2F3;
  padding: 25px 30px;
  width: calc(100% + 48px);
  margin-left: -24px;
  margin-bottom: -24px; }
  .intro-modal .text-box p {
    color: #505157;
    font-size: 16px;
    font-weight: 400; }
    .intro-modal .text-box p .underline {
      text-decoration: underline; }
    .intro-modal .text-box p .bold {
      font-weight: 600; }
    .intro-modal .text-box p .red {
      font-weight: 600;
      color: #BA4A00; }

@media (max-width: 768px) {
  .intro-modal .ant-modal {
    top: 0px !important; }
  .intro-modal .ant-modal-close {
    top: -10px;
    right: -10px; }
  .intro-modal .steps {
    display: block; }
    .intro-modal .steps .step {
      margin-bottom: 40px; }
    .intro-modal .steps .arrow {
      display: none; }
  .intro-modal .ant-btn.primary-medium-2 {
    margin-left: -24px;
    position: relative;
    top: 24px;
    width: calc(100% + 48px);
    border-radius: 0px; } }
