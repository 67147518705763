#product-preview {
  width: 500px;
  position: absolute;
  right: -520px; }
  #product-preview > * {
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.08), 0 1px 18px 0 rgba(0, 0, 0, 0.1), 0 3px 5px 0 rgba(0, 0, 0, 0.1);
    border-radius: 8px; }
  #product-preview p {
    margin-bottom: 0px;
    white-space: break-spaces; }
  #product-preview .product-head {
    background-color: #BA4A00;
    display: flex;
    height: 52px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    font-size: 16px;
    color: white;
    padding: 20px; }
    #product-preview .product-head .bold {
      font-weight: 500; }
  #product-preview .previewImgDiv {
    position: relative;
    margin-bottom: 20px;
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.08), 0 1px 18px 0 rgba(0, 0, 0, 0.1), 0 3px 5px 0 rgba(0, 0, 0, 0.1); }
  #product-preview .closeBtn {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    padding: 5px; }
  #product-preview img {
    width: 100%;
    height: auto;
    border-radius: 8px; }
  #product-preview .description-container {
    padding: 20px;
    background-color: white;
    margin-bottom: 20px; }

@media (max-width: 768px) {
  #product-preview {
    width: 100%;
    right: 0px;
    top: 60px;
    padding: 20px; }
    #product-preview .product-head {
      display: block;
      height: unset; } }
