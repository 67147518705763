@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Serif+SC:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500;700&display=swap);
label {
  color: #BA4A00;
  font-weight: 500;
  display: block;
  margin-bottom: 5px;
  font-size: 12px;
  margin-bottom: 10px; }

.ant-form-item .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #DDEFFF;
  border-color: #1890FF !important; }
  .ant-form-item .ant-checkbox-checked .ant-checkbox-inner:after {
    border-color: #1890FF; }

.ant-form-item .ant-checkbox-wrapper {
  margin-top: -20px;
  font-size: 14px;
  font-weight: normal;
  color: #505157; }

.ant-form-item .ant-input-affix-wrapper {
  border-color: transparent;
  background-color: #F5F5F5; }
  .ant-form-item .ant-input-affix-wrapper .ant-input-prefix {
    color: #505157;
    font-size: 14px !important; }
  .ant-form-item .ant-input-affix-wrapper .ant-input:focus, .ant-form-item .ant-input-affix-wrapper .ant-input {
    background-color: transparent; }

.ant-form-item .ant-form-item-label {
  padding-bottom: 0px !important; }
  .ant-form-item .ant-form-item-label > label {
    width: 100%; }
  .ant-form-item .ant-form-item-label label {
    color: #505157;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0px; }
  .ant-form-item .ant-form-item-label .form-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%; }
  .ant-form-item .ant-form-item-label label label.addon {
    font-size: 10px;
    font-weight: 300;
    color: #8B8D96; }

.ant-form-item input, .ant-form-item textarea {
  height: 50px;
  color: #505157;
  border-color: transparent;
  background-color: #F5F5F5; }

.ant-form-item.no-label .ant-form-item-label {
  display: none; }

.ant-form-item-has-error .ant-input {
  background-color: #F5F5F5 !important; }

.ant-form-item-has-error .ant-input-affix-wrapper {
  background-color: #F5F5F5 !important; }

.ant-form-item-has-error .ant-picker {
  border: 1px solid #CA0000 !important; }

.ant-input:focus, .ant-input-affix-wrapper-focused {
  box-shadow: none !important;
  border: 1px solid #1890FF;
  background-color: #F4F7FB !important;
  border-color: #1890FF !important; }

@media (max-width: 768px) {
  .ant-form-item .ant-form-item-label label {
    font-size: 14px;
    margin-bottom: 5px; }
  .ant-form-item .addon {
    font-size: 12px !important; }
  .ant-form-item input {
    height: 50px; }
  .ant-checkbox-inner {
    width: 24px !important;
    height: 24px !important; } }

.ant-checkbox-group .ant-checkbox-inner {
  background-color: transparent; }

.ant-checkbox-group .ant-checkbox-checked .ant-checkbox-inner {
  border-color: #1890FF;
  background-color: #D4E6F6; }
  .ant-checkbox-group .ant-checkbox-checked .ant-checkbox-inner:after {
    border-color: #1890FF; }

.ant-checkbox-group span {
  font-size: 14px; }

.ant-form-item .ant-select .ant-select-selector {
  height: 50px !important;
  color: #505157 !important;
  border-color: transparent !important;
  background-color: #F5F5F5 !important; }
  .ant-form-item .ant-select .ant-select-selector .ant-select-selection-item {
    padding-top: 9px; }

.split-fields {
  display: flex;
  align-items: flex-end; }
  .split-fields .ant-form-item {
    width: 50%; }
  .split-fields .ant-form-item:first-of-type .ant-input-affix-wrapper {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px; }
  .split-fields .ant-form-item:first-of-type input {
    color: #8B8D96; }
  .split-fields .ant-form-item:last-of-type .ant-input-affix-wrapper {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px; }
  .split-fields .ant-input-affix-wrapper .ant-input-prefix {
    color: #8B8D96;
    font-size: 12px !important; }
  .split-fields label {
    white-space: pre-wrap; }

.inline-fields {
  display: flex;
  align-items: flex-end; }
  .inline-fields .ant-input-affix-wrapper:first-of-type {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px; }
    .inline-fields .ant-input-affix-wrapper:first-of-type input {
      color: #8B8D96;
      font-size: 12px !important; }
  .inline-fields .ant-input-affix-wrapper:last-of-type {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px; }
  .inline-fields .ant-input-affix-wrapper .ant-input-prefix, .inline-fields .ant-input-affix-wrapper .ant-input-suffix {
    color: #8B8D96;
    font-size: 12px !important; }
  .inline-fields .ant-input-prefix {
    margin-top: 2px; }
  .inline-fields label {
    white-space: pre-wrap; }
  .inline-fields .products-overview td .ant-input-affix-wrapper.ant-input-affix-wrapper-disabled, .inline-fields .ant-input-affix-wrapper-disabled .ant-input[disabled], .inline-fields .ant-input-affix-wrapper-disabled {
    background-color: #F5F5F5 !important; }

.field-description {
  font-weight: 300;
  color: #8B8D96;
  margin-top: -20px; }
  .field-description a {
    color: #1890FF;
    text-decoration: underline; }

.field-title {
  font-size: 14px;
  font-weight: 500;
  color: #505157;
  margin-bottom: 30px; }

.right-align input {
  text-align: right;
  -moz-appearance: textfield; }
  .right-align input::-webkit-outer-spin-button, .right-align input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; }

.ant-input-affix-wrapper-disabled .ant-input[disabled], .ant-input-affix-wrapper-disabled {
  background-color: #DADADA !important; }

.ant-form-item-explain.ant-form-item-explain-error {
  margin-bottom: 20px; }

.ant-layout {
  background-color: white; }

section:not(.ant-layout) {
  padding: 30px 50px;
  background-color: #EAEDEE;
  border-radius: 23px;
  margin: 0px 20px;
  min-height: calc(100vh - 57px);
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  margin-top: 56px; }
  @media (max-width: 768px) {
    section:not(.ant-layout) {
      background-color: transparent;
      padding: 0px;
      border-radius: 0px;
      margin: 0px;
      width: 100%;
      margin-top: 70px; }
      section:not(.ant-layout)#users {
        padding: 0px; } }

.select-dropdown {
  width: 160px !important; }
  .select-dropdown .ant-select-item-option-content {
    color: #505157;
    font-size: 14px;
    background-color: #F5F5F5;
    padding: 3px 10px 0px 10px;
    border-radius: 4px;
    transition: all .5s; }
    .select-dropdown .ant-select-item-option-content:hover {
      color: white;
      background-color: #505157; }
  .select-dropdown .ant-select-item {
    padding: 2px 5px; }
  .select-dropdown .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: unset !important; }
  @media (max-width: 768px) {
    .select-dropdown .ant-select-item-option-content {
      font-size: 16px;
      height: 35px;
      padding-top: 7px; } }

.styled-menu {
  width: 200px;
  padding: 5px; }
  .styled-menu .ant-dropdown-menu-item {
    padding: 5px 10px;
    margin: 5px;
    border-radius: 4px;
    background-color: #F5F5F5;
    color: #505157;
    font-size: 14px; }
    .styled-menu .ant-dropdown-menu-item:hover {
      background-color: #505157;
      color: white; }

.styled-table {
  border-top-left-radius: 14px;
  border-top-right-radius: 14px; }
  .styled-table .ant-table {
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px; }
  .styled-table .ant-table-content {
    padding-bottom: 16px; }
    .styled-table .ant-table-content .ant-table-cell {
      border-bottom: none; }
  .styled-table .ant-table-column-sorters {
    padding: 4px 0px !important; }
  .styled-table thead tr th:first-of-type {
    border-top-left-radius: 14px !important; }
  .styled-table thead tr th:last-of-type {
    border-top-right-radius: 14px !important; }
  .styled-table thead th {
    color: #595959;
    font-size: 14px;
    padding: 16px 16px !important; }
  .styled-table thead tr {
    box-shadow: 1px 15px 20px 0px #88888869;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px; }
  .styled-table tbody td {
    font-size: 14px;
    color: #595959; }
  .styled-table .left-border, .styled-table .right-border {
    background-color: white;
    width: 10px; }
  .styled-table .ant-table-row-level-0 .left-border, .styled-table .ant-table-row-level-0 .right-border {
    background-color: transparent !important; }
  .styled-table td.ant-table-column-sort {
    background-color: unset; }
  .styled-table .ant-table-tbody > tr.ant-table-row:hover > td.left-border, .styled-table .ant-table-tbody > tr.ant-table-row:hover > td.right-border {
    background-color: transparent; }

.styled-table:not(.one-color) tbody tr:nth-of-type(even) td {
  background-color: #F5F5F5; }

.styled-table:not(.one-color) tbody tr:nth-of-type(odd) td {
  background-color: white; }

.styled-table:not(.one-color) tbody tr td:nth-child(2) {
  border-top-left-radius: 14px;
  border-bottom-left-radius: 14px; }

.styled-table:not(.one-color) tbody tr td:nth-last-child(2) {
  border-top-right-radius: 14px;
  border-bottom-right-radius: 14px; }

.styled-table:not(.one-color) tbody tr:last-of-type td:first-of-type {
  border-bottom-left-radius: 14px !important; }

.styled-table:not(.one-color) tbody tr:last-of-type td:last-of-type {
  border-bottom-right-radius: 14px !important; }

.box {
  padding: 20px;
  border-radius: 10px;
  background-color: white;
  position: relative; }

.ant-radio-group.ant-radio-group-solid.section-selector {
  width: 100%;
  padding: 6px;
  background-color: white;
  border-radius: 8px; }
  .ant-radio-group.ant-radio-group-solid.section-selector > label {
    width: 50%;
    height: 38px;
    padding-top: 4px;
    text-align: center;
    border: none;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    border-color: transparent;
    border-width: 0px; }
    .ant-radio-group.ant-radio-group-solid.section-selector > label:before {
      display: none; }
    .ant-radio-group.ant-radio-group-solid.section-selector > label.ant-radio-button-wrapper-checked {
      background-color: #BA4A00 !important; }

.ant-table-column-sorter-up.active, .ant-table-column-sorter-down.active, .ant-table-column-sort .ant-table-column-sorters:first-child {
  color: #BA4A00; }

.ant-input-affix-wrapper {
  height: 50px; }
  .ant-input-affix-wrapper input {
    height: 100%; }
  .ant-input-affix-wrapper .ant-input-prefix {
    font-size: 14px !important; }

.separator {
  background-color: #EAEAEA;
  width: 100%;
  height: 1px; }

.section-selector-container {
  background-color: #EAEDEE;
  padding: 20px;
  margin-top: -20px; }

.section-title {
  font-size: 12px !important;
  font-weight: 600; }

@media (max-width: 768px) {
  .section-title {
    display: none; } }

.fixed-height-table thead th {
  position: sticky !important;
  top: 0px;
  background-color: white;
  z-index: 10;
  box-shadow: 19px 10px 11px 2px #88888887; }

.fixed-height-table .ant-table {
  height: calc(100vh - 196px);
  overflow-y: scroll; }

.fixed-height-table.half-height .ant-table {
  height: calc(100vh - 600px);
  min-height: 380px; }

.fixed-height-table .ant-table-content {
  overflow: unset !important; }

.has-padding {
  padding: 10px; }

.range-popover {
  width: 300px; }
  .range-popover .ant-popover-inner {
    width: 100%; }
  .range-popover span > label {
    color: #505157;
    font-size: 12px;
    display: block;
    margin-bottom: 5px; }
  .range-popover .range {
    display: flex; }
    .range-popover .range .ant-input-affix-wrapper {
      border: none;
      background-color: #F5F5F5; }
      .range-popover .range .ant-input-affix-wrapper input {
        height: 42px;
        font-size: 14px;
        background-color: transparent; }
      .range-popover .range .ant-input-affix-wrapper .ant-input-prefix {
        color: #8B8D96; }
      .range-popover .range .ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
        border: none;
        box-shadow: none; }
    .range-popover .range .ant-input-affix-wrapper:first-of-type {
      border-bottom-right-radius: 0px;
      border-top-right-radius: 0px;
      border-right: 1px solid #505157; }
    .range-popover .range .ant-input-affix-wrapper:last-of-type {
      border-bottom-left-radius: 0px;
      border-top-left-radius: 0px; }

.ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
  display: none; }

.centered-tabs > .ant-tabs-nav::before, .centered-tabs > div > .ant-tabs-nav::before {
  border-bottom: 1px solid #BFBFBF;
  bottom: 10px !important; }

.centered-tabs .ant-tabs-nav-list {
  background-color: #EAEDEE; }

.centered-tabs .ant-tabs-tab {
  padding-bottom: 0px; }
  .centered-tabs .ant-tabs-tab:first-of-type {
    margin-left: 30px; }

.centered-tabs .ant-tabs-tab-btn {
  color: #8B8D96; }

.centered-tabs .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: black; }

.centered-tabs .ant-tabs-ink-bar {
  background: black; }

@media (max-width: 768px) {
  .centered-tabs {
    margin-top: -15px; }
    .centered-tabs > .ant-tabs-nav::before {
      display: none; }
    .centered-tabs .ant-tabs-nav {
      margin-bottom: 45px; }
    .centered-tabs .ant-tabs-nav-list {
      background-color: transparent; } }

.ant-btn {
  height: 36px;
  border: none;
  font-size: 14px;
  font-weight: 500; }

button span {
  pointer-events: none; }

.ant-btn.active span {
  pointer-events: unset; }

.primary-medium-1 {
  background: white;
  color: #BA4A00;
  border: 1px solid #BA4A00;
  transition: none;
  transition: all .5s;
  display: flex;
  justify-content: center;
  align-items: center; }
  .primary-medium-1:hover {
    background-color: #BA4A00;
    border: 1px solid #BA4A00;
    color: white; }
  .primary-medium-1:focus {
    background-color: black;
    border: 1px solid black;
    color: white; }

.primary-medium-2 {
  background-color: #BA4A00;
  color: #E0C7A8; }
  .primary-medium-2:hover {
    background-color: #3F0506;
    color: white; }
  .primary-medium-2:focus {
    background-color: #1B1B1B;
    color: white; }

.primary-medium-3 {
  background-color: #398CAF;
  color: white;
  height: 50px; }
  .primary-medium-3:hover {
    background-color: #27617A;
    color: white; }
  .primary-medium-3:focus {
    background-color: #1B1B1B;
    color: white; }

.save {
  background-color: transparent;
  color: #1B1B1B;
  height: 32px;
  border: 1px solid #1B1B1B; }
  .save:hover {
    background-color: #BA4A00;
    color: white; }
  .save:focus {
    background-color: #1B1B1B;
    color: white; }

.secondary-medium-1 {
  background-color: #BFBFBF;
  color: #505157;
  height: 32px; }
  .secondary-medium-1:hover {
    background-color: #BA4A00;
    color: white; }
  .secondary-medium-1:focus {
    background-color: #1B1B1B;
    color: white; }

.secondary-medium-2 {
  background-color: #DADADA;
  color: #505157;
  height: 32px; }
  .secondary-medium-2:hover {
    background-color: #BA4A00;
    color: white; }
  .secondary-medium-2:focus {
    background-color: #1B1B1B;
    color: white; }

.secondary-slim-5 {
  background-color: #EAEAEA;
  color: #505157;
  padding-top: 0;
  height: 26px; }
  .secondary-slim-5:hover {
    background-color: #BA4A00;
    color: white; }
  .secondary-slim-5:focus {
    background-color: #1B1B1B;
    color: white; }

.secondary-medium-2, .confirm-delete .ant-btn {
  background-color: #DADADA;
  color: #505157; }
  .secondary-medium-2:hover, .confirm-delete .ant-btn:hover {
    background-color: #BA4A00;
    color: white; }
  .secondary-medium-2:focus, .confirm-delete .ant-btn:focus {
    background-color: #1B1B1B;
    color: white; }

table .secondary-medium-2 {
  height: 30px;
  margin-right: 10px; }
  table .secondary-medium-2.green {
    background-color: #52C41A;
    color: white; }
  table .secondary-medium-2.blue {
    background-color: #1890FF;
    color: white; }

.delete-button, .confirm-delete .ant-btn.ant-btn-primary {
  background: #FB4953;
  color: white;
  transition: none; }
  .delete-button:hover, .confirm-delete .ant-btn.ant-btn-primary:hover {
    background-color: #fb495370;
    color: white; }
  .delete-button:focus, .confirm-delete .ant-btn.ant-btn-primary:focus {
    background-color: #B9353D;
    color: white; }

* {
  font-family: Montserrat; }

.ant-input-prefix {
  font-size: 14px; }

#add-lead {
  overflow-x: hidden; }
  #add-lead label.title {
    color: #BA4A00;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 10px;
    display: block; }
  #add-lead .divider {
    margin-top: 10px;
    margin-left: -20px;
    width: 110%;
    height: 1px;
    background-color: #EAEAEA; }
  #add-lead > .ant-form > .ant-row > .ant-col {
    padding-right: 20px !important; }

@media (max-width: 768px) {
  input {
    font-size: 16px !important; }
  #add-lead {
    overflow-x: initial; }
    #add-lead .ant-form .ant-row .ant-col {
      padding-right: 0px !important; }
    #add-lead .top-container {
      margin-top: -20px;
      display: block;
      padding: 20px;
      margin-bottom: 10px;
      background-color: #EAEAEA;
      flex-direction: column; }
    #add-lead .status {
      padding: 15px;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border-radius: 8px;
      margin-bottom: 20px;
      cursor: pointer; }
      #add-lead .status .title {
        font-size: 14px;
        color: #595959;
        width: 100%;
        display: flex;
        align-items: center; }
        #add-lead .status .title div {
          width: 14px;
          height: 14px;
          border-radius: 50%;
          margin-right: 10px;
          margin-left: 10px; }
        #add-lead .status .title.Open div {
          background-color: #FFC100; }
        #add-lead .status .title.Closed div {
          background-color: #52C41A; }
        #add-lead .status .title.Lost div {
          background-color: #FB4953; }
      #add-lead .status .ant-progress-steps-item {
        height: 3px !important;
        border-radius: 3px;
        background: #dadada; }
        #add-lead .status .ant-progress-steps-item:last-of-type {
          margin-left: 10px; }
      #add-lead .status p {
        margin: 0px;
        font-weight: 500;
        font-size: 12px; }
      #add-lead .status.lost .ant-progress-steps-item:last-of-type {
        background-color: #fb4953; }
    #add-lead .inline {
      display: flex;
      width: 100%;
      margin-bottom: 20px; }
      #add-lead .inline:last-of-type {
        margin-bottom: 0px; }
      #add-lead .inline button {
        height: 50px;
        min-width: 50px; }
      #add-lead .inline .ant-radio-group {
        width: 100%;
        padding: 6px;
        background-color: white;
        border-radius: 8px;
        display: flex; }
        #add-lead .inline .ant-radio-group > label {
          flex: 1;
          height: 38px;
          padding-top: 4px;
          text-align: center;
          border: none;
          border-top-right-radius: 6px;
          border-top-left-radius: 6px;
          border-bottom-right-radius: 6px;
          border-bottom-left-radius: 6px;
          border-color: transparent;
          border-width: 0px; }
          #add-lead .inline .ant-radio-group > label:before {
            display: none; }
          #add-lead .inline .ant-radio-group > label.ant-radio-button-wrapper-checked {
            background-color: #BA4A00; } }

.filters-bar-products {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  flex-wrap: wrap; }
  .filters-bar-products > * {
    margin-bottom: 20px; }
  .filters-bar-products .primary-medium-3 i::before {
    font-size: 18px;
    position: relative;
    top: 2px; }
  .filters-bar-products .primary-medium-3 span {
    position: relative;
    bottom: 2px;
    margin-left: 10px; }
  .filters-bar-products .ant-input-affix-wrapper {
    margin-right: 20px;
    border-color: transparent;
    border-radius: 8px;
    width: 230px; }
    .filters-bar-products .ant-input-affix-wrapper input {
      height: 42px;
      font-size: 16px;
      color: #8B8D96; }
    .filters-bar-products .ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
      box-shadow: none;
      border: 1px solid #1890FF;
      background-color: #F4F9FF; }
      .filters-bar-products .ant-input-affix-wrapper.ant-input-affix-wrapper-focused input {
        background-color: transparent; }
  .filters-bar-products .primary-medium-3 {
    margin-right: 20px; }
  .filters-bar-products .primary-medium-1 {
    margin-left: auto; }
  .filters-bar-products .clear {
    font-weight: 500;
    color: #398CAF;
    cursor: pointer; }

@media (max-width: 768px) {
  .filters-bar-products {
    display: block;
    padding: 20px;
    margin-bottom: 10px;
    background-color: #EAEDEE;
    position: fixed;
    width: 100%; }
    .filters-bar-products .ant-input-affix-wrapper {
      width: inherit; }
    .filters-bar-products .inline {
      display: flex;
      width: 100%;
      margin-bottom: 20px; }
      .filters-bar-products .inline:last-of-type {
        margin-bottom: 0px; }
      .filters-bar-products .inline button {
        height: 50px;
        min-width: 50px; }
        .filters-bar-products .inline button svg {
          font-size: 20px; }
      .filters-bar-products .inline .filters {
        min-width: 50px;
        min-height: 50px;
        background-color: white;
        border-radius: 8px;
        margin-left: 20px;
        display: flex;
        align-items: center;
        justify-content: center; }
        .filters-bar-products .inline .filters i:before {
          font-size: 20px;
          color: #505157;
          position: relative;
          top: 3px; }
        .filters-bar-products .inline .filters.marketplace {
          margin-left: 0px; } }

.filters-bar-commissions {
  display: flex;
  align-items: center;
  margin-bottom: 30px; }
  .filters-bar-commissions .inline .ant-radio-group-solid {
    overflow: hidden; }
    .filters-bar-commissions .inline .ant-radio-group-solid .ant-radio-button-wrapper span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden; }
  .filters-bar-commissions .ant-input-affix-wrapper {
    max-width: 390px;
    margin-right: 20px;
    border: none;
    border-radius: 8px; }
    .filters-bar-commissions .ant-input-affix-wrapper input {
      height: 42px;
      font-size: 16px;
      color: #8B8D96; }
    .filters-bar-commissions .ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
      box-shadow: none;
      border: 1px solid #1890FF;
      background-color: #F4F9FF; }
      .filters-bar-commissions .ant-input-affix-wrapper.ant-input-affix-wrapper-focused input {
        background-color: transparent; }
  .filters-bar-commissions button {
    margin-left: auto; }
  .filters-bar-commissions .clear {
    font-weight: 500;
    color: #398CAF;
    white-space: nowrap;
    margin-bottom: 0px;
    margin-right: 20px;
    cursor: pointer; }

@media (max-width: 768px) {
  .filters-bar-commissions {
    display: block;
    padding: 20px;
    margin-bottom: 10px;
    background-color: #EAEDEE;
    position: fixed;
    width: 100%; }
    .filters-bar-commissions .inline {
      display: flex;
      width: 100%;
      margin-bottom: 20px; }
      .filters-bar-commissions .inline:last-of-type {
        margin-bottom: 0px; }
      .filters-bar-commissions .inline button {
        height: 50px;
        min-width: 50px; }
        .filters-bar-commissions .inline button svg {
          font-size: 20px; }
      .filters-bar-commissions .inline .filters {
        min-width: 50px;
        min-height: 50px;
        background-color: white;
        border-radius: 8px;
        margin-left: 20px;
        display: flex;
        align-items: center;
        justify-content: center; }
        .filters-bar-commissions .inline .filters i:before {
          font-size: 20px;
          color: #505157;
          position: relative;
          top: 2px; } }

.filters-bar-leads {
  display: flex;
  align-items: center;
  margin-bottom: 30px; }
  .filters-bar-leads .ant-input-affix-wrapper {
    max-width: 390px;
    margin-right: 20px;
    border: none;
    border-radius: 8px; }
    .filters-bar-leads .ant-input-affix-wrapper input {
      height: 42px;
      font-size: 16px;
      color: #8B8D96; }
    .filters-bar-leads .ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
      box-shadow: none;
      border: 1px solid #1890FF;
      background-color: #F4F9FF; }
      .filters-bar-leads .ant-input-affix-wrapper.ant-input-affix-wrapper-focused input {
        background-color: transparent; }
  .filters-bar-leads .archived {
    margin-bottom: 0px;
    color: #8B8D96;
    margin-left: auto; }
  .filters-bar-leads button {
    margin-left: auto; }
  .filters-bar-leads .clear {
    font-weight: 500;
    color: #398CAF;
    white-space: nowrap;
    margin-bottom: 0px;
    margin-right: 20px;
    cursor: pointer; }

@media (max-width: 768px) {
  .filters-bar-leads {
    display: block;
    padding: 20px;
    margin-bottom: 10px;
    background-color: #EAEDEE;
    position: fixed;
    width: 100%;
    z-index: 1; }
    .filters-bar-leads .inline {
      display: flex;
      width: 100%;
      margin-bottom: 20px; }
      .filters-bar-leads .inline:last-of-type {
        margin-bottom: 0px; }
      .filters-bar-leads .inline button {
        height: 50px;
        min-width: 50px; }
        .filters-bar-leads .inline button svg {
          font-size: 20px; }
      .filters-bar-leads .inline .filters {
        min-width: 50px;
        min-height: 50px;
        background-color: white;
        border-radius: 8px;
        margin-left: 20px;
        display: flex;
        align-items: center;
        justify-content: center; }
        .filters-bar-leads .inline .filters i:before {
          font-size: 20px;
          color: #505157; } }

.ant-checkbox-wrapper .status {
  display: flex;
  align-items: center;
  font-size: 14px; }
  .ant-checkbox-wrapper .status p {
    font-size: 14px !important; }
  .ant-checkbox-wrapper .status .dot {
    min-height: 10px;
    min-width: 10px;
    margin-right: 5px;
    border-radius: 50%; }
    .ant-checkbox-wrapper .status .dot.Open {
      background-color: #FFC100; }
    .ant-checkbox-wrapper .status .dot.Payment {
      background-color: #8B8D96; }
    .ant-checkbox-wrapper .status .dot.Closed {
      background-color: #52C41A; }
    .ant-checkbox-wrapper .status .dot.Lost {
      background-color: #FB4953; }

.filters-drawer-leads .ant-drawer-close {
  display: none; }

.filters-drawer-leads .head {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #BFBFBF; }
  .filters-drawer-leads .head p {
    font-size: 16px;
    color: #8B8D96; }

.filters-drawer-leads p.blue {
  color: #398CAF; }

.filters-drawer-leads .clear-all {
  font-size: 16px;
  margin-top: 20px;
  text-align: center; }

.filters-drawer-leads .filter {
  box-shadow: 0 6px 20px 8px #00000026;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 20px; }
  .filters-drawer-leads .filter .ant-checkbox-group {
    display: flex;
    flex-direction: column; }
    .filters-drawer-leads .filter .ant-checkbox-group label {
      background-color: #F5F5F5;
      margin-right: 0px;
      padding: 10px;
      border-radius: 4px;
      margin-bottom: 5px;
      font-size: 14px;
      color: #505157; }
    .filters-drawer-leads .filter .ant-checkbox-group .ant-checkbox-inner {
      height: 24px;
      width: 24px; }
    .filters-drawer-leads .filter .ant-checkbox-group .ant-checkbox-checked .ant-checkbox-inner:after {
      margin-left: 2px; }
  .filters-drawer-leads .filter .controls {
    margin-top: 5px;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between; }
    .filters-drawer-leads .filter .controls p {
      margin: 0px;
      font-size: 14px; }
      .filters-drawer-leads .filter .controls p:first-of-type {
        color: #505157;
        cursor: pointer; }
      .filters-drawer-leads .filter .controls p:last-of-type {
        color: #8B8D96;
        font-weight: 300; }
      .filters-drawer-leads .filter .controls p span {
        font-weight: 300; }
  .filters-drawer-leads .filter .separator {
    background-color: #BFBFBF;
    height: 1px;
    margin: 0 auto;
    display: block;
    width: 70%;
    position: relative;
    top: 1px;
    z-index: 1;
    z-index: 10; }
  .filters-drawer-leads .filter .ant-input-affix-wrapper {
    border: none;
    background-color: #F5F5F5; }
    .filters-drawer-leads .filter .ant-input-affix-wrapper input {
      background-color: transparent;
      height: 50px;
      color: #505157; }
    .filters-drawer-leads .filter .ant-input-affix-wrapper:first-of-type {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px; }
    .filters-drawer-leads .filter .ant-input-affix-wrapper:last-of-type {
      margin-top: -1px;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px; }
    .filters-drawer-leads .filter .ant-input-affix-wrapper .ant-input-prefix {
      color: #BFBFBF; }
    .filters-drawer-leads .filter .ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
      border: none;
      box-shadow: none; }
  .filters-drawer-leads .filter.statuses .ant-checkbox-wrapper {
    margin-left: 0px;
    display: flex;
    align-items: center; }
    .filters-drawer-leads .filter.statuses .ant-checkbox-wrapper .ant-checkbox {
      margin-right: 10px; }

.filters-drawer-leads .status .ant-progress-steps-item {
  height: 3px !important;
  border-radius: 3px; }
  .filters-drawer-leads .status .ant-progress-steps-item:last-of-type {
    margin-left: 10px; }

.filters-drawer-leads .status p {
  margin: 0px;
  font-weight: 500;
  font-size: 12px; }

.filters-bar-payments {
  z-index: 1;
  display: flex;
  align-items: center;
  margin-bottom: 30px; }
  .filters-bar-payments .inline .ant-radio-group-solid {
    overflow: hidden; }
    .filters-bar-payments .inline .ant-radio-group-solid .ant-radio-button-wrapper span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden; }
  .filters-bar-payments .ant-input-affix-wrapper {
    max-width: 390px;
    margin-right: 20px;
    border: none;
    border-radius: 8px; }
    .filters-bar-payments .ant-input-affix-wrapper input {
      height: 42px;
      font-size: 16px;
      color: #8B8D96; }
    .filters-bar-payments .ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
      box-shadow: none;
      border: 1px solid #1890FF;
      background-color: #F4F9FF; }
      .filters-bar-payments .ant-input-affix-wrapper.ant-input-affix-wrapper-focused input {
        background-color: transparent; }
  .filters-bar-payments button {
    margin-left: auto; }
  .filters-bar-payments .clear {
    font-weight: 500;
    color: #398CAF;
    white-space: nowrap;
    margin-bottom: 0px;
    margin-right: 20px;
    cursor: pointer; }

@media (max-width: 768px) {
  .filters-bar-payments {
    display: block;
    padding: 20px;
    margin-bottom: 10px;
    background-color: #EAEDEE;
    position: fixed;
    width: 100%; }
    .filters-bar-payments .inline {
      display: flex;
      width: 100%;
      margin-bottom: 20px; }
      .filters-bar-payments .inline:last-of-type {
        margin-bottom: 0px; }
      .filters-bar-payments .inline button {
        height: 50px;
        min-width: 50px; }
        .filters-bar-payments .inline button svg {
          font-size: 20px; }
      .filters-bar-payments .inline .filters {
        min-width: 50px;
        min-height: 50px;
        background-color: white;
        border-radius: 8px;
        margin-left: 20px;
        display: flex;
        align-items: center;
        justify-content: center; }
        .filters-bar-payments .inline .filters i:before {
          font-size: 20px;
          color: #505157;
          position: relative;
          top: 2px; } }

.filters-drawer-leads .ant-drawer-close {
  display: none; }

.filters-drawer-leads .head {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #BFBFBF; }
  .filters-drawer-leads .head p {
    font-size: 16px;
    color: #8B8D96; }

.filters-drawer-leads p.blue {
  color: #398CAF; }

.filters-drawer-leads .clear-all {
  font-size: 16px;
  margin-top: 20px;
  text-align: center; }

.filters-drawer-leads .filter {
  box-shadow: 0 6px 20px 8px #00000026;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 20px; }
  .filters-drawer-leads .filter .ant-checkbox-group {
    display: flex;
    flex-direction: column; }
    .filters-drawer-leads .filter .ant-checkbox-group label {
      background-color: #F5F5F5;
      margin-right: 0px;
      padding: 10px;
      border-radius: 4px;
      margin-bottom: 5px;
      font-size: 14px;
      color: #505157; }
    .filters-drawer-leads .filter .ant-checkbox-group .ant-checkbox-inner {
      height: 24px;
      width: 24px; }
    .filters-drawer-leads .filter .ant-checkbox-group .ant-checkbox-checked .ant-checkbox-inner:after {
      margin-left: 2px; }
  .filters-drawer-leads .filter .controls {
    margin-top: 5px;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between; }
    .filters-drawer-leads .filter .controls p {
      margin: 0px;
      font-size: 14px; }
      .filters-drawer-leads .filter .controls p:first-of-type {
        color: #505157;
        cursor: pointer; }
      .filters-drawer-leads .filter .controls p:last-of-type {
        color: #8B8D96;
        font-weight: 300; }
      .filters-drawer-leads .filter .controls p span {
        font-weight: 300; }
  .filters-drawer-leads .filter .separator {
    background-color: #BFBFBF;
    height: 1px;
    margin: 0 auto;
    display: block;
    width: 70%;
    position: relative;
    top: 1px;
    z-index: 1;
    z-index: 10; }
  .filters-drawer-leads .filter .ant-input-affix-wrapper {
    border: none;
    background-color: #F5F5F5; }
    .filters-drawer-leads .filter .ant-input-affix-wrapper input {
      background-color: transparent;
      height: 50px;
      color: #505157; }
    .filters-drawer-leads .filter .ant-input-affix-wrapper:first-of-type {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px; }
    .filters-drawer-leads .filter .ant-input-affix-wrapper:last-of-type {
      margin-top: -1px;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px; }
    .filters-drawer-leads .filter .ant-input-affix-wrapper .ant-input-prefix {
      color: #BFBFBF; }
    .filters-drawer-leads .filter .ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
      border: none;
      box-shadow: none; }
  .filters-drawer-leads .filter.statuses .ant-checkbox-wrapper {
    margin-left: 0px;
    display: flex;
    align-items: center; }
    .filters-drawer-leads .filter.statuses .ant-checkbox-wrapper .ant-checkbox {
      margin-right: 10px; }

.filters-drawer-leads .status {
  display: flex;
  align-items: center; }
  .filters-drawer-leads .status .ant-progress-steps-item {
    height: 3px !important;
    border-radius: 3px; }
    .filters-drawer-leads .status .ant-progress-steps-item:last-of-type {
      margin-left: 10px; }
  .filters-drawer-leads .status p {
    margin: 0px; }
  .filters-drawer-leads .status .dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 10px; }
    .filters-drawer-leads .status .dot.Cleared {
      background-color: #52C41A; }
    .filters-drawer-leads .status .dot.Due {
      background-color: #FFC100; }
    .filters-drawer-leads .status .dot.Late {
      background-color: #FB4953; }
    .filters-drawer-leads .status .dot.Cancelled {
      background-color: #1B1B1B; }

.basic .earnings {
  display: flex;
  width: 100%; }
  .basic .earnings .earnings-info {
    margin-right: 40px; }

.basic .search {
  display: flex;
  align-items: center;
  margin-top: 40px; }
  .basic .search .ant-input-affix-wrapper {
    width: 465px;
    margin-right: 15px;
    border-radius: 10px;
    border-color: white; }
    .basic .search .ant-input-affix-wrapper input {
      font-size: 16px; }
    .basic .search .ant-input-affix-wrapper svg {
      font-size: 18px;
      color: #8C8C8C;
      margin-right: 10px; }
  .basic .search > div {
    background: #FFFFFF;
    border-radius: 10px;
    height: 50px;
    padding: 14px; }
    .basic .search > div .ant-checkbox-wrapper-checked .ant-checkbox-inner {
      background: rgba(24, 144, 255, 0.15);
      border-color: #1890FF; }
      .basic .search > div .ant-checkbox-wrapper-checked .ant-checkbox-inner::after {
        border-color: #1890FF; }
    .basic .search > div .ant-checkbox-wrapper-checked span {
      color: #1890FF; }

.basic .product-list {
  margin: 0px -12px;
  margin-top: 20px; }

@media screen and (max-width: 768px) {
  .basic {
    background-color: #EAEDEE !important;
    padding: 20px !important; }
    .basic .earnings {
      display: unset; }
    .basic .search .ant-input-affix-wrapper {
      width: 44px;
      transition: all .3s; }
      .basic .search .ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
        width: 100%; }
    .basic .search .ant-checkbox-wrapper.small > span:not(.ant-checkbox) {
      display: none; } }

.earnings-info {
  height: 88px;
  padding: 13px 15px;
  border-radius: 10px;
  width: 298px;
  position: relative; }
  .earnings-info label {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 0px; }
  .earnings-info p {
    font-family: Poppins;
    font-weight: 700;
    font-size: 26px;
    margin-bottom: 0px; }
    .earnings-info p span {
      font-size: 18px; }
    .earnings-info p.placeholder {
      font-size: 20px;
      margin-top: 5px;
      opacity: .5; }
  .earnings-info.month {
    background-color: #BA4A00; }
    .earnings-info.month label {
      color: #E0C7A8; }
    .earnings-info.month p {
      color: white; }
      .earnings-info.month p.placeholder {
        color: white; }
  .earnings-info.future {
    background-color: white; }
    .earnings-info.future label {
      color: #BA4A00; }
    .earnings-info.future p {
      color: black; }
      .earnings-info.future p.placeholder {
        color: black; }
  .earnings-info img {
    position: absolute;
    filter: drop-shadow(2px 2px 8px rgba(148, 23, 46, 0.4));
    right: 10px;
    top: 15px;
    cursor: pointer; }
  @media (max-width: 768px) {
    .earnings-info {
      width: 100%;
      margin-bottom: 15px; } }

.make-money-modal h1 {
  font-family: Poppins;
  font-weight: 700;
  color: #8C8C8C;
  font-size: 20px;
  margin-top: -10px;
  margin-bottom: 20px; }
  .make-money-modal h1 img {
    position: relative;
    bottom: 14px; }

.make-money-modal .container {
  display: flex; }
  .make-money-modal .container .product-example {
    width: 35%;
    position: relative; }
    .make-money-modal .container .product-example .product {
      border-radius: 8px;
      box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.08), 0px 1px 18px rgba(0, 0, 0, 0.1), 0px 3px 5px rgba(0, 0, 0, 0.101535);
      overflow: hidden; }
      .make-money-modal .container .product-example .product img {
        width: 100%;
        height: 113px;
        object-fit: cover; }
      .make-money-modal .container .product-example .product > div {
        padding: 12px 10px; }
        .make-money-modal .container .product-example .product > div .title {
          color: #505157;
          font-weight: 300;
          font-size: 12px;
          margin-bottom: 10px; }
        .make-money-modal .container .product-example .product > div .price {
          font-weight: 500;
          color: black;
          font-size: 18px;
          margin-bottom: 0px; }
          .make-money-modal .container .product-example .product > div .price span {
            font-size: 12px; }
    .make-money-modal .container .product-example > img {
      position: absolute;
      bottom: 50px;
      right: -37px; }
  .make-money-modal .container .amounts {
    width: 67%;
    padding-left: 20px;
    z-index: 1; }
    .make-money-modal .container .amounts h2 {
      font-family: Poppins;
      font-weight: 700;
      font-size: 16px;
      color: #BA4A00;
      line-height: 24px; }
    .make-money-modal .container .amounts .example {
      border: 2px solid #DEDEDE;
      border-radius: 8px;
      margin-bottom: 12px;
      padding: 15px;
      background-color: white; }
      .make-money-modal .container .amounts .example label {
        font-size: 12px;
        color: #505157;
        margin-bottom: 0px; }
      .make-money-modal .container .amounts .example p {
        color: black;
        font-weight: 600;
        margin-bottom: 0px; }
        .make-money-modal .container .amounts .example p.red {
          color: #BA4A00; }

@media (max-width: 768px) {
  .make-money-modal .ant-modal {
    top: 0px; }
    .make-money-modal .ant-modal .container {
      display: unset; }
      .make-money-modal .ant-modal .container h1 img {
        display: none; }
      .make-money-modal .ant-modal .container > div {
        width: 100%;
        padding: 0px; }
        .make-money-modal .ant-modal .container > div > img {
          display: none; }
      .make-money-modal .ant-modal .container h2 {
        margin-top: 20px; } }

.product-item {
  padding: 12px; }
  .product-item > div {
    transition: all .5s;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: none; }
    .product-item > div .img-container {
      width: 100%;
      padding-top: 58%;
      background-size: cover !important;
      background-position: center !important; }
      .product-item > div .img-container img {
        width: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0; }
    .product-item > div .info {
      background-color: white;
      padding: 10px; }
      .product-item > div .info label {
        color: #505157;
        display: block;
        font-size: 12px; }
      .product-item > div .info h3 {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 0px; }
        .product-item > div .info h3 span {
          font-size: 14px; }
      .product-item > div .info h4 {
        font-size: 10px;
        color: #B6B8BF;
        text-decoration: line-through;
        min-height: 15px;
        margin-bottom: 10px; }
        .product-item > div .info h4 span {
          color: #4E505B;
          text-decoration: none; }
      .product-item > div .info .commission {
        height: 20px;
        width: 100%;
        background: #557672;
        border-radius: 3px;
        position: relative;
        padding-left: 33px;
        font-weight: 500;
        color: white;
        line-height: 1.45; }
        .product-item > div .info .commission img {
          position: absolute;
          left: -8px;
          top: -8px; }
        .product-item > div .info .commission .get {
          opacity: .5;
          margin-right: 10px; }
        .product-item > div .info .commission .smaller {
          font-size: 10px; }
    .product-item > div .mobile-share-btn {
      width: 30px;
      height: 30px;
      position: absolute;
      border-radius: 50%;
      background: #BA4A00;
      top: 20px;
      left: 20px; }
      .product-item > div .mobile-share-btn i:before {
        color: white;
        position: relative;
        top: 6px;
        left: 9px; }
    .product-item > div.hover {
      box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.08), 0px 1px 18px rgba(0, 0, 0, 0.1), 0px 3px 5px rgba(0, 0, 0, 0.101535); }
      .product-item > div.hover .img-container {
        filter: brightness(0.5);
        -webkit-filter: brightness(0.5);
        -moz-filter: brightness(0.5);
        -o-filter: brightness(0.5);
        -ms-filter: brightness(0.5); }
      .product-item > div.hover .share-btn {
        position: absolute;
        font-size: 14px;
        color: white;
        padding: 9px 11px;
        cursor: pointer;
        background-color: #BA4A00;
        border-radius: 100px;
        z-index: 1;
        margin: 0;
        top: 25%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        transition: all .5s; }
        .product-item > div.hover .share-btn i {
          margin-right: 5px; }
          .product-item > div.hover .share-btn i:before {
            position: relative;
            top: 3px; }
        .product-item > div.hover .share-btn:hover {
          background-color: #595959; }
  .product-item.promoted > div {
    border: 2px solid #BA4A00; }
    .product-item.promoted > div h3 {
      color: #BA4A00; }

.intro-modal .ant-modal-content {
  overflow: hidden; }

.intro-modal h1 {
  color: #BA4A00;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 40px; }

.intro-modal .steps {
  display: flex; }
  .intro-modal .steps .step {
    flex-grow: 1;
    flex-basis: 0;
    position: relative; }
    .intro-modal .steps .step .img-container {
      width: 100%;
      background: #EAEDEE;
      background-size: contain !important;
      background-repeat: no-repeat !important;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 186px;
      width: 186px;
      margin: 0 auto; }
      .intro-modal .steps .step .img-container img {
        width: 100%;
        height: 100%;
        object-fit: contain; }
    .intro-modal .steps .step.1 img {
      transform: scale(1.3); }
    .intro-modal .steps .step.2 img {
      height: 70%; }
    .intro-modal .steps .step.3 img {
      height: 80%; }
    .intro-modal .steps .step .text {
      display: flex;
      color: #687A80;
      font-weight: 800;
      font-size: 16px;
      align-items: center;
      justify-content: center;
      margin-top: 15px; }
      .intro-modal .steps .step .text .number {
        border: 3px solid #687A80;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px; }
      .intro-modal .steps .step .text p {
        margin: 0px; }
    .intro-modal .steps .step .arrow {
      position: absolute;
      right: -5%;
      top: 25%; }

.intro-modal .text-box {
  margin-top: 40px;
  background-color: #EFF2F3;
  padding: 25px 30px;
  width: calc(100% + 48px);
  margin-left: -24px;
  margin-bottom: -24px; }
  .intro-modal .text-box p {
    color: #505157;
    font-size: 16px;
    font-weight: 400; }
    .intro-modal .text-box p .underline {
      text-decoration: underline; }
    .intro-modal .text-box p .bold {
      font-weight: 600; }
    .intro-modal .text-box p .red {
      font-weight: 600;
      color: #BA4A00; }

@media (max-width: 768px) {
  .intro-modal .ant-modal {
    top: 0px !important; }
  .intro-modal .ant-modal-close {
    top: -10px;
    right: -10px; }
  .intro-modal .steps {
    display: block; }
    .intro-modal .steps .step {
      margin-bottom: 40px; }
    .intro-modal .steps .arrow {
      display: none; }
  .intro-modal .ant-btn.primary-medium-2 {
    margin-left: -24px;
    position: relative;
    top: 24px;
    width: calc(100% + 48px);
    border-radius: 0px; } }

.loading-placeholder {
  height: 100vh;
  width: 100vw; }

.anticon-loading svg {
  color: #BA4A00; }

.cost-breakdown {
  background-color: #EAEDEE;
  min-height: 100vh;
  overflow: auto; }
  .cost-breakdown .discount-rate {
    background-color: #BA4A00;
    font-weight: 600;
    border-radius: 4px;
    padding: 3px 10px;
    color: white;
    margin-right: 10px; }
  .cost-breakdown .container {
    width: 620px;
    margin: 0 auto;
    display: block;
    margin-top: 20px;
    border-radius: 8px;
    position: relative;
    margin-bottom: 20px; }
    .cost-breakdown .container .head {
      background-color: #505157;
      display: flex;
      padding: 20px;
      border-radius: 8px 8px 0px 0px;
      box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.08), 0 1px 18px 0 rgba(0, 0, 0, 0.1), 0 3px 5px 0 rgba(0, 0, 0, 0.1); }
      .cost-breakdown .container .head p {
        color: white;
        margin-bottom: 0px;
        font-weight: 300; }
        .cost-breakdown .container .head p.bold {
          font-weight: 500; }
      .cost-breakdown .container .head div:last-of-type {
        text-align: right;
        margin-left: auto; }
    .cost-breakdown .container .body {
      box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.08), 0 1px 18px 0 rgba(0, 0, 0, 0.1), 0 3px 5px 0 rgba(0, 0, 0, 0.1);
      padding: 20px;
      overflow: hidden;
      border-radius: 0px 0px 8px 8px;
      background-color: white; }
      .cost-breakdown .container .body h1 {
        font-size: 16px;
        margin-bottom: 40px; }
      .cost-breakdown .container .body .labels {
        display: inline-block;
        width: 100%;
        border-bottom: 1px solid #8B8D96;
        padding-bottom: 10px;
        margin-bottom: -10px; }
        .cost-breakdown .container .body .labels div {
          float: left;
          font-weight: 500; }
      .cost-breakdown .container .body .width-5 {
        width: 5%; }
      .cost-breakdown .container .body .width-15 {
        width: 15%; }
      .cost-breakdown .container .body .width-45 {
        width: 45%; }
      .cost-breakdown .container .body .width-20 {
        width: 20%; }
      .cost-breakdown .container .body .discounted-price {
        width: 90%;
        text-align: right;
        margin-top: 5px;
        padding-right: 13px;
        color: #BA4A00; }
      .cost-breakdown .container .body .strikethrough {
        text-decoration: line-through; }
      .cost-breakdown .container .body .product-item {
        width: 100%;
        cursor: pointer;
        overflow: hidden; }
        .cost-breakdown .container .body .product-item > div {
          float: left; }
        .cost-breakdown .container .body .product-item .image-container {
          width: 100%;
          margin-top: 20px; }
          .cost-breakdown .container .body .product-item .image-container img {
            width: 130px;
            height: auto;
            border-radius: 4px; }
        .cost-breakdown .container .body .product-item .product-details {
          width: 100%;
          display: inline-block;
          margin-top: 20px;
          border-bottom: 1px solid #EAEAEA;
          padding-bottom: 10px;
          cursor: pointer; }
          .cost-breakdown .container .body .product-item .product-details div {
            float: left;
            font-weight: 300; }
            .cost-breakdown .container .body .product-item .product-details div.gray {
              color: #BFBFBF; }
            .cost-breakdown .container .body .product-item .product-details div .width-5 {
              text-align: right; }
      .cost-breakdown .container .body .total {
        width: 100%;
        display: flex;
        border-top: 1px solid black;
        padding-top: 10px; }
        .cost-breakdown .container .body .total div {
          float: left;
          color: black;
          font-size: 16px;
          font-weight: 600;
          margin-left: -5px; }
    .cost-breakdown .container .options {
      position: absolute;
      right: -190px; }
      .cost-breakdown .container .options p {
        color: #505157;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 0px; }
      .cost-breakdown .container .options .ant-select {
        margin-bottom: 30px; }
      .cost-breakdown .container .options .ant-select-selector {
        padding-left: 0px;
        border: none;
        font-size: 16px;
        background-color: transparent; }
      .cost-breakdown .container .options .ant-select-arrow {
        color: #505157;
        top: 48%; }
      .cost-breakdown .container .options .share-buttons div {
        cursor: pointer;
        width: 100%;
        color: white;
        border-radius: 4px;
        margin-bottom: 5px;
        padding: 4px 8px; }
        .cost-breakdown .container .options .share-buttons div.whatsapp {
          background-color: #23CD64; }
        .cost-breakdown .container .options .share-buttons div.link {
          background-color: #505157; }
  .cost-breakdown.product-open .container {
    left: -520px; }
    .cost-breakdown.product-open .container .options {
      right: -720px; }
    .cost-breakdown.product-open .container .head, .cost-breakdown.product-open .container .body {
      opacity: .5; }
  .cost-breakdown .become-reseller {
    width: 176px;
    border-radius: 8px;
    box-shadow: 1px 3px 10px rgba(90, 48, 49, 0.1);
    background-color: white;
    padding: 10px;
    margin-top: 30px; }
    .cost-breakdown .become-reseller p {
      font-size: 14px !important;
      font-weight: 400 !important;
      color: #505157 !important;
      margin-bottom: 30px !important; }
    .cost-breakdown .become-reseller button {
      color: white;
      width: 100%; }

@media (max-width: 768px) {
  div.cost-breakdown {
    display: inherit; }
    div.cost-breakdown .container {
      width: 100%;
      padding: 20px;
      margin-top: 0px; }
    div.cost-breakdown .options {
      background-color: #EAEDEE;
      margin-bottom: 20px;
      position: relative !important;
      right: 0px !important;
      display: flex;
      justify-content: space-between; }
      div.cost-breakdown .options .ant-select {
        margin-bottom: 0px !important;
        margin-top: 6px; }
      div.cost-breakdown .options .share-button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 146px;
        height: 40px;
        background-color: #23CD64;
        border-radius: 4px;
        padding: 10px;
        color: white; }
        div.cost-breakdown .options .share-button p {
          color: white; }
    div.cost-breakdown .head {
      display: block !important; }
      div.cost-breakdown .head div:last-of-type {
        margin-top: 30px;
        text-align: left !important; }
    div.cost-breakdown .body {
      padding-bottom: 60px !important; }
      div.cost-breakdown .body h1 {
        margin-bottom: 30px !important; }
      div.cost-breakdown .body .product-item-mobile {
        cursor: pointer;
        display: flex;
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid #EAEAEA; }
        div.cost-breakdown .body .product-item-mobile img {
          width: 50px;
          height: 50px;
          object-fit: cover;
          border-radius: 4px;
          margin-right: 20px; }
        div.cost-breakdown .body .product-item-mobile .product-details {
          width: 100%; }
          div.cost-breakdown .body .product-item-mobile .product-details .inline {
            display: flex;
            justify-content: space-between; }
            div.cost-breakdown .body .product-item-mobile .product-details .inline .item-total {
              margin-left: auto;
              margin-right: 10px; }
      div.cost-breakdown .body .total {
        margin-top: -20px;
        justify-content: space-between; }
        div.cost-breakdown .body .total div {
          margin-left: 0px !important; }
    div.cost-breakdown.product-open .container {
      left: 0px !important; }
      div.cost-breakdown.product-open .container .head, div.cost-breakdown.product-open .container .body {
        display: none !important; } }

@media (min-width: 768px) and (max-width: 1035px) {
  .cost-breakdown .container {
    width: 537px !important;
    right: 96px; }
  .cost-breakdown.product-open .container {
    left: -620px !important; } }

@media (max-width: 768px) {
  .become-reseller {
    width: 100% !important;
    margin-bottom: 20px; }
  .cost-breakdown .container .body {
    margin-bottom: 50px; } }

#dashboard .dashboard-section-title {
  font-size: 14px !important;
  font-weight: normal;
  color: #BA4A00;
  margin-bottom: 20px; }

#dashboard .no-data {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }
  #dashboard .no-data img {
    margin: 0 auto;
    display: block; }
  #dashboard .no-data p {
    text-align: center;
    margin-top: 10px; }

#dashboard .number {
  border-radius: 6px;
  padding: 15px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around; }
  #dashboard .number h4 {
    font-size: 12px;
    margin-bottom: 0px; }
  #dashboard .number p {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0px; }
  #dashboard .number.primary {
    background-color: #BA4A00; }
    #dashboard .number.primary * {
      color: white; }
  #dashboard .number.secondary {
    background-color: #ba4a0021; }
    #dashboard .number.secondary * {
      color: #8B8D96; }
  #dashboard .number.gray {
    background-color: #F3F4F4; }
    #dashboard .number.gray * {
      color: #8B8D96; }
  #dashboard .number.bigger h4 {
    font-size: 14px;
    margin-bottom: 10px; }

#dashboard .padding {
  padding: 10px; }

#dashboard .user-filter-header {
  display: flex;
  align-items: center;
  margin: 10px 0px; }
  #dashboard .user-filter-header p {
    margin-bottom: 0px;
    margin-right: 20px;
    line-height: 3.5; }
  #dashboard .user-filter-header .ant-select {
    width: 400px;
    border-radius: 8px;
    border: 2px solid white; }
    #dashboard .user-filter-header .ant-select .ant-select-selector {
      border: none; }
    #dashboard .user-filter-header .ant-select svg {
      position: relative;
      bottom: 7px;
      right: 4px;
      font-size: 20px; }
    #dashboard .user-filter-header .ant-select.active {
      border: 2px solid #398CAF;
      background-color: #D9EBF3;
      color: #398CAF; }
      #dashboard .user-filter-header .ant-select.active .ant-select-selector {
        background-color: #D9EBF3; }
      #dashboard .user-filter-header .ant-select.active .ant-select-arrow {
        display: none; }
      #dashboard .user-filter-header .ant-select.active .ant-select-clear {
        opacity: 1; }
      #dashboard .user-filter-header .ant-select.active .ant-select-clear {
        background-color: #398CAF;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2px;
        top: 42% !important; }
        #dashboard .user-filter-header .ant-select.active .ant-select-clear svg {
          bottom: 0px;
          right: 0px;
          font-size: 10px;
          color: white; }
    #dashboard .user-filter-header .ant-select.inactive .ant-select-clear {
      opacity: 0 !important; }

#dashboard .chart-container {
  border-radius: 10px;
  box-shadow: 0px 10px 20px rgba(112, 144, 176, 0.1);
  background-color: white;
  padding: 20px 20px 40px 20px;
  height: 100%;
  position: relative;
  min-height: 350px; }
  #dashboard .chart-container > p, #dashboard .chart-container .ant-col-xs-24 > p {
    font-weight: 400;
    font-size: 18px; }
  #dashboard .chart-container .ant-col-xs-24 > p {
    margin-bottom: 0px;
    margin-top: -10px; }
  #dashboard .chart-container .recharts-cartesian-axis-line, #dashboard .chart-container .recharts-cartesian-axis-tick-line {
    display: none; }
  #dashboard .chart-container .recharts-default-legend {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: -27%;
    bottom: 0px; }
    #dashboard .chart-container .recharts-default-legend li {
      margin-bottom: 10px; }
  #dashboard .chart-container .recharts-surface {
    margin-left: -30px; }
  #dashboard .chart-container .recharts-legend-item-text {
    color: #8B8D96 !important; }
  #dashboard .chart-container .recharts-yAxis .recharts-cartesian-axis-ticks .recharts-layer:first-of-type {
    display: none; }
  #dashboard .chart-container .recharts-cartesian-axis-ticks .recharts-layer {
    color: #8B8D96; }
  #dashboard .chart-container .recharts-tooltip-wrapper * {
    color: #8B8D96;
    border-radius: 6px; }
  #dashboard .chart-container .controls {
    position: absolute;
    right: 20px;
    bottom: 20px; }
    #dashboard .chart-container .controls .anticon-left {
      margin-right: 20px; }
  #dashboard .chart-container .sub-title {
    color: #8B8D96;
    margin-top: 30px;
    font-size: 14px; }
  #dashboard .chart-container .bar {
    font-size: 14px;
    color: white;
    background-color: #BA4A00;
    border-radius: 25px;
    padding: 10px 20px; }
  #dashboard .chart-container .numbers .ant-col {
    padding: 10px; }
    #dashboard .chart-container .numbers .ant-col .notice {
      font-size: 12px;
      color: #8B8D96;
      margin-bottom: 0px; }
  #dashboard .chart-container.opportunities-amount {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 20px !important; }
    #dashboard .chart-container.opportunities-amount > p:first-of-type {
      margin-bottom: 0px; }
    #dashboard .chart-container.opportunities-amount.nodata {
      justify-content: unset; }
  #dashboard .chart-container .recharts-responsive-container {
    position: relative;
    top: 20px; }
  #dashboard .chart-container .title {
    font-size: 14px;
    color: #8B8D96; }
  #dashboard .chart-container.leaderboard {
    overflow-x: auto; }
    #dashboard .chart-container.leaderboard thead th {
      position: sticky !important;
      top: 0px;
      background-color: white;
      z-index: 10;
      font-weight: 400 !important;
      box-shadow: 19px 10px 11px 2px #88888824; }
      #dashboard .chart-container.leaderboard thead th:first-of-type {
        box-shadow: 7px 10px 18px 8px #88888824; }
    #dashboard .chart-container.leaderboard .controls {
      bottom: 30px; }
    #dashboard .chart-container.leaderboard .number {
      height: auto !important; }
    #dashboard .chart-container.leaderboard .ant-pagination {
      display: none; }
    #dashboard .chart-container.leaderboard .ant-table-content * {
      font-size: 12px !important; }
    #dashboard .chart-container.leaderboard .ant-table-content th, #dashboard .chart-container.leaderboard .ant-table-content td, #dashboard .chart-container.leaderboard .ant-table-content .ant-table-column-sorters > span:first-of-type {
      color: #8B8D96 !important; }
    #dashboard .chart-container.leaderboard .ant-table-content .ant-table-cell {
      border-bottom: none !important; }
    #dashboard .chart-container.leaderboard .ant-table-content .ant-table-column-sort {
      background: white; }
    #dashboard .chart-container.leaderboard .ant-table-content .ant-table-tbody > tr.ant-table-row:hover > td {
      background: #fafafa !important; }
    #dashboard .chart-container.leaderboard .inline {
      display: flex; }
      #dashboard .chart-container.leaderboard .inline .numbers {
        width: 20%;
        padding-left: 20px;
        margin: 0 auto; }
        #dashboard .chart-container.leaderboard .inline .numbers .number {
          border-radius: 6px;
          margin-bottom: 25px;
          padding: 10px; }
          #dashboard .chart-container.leaderboard .inline .numbers .number h4 {
            font-size: 12px;
            font-weight: normal;
            margin: 0px; }
          #dashboard .chart-container.leaderboard .inline .numbers .number p {
            font-size: 19px;
            font-weight: 600;
            margin: 0px; }
          #dashboard .chart-container.leaderboard .inline .numbers .number.primary {
            background-color: #ba4a0021; }
            #dashboard .chart-container.leaderboard .inline .numbers .number.primary h4, #dashboard .chart-container.leaderboard .inline .numbers .number.primary p {
              color: #8B8D96; }
          #dashboard .chart-container.leaderboard .inline .numbers .number.secondary {
            background-color: #BA4A00; }
            #dashboard .chart-container.leaderboard .inline .numbers .number.secondary h4, #dashboard .chart-container.leaderboard .inline .numbers .number.secondary p {
              color: white; }
  #dashboard .chart-container.agents-overview .ant-col {
    padding: 10px; }
  #dashboard .chart-container.income-chart .recharts-surface {
    margin-left: 0px !important; }
  #dashboard .chart-container.income-chart.admin .legend-item-2, #dashboard .chart-container.income-chart.admin .legend-item-3 {
    display: none !important; }
  #dashboard .chart-container.income-chart.admin .recharts-tooltip-item-list li:nth-of-type(3), #dashboard .chart-container.income-chart.admin .recharts-tooltip-item-list li:nth-of-type(4) {
    display: none !important; }
  #dashboard .chart-container.income-chart.agent-reseller .legend-item-1 {
    display: none !important; }
  #dashboard .chart-container.income-chart.agent-reseller li:nth-of-type(2) {
    display: none !important; }
  #dashboard .chart-container.promotions {
    height: auto; }
    #dashboard .chart-container.promotions .promotion-product {
      padding: 15px;
      border-radius: 4px; }
      #dashboard .chart-container.promotions .promotion-product .discount-rate {
        background-color: #BA4A00;
        font-weight: 600;
        border-radius: 4px;
        padding: 3px 7px;
        color: white;
        margin-right: 10px;
        max-width: 45px; }
      #dashboard .chart-container.promotions .promotion-product .icon {
        background-color: #505157;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px;
        max-height: 32px;
        max-width: 32px;
        margin-left: auto;
        cursor: pointer; }
        #dashboard .chart-container.promotions .promotion-product .icon i {
          color: #F0F0F0; }
          #dashboard .chart-container.promotions .promotion-product .icon i:before {
            position: relative;
            top: 2px; }
      #dashboard .chart-container.promotions .promotion-product p {
        margin-bottom: 0px; }
        #dashboard .chart-container.promotions .promotion-product p span {
          margin-right: 10px; }
      #dashboard .chart-container.promotions .promotion-product .name {
        font-weight: 300;
        color: #8B8D96; }
      #dashboard .chart-container.promotions .promotion-product .original-price {
        color: #8B8D96;
        text-decoration: line-through;
        font-weight: 300; }
      #dashboard .chart-container.promotions .promotion-product .price {
        color: #BA4A00; }
      #dashboard .chart-container.promotions .promotion-product:nth-of-type(odd) {
        background-color: #F0F0F0; }

@media (max-width: 768px) {
  #dashboard {
    background-color: #EAEDEE !important;
    padding: 15px;
    overflow: hidden; }
    #dashboard .agent-dashboard-sales {
      flex-direction: column-reverse; }
    #dashboard .recharts-responsive-container {
      top: 0px !important; }
    #dashboard .right-section .filters {
      flex-wrap: wrap;
      justify-content: unset !important; }
      #dashboard .right-section .filters .ant-btn {
        margin-bottom: 20px; }
    #dashboard .recharts-responsive-container {
      width: 95% !important; }
    #dashboard .recharts-default-legend {
      right: -10% !important;
      top: -175px; }
    #dashboard .income-chart .recharts-default-legend {
      display: block;
      top: -200px !important; }
    #dashboard .controls {
      width: calc(100% - 40px);
      display: flex;
      justify-content: space-between;
      bottom: 25px !important; }
    #dashboard .chart-container .recharts-wrapper {
      top: 20px; }
    #dashboard .chart-container.leaderboard {
      position: relative;
      margin-bottom: 20px; }
      #dashboard .chart-container.leaderboard .inline {
        width: 100%;
        display: block; }
      #dashboard .chart-container.leaderboard .controls {
        justify-content: flex-end !important;
        top: 25px; }
    #dashboard .chart-container .numbers {
      display: flex; }
      #dashboard .chart-container .numbers > div:nth-child(odd) {
        padding-left: 0px; }
      #dashboard .chart-container .numbers > div:nth-child(even) {
        padding-right: 0px; }
      #dashboard .chart-container .numbers p {
        margin-bottom: 0px; }
    #dashboard .user-filter-header {
      padding: 0px 10px; }
      #dashboard .user-filter-header .ant-select {
        width: 50vw; }
      #dashboard .user-filter-header p {
        line-height: 1.5; }
    #dashboard .agent-opportunities-container {
      order: 2; } }

.dashboard-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px; }
  .dashboard-header .left-section {
    display: flex; }
    .dashboard-header .left-section .view-btn {
      height: 50px;
      margin-right: 20px;
      border: 1px solid #398CAF;
      border-radius: 8px;
      background-color: transparent;
      color: #398CAF; }
      .dashboard-header .left-section .view-btn.active {
        background-color: #398CAF;
        color: white; }
  .dashboard-header .right-section .inline {
    display: flex;
    margin-bottom: 20px;
    margin-top: 10px; }
    .dashboard-header .right-section .inline p {
      text-align: right;
      color: #BA4A00;
      margin-bottom: 0px;
      line-height: 32px; }
      .dashboard-header .right-section .inline p span {
        font-weight: 500; }
    .dashboard-header .right-section .inline .ant-picker {
      border: none;
      background-color: transparent; }
      .dashboard-header .right-section .inline .ant-picker input {
        color: #BA4A00;
        font-weight: 500;
        text-align: center; }
      .dashboard-header .right-section .inline .ant-picker .anticon {
        font-size: 20px; }
      .dashboard-header .right-section .inline .ant-picker .ant-picker-clear {
        background-color: #EAEDEE; }
  .dashboard-header .right-section .filters {
    display: flex;
    justify-content: flex-end; }
    .dashboard-header .right-section .filters .ant-btn {
      margin-right: 20px;
      height: 30px;
      color: #8B8D96;
      border: 1px solid #8B8D96;
      background-color: white;
      border-radius: 7px;
      font-weight: normal;
      display: flex;
      height: 38px;
      padding-top: 6px; }
      .dashboard-header .right-section .filters .ant-btn .anticon-down {
        margin-top: 4px; }
      .dashboard-header .right-section .filters .ant-btn.active {
        background-color: #D9EBF3;
        color: #398CAF;
        border: 1px solid #398CAF; }
        .dashboard-header .right-section .filters .ant-btn.active .anticon-close {
          color: white;
          background: #398CAF;
          min-width: 20px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 2px;
          margin-top: 2px; }
          .dashboard-header .right-section .filters .ant-btn.active .anticon-close svg {
            font-size: 10px;
            font-weight: bold; }

@media (max-width: 992px) and (min-width: 768px) {
  .dashboard-header {
    display: block !important; }
    .dashboard-header .filters {
      justify-content: flex-start !important; } }

@media (max-width: 768px) {
  .ant-picker-dropdown {
    max-width: 282px;
    margin: 20px; }
    .ant-picker-dropdown .ant-picker-panels {
      flex-wrap: wrap; }
  .section-selector.reseller.ant-radio-group.ant-radio-group-solid > label.ant-radio-button-wrapper {
    width: 100% !important;
    color: #BA4A00 !important;
    background-color: white !important; }
  .dashboard-header {
    display: block !important;
    margin-bottom: 0px; }
    .dashboard-header .filters .ant-btn {
      margin-right: 10px; }
    .dashboard-header .mobile-header {
      margin-bottom: 20px; }
      .dashboard-header .mobile-header .selectors {
        display: flex; }
        .dashboard-header .mobile-header .selectors .ant-picker {
          background-color: white !important;
          margin-left: 10px;
          padding: 14px; }
          .dashboard-header .mobile-header .selectors .ant-picker > *:not(.ant-picker-suffix) {
            display: none; }
          .dashboard-header .mobile-header .selectors .ant-picker svg {
            font-size: 20px;
            color: black; }
      .dashboard-header .mobile-header .date-display {
        display: flex;
        margin-top: 20px;
        color: #BA4A00; }
        .dashboard-header .mobile-header .date-display p {
          margin-bottom: 0px; }
        .dashboard-header .mobile-header .date-display .bold {
          text-align: right;
          font-weight: 600; } }

.selector .ant-popover-inner-content {
  max-height: 300px;
  overflow-y: scroll; }
  .selector .ant-popover-inner-content .ant-checkbox-wrapper {
    width: 100%;
    margin-bottom: 10px;
    font-weight: normal; }

.selector .checkbox-group {
  display: flex;
  flex-direction: column; }
  .selector .checkbox-group label {
    background-color: #F5F5F5;
    margin-right: 0px;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 5px;
    font-size: 12px; }
    .selector .checkbox-group label.ant-checkbox-wrapper {
      display: flex;
      margin-left: 0px;
      align-items: center; }

.rangepicker-footer p {
  color: #8B8D96;
  margin-bottom: 0px;
  line-height: 1.5;
  margin-top: 10px; }

.rangepicker-footer .ant-select {
  height: 37px;
  margin: 20px 0px;
  margin-right: 20px; }
  .rangepicker-footer .ant-select .ant-select-selector {
    height: 37px;
    padding-top: 2px;
    border-color: #8B8D96; }

.rangepicker-footer .controls {
  background-color: #EAEDEE;
  margin-left: -20px;
  width: calc(100% + 40px);
  margin-bottom: -1px;
  padding: 20px;
  display: flex;
  justify-content: flex-end; }
  .rangepicker-footer .controls button {
    margin-left: 20px; }

.filters-bar-resellers {
  display: flex;
  align-items: center;
  margin-bottom: 10px; }
  .filters-bar-resellers > * {
    margin-bottom: 20px; }
  .filters-bar-resellers .primary-medium-3 span {
    position: relative;
    bottom: 2px; }
  .filters-bar-resellers .ant-input-affix-wrapper {
    margin-right: 20px;
    border-color: transparent;
    border-radius: 8px;
    max-width: 350px; }
    .filters-bar-resellers .ant-input-affix-wrapper input {
      height: 42px;
      font-size: 16px;
      color: #8B8D96; }
    .filters-bar-resellers .ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
      box-shadow: none;
      border: 1px solid #1890FF;
      background-color: #F4F9FF; }
      .filters-bar-resellers .ant-input-affix-wrapper.ant-input-affix-wrapper-focused input {
        background-color: transparent; }
  .filters-bar-resellers .primary-medium-3 {
    margin-right: 20px; }
  .filters-bar-resellers .primary-medium-1 {
    margin-left: auto; }
  .filters-bar-resellers .clear {
    font-weight: 500;
    color: #398CAF;
    cursor: pointer; }

@media (max-width: 768px) {
  .filters-bar-resellers {
    display: block;
    padding: 20px;
    margin-bottom: 10px;
    background-color: #EAEDEE;
    position: fixed;
    width: 100%; }
    .filters-bar-resellers .inline {
      display: flex;
      width: 100%;
      margin-bottom: 20px; }
      .filters-bar-resellers .inline:last-of-type {
        margin-bottom: 0px; }
      .filters-bar-resellers .inline button {
        height: 50px;
        min-width: 50px; }
        .filters-bar-resellers .inline button svg {
          font-size: 20px; }
      .filters-bar-resellers .inline .filters {
        min-width: 50px;
        min-height: 50px;
        background-color: white;
        border-radius: 8px;
        margin-left: 20px;
        display: flex;
        align-items: center;
        justify-content: center; }
        .filters-bar-resellers .inline .filters i:before {
          font-size: 20px;
          color: #505157; } }

.ant-checkbox-wrapper .status {
  display: flex;
  align-items: center;
  font-size: 14px; }
  .ant-checkbox-wrapper .status p {
    font-size: 14px !important; }
  .ant-checkbox-wrapper .status .dot {
    height: 10px;
    width: 10px;
    margin-right: 5px;
    border-radius: 50%; }
    .ant-checkbox-wrapper .status .dot.Inactive {
      background-color: #FFC100; }
    .ant-checkbox-wrapper .status .dot.Active {
      background-color: #52C41A; }
    .ant-checkbox-wrapper .status .dot.Pending {
      background-color: #1890FF; }

.filters-bar-promotions {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  flex-wrap: wrap; }
  .filters-bar-promotions > * {
    margin-bottom: 20px; }
  .filters-bar-promotions .primary-medium-3 i::before {
    font-size: 18px;
    position: relative;
    top: 2px; }
  .filters-bar-promotions .primary-medium-3 span {
    position: relative;
    bottom: 2px;
    margin-left: 10px; }
  .filters-bar-promotions .ant-input-affix-wrapper {
    margin-right: 20px;
    border-color: transparent;
    border-radius: 8px;
    width: 315px; }
    .filters-bar-promotions .ant-input-affix-wrapper input {
      height: 42px;
      font-size: 16px;
      color: #8B8D96; }
    .filters-bar-promotions .ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
      box-shadow: none;
      border: 1px solid #1890FF;
      background-color: #F4F9FF; }
      .filters-bar-promotions .ant-input-affix-wrapper.ant-input-affix-wrapper-focused input {
        background-color: transparent; }
  .filters-bar-promotions .primary-medium-3 {
    margin-right: 20px; }
  .filters-bar-promotions .primary-medium-1 {
    margin-left: auto; }
  .filters-bar-promotions .clear {
    font-weight: 500;
    color: #398CAF;
    cursor: pointer; }

@media (max-width: 768px) {
  .filters-bar-promotions {
    display: block;
    padding: 20px;
    margin-bottom: 10px;
    background-color: #EAEDEE;
    position: fixed;
    width: 100%; }
    .filters-bar-promotions .ant-input-affix-wrapper {
      width: inherit; }
    .filters-bar-promotions .section-selector {
      margin-top: 0px !important; }
    .filters-bar-promotions .inline {
      display: flex;
      width: 100%;
      margin-bottom: 20px; }
      .filters-bar-promotions .inline:last-of-type {
        margin-bottom: 0px; }
      .filters-bar-promotions .inline button {
        height: 50px;
        min-width: 50px; }
        .filters-bar-promotions .inline button svg {
          font-size: 20px; }
      .filters-bar-promotions .inline .filters {
        min-width: 50px;
        min-height: 50px;
        background-color: white;
        border-radius: 8px;
        margin-left: 20px;
        display: flex;
        align-items: center;
        justify-content: center; }
        .filters-bar-promotions .inline .filters i:before {
          font-size: 20px;
          color: #505157;
          position: relative;
          top: 3px; }
        .filters-bar-promotions .inline .filters.marketplace {
          margin-left: 0px; } }

.status-promotion {
  display: flex;
  align-items: center; }
  .status-promotion p {
    margin: 0px; }
  .status-promotion .dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 10px; }
    .status-promotion .dot.Active {
      background-color: #52C41A; }
    .status-promotion .dot.Inactive {
      background-color: #FFC100; }

.filters-bar-customers {
  display: flex;
  align-items: center;
  margin-bottom: 30px; }
  .filters-bar-customers .ant-input-affix-wrapper {
    max-width: 390px;
    margin-right: 20px;
    border: none;
    border-radius: 8px; }
    .filters-bar-customers .ant-input-affix-wrapper input {
      height: 42px;
      font-size: 16px;
      color: #8B8D96; }
    .filters-bar-customers .ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
      box-shadow: none;
      border: 1px solid #1890FF;
      background-color: #F4F9FF; }
      .filters-bar-customers .ant-input-affix-wrapper.ant-input-affix-wrapper-focused input {
        background-color: transparent; }
  .filters-bar-customers .archived {
    margin-bottom: 0px;
    color: #8B8D96;
    margin-left: auto; }
  .filters-bar-customers button.bulk-import-btn {
    margin-left: auto;
    margin-right: 20px; }
  .filters-bar-customers .clear {
    font-weight: 500;
    color: #398CAF;
    white-space: nowrap;
    margin-bottom: 0px;
    margin-right: 20px;
    cursor: pointer; }

@media (max-width: 768px) {
  .filters-bar-customers {
    display: block;
    padding: 20px;
    margin-bottom: 10px;
    background-color: #EAEDEE;
    position: fixed;
    width: 100%;
    z-index: 1; }
    .filters-bar-customers .inline {
      display: flex;
      width: 100%;
      margin-bottom: 20px; }
      .filters-bar-customers .inline:last-of-type {
        margin-bottom: 0px; }
      .filters-bar-customers .inline button {
        height: 50px;
        min-width: 50px; }
        .filters-bar-customers .inline button svg {
          font-size: 20px; }
      .filters-bar-customers .inline .filters {
        min-width: 50px;
        min-height: 50px;
        background-color: white;
        border-radius: 8px;
        margin-left: 20px;
        display: flex;
        align-items: center;
        justify-content: center; }
        .filters-bar-customers .inline .filters i:before {
          font-size: 20px;
          color: #505157; } }

.ant-checkbox-wrapper .status {
  display: flex;
  align-items: center;
  font-size: 14px; }
  .ant-checkbox-wrapper .status p {
    font-size: 14px !important; }
  .ant-checkbox-wrapper .status .dot {
    min-height: 10px;
    min-width: 10px;
    margin-right: 5px;
    border-radius: 50%; }
    .ant-checkbox-wrapper .status .dot.Open, .ant-checkbox-wrapper .status .dot.Payment {
      background-color: #FFC100; }
    .ant-checkbox-wrapper .status .dot.Closed {
      background-color: #52C41A; }
    .ant-checkbox-wrapper .status .dot.Lost {
      background-color: #FB4953; }

.ant-dropdown-menu {
  padding: 6px; }
  .ant-dropdown-menu li {
    background: #F5F5F5;
    border-radius: 6px;
    margin-bottom: 6px; }
    .ant-dropdown-menu li:last-of-type {
      margin-bottom: 0px; }

.customers-table .customer-mobile {
  margin: 10px 20px;
  display: flex;
  width: calc(100% - 40px);
  align-items: center;
  border-radius: 8px;
  height: 80px;
  padding: 8px 15px;
  align-items: center; }
  .customers-table .customer-mobile:nth-of-type(even) {
    background-color: #F5F5F5; }
  .customers-table .customer-mobile div:first-of-type {
    height: 100%; }
  .customers-table .customer-mobile div p:first-of-type {
    font-size: 16px;
    color: #505157;
    margin: 0px; }
  .customers-table .customer-mobile div p:last-of-type {
    font-size: 14px;
    font-weight: 300;
    margin: 0px; }
  .customers-table .customer-mobile div:nth-of-type(2) {
    margin-left: auto; }
  .customers-table .customer-mobile > span {
    margin-left: 20px; }
  .customers-table .customer-mobile .date {
    max-width: 30%;
    min-width: 30%;
    padding-left: 10px; }
  .customers-table .customer-mobile .info {
    max-width: 60%;
    min-width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .customers-table .customer-mobile .info p {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden; }
  .customers-table .customer-mobile .anticon {
    max-width: 5%;
    min-width: 5%; }

@media (max-width: 768px) {
  .customers-table {
    padding-top: 178px; } }

.import-customers .ant-modal-content {
  overflow: hidden; }

.import-customers.full-height .ant-drawer-content-wrapper {
  height: 100% !important; }

.import-customers h3 {
  font-weight: 500;
  font-size: 24px;
  color: #BA4A00; }

.import-customers label {
  font-weight: 500;
  font-size: 16px;
  color: #1B1B1B; }
  @media (max-width: 768px) {
    .import-customers label {
      font-size: 14px; } }

.import-customers .default-view .import-option .img-container {
  padding: 40px;
  background-color: #F5F5F5;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (max-width: 768px) {
    .import-customers .default-view .import-option .img-container {
      padding: 20px;
      margin-right: 20px; }
      .import-customers .default-view .import-option .img-container img {
        max-width: 75px;
        height: auto; } }

.import-customers .default-view .import-option.customers {
  padding-left: 0px;
  padding-right: 10px; }

.import-customers .default-view .import-option.images {
  padding-right: 0px;
  padding-left: 10px; }

.import-customers .default-view .import-option .ant-upload, .import-customers .default-view .import-option > button {
  width: 100%;
  margin-top: 20px;
  height: 50px; }
  .import-customers .default-view .import-option .ant-upload button, .import-customers .default-view .import-option > button button {
    width: 100%;
    height: 50px; }

.import-customers .default-view .import-option p {
  color: #8C8C8C;
  margin-top: 20px; }

.import-customers .default-view .import-option .inline {
  display: flex; }

@media (max-width: 768px) {
  .import-customers .default-view .import-option.customers {
    margin-bottom: 40px;
    padding-bottom: 40px;
    border-bottom: 1px solid #DADADA; } }

.import-customers .duplicates-view .head, .import-customers .after-import-view .head {
  display: flex; }
  .import-customers .duplicates-view .head img, .import-customers .after-import-view .head img {
    margin-right: 20px;
    margin-bottom: 20px; }
    @media (max-width: 768px) {
      .import-customers .duplicates-view .head img, .import-customers .after-import-view .head img {
        margin-bottom: 0px; } }
  .import-customers .duplicates-view .head h5, .import-customers .after-import-view .head h5 {
    color: #BA4A00;
    font-weight: 600;
    font-size: 14px; }
    @media (max-width: 768px) {
      .import-customers .duplicates-view .head h5, .import-customers .after-import-view .head h5 {
        line-height: 29px; } }
  .import-customers .duplicates-view .head p, .import-customers .after-import-view .head p {
    color: #8C8C8C; }
  @media (max-width: 768px) {
    .import-customers .duplicates-view .head, .import-customers .after-import-view .head {
      display: block;
      border-bottom: 1px solid #DADADA; }
      .import-customers .duplicates-view .head h5, .import-customers .after-import-view .head h5 {
        font-size: 24px;
        margin-top: 20px; } }

.import-customers .duplicates-view .body, .import-customers .after-import-view .body {
  margin-left: -24px;
  margin-right: -24px;
  margin-bottom: -24px;
  padding: 24px;
  background-color: #F5F7F7;
  height: 50vh;
  overflow-y: auto; }
  .import-customers .duplicates-view .body h4, .import-customers .after-import-view .body h4 {
    font-size: 16px;
    color: #BA4A00;
    font-weight: bold;
    text-align: center;
    width: 100%;
    margin-bottom: 20px;
    margin-top: -4px;
    text-transform: uppercase; }
  @media (max-width: 768px) {
    .import-customers .duplicates-view .body, .import-customers .after-import-view .body {
      background-color: white;
      height: auto;
      padding-top: 0px; } }
  .import-customers .duplicates-view .body .customer-duplicates, .import-customers .after-import-view .body .customer-duplicates {
    overflow: scroll;
    height: 100%; }
    .import-customers .duplicates-view .body .customer-duplicates .ant-table, .import-customers .after-import-view .body .customer-duplicates .ant-table {
      padding: 0px 10px 40px 10px; }
    .import-customers .duplicates-view .body .customer-duplicates table thead, .import-customers .after-import-view .body .customer-duplicates table thead {
      position: sticky;
      z-index: 999;
      background-color: white; }
    .import-customers .duplicates-view .body .customer-duplicates table thead > tr > th, .import-customers .after-import-view .body .customer-duplicates table thead > tr > th {
      background-color: white; }
    .import-customers .duplicates-view .body .customer-duplicates table thead tr, .import-customers .after-import-view .body .customer-duplicates table thead tr {
      box-shadow: none; }
    .import-customers .duplicates-view .body .customer-duplicates table tbody tr td:nth-last-child(2), .import-customers .after-import-view .body .customer-duplicates table tbody tr td:nth-last-child(2) {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px; }
    .import-customers .duplicates-view .body .customer-duplicates table tbody tr:nth-of-type(even) td:last-child, .import-customers .after-import-view .body .customer-duplicates table tbody tr:nth-of-type(even) td:last-child {
      border-top-right-radius: 14px;
      border-bottom-right-radius: 14px; }
    .import-customers .duplicates-view .body .customer-duplicates table tbody tr td:nth-child(2), .import-customers .after-import-view .body .customer-duplicates table tbody tr td:nth-child(2) {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px; }
    .import-customers .duplicates-view .body .customer-duplicates table tbody tr:nth-of-type(even) td:nth-child(1), .import-customers .after-import-view .body .customer-duplicates table tbody tr:nth-of-type(even) td:nth-child(1) {
      border-top-left-radius: 14px;
      border-bottom-left-radius: 14px; }
  .import-customers .duplicates-view .body .duplicate-items, .import-customers .after-import-view .body .duplicate-items {
    padding-bottom: 165px;
    height: calc(100vh - 156px);
    overflow: scroll; }
    .import-customers .duplicates-view .body .duplicate-items > .ant-checkbox-wrapper, .import-customers .after-import-view .body .duplicate-items > .ant-checkbox-wrapper {
      padding-left: 15px !important; }
    .import-customers .duplicates-view .body .duplicate-items .ant-checkbox-checked .ant-checkbox-inner::after, .import-customers .after-import-view .body .duplicate-items .ant-checkbox-checked .ant-checkbox-inner::after {
      zoom: 1.4; }
    .import-customers .duplicates-view .body .duplicate-items .ant-checkbox-disabled .ant-checkbox-inner, .import-customers .after-import-view .body .duplicate-items .ant-checkbox-disabled .ant-checkbox-inner {
      background-color: #BFBFBF;
      border: none; }
    .import-customers .duplicates-view .body .duplicate-items > .ant-checkbox-wrapper, .import-customers .after-import-view .body .duplicate-items > .ant-checkbox-wrapper {
      width: 100%;
      padding: 20px 20px 10px 34px; }
      .import-customers .duplicates-view .body .duplicate-items > .ant-checkbox-wrapper span, .import-customers .after-import-view .body .duplicate-items > .ant-checkbox-wrapper span {
        font-weight: 300;
        font-size: 16px; }
      .import-customers .duplicates-view .body .duplicate-items > .ant-checkbox-wrapper .ant-checkbox, .import-customers .after-import-view .body .duplicate-items > .ant-checkbox-wrapper .ant-checkbox {
        margin-right: 10px; }
    .import-customers .duplicates-view .body .duplicate-items .duplicate-mobile, .import-customers .after-import-view .body .duplicate-items .duplicate-mobile {
      margin: 10px 0px;
      display: flex;
      align-items: center;
      border-radius: 8px;
      height: 80px;
      padding: 8px 15px;
      align-items: center;
      justify-content: space-between; }
      .import-customers .duplicates-view .body .duplicate-items .duplicate-mobile.selected .left p, .import-customers .after-import-view .body .duplicate-items .duplicate-mobile.selected .left p {
        color: #1890FF; }
      .import-customers .duplicates-view .body .duplicate-items .duplicate-mobile p, .import-customers .after-import-view .body .duplicate-items .duplicate-mobile p {
        margin: 0px; }
      .import-customers .duplicates-view .body .duplicate-items .duplicate-mobile:nth-of-type(odd), .import-customers .after-import-view .body .duplicate-items .duplicate-mobile:nth-of-type(odd) {
        background-color: #F5F5F5; }
      .import-customers .duplicates-view .body .duplicate-items .duplicate-mobile div p:first-of-type, .import-customers .after-import-view .body .duplicate-items .duplicate-mobile div p:first-of-type {
        font-size: 16px;
        color: #505157;
        margin: 0px; }
      .import-customers .duplicates-view .body .duplicate-items .duplicate-mobile div p:last-of-type, .import-customers .after-import-view .body .duplicate-items .duplicate-mobile div p:last-of-type {
        font-size: 14px;
        font-weight: 300;
        margin: 0px; }
      .import-customers .duplicates-view .body .duplicate-items .duplicate-mobile > span, .import-customers .after-import-view .body .duplicate-items .duplicate-mobile > span {
        margin-left: 10px; }

.import-customers .missing-fields-view h5 {
  color: #BA4A00;
  font-weight: 600;
  font-size: 14px; }
  @media (max-width: 768px) {
    .import-customers .missing-fields-view h5 {
      line-height: 29px;
      font-size: 24px; } }

.import-customers .missing-fields-view p {
  color: #8C8C8C; }

.import-customers .duplicates-view .buttons, .import-customers .missing-fields-view .buttons {
  position: absolute;
  bottom: 0px;
  display: flex;
  height: 64px;
  background-color: #F5F7F7;
  width: 100%;
  padding-top: 12px;
  padding-right: 20px;
  width: calc(100% - 48px); }
  .import-customers .duplicates-view .buttons button, .import-customers .missing-fields-view .buttons button {
    height: 32px;
    margin-left: 10px; }
    .import-customers .duplicates-view .buttons button.secondary-medium-2, .import-customers .missing-fields-view .buttons button.secondary-medium-2 {
      margin-left: auto; }
  @media (max-width: 768px) {
    .import-customers .duplicates-view .buttons, .import-customers .missing-fields-view .buttons {
      display: block;
      background-color: white;
      height: auto;
      padding: 0px; }
      .import-customers .duplicates-view .buttons button, .import-customers .missing-fields-view .buttons button {
        width: 100%;
        height: 50px;
        margin-left: 0px;
        margin-bottom: 10px; } }

.import-customers .after-import-view .body {
  height: auto;
  min-height: 160px; }
  .import-customers .after-import-view .body > p {
    font-size: 14px;
    color: #505157;
    width: 100%; }
    .import-customers .after-import-view .body > p.bold {
      font-weight: 600;
      margin-bottom: 5px; }
    @media (max-width: 768px) {
      .import-customers .after-import-view .body > p {
        margin-top: 20px; } }
  .import-customers .after-import-view .body .ant-upload {
    border: none;
    background-color: white;
    height: auto;
    padding: 20px; }
    .import-customers .after-import-view .body .ant-upload .ant-upload-btn {
      padding: 0px;
      height: 212px;
      background-color: #F5F5F5; }
      .import-customers .after-import-view .body .ant-upload .ant-upload-btn svg {
        color: #8B8D96;
        margin-bottom: 20px; }
      .import-customers .after-import-view .body .ant-upload .ant-upload-btn p {
        color: #8B8D96;
        font-size: 14px; }
        .import-customers .after-import-view .body .ant-upload .ant-upload-btn p.underline {
          text-decoration: underline; }
  .import-customers .after-import-view .body .images-failed-dragger .ant-upload {
    padding: 10px; }
    .import-customers .after-import-view .body .images-failed-dragger .ant-upload p {
      font-size: 12px; }
    .import-customers .after-import-view .body .images-failed-dragger .ant-upload svg {
      font-size: 30px; }
  .import-customers .after-import-view .body .images-failed {
    padding-right: 10px; }
    .import-customers .after-import-view .body .images-failed > div {
      background-color: white;
      padding: 10px;
      border-radius: 4px;
      height: 100%;
      height: 233px;
      overflow-y: scroll; }
      .import-customers .after-import-view .body .images-failed > div p {
        color: #8B8D96;
        margin-bottom: 5px; }
      @media (max-width: 768px) {
        .import-customers .after-import-view .body .images-failed > div {
          background: #F5F5F5;
          max-height: calc(100vh - 505px);
          overflow: scroll; }
          .import-customers .after-import-view .body .images-failed > div p {
            font-size: 12px; } }
  .import-customers .after-import-view .body .buttons {
    width: 100%;
    margin-top: 20px; }
    .import-customers .after-import-view .body .buttons button {
      margin-left: auto;
      display: block; }
    @media (max-width: 768px) {
      .import-customers .after-import-view .body .buttons {
        position: absolute;
        bottom: 30px;
        width: calc(100% - 48px); }
        .import-customers .after-import-view .body .buttons button {
          width: 100%;
          height: 50px;
          margin-left: 0px; }
        .import-customers .after-import-view .body .buttons .ant-upload {
          width: 100%;
          padding: 0px; } }

.import-customers .after-import-view.upload-images .head {
  border-bottom: none; }
  .import-customers .after-import-view.upload-images .head p {
    text-align: center; }
    @media (max-width: 768px) {
      .import-customers .after-import-view.upload-images .head p {
        text-align: left;
        margin-top: 20px; } }

.import-customers .after-import-view.upload-images .body {
  border-radius: 5px; }
  @media (max-width: 768px) {
    .import-customers .after-import-view.upload-images .body div.ant-upload {
      padding: 0px;
      margin-top: 20px;
      width: calc(100vw - 48px); }
      .import-customers .after-import-view.upload-images .body div.ant-upload span.ant-upload {
        padding: 0px; }
      .import-customers .after-import-view.upload-images .body div.ant-upload button {
        height: 50px;
        margin: 0px;
        width: 100%; } }

.pay {
  height: 100vh;
  width: 100vw;
  background: #EAEDEE; }
  .pay .payment {
    width: 620px;
    margin: 0 auto;
    display: block;
    margin-top: 100px; }
    .pay .payment .head {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .pay .payment .head img {
        width: 140px; }
      .pay .payment .head .ant-select-selector {
        padding-left: 0px !important;
        border: none !important;
        font-size: 16px;
        background-color: transparent !important; }
      .pay .payment .head .ant-select-arrow {
        color: #505157;
        top: 48%; }
    .pay .payment .container {
      margin-top: 20px;
      background-color: white;
      box-shadow: 1px 3px 4px rgba(131, 86, 86, 0.15);
      padding: 20px 60px; }
      .pay .payment .container .icon {
        width: 43px;
        height: 43px;
        border-radius: 50%;
        background: #F5DDD9;
        margin: 0 auto;
        display: block;
        display: flex;
        align-items: center;
        justify-content: center; }
        .pay .payment .container .icon i {
          color: #BA4A00; }
          .pay .payment .container .icon i:before {
            line-height: 3; }
      .pay .payment .container h1 {
        color: #BA4A00;
        font-weight: 400;
        font-size: 20px;
        text-align: center;
        margin-top: 20px;
        margin-bottom: 20px; }
      .pay .payment .container p {
        color: #8B8D96;
        font-weight: 300;
        text-align: center; }
      .pay .payment .container .instalment-of {
        margin-top: 20px; }
      .pay .payment .container .pay-btn {
        width: 390px;
        height: 45px;
        color: white;
        margin: 0 auto;
        display: block;
        margin-bottom: 20px; }
    .pay .payment .contact {
      color: white;
      margin-top: 40px;
      position: unset; }
      .pay .payment .contact .row {
        display: flex;
        width: 393px;
        margin: 0 auto; }
        .pay .payment .contact .row .phone {
          cursor: pointer;
          background-color: #505157;
          padding: 8px 12px;
          border-radius: 4px;
          width: 268px; }
          .pay .payment .contact .row .phone span {
            margin-right: 50px; }
        .pay .payment .contact .row .whatsapp {
          cursor: pointer;
          margin-left: 20px;
          border-radius: 4px;
          background-color: #23CD64;
          width: 104px;
          text-align: center; }
          .pay .payment .contact .row .whatsapp svg {
            font-size: 24px;
            margin-top: 7px; }
      .pay .payment .contact .email {
        cursor: pointer;
        margin: 0 auto;
        background: #1890FF;
        height: 38px;
        padding: 8px 12px;
        text-align: center;
        margin-top: 20px;
        width: 393px;
        border-radius: 4px; }
  @media (max-width: 768px) {
    .pay .payment {
      width: 100%;
      padding: 20px;
      margin-top: 0px; }
      .pay .payment .try-again {
        position: fixed;
        bottom: 0px;
        left: 0px;
        width: 100% !important;
        border-radius: 0px; }
      .pay .payment .container {
        padding: 20px !important; }
      .pay .payment .contact .email {
        width: 100%; }
      .pay .payment .contact .row {
        width: 100%; }
        .pay .payment .contact .row .phone {
          width: unset;
          flex: 2; }
        .pay .payment .contact .row .whatsapp {
          width: unset;
          flex: 1; } }

#change-language {
  display: flex;
  color: #505157;
  margin-bottom: 10px;
  margin-top: 20px; }
  #change-language p {
    margin-right: 15px;
    margin-left: 5px;
    font-size: 16px; }
    #change-language p.lang {
      font-weight: 300;
      cursor: pointer; }
    #change-language p.active {
      font-weight: 500;
      color: #BA4A00;
      padding-bottom: 3px;
      border-bottom: 1px solid #BA4A00;
      margin-top: -3px; }
  @media screen and (max-width: 768px) {
    #change-language {
      margin: 0px;
      display: unset; } }

.select-language-mobile .ant-select-selector {
  background-color: transparent !important;
  color: #505157;
  border: none !important; }

.select-language-mobile .ant-select-arrow {
  color: #505157;
  margin-top: -9px; }

.select-dropdown.language {
  left: unset !important;
  right: 20px !important;
  margin-top: -100px; }

.contact {
  position: absolute;
  right: 40px;
  bottom: 35px;
  color: white;
  font-size: 14px;
  font-weight: 500; }
  .contact > div {
    display: flex; }
  .contact .top div:first-of-type {
    background-color: #1B1B1B;
    padding: 5px 10px; }
  .contact .top .help {
    width: 149px; }
  .contact .top .whatsapp, .contact .top .wechat {
    height: 33px;
    width: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: white; }
  .contact .top .whatsapp {
    background-color: #23CD64 !important;
    margin-right: 4px; }
  .contact .top .wechat {
    background-color: #78AD2C; }
  .contact .bottom {
    background-color: #505157;
    width: 189px;
    align-items: center;
    justify-content: space-between;
    display: flex;
    padding: 5px 10px;
    margin-top: 5px;
    cursor: pointer;
    color: white; }
    .contact .bottom p {
      margin: 0px; }
  .contact div {
    border-radius: 4px; }
  .contact svg {
    font-size: 20px; }
  @media (max-width: 768px) {
    .contact {
      position: unset;
      right: unset;
      bottom: unset;
      margin-top: 40px;
      padding-bottom: 20px; }
      .contact.absolute {
        position: absolute;
        bottom: 20px; } }

#terms {
  padding: 30px 93px;
  min-height: 100vh;
  margin: 0px;
  background-repeat: no-repeat !important;
  background-size: contain !important;
  border-radius: 0px !important;
  background-color: #FCF9F4 !important; }
  #terms > img {
    margin-bottom: 20px; }
  #terms h1 {
    font-size: 20px;
    color: black;
    margin-bottom: 30px; }
  #terms .box {
    box-shadow: 0px 1px 20px #888888;
    width: calc(100% - 196px);
    max-width: 1050px; }
  @media (max-width: 768px) {
    #terms {
      padding: 20px;
      background-position: bottom !important; }
      #terms .box {
        width: 100%;
        margin-bottom: 20px; }
      #terms h1 {
        font-size: 18px; }
      #terms .head {
        display: flex;
        justify-content: space-between;
        margin-bottom: 40px; }
        #terms .head img {
          width: 142px;
          height: 100%; }
        #terms .head .ant-select {
          font-size: 16px;
          margin-top: 4px; } }

.auth-form {
  max-width: 610px; }
  .auth-form > h5 {
    color: black;
    font-size: 20px; }
    @media (max-width: 768px) {
      .auth-form > h5 {
        font-size: 18px; } }
    .auth-form > h5 span {
      font-weight: 600; }
  .auth-form > p {
    font-weight: 300;
    font-size: 16px; }
  .auth-form .ant-form-item .ant-input-affix-wrapper .ant-input-prefix {
    font-size: 20px !important; }
  .auth-form .forgot-pass {
    margin: 20px 0px 0px 20px;
    font-size: 18px;
    color: #BA4A00;
    font-weight: 400;
    cursor: pointer; }
  .auth-form #error.zh-error {
    font-size: 19px; }
  .auth-form form, .auth-form .form {
    overflow: visible !important;
    max-width: 448px;
    background: white;
    border-radius: 24px;
    overflow: hidden;
    box-shadow: 0px 1px 20px #888888;
    position: relative; }
    .auth-form form > div > h5, .auth-form .form > div > h5 {
      color: #BA4A00;
      font-size: 24px;
      margin: 20px;
      padding-top: 20px; }
      @media (max-width: 768px) {
        .auth-form form > div > h5, .auth-form .form > div > h5 {
          font-size: 18px; } }
    .auth-form form > div > p, .auth-form .form > div > p {
      margin: 20px;
      color: #8C8C8C;
      font-weight: 300; }
      @media (max-width: 768px) {
        .auth-form form > div > p, .auth-form .form > div > p {
          font-size: 16px; } }
    .auth-form form .ant-form-item, .auth-form .form .ant-form-item {
      padding: 15px 24px;
      margin-bottom: 0px; }
      .auth-form form .ant-form-item label, .auth-form .form .ant-form-item label {
        color: #BA4A00;
        font-weight: 500;
        margin-bottom: 10px;
        font-size: 16px; }
        @media (max-width: 768px) {
          .auth-form form .ant-form-item label, .auth-form .form .ant-form-item label {
            font-size: 14px; } }
      .auth-form form .ant-form-item input, .auth-form form .ant-form-item .ant-input-affix-wrapper, .auth-form .form .ant-form-item input, .auth-form .form .ant-form-item .ant-input-affix-wrapper {
        font-size: 20px;
        font-weight: 300;
        color: #595959;
        border: none;
        background: none;
        outline-width: 0;
        box-shadow: none; }
        @media (max-width: 768px) {
          .auth-form form .ant-form-item input, .auth-form form .ant-form-item .ant-input-affix-wrapper, .auth-form .form .ant-form-item input, .auth-form .form .ant-form-item .ant-input-affix-wrapper {
            font-size: 18px; } }
      .auth-form form .ant-form-item.ant-form-item-has-error input, .auth-form .form .ant-form-item.ant-form-item-has-error input {
        color: #FB4953;
        background-color: transparent !important; }
    .auth-form form .password-box, .auth-form .form .password-box {
      position: relative; }
      .auth-form form .password-box span, .auth-form .form .password-box span {
        position: absolute;
        right: 36px;
        top: 73px;
        color: #398CAF;
        font-weight: 300;
        cursor: pointer; }
        @media (max-width: 768px) {
          .auth-form form .password-box span, .auth-form .form .password-box span {
            top: 70px; } }
    .auth-form form .separator, .auth-form .form .separator {
      background: #DADADA;
      height: 1px;
      width: 100%; }
    .auth-form form button, .auth-form .form button {
      color: #E0C7A8;
      font-weight: 300;
      font-size: 24px;
      padding: 0px 24px;
      width: 100%;
      height: 80px;
      text-align: left;
      background-color: #BA4A00;
      border-radius: 0px;
      border-bottom-left-radius: 24px;
      border-bottom-right-radius: 24px; }
      .auth-form form button:hover, .auth-form .form button:hover {
        background-color: #410506;
        color: #E0C7A8; }
      .auth-form form button:focus, .auth-form .form button:focus {
        background-color: #1B1B1B;
        color: #E0C7A8; }
      .auth-form form button.with-terms, .auth-form .form button.with-terms {
        padding-top: 20px;
        height: 106px; }
        @media (max-width: 768px) {
          .auth-form form button.with-terms, .auth-form .form button.with-terms {
            padding-top: 42px; } }
      .auth-form form button .anticon, .auth-form .form button .anticon {
        float: right;
        margin-top: 5px; }
      .auth-form form button #terms-text, .auth-form .form button #terms-text {
        position: absolute;
        font-size: 12px;
        color: white;
        opacity: .5;
        top: 20px; }
        .auth-form form button #terms-text a, .auth-form .form button #terms-text a {
          color: white;
          text-decoration: underline; }
      @media (max-width: 768px) {
        .auth-form form button, .auth-form .form button {
          font-size: 20px;
          top: 12px;
          white-space: break-spaces; } }
    .auth-form form #error, .auth-form .form #error {
      background-color: #FB4953;
      font-weight: 500;
      font-size: 12px;
      color: white;
      padding: 0px 3px;
      margin-left: 10px;
      position: absolute;
      left: 15px;
      bottom: -90px;
      border-radius: 2px; }
      @media (max-width: 768px) {
        .auth-form form #error, .auth-form .form #error {
          font-size: 14px;
          margin-left: -10px;
          bottom: -50px; } }
    .auth-form form .info, .auth-form .form .info {
      color: #8C8C8C;
      font-weight: 300;
      font-size: 12px !important;
      margin-left: 24px;
      margin-bottom: 0px !important;
      margin-top: 0px !important; }
      .auth-form form .info.marginbottom, .auth-form .form .info.marginbottom {
        margin-bottom: 20px !important; }
      @media (max-width: 768px) {
        .auth-form form .info, .auth-form .form .info {
          margin-bottom: 0px; } }
    .auth-form form .ant-form-item-explain, .auth-form .form .ant-form-item-explain {
      font-size: 12px;
      font-weight: 500;
      text-transform: uppercase;
      margin-top: 10px;
      background-color: #FEEDEE;
      padding: 1px 6px;
      border-radius: 5px;
      min-height: 17px;
      color: #FB4953;
      width: fit-content; }
      @media (max-width: 768px) {
        .auth-form form .ant-form-item-explain, .auth-form .form .ant-form-item-explain {
          background-color: transparent; } }
  @media (max-width: 768px) {
    .auth-form {
      padding-bottom: 60px; } }

.mobile-drawer .ant-drawer-content-wrapper {
  height: auto !important; }

.mobile-drawer .head {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .mobile-drawer .head p:first-of-type {
    color: #505157; }
  .mobile-drawer .head p:last-of-type {
    color: #8B8D96;
    cursor: pointer; }

.mobile-drawer .drawer-button {
  width: 100%;
  color: #505157;
  background-color: #F5F5F5;
  padding: 16px 12px;
  margin-bottom: 10px;
  border-radius: 3px; }
  .mobile-drawer .drawer-button i:before {
    margin-right: 10px; }

.mobile-drawer .ant-drawer-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.mobile-drawer .inactive-status {
  height: 14px;
  width: 14px;
  border-radius: 50%;
  margin-right: 10px;
  background-color: #FFC100;
  display: inline-block;
  margin-bottom: -3px; }

.mobile-drawer .active-status {
  height: 14px;
  width: 14px;
  border-radius: 50%;
  margin-right: 10px;
  background-color: #52C41A;
  display: inline-block;
  margin-bottom: -3px; }

.mobile-drawer .notice {
  background: black;
  border-radius: 8px;
  padding: 10px;
  margin-top: 30px; }
  .mobile-drawer .notice p {
    color: white;
    margin: 0px;
    font-weight: 300;
    font-size: 14px; }

.mobile-drawer .language-selector {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px; }
  .mobile-drawer .language-selector div {
    width: 49%;
    color: #505157;
    font-size: 14px;
    height: 50px;
    background-color: #F5F5F5;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center; }

.mobile-drawer .getpaid {
  background-color: #BA4A00;
  color: white; }

.view-selector .drawer-button {
  background-color: #F5F5F5;
  color: #1B1B1B;
  height: 50px;
  display: flex;
  align-items: center; }
  .view-selector .drawer-button i:before {
    color: #1B1B1B;
    position: relative;
    top: 3px;
    font-size: 18px; }
  .view-selector .drawer-button.active {
    background-color: #398CAF;
    color: white; }
    .view-selector .drawer-button.active i:before {
      color: white; }

.header {
  height: 56px;
  background-color: white;
  display: flex;
  align-items: center;
  padding: 0px 50px;
  position: fixed;
  width: 100%;
  z-index: 10; }
  .header > img {
    margin-right: 40px; }
  .header .ant-menu-horizontal {
    line-height: 46px !important;
    width: 100%; }
    .header .ant-menu-horizontal .ant-menu-overflowed-submenu .ant-menu-submenu-title {
      padding-right: 20px !important; }
    .header .ant-menu-horizontal .ant-menu-item {
      padding: 4px 20px;
      margin: 0px;
      font-weight: 500;
      color: #8C8C8C; }
      .header .ant-menu-horizontal .ant-menu-item:hover {
        background: linear-gradient(0deg, #a3151800 0%, #BA4A003b 100%); }
      .header .ant-menu-horizontal .ant-menu-item i:before {
        font-size: 20px;
        margin-right: 10px;
        position: relative;
        top: 3px;
        margin-left: 0px; }
    .header .ant-menu-horizontal .ant-menu-submenu {
      margin: 0px; }
      .header .ant-menu-horizontal .ant-menu-submenu .ant-menu-submenu-title {
        display: flex;
        padding: 0px 5px 0px 20px;
        align-items: flex-end;
        height: 55px;
        font-weight: 500;
        color: #8C8C8C; }
        .header .ant-menu-horizontal .ant-menu-submenu .ant-menu-submenu-title i {
          margin-right: 10px; }
          .header .ant-menu-horizontal .ant-menu-submenu .ant-menu-submenu-title i:before {
            font-size: 20px; }
        .header .ant-menu-horizontal .ant-menu-submenu .ant-menu-submenu-title > span > div {
          position: relative;
          bottom: 4px; }
        .header .ant-menu-horizontal .ant-menu-submenu .ant-menu-submenu-title:hover {
          background: linear-gradient(0deg, #a3151800 0%, #BA4A003b 100%); }
        .header .ant-menu-horizontal .ant-menu-submenu .ant-menu-submenu-title .anticon {
          margin-right: 0px; }
    .header .ant-menu-horizontal .ant-menu-item-selected {
      color: #BA4A00;
      border-bottom-color: #BA4A00; }
    .header .ant-menu-horizontal .ant-menu-item-active, .header .ant-menu-horizontal .ant-menu-item:hover, .header .ant-menu-horizontal .ant-menu-submenu-horizontal:hover {
      border-bottom-color: #BA4A00; }
  .header .right-section {
    margin-left: auto;
    height: 46px;
    display: flex;
    align-items: center; }
    .header .right-section > * {
      margin-left: 10px; }
    .header .right-section .select-language .ant-select-selector {
      text-transform: uppercase; }
    .header .right-section .anticon-question-circle svg {
      color: #BFBFBF;
      font-size: 44px;
      padding: 12px;
      margin: -12px; }
    .header .right-section .ant-select {
      font-size: 14px;
      color: #8B8D96;
      font-weight: 500; }
      .header .right-section .ant-select .ant-select-arrow svg {
        color: #8B8D96;
        font-weight: 500; }
      .header .right-section .ant-select .ant-select-selector, .header .right-section .ant-select .ant-select-focused {
        border-color: transparent !important;
        box-shadow: none !important; }
    .header .right-section .new-opportunity {
      display: flex;
      align-items: center;
      background-color: #BA4A00;
      justify-content: center;
      color: white;
      font-weight: 500;
      margin-right: 10px;
      cursor: pointer; }
      .header .right-section .new-opportunity svg {
        margin-right: 5px; }
  @media (max-width: 1300px) {
    .header .ant-menu-horizontal .ant-menu-item {
      padding: 4px 12px; }
    .header > img {
      margin-right: 20px; } }
  @media (max-width: 768px) {
    .header {
      padding: 0px 25px;
      height: 70px;
      border-bottom: 1px solid #EAEAEA; }
      .header > img {
        margin-left: 0px;
        margin-right: 15px; }
      .header > div:not(.mobile-menu-opened):not(.back) {
        padding: 0px 0px 0px 25px;
        height: 70px;
        width: 100% !important;
        display: flex;
        align-items: center;
        border-left: 1px solid #EAEAEA; }
        .header > div:not(.mobile-menu-opened):not(.back) > div * {
          line-height: 20px;
          margin: 0px; }
        .header > div:not(.mobile-menu-opened):not(.back) > div h5 {
          margin: 0px;
          color: #BA4A00;
          font-size: 14px;
          font-weight: 500; }
        .header > div:not(.mobile-menu-opened):not(.back) > div p {
          color: #505157; }
        .header > div:not(.mobile-menu-opened):not(.back) svg {
          font-size: 24px; }
        .header > div:not(.mobile-menu-opened):not(.back) .anticon-question-circle {
          color: #BFBFBF;
          margin-left: auto;
          margin-right: 10px; }
        .header > div:not(.mobile-menu-opened):not(.back) .anticon-menu {
          color: #8C8C8C;
          margin-left: 0px; } }
  .header .back svg {
    color: #BA4A00;
    font-size: 24px;
    margin-right: 18px;
    margin-top: 28px; }
  .header .mobile-menu-opened {
    background-color: white;
    position: absolute;
    width: 100vw;
    top: 0px;
    left: 0px;
    border-bottom-right-radius: 60px;
    box-shadow: -5px 8px 20px 0px #808080b8;
    z-index: 2; }
    .header .mobile-menu-opened .content {
      overflow: scroll;
      max-height: 100vh;
      padding: 15px 15px 30px 15px; }
    .header .mobile-menu-opened .menuitem i:before {
      margin-right: 10px; }
    .header .mobile-menu-opened .inline {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .header .mobile-menu-opened .inline * {
        font-size: 16px; }
      .header .mobile-menu-opened .inline p {
        line-height: 20px;
        margin: 0px; }
      .header .mobile-menu-opened .inline .ant-select {
        margin-bottom: -3px; }
      .header .mobile-menu-opened .inline:first-of-type {
        margin-bottom: 40px; }
      .header .mobile-menu-opened .inline img {
        width: 150px; }
      .header .mobile-menu-opened .inline .anticon-close svg {
        font-size: 24px;
        color: #1B1B1B; }
    .header .mobile-menu-opened .signout {
      color: #8B8D96;
      font-size: 16px;
      margin-bottom: 0px;
      margin-top: 70px;
      line-height: 30px;
      margin-bottom: 15px;
      display: table; }
    .header .mobile-menu-opened .menuitem {
      color: #8B8D96;
      font-size: 16px;
      line-height: 20px;
      display: table;
      padding: 10px;
      border-radius: 4px;
      margin-bottom: 20px; }
      .header .mobile-menu-opened .menuitem.active {
        background-color: #BA4A003d;
        color: #BA4A00; }
      .header .mobile-menu-opened .menuitem.indented {
        margin-left: 40px; }

.select-language-mobile .ant-select-selector {
  background-color: transparent !important;
  color: #505157;
  border: none !important; }

.select-language-mobile .ant-select-arrow {
  color: #505157;
  margin-top: -9px; }

.help-menu .ant-popover-inner {
  width: unset; }

.help-menu .ant-popover-inner-content {
  width: 240px; }
  .help-menu .ant-popover-inner-content div label {
    font-size: 12px;
    color: #505157;
    font-weight: 500; }
  .help-menu .ant-popover-inner-content div > div:not(.notice) {
    width: 100%;
    height: 36px;
    padding: 7px 10px;
    border-radius: 4px;
    color: white;
    margin-bottom: 5px;
    cursor: pointer; }
    .help-menu .ant-popover-inner-content div > div:not(.notice):nth-of-type(1) {
      background-color: #F5F5F5;
      color: #505157;
      margin-bottom: 20px; }
    .help-menu .ant-popover-inner-content div > div:not(.notice):nth-of-type(2) {
      background-color: #23CD64; }
    .help-menu .ant-popover-inner-content div > div:not(.notice):nth-of-type(3) {
      background-color: #78AD2C; }
    .help-menu .ant-popover-inner-content div > div:not(.notice):nth-of-type(4) {
      background-color: #505157;
      margin-bottom: 0px;
      margin-bottom: 20px; }
    .help-menu .ant-popover-inner-content div > div:not(.notice):nth-of-type(5) {
      background-color: #F5F5F5;
      color: #BA4A00; }
  .help-menu .ant-popover-inner-content div svg {
    font-size: 16px;
    margin-right: 5px; }
  .help-menu .ant-popover-inner-content div .notice {
    position: absolute;
    background: black;
    top: 363px;
    border-radius: 8px;
    padding: 10px;
    width: 241px;
    left: 0px; }
    .help-menu .ant-popover-inner-content div .notice p {
      color: white;
      margin: 0px;
      font-weight: 300;
      font-size: 14px; }
    .help-menu .ant-popover-inner-content div .notice button {
      width: 100%;
      height: 36px;
      color: white;
      background-color: #BA4A00;
      border-radius: 3px;
      margin-top: 20px; }

.ant-menu-submenu.ant-menu-submenu-popup {
  top: 57px !important;
  position: absolute; }
  .ant-menu-submenu.ant-menu-submenu-popup .submenu-opened {
    padding-left: 100px; }
    .ant-menu-submenu.ant-menu-submenu-popup .submenu-opened .submenu-item {
      padding: 20px; }
      .ant-menu-submenu.ant-menu-submenu-popup .submenu-opened .submenu-item > div {
        padding: 10px;
        cursor: pointer;
        margin-bottom: 10px; }
        .ant-menu-submenu.ant-menu-submenu-popup .submenu-opened .submenu-item > div:hover {
          background-color: #F5F5F5;
          border-radius: 10px; }
      .ant-menu-submenu.ant-menu-submenu-popup .submenu-opened .submenu-item h3 {
        font-size: 18px;
        color: #BA4A00;
        font-weight: 500; }
        .ant-menu-submenu.ant-menu-submenu-popup .submenu-opened .submenu-item h3 i:before {
          margin-right: 10px;
          position: relative;
          top: 2px; }
      .ant-menu-submenu.ant-menu-submenu-popup .submenu-opened .submenu-item p {
        line-height: 22px;
        color: #8C8C8C;
        font-weight: 300; }
      .ant-menu-submenu.ant-menu-submenu-popup .submenu-opened .submenu-item .submenu-link {
        color: #505157;
        cursor: pointer;
        margin-left: 10px;
        font-weight: 500; }
        .ant-menu-submenu.ant-menu-submenu-popup .submenu-opened .submenu-item .submenu-link i:before {
          position: relative;
          top: 2px;
          margin-left: 5px; }
  .ant-menu-submenu.ant-menu-submenu-popup .img-container {
    margin-left: auto;
    padding: 20px; }
    .ant-menu-submenu.ant-menu-submenu-popup .img-container img {
      position: relative;
      top: 60px;
      width: 100%;
      max-width: 250px;
      margin-left: auto;
      display: block; }

.contact-drawer .ant-drawer-body label {
  color: #505157;
  font-weight: 500;
  display: block;
  margin-bottom: 5px; }
  .contact-drawer .ant-drawer-body label:nth-of-type(2) {
    margin-top: 25px; }

.contact-drawer .ant-drawer-body .drawer-button {
  font-weight: 500; }
  .contact-drawer .ant-drawer-body .drawer-button svg {
    font-size: 18px;
    margin-right: 10px;
    position: relative;
    top: 2px; }
  .contact-drawer .ant-drawer-body .drawer-button:nth-of-type(2) {
    background-color: #23CD64;
    color: white; }
  .contact-drawer .ant-drawer-body .drawer-button:nth-of-type(3) {
    background-color: #78AD2C;
    color: white; }
  .contact-drawer .ant-drawer-body .drawer-button:nth-of-type(4) {
    background-color: #505157;
    color: white; }

.ant-menu-submenu li {
  display: flex;
  align-items: center;
  color: #8C8C8C;
  font-weight: 500; }
  .ant-menu-submenu li i {
    margin-right: 10px; }
  .ant-menu-submenu li:hover {
    color: #BA4A00;
    background: linear-gradient(0deg, #a3151800 0%, #BA4A003b 100%); }

.ant-menu-vertical.ant-menu-sub:not([class*='-active']) {
  overflow-y: hidden; }

.ant-menu-submenu .ant-menu-submenu-title {
  display: flex; }
  .ant-menu-submenu .ant-menu-submenu-title .anticon-caret-down {
    display: none; }
  .ant-menu-submenu .ant-menu-submenu-title .ant-menu-submenu-arrow {
    display: none; }

.ant-menu-submenu .ant-menu-submenu .ant-menu-submenu {
  width: 100vw;
  top: 0px !important; }

.basic-header {
  height: 56px;
  background-color: white;
  display: flex;
  align-items: center;
  padding: 0px 50px;
  position: fixed;
  width: 100%;
  z-index: 10; }
  .basic-header > img {
    margin-right: 30px; }
  .basic-header h1 {
    margin-bottom: 0px;
    color: #BA4A00;
    font-size: 17px;
    font-family: Noto Serif SC;
    font-weight: 600; }
  .basic-header button {
    background-color: #E8E8E8;
    border: 1px solid #E8E8E8;
    height: 31px;
    color: black;
    font-size: 14px;
    padding-left: 7px;
    display: flex;
    align-items: center;
    padding-right: 7px;
    margin: 0px 20px; }
    .basic-header button img {
      position: absolute;
      top: -5px;
      right: 24px; }
    .basic-header button p {
      margin-bottom: 0px;
      margin-right: 48px; }
    .basic-header button:hover {
      border-color: #BA4A00;
      background-color: white;
      color: black; }
  .basic-header > p {
    margin-bottom: 0px;
    color: #0094FF;
    text-decoration: underline;
    cursor: pointer; }
  .basic-header > .ant-select {
    margin-left: auto;
    margin-right: 20px; }
    .basic-header > .ant-select .ant-select-selector {
      border: none;
      color: #8B8D96;
      font-weight: 500;
      text-transform: uppercase; }
      .basic-header > .ant-select .ant-select-selector svg {
        position: relative;
        bottom: 2px;
        font-size: 13px; }
  .basic-header .header-menu {
    height: 56px;
    display: flex;
    align-items: center; }
    .basic-header .header-menu svg {
      margin-left: 5px; }
    .basic-header .header-menu.open svg {
      color: #BA4A00; }
  @media (max-width: 992px) {
    .basic-header img {
      margin-left: 10px; }
    .basic-header * {
      font-size: 12px !important; }
    .basic-header h1 {
      white-space: nowrap; }
    .basic-header > p {
      width: 139px;
      line-height: 17px; } }
  @media (max-width: 768px) {
    .basic-header {
      height: 70px;
      padding: 0px 18px; }
      .basic-header > h1, .basic-header > p, .basic-header > .ant-select, .basic-header .header-menu svg {
        display: none; }
      .basic-header > img {
        margin-right: 0px; }
      .basic-header .header-menu {
        margin-left: auto; } }

.profile-menu .ant-popover-inner {
  width: unset; }

.profile-menu .ant-popover-inner-content {
  width: 221px;
  padding: 10px; }
  .profile-menu .ant-popover-inner-content div label {
    font-size: 12px;
    color: #505157;
    font-weight: 500;
    margin-bottom: 0px;
    margin-top: 20px; }
  .profile-menu .ant-popover-inner-content div > div {
    width: 100%;
    height: 36px;
    padding: 7px 10px;
    border-radius: 4px;
    margin-bottom: 5px;
    cursor: pointer;
    background-color: #F5F5F5;
    color: black;
    transition: all 0.3s; }
    .profile-menu .ant-popover-inner-content div > div:hover {
      background-color: #e2e2e2; }
    .profile-menu .ant-popover-inner-content div > div i {
      margin-right: 8px;
      position: relative;
      top: 2px; }
    .profile-menu .ant-popover-inner-content div > div.getpaid {
      background-color: #BA4A00;
      color: white; }
    .profile-menu .ant-popover-inner-content div > div.whatsapp {
      background-color: #23CD64;
      color: white; }
      .profile-menu .ant-popover-inner-content div > div.whatsapp:hover {
        background-color: #19b555; }
    .profile-menu .ant-popover-inner-content div > div.phone {
      background-color: #505157;
      margin-bottom: 0px;
      color: white; }
      .profile-menu .ant-popover-inner-content div > div.phone:hover {
        background-color: #27282b; }
    .profile-menu .ant-popover-inner-content div > div.logout {
      margin-top: 20px; }
  .profile-menu .ant-popover-inner-content div svg {
    font-size: 16px;
    margin-right: 5px; }

.menu-drawer label {
  margin-top: 20px;
  margin-bottom: 5px; }

.menu-drawer .whatsapp {
  background-color: #23CD64;
  color: white; }
  .menu-drawer .whatsapp svg {
    margin-right: 10px; }

.menu-drawer .phone {
  background-color: #505157;
  color: white; }
  .menu-drawer .phone svg {
    margin-right: 10px; }

.menu-drawer .signout {
  margin-top: 30px; }

.go-advanced-modal .ant-modal-content {
  overflow: hidden;
  border-radius: 10px; }

.go-advanced-modal .ant-modal-close {
  display: none; }

.go-advanced-modal .ant-modal-body {
  overflow: hidden;
  padding: 0px; }
  .go-advanced-modal .ant-modal-body img {
    width: 100%; }
  .go-advanced-modal .ant-modal-body .padding {
    padding: 10px; }
    .go-advanced-modal .ant-modal-body .padding p {
      color: #505157;
      font-weight: 300;
      font-size: 16px;
      line-height: 22px; }
    .go-advanced-modal .ant-modal-body .padding button {
      width: 100%;
      height: 50px;
      margin-top: 10px; }
      .go-advanced-modal .ant-modal-body .padding button.learn {
        background-color: #BA4A00;
        color: white;
        margin-top: 0px; }
      .go-advanced-modal .ant-modal-body .padding button.goadvanced {
        background-color: #505157;
        color: white; }
      .go-advanced-modal .ant-modal-body .padding button.cancel {
        background-color: #DADADA;
        color: #505157; }

.account-modal h1 {
  font-size: 18px;
  font-weight: 600; }

.account-modal .ant-modal-content {
  overflow: hidden;
  border-radius: 10px; }

.account-modal p {
  font-size: 14px;
  color: #8C8C8C;
  font-weight: 300;
  margin-top: -15px; }

.account-modal .buttons {
  display: flex;
  padding: 25px;
  background: #E6EBEC;
  margin-left: -24px;
  margin-bottom: -24px;
  width: calc(100% + 48px); }
  .account-modal .buttons button {
    margin-left: auto; }

.account-modal label {
  margin-bottom: 0px; }

.account-modal .password-box {
  position: relative; }
  .account-modal .password-box span {
    position: absolute;
    right: 15px;
    color: #398CAF;
    font-weight: 300;
    cursor: pointer;
    top: 36px; }
    @media (max-width: 768px) {
      .account-modal .password-box span {
        top: 42px; } }

.account-modal .success .anticon-check-circle {
  margin: 0 auto;
  display: block;
  margin-bottom: 20px; }
  .account-modal .success .anticon-check-circle svg {
    color: #89C763;
    font-size: 100px; }

.account-modal .success p {
  font-weight: 300;
  font-size: 16px;
  color: #8C8C8C;
  text-align: center;
  margin-top: 20px; }

.account-modal .success button {
  height: 50px;
  border-radius: 8px;
  background: #DADADA;
  color: black;
  width: 100%; }

.account-modal .bank {
  padding-right: 10px; }

.account-modal .account {
  padding-left: 10px; }

.tutorials .ant-modal {
  width: 100vw;
  top: 0px;
  margin: 0px;
  max-width: 100vw;
  min-height: 100vh; }
  .tutorials .ant-modal .ant-modal-content {
    border-radius: 0px;
    min-height: 100vh;
    background: #ffffffe6;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px); }
    .tutorials .ant-modal .ant-modal-content .ant-modal-close {
      margin-top: 15px; }
      .tutorials .ant-modal .ant-modal-content .ant-modal-close svg {
        font-size: 24px; }
    .tutorials .ant-modal .ant-modal-content .inactive svg {
      color: #8C8C8C !important;
      cursor: unset !important; }
    .tutorials .ant-modal .ant-modal-content p {
      color: #505157;
      font-size: 16px;
      font-weight: 300; }
    .tutorials .ant-modal .ant-modal-content .head {
      display: flex;
      align-items: center;
      margin-bottom: 30px; }
      .tutorials .ant-modal .ant-modal-content .head .anticon-left-circle svg, .tutorials .ant-modal .ant-modal-content .head .anticon-right-circle svg {
        color: #BA4A00;
        font-size: 30px;
        margin-right: 10px;
        cursor: pointer; }
      .tutorials .ant-modal .ant-modal-content .head .ant-select-selector {
        border: none;
        background-color: transparent;
        color: #BA4A00;
        margin: 0px;
        font-size: 18px;
        font-weight: 500; }
      .tutorials .ant-modal .ant-modal-content .head .anticon-down {
        font-size: 18px;
        color: #BA4A00;
        position: relative;
        bottom: 5px; }
      .tutorials .ant-modal .ant-modal-content .head .ant-checkbox-wrapper {
        margin-left: auto;
        color: #8C8C8C;
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        margin-right: 70px; }
    .tutorials .ant-modal .ant-modal-content .ant-row .single-image {
      width: 100%;
      border-radius: 16px;
      margin-bottom: 20px;
      filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.15)); }
    .tutorials .ant-modal .ant-modal-content .ant-row .images {
      position: relative;
      margin-bottom: 20px;
      filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.15)); }
      .tutorials .ant-modal .ant-modal-content .ant-row .images .image {
        width: 100%;
        border-radius: 16px; }
      .tutorials .ant-modal .ant-modal-content .ant-row .images .arrow {
        position: absolute;
        width: 40px;
        height: 40px;
        background: rgba(0, 0, 0, 0.4);
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        cursor: pointer; }
        .tutorials .ant-modal .ant-modal-content .ant-row .images .arrow svg {
          color: white;
          font-size: 18px; }
        .tutorials .ant-modal .ant-modal-content .ant-row .images .arrow.left {
          left: 20px; }
        .tutorials .ant-modal .ant-modal-content .ant-row .images .arrow.right {
          right: 20px; }
      .tutorials .ant-modal .ant-modal-content .ant-row .images .previews {
        position: absolute;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        bottom: -25px; }
        .tutorials .ant-modal .ant-modal-content .ant-row .images .previews img {
          width: 60px;
          height: 60px;
          object-fit: cover;
          margin: 5px;
          filter: contrast(0.6); }
          .tutorials .ant-modal .ant-modal-content .ant-row .images .previews img.active {
            filter: contrast(0.2); }
    .tutorials .ant-modal .ant-modal-content .ant-row .text {
      padding-left: 20px; }
    .tutorials .ant-modal .ant-modal-content .ant-row .steps .steps-header {
      display: flex;
      align-self: center;
      margin-bottom: 30px; }
      .tutorials .ant-modal .ant-modal-content .ant-row .steps .steps-header h3 {
        color: #505157;
        font-weight: 500;
        font-size: 16px;
        margin-left: 10px; }
      .tutorials .ant-modal .ant-modal-content .ant-row .steps .steps-header .arrow {
        min-width: 30px;
        min-height: 30px;
        max-width: 30px;
        max-height: 30px;
        background: #505157;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-right: 10px; }
        .tutorials .ant-modal .ant-modal-content .ant-row .steps .steps-header .arrow svg {
          color: white;
          font-size: 18px; }
        .tutorials .ant-modal .ant-modal-content .ant-row .steps .steps-header .arrow.inactive {
          background-color: #8C8C8C; }
          .tutorials .ant-modal .ant-modal-content .ant-row .steps .steps-header .arrow.inactive svg {
            color: white !important; }

@media (max-width: 768px) {
  .tutorials .head {
    flex-direction: column;
    align-items: baseline !important; }
    .tutorials .head .ant-checkbox-wrapper {
      order: -1 !important;
      margin-left: 0px !important;
      margin-left: 0px !important;
      margin-bottom: 20px !important;
      display: block !important; }
      .tutorials .head .ant-checkbox-wrapper .ant-checkbox-inner {
        width: 18px !important;
        height: 18px !important;
        background-color: transparent; }
  .tutorials .steps-header {
    margin-bottom: 20px !important; }
  .tutorials .previews {
    bottom: -15px !important; }
    .tutorials .previews .preview-img {
      height: 30px !important;
      width: 30px !important; } }

.image-preview {
  width: 100% !important; }
  .image-preview .ant-modal-body {
    padding: 20px; }
    .image-preview .ant-modal-body img {
      width: 100%;
      border-radius: 8px; }

.payout-modal .ant-modal-content {
  overflow: hidden; }

.payout-modal .buttons {
  display: flex;
  padding: 25px;
  background: #E6EBEC;
  margin-left: -24px;
  margin-bottom: -24px;
  width: calc(100% + 48px); }

.payout-modal .choose-payout .inline {
  display: flex; }
  .payout-modal .choose-payout .inline .commission-amount {
    width: 298px;
    height: 88px;
    border: 1px solid #505157;
    border-radius: 10px;
    padding: 13px 15px; }
    .payout-modal .choose-payout .inline .commission-amount p {
      color: #BA4A00;
      font-weight: 500;
      font-family: Poppins;
      margin-bottom: 0px; }
    .payout-modal .choose-payout .inline .commission-amount .amount {
      font-weight: 600;
      font-family: Poppins;
      color: black;
      font-size: 30px; }
      .payout-modal .choose-payout .inline .commission-amount .amount span {
        font-size: 20px; }
  .payout-modal .choose-payout .inline img {
    margin-left: 20px;
    width: 85px;
    height: auto;
    object-fit: cover; }

.payout-modal .choose-payout .text {
  font-family: Montserrat;
  font-weight: 300;
  font-size: 16px;
  color: #8C8C8C;
  margin-top: 20px; }

.payout-modal .choose-payout .buttons .button {
  flex: 1;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 15px;
  cursor: pointer; }
  .payout-modal .choose-payout .buttons .button label {
    font-family: Poppins;
    font-weight: 500;
    font-size: 10px;
    margin-bottom: 5px; }
  .payout-modal .choose-payout .buttons .button h3 {
    font-family: Poppins;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 5px; }
  .payout-modal .choose-payout .buttons .button p {
    margin-bottom: 0px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 0px; }
  .payout-modal .choose-payout .buttons .button.auto {
    margin-right: 10px;
    background-color: #BA4A00; }
    .payout-modal .choose-payout .buttons .button.auto label {
      color: white;
      opacity: .4; }
    .payout-modal .choose-payout .buttons .button.auto h3 {
      color: #F6E9D9; }
    .payout-modal .choose-payout .buttons .button.auto p {
      color: white; }
  .payout-modal .choose-payout .buttons .button.manual {
    margin-left: 10px;
    background-color: white; }
    .payout-modal .choose-payout .buttons .button.manual label {
      color: #8C8C8C; }
    .payout-modal .choose-payout .buttons .button.manual h3 {
      color: black; }
    .payout-modal .choose-payout .buttons .button.manual p {
      color: #BA4A00; }

@media (max-width: 768px) {
  .payout-modal .choose-payout img {
    display: none; }
  .payout-modal .choose-payout .text {
    font-size: 14px; }
  .payout-modal .choose-payout .buttons {
    display: block; }
    .payout-modal .choose-payout .buttons .button {
      margin-left: 0px !important;
      margin-right: 0px !important; }
      .payout-modal .choose-payout .buttons .button.auto {
        margin-bottom: 20px; } }

.payout-modal .bankdetails .back {
  color: #BA4A00;
  font-weight: 500;
  margin-bottom: 40px;
  cursor: pointer; }

.payout-modal .bankdetails h1 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0px; }

.payout-modal .bankdetails p {
  font-weight: 300;
  font-size: 16px;
  color: #8C8C8C; }

.payout-modal .bankdetails .account {
  padding-right: 10px; }

.payout-modal .bankdetails .accountolder {
  padding-left: 10px; }

.payout-modal .bankdetails .buttons button {
  margin-left: auto; }

.payout-modal .stripe-success .anticon-check-circle {
  margin: 0 auto;
  display: block;
  margin-bottom: 20px; }
  .payout-modal .stripe-success .anticon-check-circle svg {
    color: #89C763;
    font-size: 100px; }

.payout-modal .stripe-success p {
  font-weight: 300;
  font-size: 16px;
  color: #8C8C8C;
  text-align: center; }

.payout-modal .stripe-success button {
  height: 50px;
  border-radius: 8px;
  background: #DADADA;
  color: black;
  width: 100%; }

.no-x .ant-modal-close, .no-x .ant-drawer-close {
  display: none; }

.ant-popover-arrow {
  display: none; }

.ant-popover-inner {
  width: 200px; }

.ant-popover.wide {
  width: 240px; }
  .ant-popover.wide .ant-popover-inner {
    width: 100%; }

.ant-popover-inner-content {
  padding: 15px; }

.popover-content .ant-checkbox-group {
  display: flex;
  flex-direction: column; }
  .popover-content .ant-checkbox-group label {
    background-color: #F5F5F5;
    margin-right: 0px;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 5px;
    font-size: 12px; }
    .popover-content .ant-checkbox-group label.ant-checkbox-wrapper {
      display: flex;
      margin-left: 0px;
      align-items: center; }

.popover-content .controls {
  margin-top: 15px;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between; }
  .popover-content .controls p {
    margin: 0px;
    font-size: 14px;
    cursor: pointer; }
    .popover-content .controls p:first-of-type {
      color: #DADADA; }
    .popover-content .controls p:last-of-type {
      color: #398CAF; }

.popover-content .status .ant-progress-steps-item {
  height: 3px !important;
  border-radius: 3px;
  background: #DADADA; }
  .popover-content .status .ant-progress-steps-item:last-of-type {
    margin-left: 10px; }

.popover-content .status p {
  margin: 0px;
  font-weight: 500;
  font-size: 12px; }

.filter-selector button {
  height: 50px;
  margin-right: 20px;
  font-size: 14px;
  border-color: transparent;
  color: #8C8C8C; }
  .filter-selector button:focus {
    border: 1px solid #505157 !important;
    background-color: #F5F5F5;
    color: #595959; }

.filter-selector.active button {
  display: flex;
  align-items: center;
  border: 2px solid #398CAF;
  background-color: #D9EBF3;
  color: #398CAF; }
  .filter-selector.active button .indicator {
    display: flex;
    align-items: center;
    margin-left: 8px; }
    .filter-selector.active button .indicator div {
      min-width: 20px;
      min-height: 20px;
      font-size: 12px;
      color: white;
      padding-top: 1px;
      padding-left: 1px;
      cursor: pointer; }
      .filter-selector.active button .indicator div:first-of-type {
        background-color: #70ADC6; }
      .filter-selector.active button .indicator div:last-of-type {
        background-color: #398CAF; }

.status-payment {
  display: flex;
  align-items: center; }
  .status-payment p {
    margin: 0px; }
  .status-payment .dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 10px; }
    .status-payment .dot.Cleared {
      background-color: #52C41A; }
    .status-payment .dot.Due {
      background-color: #FFC100; }
    .status-payment .dot.Late {
      background-color: #FB4953; }
    .status-payment .dot.Cancelled {
      background-color: #1B1B1B; }

.filters-drawer-leads .ant-drawer-close {
  display: none; }

.filters-drawer-leads .head {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #BFBFBF; }
  .filters-drawer-leads .head p {
    font-size: 16px;
    color: #8B8D96; }

.filters-drawer-leads p.blue {
  color: #398CAF; }

.filters-drawer-leads .clear-all {
  font-size: 16px;
  margin-top: 20px;
  text-align: center; }

.filters-drawer-leads .filter {
  box-shadow: 0 6px 20px 8px #00000026;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 20px; }
  .filters-drawer-leads .filter .ant-checkbox-group {
    display: flex;
    flex-direction: column; }
    .filters-drawer-leads .filter .ant-checkbox-group label {
      background-color: #F5F5F5;
      margin-right: 0px;
      padding: 10px;
      border-radius: 4px;
      margin-bottom: 5px;
      font-size: 14px;
      color: #505157; }
    .filters-drawer-leads .filter .ant-checkbox-group .ant-checkbox-inner {
      height: 24px;
      width: 24px; }
    .filters-drawer-leads .filter .ant-checkbox-group .ant-checkbox-checked .ant-checkbox-inner:after {
      margin-left: 2px; }
  .filters-drawer-leads .filter .controls {
    margin-top: 5px;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between; }
    .filters-drawer-leads .filter .controls p {
      margin: 0px;
      font-size: 14px; }
      .filters-drawer-leads .filter .controls p:first-of-type {
        color: #505157;
        cursor: pointer; }
      .filters-drawer-leads .filter .controls p:last-of-type {
        color: #8B8D96;
        font-weight: 300; }
      .filters-drawer-leads .filter .controls p span {
        font-weight: 300; }
  .filters-drawer-leads .filter .separator {
    background-color: #BFBFBF;
    height: 1px;
    margin: 0 auto;
    display: block;
    width: 70%;
    position: relative;
    top: 1px;
    z-index: 1;
    z-index: 10; }
  .filters-drawer-leads .filter .ant-input-affix-wrapper {
    border: none;
    background-color: #F5F5F5; }
    .filters-drawer-leads .filter .ant-input-affix-wrapper input {
      background-color: transparent;
      height: 50px;
      color: #505157; }
    .filters-drawer-leads .filter .ant-input-affix-wrapper:first-of-type {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px; }
    .filters-drawer-leads .filter .ant-input-affix-wrapper:last-of-type {
      margin-top: -1px;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px; }
    .filters-drawer-leads .filter .ant-input-affix-wrapper .ant-input-prefix {
      color: #BFBFBF; }
    .filters-drawer-leads .filter .ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
      border: none;
      box-shadow: none; }
  .filters-drawer-leads .filter.statuses .ant-checkbox-wrapper {
    margin-left: 0px;
    display: flex;
    align-items: center; }
    .filters-drawer-leads .filter.statuses .ant-checkbox-wrapper .ant-checkbox {
      margin-right: 10px; }

.filters-drawer-leads .status .ant-progress-steps-item {
  height: 3px !important;
  border-radius: 3px; }
  .filters-drawer-leads .status .ant-progress-steps-item:last-of-type {
    margin-left: 10px; }

.filters-drawer-leads .status p {
  margin: 0px;
  font-weight: 500;
  font-size: 12px; }

.products-overview > .ant-row {
  margin-left: -20px;
  margin-right: -20px;
  margin-left: 0px;
  margin-right: 0px; }

.products-overview .section-header {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .products-overview .section-header label {
    font-size: 14px;
    font-weight: 600; }
  .products-overview .section-header .view-selector {
    display: flex; }
    .products-overview .section-header .view-selector p {
      margin-left: 20px;
      color: #8B8D96;
      font-weight: 500;
      cursor: pointer; }
      .products-overview .section-header .view-selector p.active {
        cursor: unset;
        color: black;
        border-bottom: 1px solid black; }
  .products-overview .section-header.agent-products {
    margin-top: 40px; }

.products-overview .promoted .ant-table-cell > div {
  color: #BA4A00; }

.products-overview .promoted .discount-rate {
  background-color: #BA4A00;
  font-weight: 600;
  border-radius: 4px;
  padding: 3px 7px;
  color: white;
  margin-right: 10px;
  max-width: 45px; }

.products-overview td .ant-input-affix-wrapper {
  background-color: #F5F5F5;
  height: 40px;
  border-color: transparent; }
  .products-overview td .ant-input-affix-wrapper input {
    background-color: transparent;
    color: #505157; }
  .products-overview td .ant-input-affix-wrapper .ant-input-prefix {
    color: #8B8D96; }
  .products-overview td .ant-input-affix-wrapper.ant-input-affix-wrapper-disabled {
    background-color: #DADADA; }

.products-overview td button {
  height: 28px; }

.products-overview .perpetual-product-name {
  display: flex;
  align-items: center;
  color: #BA4A00; }
  .products-overview .perpetual-product-name img {
    width: auto;
    height: 17px;
    margin-right: 10px; }

.products-overview .product-actions {
  display: flex;
  width: 146px; }
  .products-overview .product-actions > div {
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;
    background-color: #505157; }
    .products-overview .product-actions > div i:before {
      font-size: 16px;
      color: white;
      position: relative;
      top: 2px; }

.products-overview .price span {
  font-size: 12px;
  color: #8B8D96;
  margin-right: 10px; }

.products-overview .product-card {
  padding: 20px;
  position: relative; }
  .products-overview .product-card > div {
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.08), 0 1px 18px 0 rgba(0, 0, 0, 0.1), 0 3px 5px 0 rgba(0, 0, 0, 0.1);
    background-color: white;
    border-radius: 4px;
    overflow: hidden; }
    .products-overview .product-card > div .image {
      padding-top: 70%;
      background-position: center !important;
      background-repeat: no-repeat !important;
      background-size: cover !important; }
    .products-overview .product-card > div .category {
      font-size: 12px;
      color: #8B8D96;
      margin: 10px 0px 0px 10px; }
    .products-overview .product-card > div h5 {
      font-weight: 500;
      color: #BA4A00;
      font-size: 14px;
      margin: 0px 0px 0px 10px; }
    .products-overview .product-card > div .description {
      height: 5vw;
      position: relative;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      overflow: hidden; }
      .products-overview .product-card > div .description .gradient {
        top: 0px;
        left: 0px;
        height: 5vw;
        background: linear-gradient(0deg, white 0%, rgba(255, 255, 255, 0) 33%, rgba(255, 255, 255, 0) 100%);
        width: 100%;
        position: absolute; }
      .products-overview .product-card > div .description p {
        margin: 0px;
        align-self: flex-start;
        color: #505157;
        line-height: 1.5;
        margin-right: 10px; }
      .products-overview .product-card > div .description div:not(.gradient) {
        min-width: 28px;
        min-height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        border-radius: 4px;
        overflow: hidden;
        cursor: pointer;
        background-color: #505157; }
        .products-overview .product-card > div .description div:not(.gradient) i:before {
          font-size: 16px;
          color: white; }
  @media (max-width: 768px) {
    .products-overview .product-card {
      padding-top: 10px; }
      .products-overview .product-card:nth-of-type(odd) {
        padding-right: 10px; }
      .products-overview .product-card:nth-of-type(even) {
        padding-left: 10px; }
      .products-overview .product-card .category {
        font-size: 10px !important; }
      .products-overview .product-card h5 {
        font-size: 12px !important;
        margin-bottom: 5px !important; } }

@media (max-width: 768px) {
  .products-overview {
    margin-top: 161px;
    min-height: calc(100vh - 231px); }
    .products-overview.agent {
      background-color: #EAEDEE; }
    .products-overview.admin {
      background-color: white; } }

.products-overview table input {
  text-align: right; }

.product-preview .ant-popover-inner-content > div img {
  width: 173px; }

.product-mobile {
  margin: 10px 20px;
  display: flex;
  width: calc(100% - 40px);
  align-items: center;
  border-radius: 8px;
  height: 80px;
  padding: 8px 15px;
  justify-content: space-between; }
  .product-mobile:nth-of-type(even) {
    background-color: #F5F5F5; }
  .product-mobile.promoted .bold {
    color: #BA4A00; }
  .product-mobile p {
    margin-bottom: 0px; }
    .product-mobile p.bold {
      font-weight: 500 !important; }
  .product-mobile .value {
    margin-left: auto; }
    .product-mobile .value p {
      text-align: right; }
      .product-mobile .value p span {
        font-weight: 300;
        margin-right: 10px; }
  .product-mobile > span {
    margin-left: 10px; }
  .product-mobile .product-actions {
    justify-content: flex-end; }
    .product-mobile .product-actions > div {
      margin-right: 0px;
      margin-left: 10px; }

.filters-bar {
  display: flex;
  align-items: center;
  margin-bottom: 30px; }
  .filters-bar .ant-input-affix-wrapper {
    max-width: 390px;
    margin-right: 20px;
    border: none;
    border-radius: 8px; }
    .filters-bar .ant-input-affix-wrapper input {
      height: 42px;
      font-size: 16px;
      color: #8B8D96; }
    .filters-bar .ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
      box-shadow: none;
      border: 1px solid #1890FF;
      background-color: #F4F9FF; }
      .filters-bar .ant-input-affix-wrapper.ant-input-affix-wrapper-focused input {
        background-color: transparent; }
  .filters-bar .ant-select, .filters-bar .ant-select:hover, .filters-bar .ant-select-focused {
    background-color: white;
    border-radius: 8px;
    font-size: 14px;
    color: #595959;
    margin-right: 20px; }
    .filters-bar .ant-select .ant-select-selector, .filters-bar .ant-select:hover .ant-select-selector, .filters-bar .ant-select-focused .ant-select-selector {
      height: 48px;
      border-color: transparent !important;
      box-shadow: none !important; }
    .filters-bar .ant-select .ant-select-selection-item, .filters-bar .ant-select:hover .ant-select-selection-item, .filters-bar .ant-select-focused .ant-select-selection-item {
      line-height: 48px; }
  .filters-bar > button {
    margin-left: auto; }
  .filters-bar .ant-input-prefix {
    margin-right: 20px;
    font-size: 16px;
    color: #8B8D96; }

@media (max-width: 768px) {
  .filters-bar {
    display: block;
    padding: 20px;
    margin-bottom: 10px;
    background-color: #EAEDEE; }
    .filters-bar .inline {
      display: flex;
      width: 100%;
      margin-bottom: 20px; }
      .filters-bar .inline:last-of-type {
        margin-bottom: 0px; }
      .filters-bar .inline button {
        height: 50px;
        min-width: 50px; }
      .filters-bar .inline .filters {
        min-width: 50px;
        min-height: 50px;
        background-color: white;
        border-radius: 8px;
        margin-left: 20px;
        display: flex;
        align-items: center;
        justify-content: center; }
        .filters-bar .inline .filters i:before {
          font-size: 20px;
          color: #505157; } }

.filters-drawer .ant-drawer-close {
  display: none; }

.filters-drawer .head {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #BFBFBF; }
  .filters-drawer .head p {
    font-size: 16px;
    color: #8B8D96; }

.filters-drawer p.blue {
  color: #398CAF; }

.filters-drawer .clear-all {
  font-size: 16px;
  margin-top: 20px;
  text-align: center; }

.filters-drawer .filter {
  box-shadow: 0 6px 20px 8px #00000026;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 20px; }
  .filters-drawer .filter .ant-checkbox-group {
    display: flex;
    flex-direction: column; }
    .filters-drawer .filter .ant-checkbox-group label {
      background-color: #F5F5F5;
      margin-right: 0px;
      padding: 10px;
      border-radius: 4px;
      margin-bottom: 5px;
      font-size: 14px;
      color: #505157; }
  .filters-drawer .filter .controls {
    margin-top: 5px;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between; }
    .filters-drawer .filter .controls p {
      margin: 0px;
      font-size: 14px; }
      .filters-drawer .filter .controls p:first-of-type {
        color: #505157;
        cursor: pointer; }
      .filters-drawer .filter .controls p:last-of-type {
        color: #8B8D96;
        font-weight: 300; }

.filters-drawer .status-selector label .ant-checkbox span:before {
  content: '';
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  margin-left: 30px; }

.filters-drawer .status-selector label:nth-of-type(1) .ant-checkbox span:before {
  background-color: #52C41A; }

.filters-drawer .status-selector label:nth-of-type(2) .ant-checkbox span:before {
  background-color: #1890FF; }

.filters-drawer .status-selector label:nth-of-type(3) .ant-checkbox span:before {
  background-color: #FFC100; }

.filters-drawer .status-selector label > span:last-of-type {
  margin-left: 30px; }

.status-cell {
  display: flex;
  align-items: center; }
  .status-cell .status-dot {
    width: 10px;
    margin-right: 10px;
    height: 10px;
    border-radius: 50%; }
    .status-cell .status-dot.Active {
      background-color: #52C41A; }
    .status-cell .status-dot.Suspended {
      background-color: #FFC100; }
    .status-cell .status-dot.Pending {
      background-color: #1890FF; }

.table-btns {
  display: flex;
  justify-content: flex-end; }
  .table-btns button {
    height: 28px;
    margin-left: 10px; }

.ant-table-column-sort .ant-table-column-sorters:first-child {
  color: #BA4A00; }

.user-mobile {
  margin: 10px 20px;
  display: flex;
  width: calc(100% - 40px);
  align-items: center;
  border-radius: 8px;
  height: 80px;
  padding: 8px 15px; }
  .user-mobile:nth-of-type(even) {
    background-color: #F5F5F5; }
  .user-mobile div:first-of-type {
    height: 100%; }
  .user-mobile div p:first-of-type {
    font-size: 16px;
    color: #505157;
    margin: 0px; }
  .user-mobile div p:last-of-type {
    font-size: 14px;
    font-weight: 300;
    margin: 0px; }
  .user-mobile div:nth-of-type(2) {
    margin-left: auto; }
  .user-mobile > span {
    margin-left: 20px; }
  .user-mobile .joined {
    font-size: 16px !important;
    color: #505157; }
  .user-mobile .status {
    font-size: 14px;
    display: flex;
    align-items: center; }
    .user-mobile .status i {
      width: 14px;
      height: 14px;
      margin-left: 10px;
      border-radius: 50%;
      display: block; }
      .user-mobile .status i.Suspended {
        background-color: #FFC100; }
      .user-mobile .status i.Active {
        background-color: #52C41A; }
      .user-mobile .status i.Pending {
        background-color: #1890FF; }

.invite-modal .ant-modal-body {
  padding: 0px; }

.invite-modal .ant-modal-content {
  overflow: hidden; }

.invite-modal .ant-form > .ant-row {
  padding: 24px 24px 0px 24px; }

.invite-modal img {
  width: 175px;
  margin: 0 auto;
  display: block; }

.invite-modal h5 {
  color: #BA4A00;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  margin: 20px 0px; }

.invite-modal .ant-modal-close-x {
  color: #DADADA;
  font-size: 20px; }

.invite-modal .padding-right {
  padding-right: 10px; }

.invite-modal .padding-left {
  padding-left: 10px; }

.invite-modal .footer {
  padding: 24px;
  width: 100%;
  height: 80px;
  display: flex !important;
  align-items: center;
  justify-content: flex-end;
  background-color: #E6EBEC; }
  .invite-modal .footer button {
    margin-left: 10px; }

@media (max-width: 768px) {
  .invite-modal .ant-modal {
    width: 100% !important;
    margin: 0px;
    max-width: 100%;
    min-height: 100%;
    position: absolute;
    top: 0px;
    padding: 0px; }
    .invite-modal .ant-modal input {
      font-size: 16px; }
    .invite-modal .ant-modal .ant-modal-body {
      width: 100%; }
    .invite-modal .ant-modal .ant-modal-content {
      overflow: hidden;
      min-height: 100%;
      width: 100%; }
      .invite-modal .ant-modal .ant-modal-content img {
        width: 75%; }
      .invite-modal .ant-modal .ant-modal-content h5 {
        font-size: 16px;
        text-align: left; }
      .invite-modal .ant-modal .ant-modal-content .padding-left, .invite-modal .ant-modal .ant-modal-content .padding-right {
        padding: 0px; }
      .invite-modal .ant-modal .ant-modal-content .footer {
        background-color: transparent;
        flex-direction: column;
        height: auto; }
        .invite-modal .ant-modal .ant-modal-content .footer button {
          margin-left: 0px;
          margin-bottom: 20px;
          width: 100%;
          height: 50px;
          font-size: 14px; } }

#edit-user label.title {
  color: #BA4A00;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 10px;
  display: block; }

#edit-user .right {
  padding-left: 20px; }
  #edit-user .right .box {
    min-height: calc(100vh - 250px);
    background-color: transparent; }

#edit-user img {
  position: absolute;
  right: 20px;
  bottom: 20px; }

#edit-user .ant-form > .ant-row > .ant-col {
  padding: 10px; }

@media (max-width: 768px) {
  #edit-user label.title {
    display: none; }
  #edit-user .right {
    padding-left: 0px; }
  #edit-user .view-selector {
    background-color: #EAEDEE;
    padding: 20px;
    margin-top: -20px;
    width: 100%; }
    #edit-user .view-selector .ant-radio-group {
      width: 100%;
      padding: 6px;
      background-color: white;
      border-radius: 8px; }
      #edit-user .view-selector .ant-radio-group > label {
        width: 50%;
        height: 38px;
        padding-top: 4px;
        text-align: center;
        border: none;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
        border-color: transparent;
        border-width: 0px; }
        #edit-user .view-selector .ant-radio-group > label:before {
          display: none; }
        #edit-user .view-selector .ant-radio-group > label.ant-radio-button-wrapper-checked {
          background-color: #BA4A00; }
  #edit-user img {
    position: relative;
    margin: 0 auto;
    display: block;
    margin-top: 40px;
    bottom: 0px;
    right: 0px; } }

.commissions-table .money-row {
  display: flex; }
  .commissions-table .money-row span {
    margin-right: 10px;
    color: #8B8D96; }

.commissions-table .ordernumber-column {
  display: flex;
  align-items: center;
  cursor: pointer; }
  .commissions-table .ordernumber-column p {
    margin: 0px 5px 0px 0px; }
  .commissions-table .ordernumber-column svg {
    color: #8B8D96; }

.commissions-table .ant-btn[disabled] {
  background-color: #DADADA;
  color: #F5F5F5; }

.commissions-table .status-column {
  display: flex;
  align-items: center; }
  .commissions-table .status-column p {
    margin: 0px; }
  .commissions-table .status-column .dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 10px; }
    .commissions-table .status-column .dot.Cleared {
      background-color: #52C41A; }
    .commissions-table .status-column .dot.Due {
      background-color: #FFC100; }
    .commissions-table .status-column .dot.Late {
      background-color: #FB4953; }
    .commissions-table .status-column .dot.Cancelled {
      background-color: #1B1B1B; }

@media (max-width: 768px) {
  .commissions-table {
    padding-top: 170px; }
    .commissions-table .commission-mobile {
      width: 100%;
      margin: 10px 20px;
      display: flex;
      width: calc(100% - 40px);
      align-items: center;
      border-radius: 8px;
      height: 80px;
      padding: 8px 15px;
      align-items: center; }
      .commissions-table .commission-mobile .ordernumber {
        margin-top: 8px !important;
        display: flex;
        align-items: center;
        height: unset !important; }
        .commissions-table .commission-mobile .ordernumber p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: 500 !important;
          margin-right: 5px !important; }
      .commissions-table .commission-mobile .value {
        font-size: 12px; }
      .commissions-table .commission-mobile .status {
        display: flex;
        align-items: center; }
        .commissions-table .commission-mobile .status p {
          margin: 0px; }
        .commissions-table .commission-mobile .status .dot {
          width: 12px;
          height: 12px;
          border-radius: 50%;
          margin-left: 10px; }
          .commissions-table .commission-mobile .status .dot.Cleared {
            background-color: #52C41A; }
          .commissions-table .commission-mobile .status .dot.Due {
            background-color: #FFC100; }
          .commissions-table .commission-mobile .status .dot.Late {
            background-color: #FB4953; }
          .commissions-table .commission-mobile .status .dot.Cancelled {
            background-color: #1B1B1B; }
      .commissions-table .commission-mobile:nth-of-type(odd) {
        background-color: #F5F5F5; }
      .commissions-table .commission-mobile div:first-of-type {
        height: 100%; }
      .commissions-table .commission-mobile div p:first-of-type {
        font-size: 16px;
        color: #505157;
        margin: 0px;
        margin-top: 5px; }
      .commissions-table .commission-mobile div p:last-of-type {
        font-size: 14px;
        font-weight: 300;
        margin: 0px; }
      .commissions-table .commission-mobile div:nth-of-type(2) {
        margin-left: auto; }
      .commissions-table .commission-mobile > span {
        margin-left: 10px; } }

.order-details .table-btns {
  display: flex; }

.order-details .ant-drawer-body {
  background-color: #EAEDEE; }
  .order-details .ant-drawer-body .header-container {
    height: 82px;
    background-color: #d7dbdd;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% + 48px);
    margin-left: -24px;
    padding: 0px 24px;
    margin-top: -24px; }
    .order-details .ant-drawer-body .header-container > div {
      display: flex;
      align-items: center;
      justify-content: center; }
    .order-details .ant-drawer-body .header-container .title {
      font-size: 18px;
      font-weight: 600;
      color: #BA4A00;
      margin-left: 15px; }
    .order-details .ant-drawer-body .header-container button {
      background-color: #BFBFBF; }
      .order-details .ant-drawer-body .header-container button:hover {
        background-color: #BA4A00; }
    .order-details .ant-drawer-body .header-container .icon {
      height: 40px;
      width: 40px;
      border-radius: 10px;
      background: linear-gradient(135deg, #BA4A00 0%, #490708 100%);
      display: flex;
      align-items: center;
      justify-content: center; }
    .order-details .ant-drawer-body .header-container i:before {
      font-size: 20px;
      color: white; }
  .order-details .ant-drawer-body .actions {
    position: relative;
    padding: 20px 0px;
    display: flex;
    align-items: center; }
    .order-details .ant-drawer-body .actions .primary-medium-1 {
      margin-left: auto; }
    .order-details .ant-drawer-body .actions .ant-input-affix-wrapper {
      max-width: 390px;
      margin-right: 20px;
      border: none;
      border-radius: 8px; }
      .order-details .ant-drawer-body .actions .ant-input-affix-wrapper input {
        height: 42px;
        font-size: 16px;
        color: #8B8D96; }
      .order-details .ant-drawer-body .actions .ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
        box-shadow: none;
        border: 1px solid #1890FF;
        background-color: #F4F9FF; }
        .order-details .ant-drawer-body .actions .ant-input-affix-wrapper.ant-input-affix-wrapper-focused input {
          background-color: transparent; }
    .order-details .ant-drawer-body .actions .buttons {
      margin-left: auto;
      display: flex; }
      .order-details .ant-drawer-body .actions .buttons .forfeit-btn {
        background-color: #505157;
        color: white;
        margin-right: 20px; }
        .order-details .ant-drawer-body .actions .buttons .forfeit-btn:hover {
          background-color: #1B1B1B; }
      .order-details .ant-drawer-body .actions .buttons button {
        height: 40px; }
        .order-details .ant-drawer-body .actions .buttons button i:before {
          font-size: 12px;
          margin-right: 10px; }
  .order-details .ant-drawer-body .clear {
    font-weight: 500;
    color: #398CAF;
    white-space: nowrap;
    margin-bottom: 0px;
    margin-right: 20px;
    cursor: pointer; }
  .order-details .ant-drawer-body table .opportunity-id {
    display: flex;
    align-items: center;
    cursor: pointer; }
    .order-details .ant-drawer-body table .opportunity-id p {
      margin: 0px 5px 0px 0px; }
    .order-details .ant-drawer-body table .opportunity-id svg {
      color: #8B8D96; }
  .order-details .ant-drawer-body table .money-row {
    display: flex; }
    .order-details .ant-drawer-body table .money-row span {
      margin-right: 10px;
      color: #8B8D96; }
  .order-details .ant-drawer-body table td .ant-btn {
    margin-left: auto;
    display: block; }
  .order-details .ant-drawer-body table td {
    border-radius: 0px !important; }
  .order-details .ant-drawer-body table .ant-btn[disabled] {
    background-color: #DADADA;
    color: #F5F5F5; }
  .order-details .ant-drawer-body table .ant-checkbox-disabled .ant-checkbox-inner {
    background-color: #BFBFBF;
    border-color: #BFBFBF !important; }
  .order-details .ant-drawer-body table .ant-table-row-selected td, .order-details .ant-drawer-body table .ant-table-row-selected p, .order-details .ant-drawer-body table .ant-table-row-selected span {
    color: #1890FF; }
  .order-details .ant-drawer-body .status-column {
    display: flex;
    align-items: center; }
    .order-details .ant-drawer-body .status-column p {
      margin: 0px; }
    .order-details .ant-drawer-body .status-column .dot {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      margin-right: 10px; }
      .order-details .ant-drawer-body .status-column .dot.Cleared {
        background-color: #52C41A; }
      .order-details .ant-drawer-body .status-column .dot.Due {
        background-color: #FFC100; }
      .order-details .ant-drawer-body .status-column .dot.Late {
        background-color: #FB4953; }
      .order-details .ant-drawer-body .status-column .dot.Cancelled {
        background-color: #1B1B1B; }

.filters-bar-instalments {
  width: 100vw;
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  background-color: white;
  z-index: 99; }
  .filters-bar-instalments .buttons {
    display: flex;
    padding: 20px; }
    .filters-bar-instalments .buttons p {
      margin: 0px;
      font-size: 16px;
      font-weight: 300; }
      .filters-bar-instalments .buttons p.cancel {
        color: #8B8D96; }
      .filters-bar-instalments .buttons p.clear {
        color: #BA4A00;
        margin-left: auto; }
  .filters-bar-instalments .inline {
    padding: 20px;
    background-color: #EAEDEE;
    display: flex;
    width: 100%; }
    .filters-bar-instalments .inline:last-of-type {
      margin-bottom: 0px; }
    .filters-bar-instalments .inline button {
      height: 50px;
      min-width: 50px; }
      .filters-bar-instalments .inline button svg {
        font-size: 20px; }
    .filters-bar-instalments .inline .filters {
      min-width: 50px;
      min-height: 50px;
      background-color: white;
      border-radius: 8px;
      margin-left: 20px;
      display: flex;
      align-items: center;
      justify-content: center; }
      .filters-bar-instalments .inline .filters i:before {
        font-size: 20px;
        color: #505157;
        position: relative;
        top: 2px; }
  .filters-bar-instalments .instalment-items {
    height: calc(100vh - 156px);
    overflow: scroll; }
    .filters-bar-instalments .instalment-items .ant-checkbox-checked .ant-checkbox-inner::after {
      zoom: 1.4; }
    .filters-bar-instalments .instalment-items .ant-checkbox-disabled .ant-checkbox-inner {
      background-color: #BFBFBF;
      border: none; }
    .filters-bar-instalments .instalment-items > .ant-checkbox-wrapper {
      width: 100%;
      padding: 20px 20px 10px 34px; }
      .filters-bar-instalments .instalment-items > .ant-checkbox-wrapper span {
        font-weight: 300;
        font-size: 16px; }
      .filters-bar-instalments .instalment-items > .ant-checkbox-wrapper .ant-checkbox {
        margin-right: 10px; }
    .filters-bar-instalments .instalment-items .istalment-mobile {
      margin: 10px 20px;
      display: flex;
      width: calc(100% - 40px);
      align-items: center;
      border-radius: 8px;
      height: 80px;
      padding: 8px 15px;
      align-items: center;
      justify-content: space-between; }
      .filters-bar-instalments .instalment-items .istalment-mobile.selected .left p {
        color: #1890FF; }
      .filters-bar-instalments .instalment-items .istalment-mobile p {
        margin: 0px; }
      .filters-bar-instalments .instalment-items .istalment-mobile .status {
        display: flex;
        align-items: center; }
        .filters-bar-instalments .instalment-items .istalment-mobile .status p {
          margin: 0px; }
        .filters-bar-instalments .instalment-items .istalment-mobile .status .dot {
          width: 12px;
          height: 12px;
          border-radius: 50%;
          margin-left: 10px; }
          .filters-bar-instalments .instalment-items .istalment-mobile .status .dot.Cleared {
            background-color: #52C41A; }
          .filters-bar-instalments .instalment-items .istalment-mobile .status .dot.Due {
            background-color: #FFC100; }
          .filters-bar-instalments .instalment-items .istalment-mobile .status .dot.Late {
            background-color: #FB4953; }
          .filters-bar-instalments .instalment-items .istalment-mobile .status .dot.Cancelled {
            background-color: #1B1B1B; }
      .filters-bar-instalments .instalment-items .istalment-mobile:nth-of-type(odd) {
        background-color: #F5F5F5; }
      .filters-bar-instalments .instalment-items .istalment-mobile .left {
        width: 55%;
        margin-right: auto;
        margin-left: 15px; }
      .filters-bar-instalments .instalment-items .istalment-mobile .ordernumber {
        width: 100%;
        margin-top: 8px !important;
        display: flex;
        align-items: center;
        height: unset !important; }
        .filters-bar-instalments .instalment-items .istalment-mobile .ordernumber p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: 500 !important;
          margin-right: 5px !important; }
      .filters-bar-instalments .instalment-items .istalment-mobile div p:first-of-type {
        font-size: 16px;
        color: #505157;
        margin: 0px; }
      .filters-bar-instalments .instalment-items .istalment-mobile div p:last-of-type {
        font-size: 14px;
        font-weight: 300;
        margin: 0px; }
      .filters-bar-instalments .instalment-items .istalment-mobile > span {
        margin-left: 10px; }

.order-details-status {
  left: 50% !important;
  transform-origin: unset !important; }

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #D7E9F9;
  border-color: #1890FF; }
  .ant-checkbox-checked .ant-checkbox-inner:after {
    border-color: #1890FF; }

.leads-table .status {
  display: flex;
  align-items: center; }
  .leads-table .status p {
    margin: 0px; }
  .leads-table .status div {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    margin-right: 10px; }
  .leads-table .status.Open div, .leads-table .status.Payment div {
    background-color: #FFC100; }
  .leads-table .status.Closed div {
    background-color: #52C41A; }
  .leads-table .status.Lost div {
    background-color: #FB4953; }

.leads-table table .secondary-medium-2 {
  height: 30px; }

@media (max-width: 768px) {
  .leads-table {
    margin-top: 170px; } }

.value-col span {
  color: #505157;
  margin-right: 10px; }

.lead-mobile {
  margin: 10px 20px;
  display: flex;
  width: calc(100% - 40px);
  align-items: center;
  border-radius: 8px;
  height: 80px;
  padding: 8px 15px;
  align-items: center; }
  .lead-mobile:nth-of-type(even) {
    background-color: #F5F5F5; }
  .lead-mobile div:first-of-type {
    height: 100%; }
  .lead-mobile div p:first-of-type {
    font-size: 16px;
    color: #505157;
    margin: 0px; }
  .lead-mobile div p:last-of-type {
    font-size: 14px;
    font-weight: 300;
    margin: 0px; }
  .lead-mobile div:nth-of-type(2) {
    margin-left: auto; }
  .lead-mobile > span {
    margin-left: 20px; }
  .lead-mobile .status {
    display: flex; }
    .lead-mobile .status div {
      height: 14px;
      width: 14px;
      border-radius: 50%;
      margin-right: 0px;
      margin-left: 10px; }
    .lead-mobile .status.Open div {
      background-color: #FFC100; }
    .lead-mobile .status.Closed div {
      background-color: #52C41A; }
    .lead-mobile .status.Lost div {
      background-color: #FB4953; }
  .lead-mobile .profit {
    text-align: right;
    font-weight: 500; }
    .lead-mobile .profit span {
      font-weight: 300; }

.payments-table .value-column {
  display: flex; }
  .payments-table .value-column p {
    margin: 0px; }
  .payments-table .value-column span {
    margin-right: 10px;
    display: block;
    color: #8C8C8C; }

.payments-table .ordernumber-column {
  display: flex;
  align-items: center;
  cursor: pointer; }
  .payments-table .ordernumber-column p {
    margin: 0px 5px 0px 0px; }

.payments-table .ant-btn[disabled] {
  background-color: #DADADA;
  color: #F5F5F5; }

.payments-table .status-column {
  display: flex;
  align-items: center; }
  .payments-table .status-column p {
    margin: 0px; }
  .payments-table .status-column .dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 10px; }
    .payments-table .status-column .dot.Cleared {
      background-color: #52C41A; }
    .payments-table .status-column .dot.Due, .payments-table .status-column .dot.Paused {
      background-color: #FFC100; }
    .payments-table .status-column .dot.Late {
      background-color: #FB4953; }
    .payments-table .status-column .dot.Cancelled {
      background-color: #1B1B1B; }

@media (max-width: 768px) {
  .payments-table {
    padding-top: 161px; }
    .payments-table .payment-mobile {
      margin: 10px 20px;
      display: flex;
      width: calc(100% - 40px);
      align-items: center;
      border-radius: 8px;
      height: 80px;
      padding: 8px 15px;
      align-items: center; }
      .payments-table .payment-mobile > div {
        max-width: 60%; }
        .payments-table .payment-mobile > div .ordernumber {
          margin-top: 8px !important;
          display: flex;
          align-items: center;
          height: unset !important; }
          .payments-table .payment-mobile > div .ordernumber p {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: 500 !important;
            margin-right: 5px !important; }
        .payments-table .payment-mobile > div .value {
          font-size: 12px; }
      .payments-table .payment-mobile .status {
        display: flex;
        align-items: center; }
        .payments-table .payment-mobile .status p {
          margin: 0px; }
        .payments-table .payment-mobile .status .dot {
          width: 12px;
          height: 12px;
          border-radius: 50%;
          margin-left: 10px; }
          .payments-table .payment-mobile .status .dot.Cleared {
            background-color: #52C41A; }
          .payments-table .payment-mobile .status .dot.Due, .payments-table .payment-mobile .status .dot.Paused {
            background-color: #FFC100; }
          .payments-table .payment-mobile .status .dot.Late {
            background-color: #FB4953; }
          .payments-table .payment-mobile .status .dot.Cancelled {
            background-color: #1B1B1B; }
      .payments-table .payment-mobile:nth-of-type(even) {
        background-color: #F5F5F5; }
      .payments-table .payment-mobile div:first-of-type {
        height: 100%; }
      .payments-table .payment-mobile div p:first-of-type {
        font-size: 16px;
        color: #505157;
        margin: 0px; }
      .payments-table .payment-mobile div p:last-of-type {
        font-size: 14px;
        font-weight: 300;
        margin: 0px; }
      .payments-table .payment-mobile div:nth-of-type(2) {
        margin-left: auto; }
      .payments-table .payment-mobile > span {
        margin-left: 10px; } }

.order-details .ant-drawer-body {
  background-color: #EAEDEE; }
  .order-details .ant-drawer-body .header-container {
    height: 82px;
    background-color: #d7dbdd;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% + 48px);
    margin-left: -24px;
    padding: 0px 24px;
    margin-top: -24px; }
    .order-details .ant-drawer-body .header-container > div {
      display: flex;
      align-items: center;
      justify-content: center; }
    .order-details .ant-drawer-body .header-container .title, .order-details .ant-drawer-body .header-container .sub-title {
      font-size: 18px;
      font-weight: 600;
      color: #BA4A00;
      margin-left: 15px; }
    .order-details .ant-drawer-body .header-container .sub-title {
      font-weight: 300 !important; }
    .order-details .ant-drawer-body .header-container button {
      background-color: #BFBFBF;
      margin-left: auto; }
      .order-details .ant-drawer-body .header-container button:hover {
        background-color: #BA4A00; }
    .order-details .ant-drawer-body .header-container .icon {
      height: 40px;
      width: 40px;
      border-radius: 10px;
      background: linear-gradient(135deg, #BA4A00 0%, #490708 100%);
      display: flex;
      align-items: center;
      justify-content: center; }
    .order-details .ant-drawer-body .header-container i:before {
      font-size: 20px;
      color: white; }
  .order-details .ant-drawer-body .actions {
    position: relative;
    padding: 20px 0px;
    display: flex;
    align-items: center; }
    .order-details .ant-drawer-body .actions p {
      font-style: italic;
      position: relative;
      top: 8px; }
    .order-details .ant-drawer-body .actions .buttons {
      margin-left: auto;
      display: flex; }
      .order-details .ant-drawer-body .actions .buttons .forfeit-btn {
        background-color: #505157;
        color: white;
        margin-right: 20px; }
        .order-details .ant-drawer-body .actions .buttons .forfeit-btn:hover {
          background-color: #1B1B1B; }
      .order-details .ant-drawer-body .actions .buttons button {
        height: 40px;
        margin-left: 20px; }
        .order-details .ant-drawer-body .actions .buttons button i:before {
          font-size: 12px;
          margin-right: 10px; }
  .order-details .ant-drawer-body .clear {
    font-weight: 500;
    color: #398CAF;
    white-space: nowrap;
    margin-bottom: 0px;
    margin-right: 20px;
    cursor: pointer; }
  .order-details .ant-drawer-body table td .ant-btn {
    margin-left: auto;
    display: block; }
  .order-details .ant-drawer-body table td {
    border-radius: 0px !important; }
  .order-details .ant-drawer-body table .ant-btn[disabled] {
    background-color: #DADADA;
    color: #F5F5F5; }
  .order-details .ant-drawer-body table .ant-checkbox-disabled .ant-checkbox-inner {
    background-color: #BFBFBF;
    border-color: #BFBFBF !important; }
  .order-details .ant-drawer-body table .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #D7E9F9;
    border-color: #1890FF; }
    .order-details .ant-drawer-body table .ant-checkbox-checked .ant-checkbox-inner:after {
      border-color: #1890FF; }
  .order-details .ant-drawer-body table .ant-table-row-selected td {
    color: #1890FF; }
  .order-details .ant-drawer-body table .ant-table-row-selected p {
    color: #595959; }
  .order-details .ant-drawer-body .status-column {
    display: flex;
    align-items: center; }
    .order-details .ant-drawer-body .status-column p {
      margin: 0px; }
    .order-details .ant-drawer-body .status-column .dot {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      margin-right: 10px; }
      .order-details .ant-drawer-body .status-column .dot.Cleared {
        background-color: #52C41A; }
      .order-details .ant-drawer-body .status-column .dot.Due, .order-details .ant-drawer-body .status-column .dot.Paused {
        background-color: #FFC100; }
      .order-details .ant-drawer-body .status-column .dot.Late {
        background-color: #FB4953; }
      .order-details .ant-drawer-body .status-column .dot.Cancelled {
        background-color: #1B1B1B; }
  .order-details .ant-drawer-body thead .ant-table-selection {
    display: none; }

.filters-bar-instalments {
  width: 100vw;
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  background-color: white;
  z-index: 99; }
  .filters-bar-instalments .buttons {
    display: flex;
    padding: 20px; }
    .filters-bar-instalments .buttons p {
      margin: 0px;
      font-size: 16px;
      font-weight: 300; }
      .filters-bar-instalments .buttons p.cancel {
        color: #8B8D96; }
      .filters-bar-instalments .buttons p.forfeit {
        margin-left: auto;
        color: #505157; }
      .filters-bar-instalments .buttons p.fulfil {
        color: #BA4A00;
        margin-left: 20px; }
  .filters-bar-instalments .inline {
    padding: 20px;
    background-color: #EAEDEE;
    display: flex;
    width: 100%; }
    .filters-bar-instalments .inline:last-of-type {
      margin-bottom: 0px; }
    .filters-bar-instalments .inline button {
      height: 50px;
      min-width: 50px; }
      .filters-bar-instalments .inline button svg {
        font-size: 20px; }
    .filters-bar-instalments .inline .filters {
      min-width: 50px;
      min-height: 50px;
      background-color: white;
      border-radius: 8px;
      margin-left: 20px;
      display: flex;
      align-items: center;
      justify-content: center; }
      .filters-bar-instalments .inline .filters i:before {
        font-size: 20px;
        color: #505157;
        position: relative;
        top: 2px; }
  .filters-bar-instalments .instalment-items {
    height: calc(100vh - 156px);
    overflow: scroll; }
    .filters-bar-instalments .instalment-items .istalment-mobile {
      margin: 10px 20px;
      display: flex;
      width: calc(100% - 40px);
      align-items: center;
      border-radius: 8px;
      height: 80px;
      padding: 8px 15px;
      align-items: center; }
      .filters-bar-instalments .instalment-items .istalment-mobile p {
        margin: 0px; }
      .filters-bar-instalments .instalment-items .istalment-mobile .ant-checkbox-wrapper {
        margin-right: 20px; }
      .filters-bar-instalments .instalment-items .istalment-mobile .status {
        display: flex;
        align-items: center; }
        .filters-bar-instalments .instalment-items .istalment-mobile .status p {
          margin: 0px; }
        .filters-bar-instalments .instalment-items .istalment-mobile .status .dot {
          width: 12px;
          height: 12px;
          border-radius: 50%;
          margin-left: 10px; }
          .filters-bar-instalments .instalment-items .istalment-mobile .status .dot.Cleared {
            background-color: #52C41A; }
          .filters-bar-instalments .instalment-items .istalment-mobile .status .dot.Due, .filters-bar-instalments .instalment-items .istalment-mobile .status .dot.Paused {
            background-color: #FFC100; }
          .filters-bar-instalments .instalment-items .istalment-mobile .status .dot.Late {
            background-color: #FB4953; }
          .filters-bar-instalments .instalment-items .istalment-mobile .status .dot.Cancelled {
            background-color: #1B1B1B; }
      .filters-bar-instalments .instalment-items .istalment-mobile:nth-of-type(even) {
        background-color: #F5F5F5; }
      .filters-bar-instalments .instalment-items .istalment-mobile div p:first-of-type {
        font-size: 16px;
        color: #505157;
        margin: 0px; }
      .filters-bar-instalments .instalment-items .istalment-mobile div p:last-of-type {
        font-size: 14px;
        font-weight: 300;
        margin: 0px; }
      .filters-bar-instalments .instalment-items .istalment-mobile div:nth-of-type(2) {
        margin-left: auto; }
      .filters-bar-instalments .instalment-items .istalment-mobile > span {
        margin-left: 10px; }

.order-details-status {
  left: 22% !important;
  transform-origin: unset !important; }

.filters-drawer-leads .ant-drawer-close {
  display: none; }

.filters-drawer-leads .head {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #BFBFBF; }
  .filters-drawer-leads .head p {
    font-size: 16px;
    color: #8B8D96; }

.filters-drawer-leads p.blue {
  color: #398CAF; }

.filters-drawer-leads .clear-all {
  font-size: 16px;
  margin-top: 20px;
  text-align: center; }

.filters-drawer-leads .filter {
  box-shadow: 0 6px 20px 8px #00000026;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 20px; }
  .filters-drawer-leads .filter .ant-checkbox-group {
    display: flex;
    flex-direction: column; }
    .filters-drawer-leads .filter .ant-checkbox-group .ant-checkbox-wrapper {
      display: flex;
      margin-left: 0px; }
    .filters-drawer-leads .filter .ant-checkbox-group label {
      background-color: #F5F5F5;
      margin-right: 0px;
      padding: 10px;
      border-radius: 4px;
      margin-bottom: 5px;
      font-size: 14px;
      color: #505157; }
      .filters-drawer-leads .filter .ant-checkbox-group label p {
        margin: 0px; }
    .filters-drawer-leads .filter .ant-checkbox-group .ant-checkbox-inner {
      height: 24px;
      width: 24px; }
    .filters-drawer-leads .filter .ant-checkbox-group .ant-checkbox-checked .ant-checkbox-inner:after {
      margin-left: 2px; }
  .filters-drawer-leads .filter .controls {
    margin-top: 5px;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between; }
    .filters-drawer-leads .filter .controls p {
      margin: 0px;
      font-size: 14px; }
      .filters-drawer-leads .filter .controls p:first-of-type {
        color: #505157;
        cursor: pointer; }
      .filters-drawer-leads .filter .controls p:last-of-type {
        color: #8B8D96;
        font-weight: 300; }
      .filters-drawer-leads .filter .controls p span {
        font-weight: 300; }
  .filters-drawer-leads .filter .separator {
    background-color: #BFBFBF;
    height: 1px;
    margin: 0 auto;
    display: block;
    width: 70%;
    position: relative;
    top: 1px;
    z-index: 1;
    z-index: 10; }
  .filters-drawer-leads .filter .ant-input-affix-wrapper {
    border: none;
    background-color: #F5F5F5; }
    .filters-drawer-leads .filter .ant-input-affix-wrapper input {
      background-color: transparent;
      height: 50px;
      color: #505157; }
    .filters-drawer-leads .filter .ant-input-affix-wrapper:first-of-type {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px; }
    .filters-drawer-leads .filter .ant-input-affix-wrapper:last-of-type {
      margin-top: -1px;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px; }
    .filters-drawer-leads .filter .ant-input-affix-wrapper .ant-input-prefix {
      color: #BFBFBF; }
    .filters-drawer-leads .filter .ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
      border: none;
      box-shadow: none; }

.filters-drawer-promotions .ant-drawer-close {
  display: none; }

.filters-drawer-promotions .head {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #BFBFBF;
  margin-bottom: 20px; }
  .filters-drawer-promotions .head p {
    font-size: 16px;
    color: #8B8D96; }

.filters-drawer-promotions p.blue {
  color: #398CAF; }

.filters-drawer-promotions .clear-all {
  font-size: 16px;
  margin-top: 20px;
  text-align: center; }

.filters-drawer-promotions .filter {
  box-shadow: 0 6px 20px 8px #00000026;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 20px; }
  .filters-drawer-promotions .filter .ant-radio-group {
    width: 100%; }
    .filters-drawer-promotions .filter .ant-radio-group .ant-space {
      width: 100%; }
  .filters-drawer-promotions .filter .ant-radio-inner {
    width: 24px;
    height: 24px; }
    .filters-drawer-promotions .filter .ant-radio-inner:after {
      width: 12px;
      height: 12px;
      top: 5px;
      left: 5px; }
  .filters-drawer-promotions .filter .ant-radio-checked .ant-radio-inner {
    border-color: #1890FF; }
  .filters-drawer-promotions .filter .ant-radio-checked .ant-radio-inner:after {
    background-color: #1890FF; }
  .filters-drawer-promotions .filter .ant-radio-wrapper {
    background-color: #F5F5F5;
    margin-right: 0px;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: -4px;
    font-size: 14px;
    color: #505157;
    width: 100%;
    display: flex; }
  .filters-drawer-promotions .filter .ant-checkbox-group {
    display: flex;
    flex-direction: column; }
    .filters-drawer-promotions .filter .ant-checkbox-group label {
      background-color: #F5F5F5;
      margin-right: 0px;
      padding: 10px;
      border-radius: 4px;
      margin-bottom: 5px;
      font-size: 14px;
      color: #505157;
      display: flex; }
    .filters-drawer-promotions .filter .ant-checkbox-group .ant-checkbox-inner {
      height: 24px;
      width: 24px; }
    .filters-drawer-promotions .filter .ant-checkbox-group .ant-checkbox-checked .ant-checkbox-inner:after {
      margin-left: 2px; }
  .filters-drawer-promotions .filter .ant-checkbox-wrapper {
    margin-left: 0px !important; }
  .filters-drawer-promotions .filter .controls {
    margin-top: 5px;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between; }
    .filters-drawer-promotions .filter .controls p {
      margin: 0px;
      font-size: 14px; }
      .filters-drawer-promotions .filter .controls p:first-of-type {
        color: #505157;
        cursor: pointer; }
      .filters-drawer-promotions .filter .controls p:last-of-type {
        color: #8B8D96;
        font-weight: 300; }
      .filters-drawer-promotions .filter .controls p span {
        font-weight: 300; }

.promotions-overview .table-status {
  display: flex;
  align-items: center; }
  .promotions-overview .table-status p {
    margin: 0px; }
  .promotions-overview .table-status div {
    margin-right: 5px;
    width: 14px;
    height: 14px;
    border-radius: 50%; }
  .promotions-overview .table-status.Active div {
    background-color: #52C41A; }
  .promotions-overview .table-status.Inactive div {
    background-color: #FFC100; }

.promotions-overview .table-btns {
  display: flex; }

@media (max-width: 768px) {
  .promotions-overview {
    padding-top: 150px; } }

.promotion-mobile {
  margin: 10px 20px;
  display: flex;
  width: calc(100% - 40px);
  align-items: center;
  border-radius: 8px;
  height: 80px;
  padding: 8px 15px;
  justify-content: space-between; }
  .promotion-mobile:nth-of-type(even) {
    background-color: #F5F5F5; }
  .promotion-mobile p {
    margin-bottom: 0px; }
    .promotion-mobile p.bold {
      font-weight: 500 !important; }
  .promotion-mobile .value {
    margin-left: auto; }
    .promotion-mobile .value p {
      text-align: right; }
      .promotion-mobile .value p span {
        font-weight: 300;
        margin-right: 10px; }
  .promotion-mobile > span {
    margin-left: 10px; }

.header-bar {
  width: 100%;
  background-color: #D7DBDD;
  display: flex;
  border-radius: 10px;
  height: 58px;
  align-items: center;
  padding: 20px;
  margin-bottom: 25px; }
  .header-bar > span {
    display: flex;
    align-items: center; }
  .header-bar .share-container {
    cursor: pointer;
    transition: background-color .5s;
    background-color: #505157;
    height: 32px;
    width: 32px;
    border-radius: 4px;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center; }
    .header-bar .share-container i:before {
      color: white;
      font-size: 16px;
      line-height: unset; }
    .header-bar .share-container:hover {
      background-color: #BA4A00; }
  .header-bar h4 {
    margin-bottom: 0px;
    margin-left: 10px;
    cursor: pointer;
    color: #BA4A00; }
    .header-bar h4.title {
      color: #505157;
      cursor: unset; }
  .header-bar svg {
    color: #BA4A00; }
  .header-bar .right {
    display: flex;
    align-items: center;
    margin-left: auto; }
    .header-bar .right button {
      margin-left: 10px; }
  .header-bar .save i:before {
    font-size: 13px;
    margin-left: 10px; }
  .header-bar .extra-btn {
    color: #8B8D96;
    margin: 0px 10px;
    cursor: pointer;
    font-weight: 500; }
  @media (max-width: 768px) {
    .header-bar {
      background-color: white;
      height: unset;
      padding: 10px;
      box-shadow: 0 4px 8px 0 #00000033;
      border-radius: 0px; }
      .header-bar button {
        height: 40px;
        width: 100%;
        margin: 0px 5px; }
        .header-bar button.elipsis {
          max-width: 40px; }
          .header-bar button.elipsis i:before {
            font-size: 20px;
            position: relative;
            right: 4px !important;
            top: 2px !important; }
          .header-bar button.elipsis .flaticon-delete:before {
            top: 0px;
            right: 3px; }
        .header-bar button.unarchive-mobile-btn {
          width: 40%;
          margin-left: auto; }
      .header-bar .share-container {
        min-width: 40px;
        min-height: 40px;
        margin-left: 5px; } }
  @media (min-width: 768px) {
    .header-bar.account-settings {
      width: calc(100vw - 149px); } }

.confirm-delete img {
  width: 190px; }

.confirm-delete h5 {
  color: #FB4953;
  font-size: 14px;
  font-weight: 500;
  margin: 20px 0px; }

.confirm-delete p {
  color: #8C8C8C;
  font-weight: 300; }
  .confirm-delete p span {
    color: #1B1B1B;
    font-weight: normal; }

.confirm-delete .ant-modal-body {
  padding: 0px; }
  .confirm-delete .ant-modal-body .ant-modal-confirm-body {
    padding: 20px; }

.confirm-delete .ant-modal-confirm-btns {
  width: 100%;
  padding: 25px;
  background: #E6EBEC;
  margin-top: 0px;
  text-align: right;
  display: flex;
  justify-content: flex-end; }

.confirm-delete button {
  margin-left: 20px; }

@media (max-width: 768px) {
  .confirm-delete {
    position: absolute;
    bottom: -32px;
    width: 100% !important;
    max-width: 100%;
    top: unset; }
    .confirm-delete .ant-modal-content {
      border-radius: 0px; }
    .confirm-delete .ant-modal-confirm-btns {
      display: flex;
      flex-direction: column; }
      .confirm-delete .ant-modal-confirm-btns button {
        height: 50px;
        margin-left: 0px; }
        .confirm-delete .ant-modal-confirm-btns button:first-of-type {
          margin-bottom: 15px; }
    .confirm-delete h5 {
      font-size: 16px; }
    .confirm-delete img {
      width: 70%; } }

.confirm-delete.instalment .ant-btn-primary {
  background-color: #BA4A00; }

.confirm-delete.instalment p {
  margin-top: -20px;
  margin-bottom: 0px; }
  .confirm-delete.instalment p span {
    font-weight: 500; }

.confirm-delete.commission p {
  margin-top: 20px; }

@media (min-width: 768px) {
  #payment-actions .confirm-delete {
    position: absolute;
    right: 20px;
    top: 160px; } }

#payment-actions .confirm-delete .ant-modal-confirm-btns {
  border-radius: 0px 0px 4px 4px; }

#account label.title {
  color: #BA4A00;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 10px;
  display: block; }

#account .box {
  overflow: hidden; }

#account .right {
  padding-left: 20px; }

#account .connect-stripe label {
  color: #505157;
  font-size: 14px;
  font-weight: 500; }

#account .connect-stripe p {
  color: #8B8D96; }

#account .connect-stripe button {
  white-space: initial;
  height: auto;
  color: white;
  min-height: 33px;
  width: 100%; }

#account img {
  position: absolute;
  right: -73px;
  margin-left: auto;
  width: 76%;
  bottom: -74px; }
  @media (max-width: 992px) {
    #account img {
      display: none; } }

#account .ant-form > .ant-row > .ant-col {
  padding: 10px; }

#account .resetpass .password-box {
  position: relative; }
  #account .resetpass .password-box span {
    position: absolute;
    right: 36px;
    color: #398CAF;
    font-weight: 300;
    cursor: pointer;
    top: 47px; }
    @media (max-width: 768px) {
      #account .resetpass .password-box span {
        top: 42px; } }

#account .resetpass p {
  color: #8B8D96;
  font-size: 14px;
  margin-top: -10px; }

#account .resetpass .buttons {
  display: flex;
  align-items: center;
  margin-top: 40px; }
  #account .resetpass .buttons button {
    color: white;
    height: 40px; }
  #account .resetpass .buttons p {
    margin-bottom: 0px;
    color: #BA4A00;
    margin-left: 20px;
    margin-top: 0px;
    cursor: pointer; }

@media (max-width: 768px) {
  #account label.title {
    display: none; }
  #account .buttons {
    display: initial !important; }
    #account .buttons button {
      width: 100%;
      height: 50px !important;
      margin-top: 20px; }
    #account .buttons p {
      margin-left: 0px !important;
      margin-top: 20px !important; }
  #account .right {
    padding-left: 0px; }
  #account .view-selector {
    background-color: #EAEDEE;
    padding: 20px;
    width: 100%; }
    #account .view-selector .ant-radio-group {
      width: 100%;
      padding: 6px;
      background-color: white;
      border-radius: 8px; }
      #account .view-selector .ant-radio-group > label {
        width: 50%;
        height: 38px;
        padding-top: 4px;
        text-align: center;
        border: none;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
        border-color: transparent;
        border-width: 0px; }
        #account .view-selector .ant-radio-group > label:before {
          display: none; }
        #account .view-selector .ant-radio-group > label.ant-radio-button-wrapper-checked {
          background-color: #BA4A00; }
  #account img {
    position: relative;
    margin: 0 auto;
    display: block;
    margin-top: 40px;
    bottom: 0px;
    right: 0px; } }

@media (min-width: 768px) {
  #account .right-side {
    position: relative;
    top: 93px; } }

.reset-pass-modal .ant-modal-content {
  border-radius: 24px; }
  .reset-pass-modal .ant-modal-content .password-box span {
    top: 73px !important; }
  .reset-pass-modal .ant-modal-content .ant-modal-body {
    padding: 0px; }
  .reset-pass-modal .ant-modal-content .ant-form-item-label > label::after {
    top: -5px; }
  .reset-pass-modal .ant-modal-content .ant-form-item-control {
    width: 100%; }
  .reset-pass-modal .ant-modal-content .ant-btn {
    height: 84px;
    padding-top: 0px; }

@media (max-width: 768px) {
  #account .view-selector {
    background-color: #EAEDEE; }
    #account .view-selector > div {
      background-color: white;
      height: 50px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      padding: 20px;
      justify-content: space-between;
      color: #505157;
      cursor: pointer; }
      #account .view-selector > div svg {
        color: #8C8C8C; }
  .account-drawer .ant-drawer-content-wrapper {
    height: auto !important; }
  .auth-form {
    padding-bottom: 0px !important; }
    .auth-form .password-box span {
      top: 73px !important; } }

#add-customer .ant-tabs-nav::before {
  display: none; }

#add-customer .ant-tabs-nav .ant-tabs-tab {
  padding: 0px; }

#add-customer .ant-tabs-nav .ant-tabs-tab-btn {
  text-transform: uppercase;
  font-weight: 600;
  color: #8B8D96; }

#add-customer .ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: black; }

#add-customer .ant-tabs-nav .ant-tabs-ink-bar {
  background-color: black; }

@media (max-width: 768px) {
  #add-customer .ant-tabs-nav {
    display: none; } }

@media (max-width: 768px) {
  #add-customer .ant-tabs h5 {
    display: none; }
  #add-customer .ant-tabs #rc-tabs-0-panel-plot .ant-card-body > .ant-row > .ant-col {
    padding-left: 0px !important;
    padding-right: 0px !important; } }

#add-customer .select-tab-mobile {
  background: #EAEDEE;
  padding: 20px 20px 10px 20px;
  margin-top: -2px; }
  #add-customer .select-tab-mobile > div {
    background-color: white;
    height: 50px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 20px;
    justify-content: left;
    color: #505157;
    cursor: pointer; }
    #add-customer .select-tab-mobile > div .anticon {
      margin-left: auto; }
    #add-customer .select-tab-mobile > div svg {
      color: #8C8C8C; }

#add-customer h5 {
  text-transform: uppercase;
  color: #BA4A00;
  font-size: 12px;
  margin-top: 20px; }

#add-customer .separator {
  height: 1px;
  width: 100%;
  background: #BFBFBF;
  position: relative;
  bottom: 1px;
  margin: 20px 0px 30px 0px; }
  #add-customer .separator.family {
    margin-top: 20px; }
  @media (max-width: 768px) {
    #add-customer .separator {
      width: calc(100% - 40px);
      margin: -10px 20px 0px 20px; } }

#add-customer .customer-numbers input {
  height: 40px; }

#add-customer .customer-numbers .ant-col {
  padding-right: 20px; }

@media (max-width: 768px) {
  #add-customer .customer-numbers {
    margin-top: -20px !important;
    background: #EAEDEE;
    padding: 20px 0px;
    margin: 0px; }
    #add-customer .customer-numbers > .ant-col {
      padding-top: 0px !important;
      padding-bottom: 0px !important; }
      #add-customer .customer-numbers > .ant-col:last-of-type {
        margin-bottom: 10px; }
    #add-customer .customer-numbers .form-label label {
      margin-bottom: -6px;
      display: block; } }

#add-customer .ant-picker {
  border: none;
  background-color: #F5F5F5;
  height: 50px;
  width: 100%; }

#add-customer input {
  height: 50px; }

#add-customer .no-label {
  margin-top: -15px !important; }

#add-customer .ant-row {
  margin: 0px -10px; }
  @media (max-width: 768px) {
    #add-customer .ant-row {
      margin: 0px; } }
  #add-customer .ant-row .ant-col {
    padding: 10px; }

#add-customer .ant-card-body {
  padding: 10px 20px 30px 20px; }
  @media (max-width: 768px) {
    #add-customer .ant-card-body {
      padding: 10px 0px 10px 0px; } }

#add-customer .location-fields {
  display: flex;
  margin: 0px -5px; }
  #add-customer .location-fields > div {
    display: block;
    padding: 0px 5px; }
    #add-customer .location-fields > div .addon {
      display: none; }

#add-customer .ant-form-item-explain {
  display: none; }

#add-customer .family-member {
  position: relative;
  padding-bottom: 10px; }

#add-customer .remove {
  font-weight: 600;
  text-decoration: underline;
  color: #BA4A00;
  position: absolute;
  bottom: -30px;
  right: 10px;
  cursor: pointer; }
  @media (max-width: 768px) {
    #add-customer .remove {
      position: unset;
      text-align: right;
      margin-top: 10px; } }

#add-customer .add-family-btn {
  position: absolute;
  right: 70px;
  margin-top: -45px; }
  @media (max-width: 1030px) {
    #add-customer .add-family-btn {
      position: unset;
      right: unset;
      margin-top: unset;
      margin-bottom: 20px; } }
  @media (max-width: 768px) {
    #add-customer .add-family-btn {
      margin-top: 20px;
      width: 100%; } }

#add-customer .add-family-section {
  height: 120px;
  border-radius: 10px;
  background: #ba4a001a;
  display: flex;
  align-items: center;
  margin-top: 20px;
  justify-content: center; }
  @media (max-width: 768px) {
    #add-customer .add-family-section {
      width: calc(100% - 40px);
      margin: 20px 0px 20px 20px; } }

#add-customer .document-ids {
  display: flex;
  border-bottom: 1px solid #BFBFBF;
  margin-bottom: 20px;
  padding: 0px 20px 10px 20px;
  margin-left: -20px;
  width: calc(100% + 40px); }
  #add-customer .document-ids > .ant-row {
    margin-right: 20px; }
  @media (max-width: 768px) {
    #add-customer .document-ids {
      display: block;
      border-bottom: none; }
      #add-customer .document-ids > .ant-row {
        margin-right: 0px; } }

#add-customer .file-row {
  display: flex;
  margin-bottom: 20px; }
  #add-customer .file-row button {
    height: 64px;
    width: 64px;
    margin-left: 16px; }
    #add-customer .file-row button svg {
      font-size: 36px; }
  #add-customer .file-row .preview {
    display: flex;
    align-items: center;
    height: 64px;
    border-radius: 4px;
    background-color: #F5F5F5;
    overflow: hidden;
    width: 100%;
    flex: 1; }
    #add-customer .file-row .preview p {
      font-weight: 600;
      color: #505157;
      margin-bottom: 0px;
      margin-left: 20px; }
    #add-customer .file-row .preview .image {
      width: 64px;
      height: 64px;
      background-color: #8C8C8C;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative; }
      #add-customer .file-row .preview .image img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 1;
        transition: .5s ease; }
      #add-customer .file-row .preview .image .anticon-eye {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%); }
        #add-customer .file-row .preview .image .anticon-eye svg {
          font-size: 28px;
          color: white;
          transition: .5s ease;
          opacity: 0;
          transition: .5s ease; }
      #add-customer .file-row .preview .image .anticon-upload svg {
        font-size: 28px;
        color: #505157; }
  #add-customer .file-row.has-file .image {
    cursor: pointer; }
    #add-customer .file-row.has-file .image:hover {
      background-color: #ba4a0080; }
      #add-customer .file-row.has-file .image:hover img {
        opacity: 0.2; }
      #add-customer .file-row.has-file .image:hover .anticon-eye svg {
        opacity: 1; }
  @media (max-width: 768px) {
    #add-customer .file-row .preview {
      height: 55px; }
    #add-customer .file-row button {
      margin-left: 10px;
      height: 55px;
      width: 55px; }
      #add-customer .file-row button svg {
        font-size: 26px; }
    #add-customer .file-row .image {
      width: 55px !important;
      height: 55px !important;
      min-width: 55px; } }
  #add-customer .file-row:first-of-type {
    margin-top: 10px; }
  #add-customer .file-row.small {
    margin-bottom: 10px; }
    #add-customer .file-row.small .preview {
      height: 50px; }
      #add-customer .file-row.small .preview.active p {
        color: #BA4A00;
        text-decoration: underline;
        cursor: pointer; }
      #add-customer .file-row.small .preview.active img {
        margin-left: 10px; }
    #add-customer .file-row.small button {
      height: 50px;
      width: 50px; }
      #add-customer .file-row.small button svg {
        font-size: 26px;
        position: relative;
        right: 3px; }

#add-customer .ant-upload.dragger {
  border: none;
  background-color: white;
  height: auto;
  margin-bottom: 10px; }
  #add-customer .ant-upload.dragger h4 {
    color: #BA4A00;
    font-weight: bold;
    font-size: 16px;
    text-transform: uppercase; }
  #add-customer .ant-upload.dragger .ant-upload-btn {
    padding: 0px;
    height: 212px;
    background-color: #F5F5F5; }
    #add-customer .ant-upload.dragger .ant-upload-btn svg {
      color: #8B8D96;
      margin-bottom: 20px; }
    #add-customer .ant-upload.dragger .ant-upload-btn p {
      color: #8B8D96;
      font-size: 14px; }
      #add-customer .ant-upload.dragger .ant-upload-btn p.underline {
        text-decoration: underline; }

#add-customer .upload-more button, #add-customer .upload-more .ant-upload {
  width: 100%; }

@media (min-width: 768px) {
  #add-customer .header-bar {
    margin-bottom: 10px; } }

#lead-steps-bar .container {
  display: flex;
  flex-direction: row;
  margin-bottom: 40px; }
  #lead-steps-bar .container.disabled > div {
    cursor: not-allowed; }

#lead-steps-bar .step-first {
  display: flex;
  box-sizing: border-box;
  height: 40px;
  width: 155px;
  border-radius: 20px 0 0 20px;
  background-color: white;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  margin-right: 2px;
  width: 235px;
  color: #505157;
  font-size: 14px;
  cursor: pointer; }
  @media (max-width: 992px) {
    #lead-steps-bar .step-first {
      padding-left: 10px;
      padding-right: 0px;
      font-size: 12px; } }

#lead-steps-bar .step {
  display: flex;
  box-sizing: border-box;
  height: 40px;
  width: 155px;
  background-color: white;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  margin-right: 2px;
  width: 235px;
  color: #505157;
  font-size: 14px;
  cursor: pointer; }
  @media (max-width: 992px) {
    #lead-steps-bar .step {
      padding-left: 10px;
      padding-right: 0px;
      font-size: 12px; } }

#lead-steps-bar .step-last {
  display: flex;
  box-sizing: border-box;
  height: 40px;
  width: 235px;
  border-radius: 0 20px 20px 0;
  background-color: white;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  color: #505157;
  font-size: 14px;
  cursor: pointer; }
  @media (max-width: 992px) {
    #lead-steps-bar .step-last {
      padding-left: 10px;
      padding-right: 0px;
      font-size: 12px; } }

#lead-steps-bar .step-lost {
  display: flex;
  box-sizing: border-box;
  height: 40px;
  width: 235px;
  border-radius: 20px;
  background-color: white;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  color: #505157;
  margin-left: 20px;
  font-size: 14px;
  cursor: pointer; }
  @media (max-width: 992px) {
    #lead-steps-bar .step-lost {
      padding-left: 10px;
      padding-right: 0px;
      font-size: 12px; } }

#lead-steps-bar .completed {
  border: 1px solid #52C41A;
  background-color: #E4F3DB;
  color: #52C41A; }

#lead-steps-bar .icon {
  margin-left: 10px; }

#lead-steps-bar i:before {
  font-size: 12px; }

#add-lead-order label.title {
  color: #BA4A00;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 10px;
  display: block; }

#add-lead-order .discount-rate {
  background-color: #BA4A00;
  font-weight: 600;
  border-radius: 4px;
  padding: 3px 10px;
  color: white;
  margin-right: 10px; }

#add-lead-order .section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px; }
  #add-lead-order .section-header .view-selector {
    display: flex; }
    #add-lead-order .section-header .view-selector p {
      margin-left: 20px;
      color: #8B8D96;
      font-weight: 500;
      cursor: pointer; }
      #add-lead-order .section-header .view-selector p.active {
        cursor: unset;
        color: black;
        border-bottom: 1px solid black; }

#add-lead-order .divider {
  margin-top: 10px;
  margin-left: -20px;
  width: 110%;
  height: 1px;
  background-color: #EAEAEA; }

#add-lead-order .order-container .content-container {
  min-height: 400px;
  display: flex;
  justify-content: center; }

#add-lead-order .order-container .empty-placeholder {
  position: relative;
  margin-top: 10%; }
  #add-lead-order .order-container .empty-placeholder .description {
    position: absolute;
    width: 300px;
    top: 130px;
    left: 70px;
    z-index: 100;
    color: "#8B8D96";
    font-size: 14; }

#add-lead-order .order-table {
  width: 100%; }

#add-lead-order .order-top-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; }
  #add-lead-order .order-top-container > button {
    margin-bottom: 10px; }
  #add-lead-order .order-top-container .ant-input-affix-wrapper {
    max-width: 390px;
    margin-right: 20px;
    border: none;
    border-radius: 8px;
    background-color: #f5f5f5; }
    #add-lead-order .order-top-container .ant-input-affix-wrapper input {
      height: 42px;
      font-size: 16px;
      color: #8B8D96;
      background-color: #f5f5f5; }
    #add-lead-order .order-top-container .ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
      box-shadow: none;
      border: 1px solid #1890FF;
      background-color: #f4f9ff; }
      #add-lead-order .order-top-container .ant-input-affix-wrapper.ant-input-affix-wrapper-focused input {
        background-color: transparent; }
  #add-lead-order .order-top-container .ant-input-prefix {
    margin-right: 20px;
    font-size: 16px;
    color: #8B8D96; }

#add-lead-order .footer {
  position: absolute;
  bottom: 0;
  height: 52px;
  width: 100%;
  z-index: 100;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0 0 8px 8px; }
  #add-lead-order .footer .total {
    margin-left: 20px;
    font-size: 16;
    color: #BA4A00;
    font-weight: 500; }
  #add-lead-order .footer .total-profit {
    margin-right: 20px;
    width: 150px; }
  #add-lead-order .footer .total-amount {
    width: 150px; }
  #add-lead-order .footer .total-right-container {
    display: flex;
    flex-direction: row;
    font-size: 16px;
    font-weight: 500;
    color: #505157; }
    #add-lead-order .footer .total-right-container span {
      font-size: 12px;
      color: #8B8D96; }

#add-lead-order .shadow {
  background-color: rgba(255, 255, 255, 0.41);
  box-shadow: 0 -6px 10px 0 rgba(0, 0, 0, 0.06), 0 -1px 18px 0 rgba(0, 0, 0, 0.06), 0 -3px 5px 0 rgba(0, 0, 0, 0.06); }

#add-lead-order .order-container {
  width: 100%; }
  #add-lead-order .order-container .ant-input-affix-wrapper {
    border: none;
    border-radius: 8px;
    background-color: #f5f5f5; }
    #add-lead-order .order-container .ant-input-affix-wrapper input {
      font-size: 14px;
      color: #8B8D96;
      background-color: transparent; }
    #add-lead-order .order-container .ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
      box-shadow: none;
      border: 1px solid #1890FF;
      background-color: transparent input;
        background-color-background-color: transparent; }
  #add-lead-order .order-container .ant-input-prefix {
    margin-right: 5px;
    font-size: 16px;
    color: #8B8D96; }

#add-lead-order .ant-table-tbody > tr > td {
  border-bottom: 0px; }

#add-lead-order .ant-table-thead > tr > th {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  text-align: left;
  background: transparent;
  border-bottom: 0px solid #f0f0f0;
  transition: background 0.3s ease; }

@media (max-width: 768px) {
  #add-lead-order .ant-table-tbody > tr > td {
    padding-top: 0;
    padding-bottom: 0; }
  #add-lead-order .footer {
    position: fixed;
    background-color: white;
    height: 76px;
    display: flex;
    flex-direction: column; }
    #add-lead-order .footer .row {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between; }
    #add-lead-order .footer .title-total {
      font-size: 14px;
      color: #BA4A00;
      font-weight: 500; }
    #add-lead-order .footer .title-profit {
      font-size: 14px;
      color: #8B8D96; }
    #add-lead-order .footer .total-value {
      font-size: 16px;
      font-weight: 500;
      color: #505157; }
    #add-lead-order .footer .profit-value {
      font-size: 16px;
      font-weight: 500;
      color: #505157; }
  #add-lead-order .mobile-content-container {
    display: flex;
    flex-direction: column;
    justify-content: center; }
    #add-lead-order .mobile-content-container .button {
      margin: 20px;
      margin-top: 0px; }
  #add-lead-order .mobile-table-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px; }
    #add-lead-order .mobile-table-row .title-text {
      color: #505157;
      font-size: 16px; }
    #add-lead-order .mobile-table-row .subtitle-text {
      color: #505157;
      font-size: 16px;
      font-weight: 300; }
      #add-lead-order .mobile-table-row .subtitle-text.discounted {
        color: #BA4A00; }
  #add-lead-order .background-white {
    background-color: white; } }

.ant-drawer-content {
  overflow: visible; }

#add-products-to-order {
  margin: -24px; }
  #add-products-to-order .header-container {
    height: 60px;
    background-color: #d7dbdd;
    display: flex;
    align-items: center;
    padding-left: 25px;
    padding-right: 25px;
    justify-content: space-between; }
    #add-products-to-order .header-container .title {
      font-size: 18px;
      font-weight: 600;
      color: #BA4A00;
      margin-left: 15px; }
    #add-products-to-order .header-container .icon {
      height: 40px;
      width: 40px;
      border-radius: 10px;
      background: linear-gradient(135deg, #BA4A00 0%, #490708 100%);
      display: flex;
      align-items: center;
      justify-content: center; }
    #add-products-to-order .header-container i:before {
      font-size: 20px;
      color: white; }
  #add-products-to-order .ant-btn.primary-medium-1 i:before {
    margin-left: 10px;
    position: relative;
    top: 3px; }
  #add-products-to-order .product-actions {
    display: flex; }
    #add-products-to-order .product-actions > div {
      width: 28px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      border-radius: 4px;
      overflow: hidden;
      cursor: pointer;
      background-color: #505157; }
      #add-products-to-order .product-actions > div i:before {
        font-size: 16px;
        color: white;
        position: relative;
        top: 2px; }
  #add-products-to-order .content {
    padding: 20px;
    height: 87vh;
    background-color: #EAEDEE; }
    #add-products-to-order .content .top-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 25px; }
    #add-products-to-order .content .selected {
      color: #1890ff; }
    #add-products-to-order .content .perpetual-product {
      color: #BA4A00 !important; }
  #add-products-to-order .ant-input-affix-wrapper {
    border: none;
    border-radius: 8px;
    background-color: #f5f5f5; }
    #add-products-to-order .ant-input-affix-wrapper input {
      font-size: 14px;
      color: #8B8D96;
      background-color: #f5f5f5; }
    #add-products-to-order .ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
      box-shadow: none;
      border: 1px solid #1890FF;
      background-color: #f4f9ff; }
      #add-products-to-order .ant-input-affix-wrapper.ant-input-affix-wrapper-focused input {
        background-color: transparent; }
  #add-products-to-order .discount-rate {
    background-color: #BA4A00;
    font-weight: 600;
    border-radius: 4px;
    padding: 3px 10px;
    color: white;
    margin-right: 10px; }
  #add-products-to-order .selected .discount-rate {
    background-color: #1890FF !important; }
  #add-products-to-order .ant-input-prefix {
    margin-right: 5px;
    font-size: 16px;
    color: #8B8D96; }
  #add-products-to-order .ant-table-tbody > tr > td {
    border-bottom: 0px; }
  #add-products-to-order .ant-table-thead > tr > th {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    text-align: left;
    background: transparent;
    border-bottom: 0px solid #f0f0f0;
    transition: background 0.3s ease; }
  #add-products-to-order .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #1890ff15;
    border-color: #1890ff; }
  #add-products-to-order .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: #1890ff; }
  #add-products-to-order .promotion-images {
    position: absolute;
    width: 18vw;
    left: -24%;
    top: 0px;
    height: 100vh;
    padding-top: 20px;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */ }
    #add-products-to-order .promotion-images::-webkit-scrollbar {
      display: none; }
    #add-products-to-order .promotion-images .image-container {
      background-color: white;
      padding: 10px;
      border-radius: 25px;
      margin-bottom: 20px; }
      #add-products-to-order .promotion-images .image-container img {
        width: 100%;
        border-radius: 25px; }

@media (max-width: 768px) {
  #add-products-to-order {
    background-color: #EAEDEE; }
    #add-products-to-order .mobile-promotion-images {
      padding-top: 20px; }
      #add-products-to-order .mobile-promotion-images .image-container {
        background-color: white;
        padding: 10px;
        border-radius: 25px;
        margin: 0px 20px 20px 20px;
        height: 120px; }
        #add-products-to-order .mobile-promotion-images .image-container img {
          width: 100%;
          border-radius: 25px;
          height: 100%;
          object-fit: cover; }
    #add-products-to-order thead {
      display: none; }
    #add-products-to-order .ant-table-wrapper {
      margin-top: 20px; }
    #add-products-to-order .ant-table-tbody > tr > td {
      padding: 8px 16px; }
    #add-products-to-order .ant-table-tbody > tr.ant-table-row:hover > td {
      background: none; }
    #add-products-to-order .header-container {
      display: flex;
      flex-direction: row;
      background-color: white; }
      #add-products-to-order .header-container .cancel-text {
        font-size: 16px;
        font-weight: 300;
        color: #8B8D96; }
      #add-products-to-order .header-container .add-text {
        font-size: 16px;
        font-weight: 300;
        color: #BA4A00; }
    #add-products-to-order .search-container {
      margin-left: 20px;
      margin-right: 20px;
      margin-top: 20px;
      margin-bottom: 10px;
      padding-bottom: 0px;
      display: flex;
      flex-direction: row;
      justify-content: space-between; }
      #add-products-to-order .search-container .filter {
        height: 50px;
        width: 50px;
        border-radius: 8px;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center; }
    #add-products-to-order .mobile-table-row {
      display: flex;
      align-items: center;
      padding: 15px;
      border-radius: 8px;
      background-color: #f5f5f5; }
      #add-products-to-order .mobile-table-row .title-text {
        color: #505157;
        font-size: 16px; }
      #add-products-to-order .mobile-table-row .subtitle-text {
        color: #505157;
        font-size: 16px;
        font-weight: 300; }
      #add-products-to-order .mobile-table-row .selected {
        color: #1890ff;
        font-weight: 400; }
    #add-products-to-order .background-white {
      background-color: white; }
    #add-products-to-order .ant-input-affix-wrapper {
      border: none;
      border-radius: 8px;
      background-color: white; }
      #add-products-to-order .ant-input-affix-wrapper input {
        font-size: 14px;
        color: #8B8D96;
        background-color: white; }
      #add-products-to-order .ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
        box-shadow: none;
        border: 1px solid #1890FF;
        background-color: #f4f9ff; }
        #add-products-to-order .ant-input-affix-wrapper.ant-input-affix-wrapper-focused input {
          background-color: transparent; } }

.product-preview img {
  max-width: 100%;
  height: auto; }

#edit-product-mobile .header-container {
  display: flex;
  flex-direction: row;
  background-color: white;
  justify-content: space-between; }
  #edit-product-mobile .header-container .cancel-text {
    font-size: 16px;
    font-weight: 300;
    color: #8B8D96; }
  #edit-product-mobile .header-container .add-text {
    font-size: 16px;
    font-weight: 300;
    color: #BA4A00; }

#edit-product-mobile .divider {
  height: 1px;
  background-color: #BFBFBF;
  margin-top: 10px;
  margin-bottom: 10px; }

#edit-product-mobile .title {
  font-size: 16px;
  font-weight: 500;
  color: #505157;
  margin-bottom: 30px; }

#edit-product-mobile .subtitle {
  margin-top: 15px;
  margin-bottom: 5px;
  font-size: 14px;
  color: #505157; }

#edit-product-mobile .ant-input-affix-wrapper {
  border: none;
  border-radius: 8px;
  background-color: #f5f5f5; }
  #edit-product-mobile .ant-input-affix-wrapper input {
    font-size: 16px;
    color: #8B8D96;
    background-color: #f5f5f5; }
  #edit-product-mobile .ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
    box-shadow: none;
    border: 1px solid #1890FF;
    background-color: #f4f9ff; }
    #edit-product-mobile .ant-input-affix-wrapper.ant-input-affix-wrapper-focused input {
      background-color: transparent; }

#add-lead-note {
  margin-bottom: 40px; }
  #add-lead-note label.title {
    color: #BA4A00;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 10px;
    display: block; }
  #add-lead-note .notes-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
    overflow: hidden; }
    #add-lead-note .notes-container img {
      position: absolute;
      right: 0; }
  #add-lead-note .note {
    color: #505157;
    width: 255px;
    padding: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    background-color: #fcf8f3;
    border-bottom: 5px solid #f6e9d9;
    border-radius: 6px;
    z-index: 200;
    max-height: 120px;
    overflow: scroll;
    overflow-x: auto; }
    #add-lead-note .note .title {
      font-size: 14px;
      color: #AB8E6A;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    #add-lead-note .note .timestamp {
      font-size: 12px;
      color: black;
      opacity: 0.3; }
    #add-lead-note .note .description {
      font-size: 12px;
      font-weight: 300;
      color: #505157; }
  #add-lead-note .placeholder-note {
    color: #505157;
    width: 255px;
    padding: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    background-color: #fcf8f3;
    border-bottom: 5px solid #f6e9d9;
    border-radius: 6px;
    font-weight: 500;
    font-size: 12px;
    font-style: italic;
    z-index: 3; }
  #add-lead-note .button-container {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    padding-left: 20px; }
    #add-lead-note .button-container p {
      margin-top: auto;
      margin-left: auto;
      color: #505157;
      font-weight: 500;
      margin-top: 10px; }
  #add-lead-note .ant-input-affix-wrapper {
    max-width: 390px;
    margin-right: 20px;
    border: none;
    border-radius: 8px;
    background-color: #f5f5f5; }
    #add-lead-note .ant-input-affix-wrapper input {
      height: 42px;
      font-size: 16px;
      color: #8B8D96;
      background-color: #f5f5f5; }
    #add-lead-note .ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
      box-shadow: none;
      border: 1px solid #1890FF;
      background-color: #f4f9ff; }
      #add-lead-note .ant-input-affix-wrapper.ant-input-affix-wrapper-focused input {
        background-color: transparent; }
  #add-lead-note .ant-input-prefix {
    margin-right: 20px;
    font-size: 16px;
    color: #8B8D96; }

@media (max-width: 768px) {
  #add-lead-note .search-container {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row; }
    #add-lead-note .search-container button {
      height: 50px;
      min-width: 50px; }
  #add-lead-note .note {
    width: 100%;
    padding: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    background-color: #fcf8f3;
    border-bottom: 5px solid #f6e9d9;
    border-radius: 6px;
    max-height: unset; }
    #add-lead-note .note .title {
      font-size: 16px;
      color: #AB8E6A;
      margin-bottom: 10px; }
    #add-lead-note .note .timestamp {
      font-size: 14px;
      color: #8B8D96;
      font-weight: 300;
      margin-bottom: 20px; }
    #add-lead-note .note .description {
      font-size: 14px;
      color: #505157;
      font-weight: 300;
      overflow: hidden;
      max-height: 122px; }
    #add-lead-note .note .divider {
      margin-top: 15px;
      margin-bottom: 15px;
      height: 1px;
      color: #BFBFBF; }
    #add-lead-note .note .bottom-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between; }
    #add-lead-note .note .view-more-text {
      font-size: 16px;
      color: #505157;
      font-weight: 300; }
      #add-lead-note .note .view-more-text span {
        margin-left: 20px; }
    #add-lead-note .note .delete-text {
      font-size: 16px;
      color: #8B8D96;
      font-weight: 300;
      margin-right: 30px; }
    #add-lead-note .note .edit-text {
      font-size: 16px;
      color: #398CAF; }
    #add-lead-note .note.opened .description {
      max-height: unset; }
  #add-lead-note .notes-container {
    padding-left: 20px;
    padding-right: 20px; } }

@media (max-width: 1490px) {
  .notes-container .note:nth-last-of-type(3) {
    display: none; } }

@media (max-width: 1140px) {
  .notes-container .note:nth-last-of-type(2) {
    display: none; } }

#add-new-note {
  margin: -24px; }
  #add-new-note .header-container {
    height: 82px;
    background-color: #d7dbdd;
    display: flex;
    align-items: center;
    padding-left: 25px;
    padding-right: 25px;
    justify-content: space-between; }
    #add-new-note .header-container .title {
      font-size: 18px;
      font-weight: 600;
      color: #BA4A00; }
    #add-new-note .header-container .icon {
      height: 40px;
      width: 40px;
      border-radius: 10px;
      background: linear-gradient(135deg, #BA4A00 0%, #490708 100%);
      margin-right: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right: 20px; }
    #add-new-note .header-container i:before {
      font-size: 20px;
      color: white; }
  #add-new-note .content {
    padding: 20px;
    height: 87vh;
    background-color: #EAEDEE; }
    #add-new-note .content .top-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 25px; }
    #add-new-note .content .selected {
      color: #1890ff; }
    #add-new-note .content .new-note {
      color: #505157;
      padding: 10px;
      margin-right: 10px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: row; }
    #add-new-note .content .edit-container {
      width: 100%;
      border-radius: 6px;
      background-color: #fcf8f3;
      padding: 20px;
      box-shadow: 0 3px 8px 0 rgba(76, 68, 58, 0.2); }
      #add-new-note .content .edit-container input, #add-new-note .content .edit-container textarea {
        background-color: #ffffff8c;
        border-color: transparent;
        box-shadow: inset 0 0 3px 1px #0000004a; }
      #add-new-note .content .edit-container .head {
        display: flex;
        align-items: center;
        justify-content: space-between; }
        #add-new-note .content .edit-container .head * {
          margin: 0px; }
        #add-new-note .content .edit-container .head h4 {
          color: #AB8E6A;
          font-size: 16px; }
        #add-new-note .content .edit-container .head p {
          color: #0000003d; }
    #add-new-note .content .button-container {
      display: flex;
      flex-direction: column;
      width: 80px;
      margin-left: 20px; }
  #add-new-note .ant-input-affix-wrapper {
    border: none;
    border-radius: 8px;
    background-color: #f5f5f5; }
    #add-new-note .ant-input-affix-wrapper input {
      font-size: 14px;
      color: #8B8D96;
      background-color: #f5f5f5; }
    #add-new-note .ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
      box-shadow: none;
      border: 1px solid #1890FF;
      background-color: #f4f9ff; }
      #add-new-note .ant-input-affix-wrapper.ant-input-affix-wrapper-focused input {
        background-color: transparent; }

@media (max-width: 768px) {
  #add-new-note {
    background-color: #fcf8f3;
    height: 100vh; }
    #add-new-note .header-mobile-container {
      display: flex;
      flex-direction: row;
      background-color: #fcf8f3;
      justify-content: space-between;
      padding: 20px; }
      #add-new-note .header-mobile-container .cancel-text {
        font-size: 16px;
        font-weight: 300;
        color: #8B8D96; }
      #add-new-note .header-mobile-container .save-text {
        font-size: 16px;
        font-weight: 300;
        color: #398CAF; }
    #add-new-note .divider {
      height: 1px;
      background-color: #BFBFBF;
      margin-left: 20px;
      margin-right: 20px; }
    #add-new-note .mobile-content {
      padding: 20px; } }

#view-all-notes {
  margin: -24px; }
  #view-all-notes .header-container {
    height: 82px;
    background-color: #d7dbdd;
    display: flex;
    align-items: center;
    padding-left: 25px;
    padding-right: 25px;
    justify-content: space-between; }
    #view-all-notes .header-container .title {
      font-size: 18px;
      font-weight: 600;
      color: #BA4A00; }
    #view-all-notes .header-container .icon {
      height: 40px;
      width: 40px;
      border-radius: 10px;
      background: linear-gradient(135deg, #BA4A00 0%, #490708 100%);
      margin-right: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 3px; }
    #view-all-notes .header-container i:before {
      font-size: 20px;
      color: white; }
  #view-all-notes .content {
    padding: 20px;
    height: 87vh;
    background-color: #EAEDEE; }
    #view-all-notes .content .top-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 25px; }
  #view-all-notes .note {
    color: #505157;
    padding: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    background-color: #fcf8f3;
    border-bottom: 5px solid #f6e9d9;
    border-radius: 6px;
    z-index: 200; }
    #view-all-notes .note .top-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between; }
    #view-all-notes .note .title {
      font-size: 16px;
      color: #AB8E6A; }
    #view-all-notes .note .timestamp {
      font-size: 14px;
      color: black;
      opacity: 0.3; }
    #view-all-notes .note .description {
      font-size: 14px;
      font-weight: 300;
      color: #36373C; }
  #view-all-notes .ant-input-affix-wrapper {
    border: none;
    border-radius: 8px;
    background-color: #f5f5f5; }
    #view-all-notes .ant-input-affix-wrapper input {
      font-size: 14px;
      color: #8B8D96;
      background-color: #f5f5f5; }
    #view-all-notes .ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
      box-shadow: none;
      border: 1px solid #1890FF;
      background-color: #f4f9ff; }
      #view-all-notes .ant-input-affix-wrapper.ant-input-affix-wrapper-focused input {
        background-color: transparent; }

.status-drawer .head {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .status-drawer .head p:first-of-type {
    color: #505157; }
  .status-drawer .head p:last-of-type {
    color: #8B8D96;
    cursor: pointer; }

.status-drawer .drawer-button {
  width: 100%;
  color: #505157;
  background-color: #F5F5F5;
  padding: 16px 12px;
  margin-bottom: 10px;
  border-radius: 3px; }
  .status-drawer .drawer-button i:before {
    margin-right: 10px; }

.status-drawer .ant-drawer-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.status-drawer .mobile-status-selector {
  display: flex;
  align-items: center;
  justify-content: left; }
  .status-drawer .mobile-status-selector div {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    margin-right: 10px;
    margin-left: 10px; }
  .status-drawer .mobile-status-selector.Open div {
    background-color: #FFC100; }
  .status-drawer .mobile-status-selector.Closed div {
    background-color: #52C41A; }
  .status-drawer .mobile-status-selector.Lost div {
    background-color: #FB4953; }

.statusbar {
  display: flex; }
  .statusbar > div {
    height: 40px;
    width: 235px;
    background-color: white;
    border: 1px solid white;
    padding: 7px 0px 0px 20px;
    cursor: pointer; }
    .statusbar > div:first-of-type {
      margin-right: 5px;
      border-radius: 20px 0px 0px 20px; }
    .statusbar > div:last-of-type {
      border-radius: 0px 20px 20px 0px; }
    .statusbar > div.active {
      background-color: #D6EBFF;
      color: #1890FF;
      border-color: #1890FF; }

.products {
  margin-top: 20px;
  background-color: white;
  border-radius: 10px 10px 0px 0px;
  height: calc(100vh - 301px); }
  .products .head {
    padding: 20px;
    border-bottom: 1px solid #EAEAEA; }
    .products .head button {
      height: 28px;
      margin-left: auto;
      display: block; }
  .products .body {
    padding: 20px; }
    .products .body .ant-table-wrapper {
      overflow-y: scroll;
      max-height: calc(100vh - 390px); }
    .products .body .ant-table-column-sorters-with-tooltip, .products .body .remove-product, .products .body th, .products .body td {
      background-color: white !important; }
    .products .body .ant-table-cell, .products .body th, .products .body tr {
      border: none; }
    .products .body .price span {
      font-size: 12px;
      color: #8B8D96;
      margin-right: 10px; }
    .products .body .discount-rate span {
      background-color: #BA4A00;
      font-weight: 600;
      border-radius: 4px;
      padding: 3px 10px;
      color: white;
      margin-right: 10px; }
    .products .body .discount-price .price, .products .body .discount-price .price * {
      color: #BA4A00; }
  @media screen and (max-width: 768px) {
    .products {
      padding: 0px 10px; }
      .products .primary-medium-1 {
        margin: 20px auto;
        display: block;
        height: 50px;
        width: 100%; }
      .products .product-mobile {
        border-radius: 4px;
        padding: 20px 15px; }
        .products .product-mobile:nth-of-type(odd) {
          background-color: #F5F5F5; }
        .products .product-mobile .name {
          font-weight: 400;
          margin-bottom: 2px; }
        .products .product-mobile .price {
          margin-bottom: 0px;
          font-weight: 300; }
        .products .product-mobile .discount-rate {
          background-color: #BA4A00;
          font-weight: 600;
          border-radius: 4px;
          padding: 3px 10px;
          color: white;
          margin-right: 10px; }
        .products .product-mobile .discounted-price {
          margin-bottom: 0px;
          color: #BA4A00; } }

.ant-drawer-close {
  display: none; }

#add-products-to-order {
  margin: -24px; }
  #add-products-to-order button .flaticon-next {
    margin-left: 10px;
    margin-top: 3px; }
  #add-products-to-order .filter-selector {
    margin-right: auto;
    margin-left: 20px; }
  #add-products-to-order .header-container {
    height: 60px;
    background-color: #d7dbdd;
    display: flex;
    align-items: center;
    padding-left: 25px;
    padding-right: 25px;
    justify-content: space-between; }
    #add-products-to-order .header-container .title {
      font-size: 18px;
      font-weight: 600;
      color: #BA4A00;
      margin-left: 15px; }
    #add-products-to-order .header-container .icon {
      height: 40px;
      width: 40px;
      border-radius: 10px;
      background: linear-gradient(135deg, #BA4A00 0%, #490708 100%);
      display: flex;
      align-items: center;
      justify-content: center; }
    #add-products-to-order .header-container i:before {
      font-size: 20px;
      color: white; }
  #add-products-to-order .product-actions {
    display: flex; }
    #add-products-to-order .product-actions > div {
      width: 22px;
      height: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      border-radius: 4px;
      overflow: hidden;
      cursor: pointer;
      background-color: #505157; }
      #add-products-to-order .product-actions > div i:before {
        font-size: 11px;
        color: white; }
  #add-products-to-order .content {
    padding: 20px;
    height: 87vh;
    background-color: #EAEDEE; }
    #add-products-to-order .content .top-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 25px; }
    #add-products-to-order .content .selected {
      color: #1890ff; }
    #add-products-to-order .content .perpetual-product {
      color: #BA4A00 !important; }
  #add-products-to-order .ant-input-affix-wrapper {
    border: none;
    border-radius: 8px;
    background-color: #f5f5f5; }
    #add-products-to-order .ant-input-affix-wrapper input {
      font-size: 14px;
      color: #8B8D96;
      background-color: #f5f5f5; }
    #add-products-to-order .ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
      box-shadow: none;
      border: 1px solid #1890FF;
      background-color: #f4f9ff; }
      #add-products-to-order .ant-input-affix-wrapper.ant-input-affix-wrapper-focused input {
        background-color: transparent; }
  #add-products-to-order .ant-input-prefix {
    margin-right: 5px;
    font-size: 16px;
    color: #8B8D96; }
  #add-products-to-order .ant-table-tbody > tr > td {
    border-bottom: 0px; }
  #add-products-to-order .ant-table-cell {
    padding: 20px 16px; }
  #add-products-to-order .ant-table-thead > tr > th {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    text-align: left;
    background: transparent;
    border-bottom: 0px solid #f0f0f0;
    transition: background 0.3s ease; }
  #add-products-to-order .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #1890ff15;
    border-color: #1890ff; }
  #add-products-to-order .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: #1890ff; }

@media (max-width: 768px) {
  #add-products-to-order {
    background-color: #EAEDEE; }
    #add-products-to-order .order-table {
      margin-top: 10px; }
    #add-products-to-order thead {
      display: none; }
    #add-products-to-order .ant-table-wrapper {
      margin-top: 20px; }
    #add-products-to-order .ant-table-tbody > tr > td {
      padding: 8px 16px; }
    #add-products-to-order .ant-table-tbody > tr.ant-table-row:hover > td {
      background: none; }
    #add-products-to-order .header-container {
      display: flex;
      flex-direction: row;
      background-color: white; }
      #add-products-to-order .header-container .cancel-text {
        font-size: 16px;
        font-weight: 300;
        color: #8B8D96; }
      #add-products-to-order .header-container .add-text {
        font-size: 16px;
        font-weight: 300;
        color: #BA4A00; }
    #add-products-to-order .search-container {
      margin-left: 20px;
      margin-right: 20px;
      margin-top: 20px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: row;
      justify-content: space-between; }
      #add-products-to-order .search-container .filter {
        height: 50px;
        width: 50px;
        border-radius: 8px;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center; }
        #add-products-to-order .search-container .filter i:before {
          font-size: 20px;
          position: relative;
          top: 2px; }
    #add-products-to-order .mobile-table-row {
      display: flex;
      align-items: center;
      padding: 15px;
      border-radius: 8px;
      background-color: #f5f5f5; }
      #add-products-to-order .mobile-table-row .title-text {
        color: #505157;
        font-size: 16px; }
      #add-products-to-order .mobile-table-row .subtitle-text {
        color: #505157;
        font-size: 16px;
        font-weight: 300; }
      #add-products-to-order .mobile-table-row .selected {
        color: #1890ff;
        font-weight: 400; }
    #add-products-to-order .background-white {
      background-color: white; }
    #add-products-to-order .ant-input-affix-wrapper {
      border: none;
      border-radius: 8px;
      background-color: white; }
      #add-products-to-order .ant-input-affix-wrapper input {
        font-size: 14px;
        color: #8B8D96;
        background-color: white; }
      #add-products-to-order .ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
        box-shadow: none;
        border: 1px solid #1890FF;
        background-color: #f4f9ff; }
        #add-products-to-order .ant-input-affix-wrapper.ant-input-affix-wrapper-focused input {
          background-color: transparent; } }

.product-preview img {
  max-width: 100%;
  height: auto; }

.filters-drawer-promotions .ant-drawer-close {
  display: none; }

.filters-drawer-promotions .head {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #BFBFBF; }
  .filters-drawer-promotions .head p {
    font-size: 16px;
    color: #8B8D96; }

.filters-drawer-promotions p.blue {
  color: #398CAF; }

.filters-drawer-promotions .clear-all {
  font-size: 16px;
  margin-top: 20px;
  text-align: center; }

.filters-drawer-promotions .filter {
  box-shadow: 0 6px 20px 8px #00000026;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 20px; }
  .filters-drawer-promotions .filter .ant-checkbox-group {
    display: flex;
    flex-direction: column; }
    .filters-drawer-promotions .filter .ant-checkbox-group label {
      background-color: #F5F5F5;
      margin-right: 0px;
      padding: 10px;
      border-radius: 4px;
      margin-bottom: 5px;
      font-size: 14px;
      color: #505157; }
    .filters-drawer-promotions .filter .ant-checkbox-group .ant-checkbox-inner {
      height: 24px;
      width: 24px; }
    .filters-drawer-promotions .filter .ant-checkbox-group .ant-checkbox-checked .ant-checkbox-inner:after {
      margin-left: 2px; }
  .filters-drawer-promotions .filter .controls {
    margin-top: 5px;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between; }
    .filters-drawer-promotions .filter .controls p {
      margin: 0px;
      font-size: 14px; }
      .filters-drawer-promotions .filter .controls p:first-of-type {
        color: #505157;
        cursor: pointer; }
      .filters-drawer-promotions .filter .controls p:last-of-type {
        color: #8B8D96;
        font-weight: 300; }
      .filters-drawer-promotions .filter .controls p span {
        font-weight: 300; }

#promotions .ant-col-md-18 {
  padding-left: 20px; }

#promotions .promotion-details {
  height: calc(100vh - 231px);
  overflow: scroll; }

#promotions .promotion-id {
  margin-top: 20px;
  color: #BA4A00;
  height: 22px; }

#promotions .period {
  border-radius: 4px;
  display: flex;
  align-items: flex-end; }
  #promotions .period > div {
    width: 50%; }
  #promotions .period .ant-form-item-explain {
    display: none; }
  #promotions .period .ant-form-item-has-error .ant-picker {
    border: 1px solid #CA0000 !important; }
  #promotions .period .ant-form-item {
    margin-bottom: 0px; }
  #promotions .period .ant-picker {
    border: none;
    background-color: #F5F5F5;
    height: 50px;
    width: 100%; }
  #promotions .period .from-date .ant-picker {
    border-radius: 4px 0px 0px 4px; }
  #promotions .period .to-date .ant-picker {
    border-radius: 0px 4px 4px 0px; }
  #promotions .period .separator-container {
    background-color: #F5F5F5; }
    #promotions .period .separator-container .separator {
      width: 80%;
      height: 1px;
      background-color: #505157;
      margin: 0 auto;
      position: relative;
      top: -1px; }

#promotions .promotion-image {
  margin-top: 20px; }
  #promotions .promotion-image .labels {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px; }
    #promotions .promotion-image .labels p:first-of-type {
      font-size: 14px;
      font-weight: 500;
      margin: 0px;
      color: #505157; }
    #promotions .promotion-image .labels p:last-of-type {
      font-size: 10px;
      font-weight: 300;
      color: #8B8D96;
      margin: 0px; }
  #promotions .promotion-image .image-container-placeholder {
    width: 100%;
    height: 150px;
    background-color: #DADADA;
    border-radius: 4px;
    border: 10px solid #F5F5F5; }
    #promotions .promotion-image .image-container-placeholder img {
      object-fit: contain;
      height: 100%;
      margin: 0 auto;
      display: block;
      width: 100%; }
    #promotions .promotion-image .image-container-placeholder.has-image {
      border: none;
      background-color: white; }
  #promotions .promotion-image .ant-upload {
    width: 100%; }
    #promotions .promotion-image .ant-upload p {
      color: #8B8D96;
      font-weight: 500;
      margin-top: 10px;
      margin-bottom: 0px;
      cursor: pointer; }
    #promotions .promotion-image .ant-upload > div {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap; }

@media (max-width: 768px) {
  #promotions {
    padding-right: 0px; }
    #promotions .ant-col-md-18 {
      padding-left: 0px !important; }
    #promotions .ant-upload {
      margin-bottom: 20px; }
    #promotions .product-image .labels p:last-of-type {
      font-size: 12px; }
    #promotions .status-selector {
      background-color: #EAEDEE;
      padding: 20px;
      margin-top: -20px; }
      #promotions .status-selector .status {
        height: 14px;
        width: 14px;
        border-radius: 50%;
        margin-right: 20px; }
        #promotions .status-selector .status.Inactive {
          background-color: #FFC100; }
        #promotions .status-selector .status.Active {
          background-color: #52C41A; }
      #promotions .status-selector > div {
        background-color: white;
        height: 50px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        padding: 20px;
        justify-content: left;
        color: #505157;
        cursor: pointer; }
        #promotions .status-selector > div .anticon {
          margin-left: auto; }
        #promotions .status-selector > div svg {
          color: #8C8C8C; }
    #promotions .ant-radio-group.ant-radio-group-solid.section-selector {
      width: 100%;
      padding: 6px;
      background-color: white;
      border-radius: 8px;
      margin-top: 20px; }
      #promotions .ant-radio-group.ant-radio-group-solid.section-selector > label {
        width: 50%;
        height: 38px;
        padding-top: 4px;
        text-align: center;
        border: none;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
        border-color: transparent;
        border-width: 0px; }
        #promotions .ant-radio-group.ant-radio-group-solid.section-selector > label:before {
          display: none; }
        #promotions .ant-radio-group.ant-radio-group-solid.section-selector > label.ant-radio-button-wrapper-checked {
          background-color: #BA4A00 !important; }
    #promotions .period {
      display: block !important; }
      #promotions .period > div {
        width: 100%; }
      #promotions .period .from-date .ant-picker {
        border-radius: 4px 4px 0px 0px !important; }
      #promotions .period .to-date .ant-form-item-label {
        display: none; }
      #promotions .period .to-date .ant-picker {
        border-radius: 0px 0px 4px 4px !important; } }

.notification {
  overflow: visible;
  border: 2px solid #BA4A00; }
  .notification img {
    width: 87px;
    margin-left: -15px;
    margin-top: -41px; }
  .notification .ant-notification-notice-description {
    margin-left: 90px;
    font-weight: 300;
    padding-right: 20px; }
    .notification .ant-notification-notice-description p {
      margin-bottom: 0px; }
      .notification .ant-notification-notice-description p span {
        font-weight: 500; }
  .notification .ant-notification-close-x {
    color: #BA4A00;
    margin-top: 3px;
    display: block; }

.product-details .ant-col-md-16 {
  padding-left: 20px; }

.product-details .ant-row .ant-col-md-12:first-of-type {
  padding-right: 10px; }

.product-details .ant-row .ant-col-md-12:last-of-type {
  padding-left: 10px; }

.product-details textarea {
  font-size: 16px; }

.product-details .agent-selector .ant-form-item {
  max-width: 600px; }

.product-details .separator {
  height: 1px;
  background-color: #F5F5F5;
  margin-left: -20px;
  width: calc(100% + 40px);
  margin-right: -20px;
  margin-bottom: 20px; }

.product-details .product-image .labels {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px; }
  .product-details .product-image .labels p:first-of-type {
    font-size: 14px;
    font-weight: 500;
    margin: 0px;
    color: #505157; }
  .product-details .product-image .labels p:last-of-type {
    font-size: 10px;
    font-weight: 300;
    color: #8B8D96;
    margin: 0px; }

.product-details .product-image .image-container-placeholder {
  width: 100%;
  height: 150px;
  background-color: #DADADA;
  border-radius: 4px;
  border: 10px solid #F5F5F5; }
  .product-details .product-image .image-container-placeholder img {
    object-fit: contain;
    height: 100%;
    margin: 0 auto;
    display: block;
    width: 100%; }

.product-details .product-image .ant-upload {
  width: 100%; }
  .product-details .product-image .ant-upload p {
    color: #8B8D96;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 0px;
    cursor: pointer; }
  .product-details .product-image .ant-upload > div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }

@media (max-width: 768px) {
  .product-details {
    padding-right: 0px; }
    .product-details .ant-upload {
      margin-bottom: 20px; }
    .product-details .product-image .labels p:last-of-type {
      font-size: 12px; } }

@media (max-width: 768px) {
  .section-title {
    display: none; }
  .ant-col-md-16 {
    padding-left: 0px !important; }
  .ant-col-md-12 {
    padding-left: 0px !important; } }

.section-selector-container {
  background-color: #EAEDEE;
  padding: 20px;
  margin-top: -20px; }

.share-modal .ant-modal-close {
  display: none; }

.share-modal .ant-modal-content {
  overflow: hidden; }

.share-modal .share .anticon-check-circle {
  font-size: 44px;
  display: block;
  margin: 20px 0px 20px 0px; }

.share-modal .share h5 {
  color: #BA4A00;
  font-weight: 500;
  font-size: 14px; }

.share-modal .share .create-opportunity {
  margin-top: 20px;
  padding: 15px;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.08), 0 1px 18px 0 rgba(0, 0, 0, 0.1), 0 3px 5px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  border-radius: 8px;
  cursor: pointer; }
  .share-modal .share .create-opportunity p {
    font-weight: 300; }
  .share-modal .share .create-opportunity .text {
    width: 290px; }
  .share-modal .share .create-opportunity .image {
    width: 82px; }
    .share-modal .share .create-opportunity .image img {
      width: 150px; }
    @media (max-width: 768px) {
      .share-modal .share .create-opportunity .image {
        display: none; } }
  .share-modal .share .create-opportunity .link {
    text-decoration: underline;
    color: #1890FF;
    margin-top: -10px;
    margin-bottom: 0px;
    cursor: pointer; }

.share-modal .share .ant-collapse-header {
  background-color: #EAEDEE;
  margin-top: 20px;
  border-radius: 8px 8px 8px 8px !important; }
  .share-modal .share .ant-collapse-header svg {
    transform: rotate(90deg);
    font-size: 14px;
    margin-top: 8px; }
  .share-modal .share .ant-collapse-header p {
    margin-bottom: 0px; }
    .share-modal .share .ant-collapse-header p.small {
      font-size: 12px;
      color: #BA4A00; }

.share-modal .share .ant-collapse-content-box {
  padding: 15px 0px 0px 0px !important; }

.share-modal .share .ant-collapse-content-box div, .share-modal .share .share-btns div {
  height: 50px;
  width: 100%;
  font-size: 14px;
  color: white;
  display: flex;
  margin-bottom: 10px;
  border-radius: 8px;
  align-items: center;
  padding: 15px;
  cursor: pointer; }
  .share-modal .share .ant-collapse-content-box div.whatsapp, .share-modal .share .share-btns div.whatsapp {
    background-color: #23CD64; }
  .share-modal .share .ant-collapse-content-box div.wechat, .share-modal .share .share-btns div.wechat {
    background-color: #78AD2C; }
  .share-modal .share .ant-collapse-content-box div.link, .share-modal .share .share-btns div.link {
    background-color: #505157;
    margin-bottom: 0px; }
  .share-modal .share .ant-collapse-content-box div.only-save, .share-modal .share .share-btns div.only-save {
    background-color: #DADADA;
    color: #505157;
    margin-top: 10px;
    text-align: center;
    font-weight: 500;
    display: block; }
  .share-modal .share .ant-collapse-content-box div svg, .share-modal .share .share-btns div svg {
    font-size: 24px;
    margin-right: 10px; }
  .share-modal .share .ant-collapse-content-box div.cancel, .share-modal .share .share-btns div.cancel {
    background-color: #DADADA;
    color: #505157;
    height: 55px;
    margin-top: 15px;
    text-align: center;
    display: block;
    line-height: 1.8;
    font-weight: 500; }

.share-modal .share .ant-modal-close-x svg {
  color: #DADADA;
  font-size: 20px;
  position: relative;
  top: 10px; }

.content-builder {
  position: relative; }
  .content-builder .react-page-controls-mode-toggle-control-group {
    position: fixed !important;
    right: -5px !important;
    z-index: 5 !important;
    margin: 12px !important;
    bottom: 10px !important;
    top: auto !important; }
  .content-builder .react-page-controls-mode-toggle-control-group * {
    padding: 0 !important; }

:root {
  --black: #000000;
  --white: #ffffff;
  --transparent: rgba(0, 0, 0, 0);
  --fullBlack: rgba(0, 0, 0, 1);
  --darkBlack: rgba(0, 0, 0, 0.87);
  --lightBlack: rgba(0, 0, 0, 0.54);
  --minBlack: rgba(0, 0, 0, 0.26);
  --faintBlack: rgba(0, 0, 0, 0.12);
  --fullWhite: rgba(255, 255, 255, 1);
  --darkWhite: rgba(255, 255, 255, 0.87);
  --lightWhite: rgba(255, 255, 255, 0.54);
  --minWhite: rgba(255, 255, 255, 0.26);
  --faintWhite: rgba(255, 255, 255, 0.12);
  --grey300: #e0e0e0;
  --grey900: #212121; }

/* based on: https://github.com/kristoferjoseph/flexboxgrid/blob/master/src/css/flexboxgrid.css */
:root {
  --gutter-width: 0rem;
  --outer-margin: 0rem;
  --gutter-compensation: calc((var(--gutter-width) * 0.5) * -1);
  --half-gutter-width: calc((var(--gutter-width) * 0.5));
  --xs-min: 30;
  --sm-min: 48;
  --md-min: 64;
  --lg-min: 75;
  --screen-xs-min: var(--xs-min) em;
  --screen-sm-min: var(--sm-min) em;
  --screen-md-min: var(--md-min) em;
  --screen-lg-min: var(--lg-min) em;
  --container-sm: calc(var(--sm-min) + var(--gutter-width));
  --container-md: calc(var(--md-min) + var(--gutter-width));
  --container-lg: calc(var(--lg-min) + var(--gutter-width)); }

.react-page-editable {
  margin-right: auto;
  margin-left: auto;
  padding-right: 0rem;
  padding-right: var(--outer-margin, 2rem);
  padding-left: 0rem;
  padding-left: var(--outer-margin, 2rem); }

.react-page-row {
  box-sizing: border-box;
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: calc((0rem * 0.5) * -1);
  margin-right: var(--gutter-compensation, -0.5rem);
  margin-left: calc((0rem * 0.5) * -1);
  margin-left: var(--gutter-compensation, -0.5rem);
  position: relative; }

.react-page-cell-xs,
.react-page-cell-xs-1,
.react-page-cell-xs-2,
.react-page-cell-xs-3,
.react-page-cell-xs-4,
.react-page-cell-xs-5,
.react-page-cell-xs-6,
.react-page-cell-xs-7,
.react-page-cell-xs-8,
.react-page-cell-xs-9,
.react-page-cell-xs-10,
.react-page-cell-xs-11,
.react-page-cell-xs-12,
.react-page-cell-xs-offset-0,
.react-page-cell-xs-offset-1,
.react-page-cell-xs-offset-2,
.react-page-cell-xs-offset-3,
.react-page-cell-xs-offset-4,
.react-page-cell-xs-offset-5,
.react-page-cell-xs-offset-6,
.react-page-cell-xs-offset-7,
.react-page-cell-xs-offset-8,
.react-page-cell-xs-offset-9,
.react-page-cell-xs-offset-10,
.react-page-cell-xs-offset-11,
.react-page-cell-xs-offset-12 {
  box-sizing: border-box;
  flex: 0 0 auto;
  padding-right: calc((0rem * 0.5));
  padding-right: var(--half-gutter-width, 0.5rem);
  padding-left: calc((0rem * 0.5));
  padding-left: var(--half-gutter-width, 0.5rem);
  min-width: 0; }

.react-page-cell-xs {
  flex-grow: 1;
  flex-basis: 0;
  width: 100%;
  min-width: 100%; }

.react-page-cell-xs-1 {
  flex-basis: 8.33333333%;
  width: 8.33333333%; }

.react-page-cell-xs-2 {
  flex-basis: 16.66666667%;
  width: 16.66666667%; }

.react-page-cell-xs-3 {
  flex-basis: 25%;
  width: 25%;
  min-width: 25%; }

.react-page-cell-xs-4 {
  flex-basis: 33.33333333%;
  width: 33.33333333%; }

.react-page-cell-xs-5 {
  flex-basis: 41.66666667%;
  width: 41.66666667%; }

.react-page-cell-xs-6 {
  flex-basis: 50%;
  width: 50%;
  min-width: 50%; }

.react-page-cell-xs-7 {
  flex-basis: 58.33333333%;
  width: 58.33333333%; }

.react-page-cell-xs-8 {
  flex-basis: 66.66666667%;
  width: 66.66666667%; }

.react-page-cell-xs-9 {
  flex-basis: 75%;
  width: 75%;
  min-width: 75%; }

.react-page-cell-xs-10 {
  flex-basis: 83.33333333%;
  width: 83.33333333%; }

.react-page-cell-xs-11 {
  flex-basis: 91.66666667%;
  width: 91.66666667%; }

.react-page-cell-xs-12 {
  flex-basis: 100%;
  width: 100%;
  min-width: 100%; }

.react-page-cell-xs-offset-0 {
  margin-left: 0; }

.react-page-cell-xs-offset-1 {
  margin-left: 8.33333333%; }

.react-page-cell-xs-offset-2 {
  margin-left: 16.66666667%; }

.react-page-cell-xs-offset-3 {
  margin-left: 25%; }

.react-page-cell-xs-offset-4 {
  margin-left: 33.33333333%; }

.react-page-cell-xs-offset-5 {
  margin-left: 41.66666667%; }

.react-page-cell-xs-offset-6 {
  margin-left: 50%; }

.react-page-cell-xs-offset-7 {
  margin-left: 58.33333333%; }

.react-page-cell-xs-offset-8 {
  margin-left: 66.66666667%; }

.react-page-cell-xs-offset-9 {
  margin-left: 75%; }

.react-page-cell-xs-offset-10 {
  margin-left: 83.33333333%; }

.react-page-cell-xs-offset-11 {
  margin-left: 91.66666667%; }

[dir="ltr"] .start-xs {
  text-align: left; }

[dir="rtl"] .start-xs {
  text-align: right; }

.start-xs {
  justify-content: flex-start; }

.center-xs {
  justify-content: center;
  text-align: center; }

[dir="ltr"] .end-xs {
  text-align: right; }

[dir="rtl"] .end-xs {
  text-align: left; }

.end-xs {
  justify-content: flex-end; }

.top-xs {
  align-items: flex-start; }

.middle-xs {
  align-items: center; }

.bottom-xs {
  align-items: flex-end; }

.around-xs {
  justify-content: space-around; }

.between-xs {
  justify-content: space-between; }

.first-xs {
  order: -1; }

.last-xs {
  order: 1; }

@media only screen and (min-width: 48em) {
  .react-page-cell-sm,
  .react-page-cell-sm-1,
  .react-page-cell-sm-2,
  .react-page-cell-sm-3,
  .react-page-cell-sm-4,
  .react-page-cell-sm-5,
  .react-page-cell-sm-6,
  .react-page-cell-sm-7,
  .react-page-cell-sm-8,
  .react-page-cell-sm-9,
  .react-page-cell-sm-10,
  .react-page-cell-sm-11,
  .react-page-cell-sm-12,
  .react-page-cell-sm-offset-0,
  .react-page-cell-sm-offset-1,
  .react-page-cell-sm-offset-2,
  .react-page-cell-sm-offset-3,
  .react-page-cell-sm-offset-4,
  .react-page-cell-sm-offset-5,
  .react-page-cell-sm-offset-6,
  .react-page-cell-sm-offset-7,
  .react-page-cell-sm-offset-8,
  .react-page-cell-sm-offset-9,
  .react-page-cell-sm-offset-10,
  .react-page-cell-sm-offset-11,
  .react-page-cell-sm-offset-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: calc((0rem * 0.5));
    padding-right: var(--half-gutter-width, 0.5rem);
    padding-left: calc((0rem * 0.5));
    padding-left: var(--half-gutter-width, 0.5rem);
    min-width: 0; }
  .react-page-cell-sm {
    flex-grow: 1;
    flex-basis: 0;
    width: 100%;
    min-width: 100%; }
  .react-page-cell-sm-1 {
    flex-basis: 8.33333333%;
    width: 8.33333333%; }
  .react-page-cell-sm-2 {
    flex-basis: 16.66666667%;
    width: 16.66666667%; }
  .react-page-cell-sm-3 {
    flex-basis: 25%;
    width: 25%;
    min-width: 25%; }
  .react-page-cell-sm-4 {
    flex-basis: 33.33333333%;
    width: 33.33333333%; }
  .react-page-cell-sm-5 {
    flex-basis: 41.66666667%;
    width: 41.66666667%; }
  .react-page-cell-sm-6 {
    flex-basis: 50%;
    width: 50%;
    min-width: 50%; }
  .react-page-cell-sm-7 {
    flex-basis: 58.33333333%;
    width: 58.33333333%; }
  .react-page-cell-sm-8 {
    flex-basis: 66.66666667%;
    width: 66.66666667%; }
  .react-page-cell-sm-9 {
    flex-basis: 75%;
    width: 75%;
    min-width: 75%; }
  .react-page-cell-sm-10 {
    flex-basis: 83.33333333%;
    width: 83.33333333%; }
  .react-page-cell-sm-11 {
    flex-basis: 91.66666667%;
    width: 91.66666667%; }
  .react-page-cell-sm-12 {
    flex-basis: 100%;
    width: 100%;
    min-width: 100%; }
  .react-page-cell-sm-offset-0 {
    margin-left: 0; }
  .react-page-cell-sm-offset-1 {
    margin-left: 8.33333333%; }
  .react-page-cell-sm-offset-2 {
    margin-left: 16.66666667%; }
  .react-page-cell-sm-offset-3 {
    margin-left: 25%; }
  .react-page-cell-sm-offset-4 {
    margin-left: 33.33333333%; }
  .react-page-cell-sm-offset-5 {
    margin-left: 41.66666667%; }
  .react-page-cell-sm-offset-6 {
    margin-left: 50%; }
  .react-page-cell-sm-offset-7 {
    margin-left: 58.33333333%; }
  .react-page-cell-sm-offset-8 {
    margin-left: 66.66666667%; }
  .react-page-cell-sm-offset-9 {
    margin-left: 75%; }
  .react-page-cell-sm-offset-10 {
    margin-left: 83.33333333%; }
  .react-page-cell-sm-offset-11 {
    margin-left: 91.66666667%; }
  [dir="ltr"] .start-sm {
    text-align: left; }
  [dir="rtl"] .start-sm {
    text-align: right; }
  .start-sm {
    justify-content: flex-start; }
  .center-sm {
    justify-content: center;
    text-align: center; }
  [dir="ltr"] .end-sm {
    text-align: right; }
  [dir="rtl"] .end-sm {
    text-align: left; }
  .end-sm {
    justify-content: flex-end; }
  .top-sm {
    align-items: flex-start; }
  .middle-sm {
    align-items: center; }
  .bottom-sm {
    align-items: flex-end; }
  .around-sm {
    justify-content: space-around; }
  .between-sm {
    justify-content: space-between; }
  .first-sm {
    order: -1; }
  .last-sm {
    order: 1; } }

@media only screen and (min-width: 64em) {
  .react-page-cell-md,
  .react-page-cell-md-1,
  .react-page-cell-md-2,
  .react-page-cell-md-3,
  .react-page-cell-md-4,
  .react-page-cell-md-5,
  .react-page-cell-md-6,
  .react-page-cell-md-7,
  .react-page-cell-md-8,
  .react-page-cell-md-9,
  .react-page-cell-md-10,
  .react-page-cell-md-11,
  .react-page-cell-md-12,
  .react-page-cell-md-offset-0,
  .react-page-cell-md-offset-1,
  .react-page-cell-md-offset-2,
  .react-page-cell-md-offset-3,
  .react-page-cell-md-offset-4,
  .react-page-cell-md-offset-5,
  .react-page-cell-md-offset-6,
  .react-page-cell-md-offset-7,
  .react-page-cell-md-offset-8,
  .react-page-cell-md-offset-9,
  .react-page-cell-md-offset-10,
  .react-page-cell-md-offset-11,
  .react-page-cell-md-offset-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: calc((0rem * 0.5));
    padding-right: var(--half-gutter-width, 0.5rem);
    padding-left: calc((0rem * 0.5));
    padding-left: var(--half-gutter-width, 0.5rem);
    min-width: 0; }
  .react-page-cell-md {
    flex-grow: 1;
    flex-basis: 0;
    width: 100%;
    min-width: 100%; }
  .react-page-cell-md-1 {
    flex-basis: 8.33333333%;
    width: 8.33333333%; }
  .react-page-cell-md-2 {
    flex-basis: 16.66666667%;
    width: 16.66666667%; }
  .react-page-cell-md-3 {
    flex-basis: 25%;
    width: 25%;
    min-width: 25%; }
  .react-page-cell-md-4 {
    flex-basis: 33.33333333%;
    width: 33.33333333%; }
  .react-page-cell-md-5 {
    flex-basis: 41.66666667%;
    width: 41.66666667%; }
  .react-page-cell-md-6 {
    flex-basis: 50%;
    width: 50%;
    min-width: 50%; }
  .react-page-cell-md-7 {
    flex-basis: 58.33333333%;
    width: 58.33333333%; }
  .react-page-cell-md-8 {
    flex-basis: 66.66666667%;
    width: 66.66666667%; }
  .react-page-cell-md-9 {
    flex-basis: 75%;
    width: 75%;
    min-width: 75%; }
  .react-page-cell-md-10 {
    flex-basis: 83.33333333%;
    width: 83.33333333%; }
  .react-page-cell-md-11 {
    flex-basis: 91.66666667%;
    width: 91.66666667%; }
  .react-page-cell-md-12 {
    flex-basis: 100%;
    width: 100%;
    min-width: 100%; }
  .react-page-cell-md-offset-0 {
    margin-left: 0; }
  .react-page-cell-md-offset-1 {
    margin-left: 8.33333333%; }
  .react-page-cell-md-offset-2 {
    margin-left: 16.66666667%; }
  .react-page-cell-md-offset-3 {
    margin-left: 25%; }
  .react-page-cell-md-offset-4 {
    margin-left: 33.33333333%; }
  .react-page-cell-md-offset-5 {
    margin-left: 41.66666667%; }
  .react-page-cell-md-offset-6 {
    margin-left: 50%; }
  .react-page-cell-md-offset-7 {
    margin-left: 58.33333333%; }
  .react-page-cell-md-offset-8 {
    margin-left: 66.66666667%; }
  .react-page-cell-md-offset-9 {
    margin-left: 75%; }
  .react-page-cell-md-offset-10 {
    margin-left: 83.33333333%; }
  .react-page-cell-md-offset-11 {
    margin-left: 91.66666667%; }
  [dir="ltr"] .start-md {
    text-align: left; }
  [dir="rtl"] .start-md {
    text-align: right; }
  .start-md {
    justify-content: flex-start; }
  .center-md {
    justify-content: center;
    text-align: center; }
  [dir="ltr"] .end-md {
    text-align: right; }
  [dir="rtl"] .end-md {
    text-align: left; }
  .end-md {
    justify-content: flex-end; }
  .top-md {
    align-items: flex-start; }
  .middle-md {
    align-items: center; }
  .bottom-md {
    align-items: flex-end; }
  .around-md {
    justify-content: space-around; }
  .between-md {
    justify-content: space-between; }
  .first-md {
    order: -1; }
  .last-md {
    order: 1; } }

@media only screen and (min-width: 75em) {
  .react-page-cell-lg,
  .react-page-cell-lg-1,
  .react-page-cell-lg-2,
  .react-page-cell-lg-3,
  .react-page-cell-lg-4,
  .react-page-cell-lg-5,
  .react-page-cell-lg-6,
  .react-page-cell-lg-7,
  .react-page-cell-lg-8,
  .react-page-cell-lg-9,
  .react-page-cell-lg-10,
  .react-page-cell-lg-11,
  .react-page-cell-lg-12,
  .react-page-cell-lg-offset-0,
  .react-page-cell-lg-offset-1,
  .react-page-cell-lg-offset-2,
  .react-page-cell-lg-offset-3,
  .react-page-cell-lg-offset-4,
  .react-page-cell-lg-offset-5,
  .react-page-cell-lg-offset-6,
  .react-page-cell-lg-offset-7,
  .react-page-cell-lg-offset-8,
  .react-page-cell-lg-offset-9,
  .react-page-cell-lg-offset-10,
  .react-page-cell-lg-offset-11,
  .react-page-cell-lg-offset-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: calc((0rem * 0.5));
    padding-right: var(--half-gutter-width, 0.5rem);
    padding-left: calc((0rem * 0.5));
    padding-left: var(--half-gutter-width, 0.5rem);
    min-width: 0; }
  .react-page-cell-lg {
    flex-grow: 1;
    flex-basis: 0;
    width: 100%;
    min-width: 100%; }
  .react-page-cell-lg-1 {
    flex-basis: 8.33333333%;
    width: 8.33333333%; }
  .react-page-cell-lg-2 {
    flex-basis: 16.66666667%;
    width: 16.66666667%; }
  .react-page-cell-lg-3 {
    flex-basis: 25%;
    width: 25%;
    min-width: 25%; }
  .react-page-cell-lg-4 {
    flex-basis: 33.33333333%;
    width: 33.33333333%; }
  .react-page-cell-lg-5 {
    flex-basis: 41.66666667%;
    width: 41.66666667%; }
  .react-page-cell-lg-6 {
    flex-basis: 50%;
    width: 50%;
    min-width: 50%; }
  .react-page-cell-lg-7 {
    flex-basis: 58.33333333%;
    width: 58.33333333%; }
  .react-page-cell-lg-8 {
    flex-basis: 66.66666667%;
    width: 66.66666667%; }
  .react-page-cell-lg-9 {
    flex-basis: 75%;
    width: 75%;
    min-width: 75%; }
  .react-page-cell-lg-10 {
    flex-basis: 83.33333333%;
    width: 83.33333333%; }
  .react-page-cell-lg-11 {
    flex-basis: 91.66666667%;
    width: 91.66666667%; }
  .react-page-cell-lg-12 {
    flex-basis: 100%;
    width: 100%;
    min-width: 100%; }
  .react-page-cell-lg-offset-0 {
    margin-left: 0; }
  .react-page-cell-lg-offset-1 {
    margin-left: 8.33333333%; }
  .react-page-cell-lg-offset-2 {
    margin-left: 16.66666667%; }
  .react-page-cell-lg-offset-3 {
    margin-left: 25%; }
  .react-page-cell-lg-offset-4 {
    margin-left: 33.33333333%; }
  .react-page-cell-lg-offset-5 {
    margin-left: 41.66666667%; }
  .react-page-cell-lg-offset-6 {
    margin-left: 50%; }
  .react-page-cell-lg-offset-7 {
    margin-left: 58.33333333%; }
  .react-page-cell-lg-offset-8 {
    margin-left: 66.66666667%; }
  .react-page-cell-lg-offset-9 {
    margin-left: 75%; }
  .react-page-cell-lg-offset-10 {
    margin-left: 83.33333333%; }
  .react-page-cell-lg-offset-11 {
    margin-left: 91.66666667%; }
  [dir="ltr"] .start-lg {
    text-align: left; }
  [dir="rtl"] .start-lg {
    text-align: right; }
  .start-lg {
    justify-content: flex-start; }
  .center-lg {
    justify-content: center;
    text-align: center; }
  [dir="ltr"] .end-lg {
    text-align: right; }
  [dir="rtl"] .end-lg {
    text-align: left; }
  .end-lg {
    justify-content: flex-end; }
  .top-lg {
    align-items: flex-start; }
  .middle-lg {
    align-items: center; }
  .bottom-lg {
    align-items: flex-end; }
  .around-lg {
    justify-content: space-around; }
  .between-lg {
    justify-content: space-between; }
  .first-lg {
    order: -1; }
  .last-lg {
    order: 1; } }

.react-page-row-has-floating-children {
  display: block !important; }

.react-page-row-is-hovering-this:before {
  content: ' ';
  position: absolute;
  background: black;
  display: block;
  background: rgba(0, 0, 0, 0.87);
  background: var(--darkBlack);
  z-index: 2; }

.react-page-row-is-hovering-above:before {
  top: 0;
  height: 4px;
  width: 100%; }

.react-page-row-is-hovering-below:before {
  bottom: 0;
  height: 4px;
  width: 100%; }

.react-page-row-is-hovering-left-of:before {
  top: 0;
  left: 0;
  bottom: 0;
  width: 4px; }

.react-page-row-is-hovering-right-of:before {
  top: 0;
  right: 0;
  bottom: 0;
  width: 4px; }

@-webkit-keyframes reactPageCellResizeableAnimate {
  0% {
    background-color: rgba(255, 255, 255, 0.26);
    background-color: var(--minWhite);
    top: 45%;
    bottom: 45%; }
  50% {
    background-color: rgba(255, 255, 255, 0.12);
    background-color: var(--faintWhite);
    top: 0;
    bottom: 0; }
  100% {
    background-color: rgba(255, 255, 255, 0.87);
    background-color: var(--darkWhite);
    top: 45%;
    bottom: 45%; } }

@keyframes reactPageCellResizeableAnimate {
  0% {
    background-color: rgba(255, 255, 255, 0.26);
    background-color: var(--minWhite);
    top: 45%;
    bottom: 45%; }
  50% {
    background-color: rgba(255, 255, 255, 0.12);
    background-color: var(--faintWhite);
    top: 0;
    bottom: 0; }
  100% {
    background-color: rgba(255, 255, 255, 0.87);
    background-color: var(--darkWhite);
    top: 45%;
    bottom: 45%; } }

.react-page-row .resize-handle {
  position: absolute;
  z-index: 6;
  width: 4px;
  top: 0;
  bottom: 0;
  left: -2px;
  cursor: e-resize;
  background-color: rgba(0, 0, 0, 0.54);
  background-color: var(--lightBlack);
  mix-blend-mode: difference; }

.react-page-row .resize-handle:after {
  content: ' ';
  background-color: rgba(255, 255, 255, 0.87);
  background-color: var(--darkWhite);
  /* width: 30px; */
  /* height: 30px; */
  position: absolute;
  /* z-index: 1000; */
  top: 40%;
  bottom: 40%;
  left: 0;
  right: 0;
  transition: all 200ms linear;
  mix-blend-mode: normal;
  -webkit-animation-name: reactPageCellResizeableAnimate;
  animation-name: reactPageCellResizeableAnimate;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s; }

.react-page-row .resize-handle:hover:after,
.react-page-row .resize-handle:active:after {
  background-color: white;
  background-color: var(--fullWhite);
  top: 0;
  bottom: 0; }

.react-page-editable {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  align-items: stretch; }

.react-page-editable .react-page-cell-insert-new {
  cursor: cell;
  flex: 1;
  min-height: 100px;
  transition: all ease 0.4s;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0);
  z-index: 1;
  position: relative; }

.react-page-editable .react-page-cell-insert-new:after {
  content: '+';
  font-size: 52px;
  font-family: monospace;
  line-height: 52px;
  background-color: rgba(0, 0, 0, 0.26);
  background-color: var(--minBlack);
  color: white;
  width: 52px;
  text-align: center;
  border-radius: 50%; }

.react-page-editable .react-page-cell-insert-new:hover,
.react-page-editable .react-page-cell-insert-new.hover {
  background: rgba(0, 0, 0, 0.26);
  background: var(--minBlack);
  border: 1px solid rgba(255, 255, 255, 0.54);
  border: 1px solid var(--lightWhite); }

.react-page-editable.react-page-editable-mode-edit .react-page-cell-draggable, .react-page-editable.react-page-editable-mode-resizing .react-page-cell-draggable, .react-page-editable.react-page-editable-mode-layout .react-page-cell-draggable {
  cursor: move;
  position: relative;
  transition: opacity 200ms ease; }

.react-page-editable.react-page-editable-mode-edit .react-page-cell-draggable-overlay, .react-page-editable.react-page-editable-mode-resizing .react-page-cell-draggable-overlay, .react-page-editable.react-page-editable-mode-layout .react-page-cell-draggable-overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  cursor: move;
  border: 1px solid rgba(0, 0, 0, 0);
  transition: all ease 0.4s; }

.react-page-editable.react-page-editable-mode-edit .react-page-cell-draggable-overlay-handle, .react-page-editable.react-page-editable-mode-resizing .react-page-cell-draggable-overlay-handle, .react-page-editable.react-page-editable-mode-layout .react-page-cell-draggable-overlay-handle {
  position: absolute;
  top: 0;
  right: 0;
  width: 2rem;
  height: 2rem;
  cursor: move;
  background: rgba(0, 0, 0, 0.26);
  background: var(--minBlack);
  content: '';
  z-index: 500; }

.react-page-editable.react-page-editable-mode-edit .react-page-cell-draggable-overlay-handle, .react-page-editable.react-page-editable-mode-resizing .react-page-cell-draggable-overlay-handle, .react-page-editable.react-page-editable-mode-layout .react-page-cell-draggable-overlay-handle {
  opacity: 0;
  transition: opacity 0.3s; }

.react-page-editable.react-page-editable-mode-edit .react-page-cell:hover
> div
> div
> div
> div
> .react-page-cell-draggable-overlay-handle,
.react-page-editable.react-page-editable-mode-edit .react-page-cell.react-page-cell-focused
> div
> div
> div
> div
> .react-page-cell-draggable-overlay-handle,
.react-page-editable.react-page-editable-mode-resizing .react-page-cell:hover
> div
> div
> div
> div
> .react-page-cell-draggable-overlay-handle,
.react-page-editable.react-page-editable-mode-resizing .react-page-cell.react-page-cell-focused
> div
> div
> div
> div
> .react-page-cell-draggable-overlay-handle,
.react-page-editable.react-page-editable-mode-layout .react-page-cell:hover
> div
> div
> div
> div
> .react-page-cell-draggable-overlay-handle,
.react-page-editable.react-page-editable-mode-layout .react-page-cell.react-page-cell-focused
> div
> div
> div
> div
> .react-page-cell-draggable-overlay-handle {
  opacity: 1; }

.react-page-editable.react-page-editable-mode-edit .react-page-cell-draggable-overlay-handle
.react-page-cell-draggable-overlay-handle-icon::before, .react-page-editable.react-page-editable-mode-resizing .react-page-cell-draggable-overlay-handle
.react-page-cell-draggable-overlay-handle-icon::before, .react-page-editable.react-page-editable-mode-layout .react-page-cell-draggable-overlay-handle
.react-page-cell-draggable-overlay-handle-icon::before {
  top: -300%; }

.react-page-editable.react-page-editable-mode-edit .react-page-cell-draggable-overlay-handle
.react-page-cell-draggable-overlay-handle-icon, .react-page-editable.react-page-editable-mode-resizing .react-page-cell-draggable-overlay-handle
.react-page-cell-draggable-overlay-handle-icon, .react-page-editable.react-page-editable-mode-layout .react-page-cell-draggable-overlay-handle
.react-page-cell-draggable-overlay-handle-icon {
  width: 70%;
  top: 50%; }

.react-page-editable.react-page-editable-mode-edit .react-page-cell-draggable-overlay-handle
.react-page-cell-draggable-overlay-handle-icon::after, .react-page-editable.react-page-editable-mode-resizing .react-page-cell-draggable-overlay-handle
.react-page-cell-draggable-overlay-handle-icon::after, .react-page-editable.react-page-editable-mode-layout .react-page-cell-draggable-overlay-handle
.react-page-cell-draggable-overlay-handle-icon::after {
  top: 400%; }

.react-page-editable.react-page-editable-mode-edit .react-page-cell-draggable-overlay-handle
.react-page-cell-draggable-overlay-handle-icon::before,
.react-page-editable.react-page-editable-mode-edit .react-page-cell-draggable-overlay-handle
.react-page-cell-draggable-overlay-handle-icon::after,
.react-page-editable.react-page-editable-mode-resizing .react-page-cell-draggable-overlay-handle
.react-page-cell-draggable-overlay-handle-icon::before,
.react-page-editable.react-page-editable-mode-resizing .react-page-cell-draggable-overlay-handle
.react-page-cell-draggable-overlay-handle-icon::after,
.react-page-editable.react-page-editable-mode-layout .react-page-cell-draggable-overlay-handle
.react-page-cell-draggable-overlay-handle-icon::before,
.react-page-editable.react-page-editable-mode-layout .react-page-cell-draggable-overlay-handle
.react-page-cell-draggable-overlay-handle-icon::after {
  width: 100%; }

.react-page-editable.react-page-editable-mode-edit .react-page-cell-draggable-overlay-handle
.react-page-cell-draggable-overlay-handle-icon,
.react-page-editable.react-page-editable-mode-edit .react-page-cell-draggable-overlay-handle
.react-page-cell-draggable-overlay-handle-icon::before,
.react-page-editable.react-page-editable-mode-edit .react-page-cell-draggable-overlay-handle
.react-page-cell-draggable-overlay-handle-icon::after,
.react-page-editable.react-page-editable-mode-resizing .react-page-cell-draggable-overlay-handle
.react-page-cell-draggable-overlay-handle-icon,
.react-page-editable.react-page-editable-mode-resizing .react-page-cell-draggable-overlay-handle
.react-page-cell-draggable-overlay-handle-icon::before,
.react-page-editable.react-page-editable-mode-resizing .react-page-cell-draggable-overlay-handle
.react-page-cell-draggable-overlay-handle-icon::after,
.react-page-editable.react-page-editable-mode-layout .react-page-cell-draggable-overlay-handle
.react-page-cell-draggable-overlay-handle-icon,
.react-page-editable.react-page-editable-mode-layout .react-page-cell-draggable-overlay-handle
.react-page-cell-draggable-overlay-handle-icon::before,
.react-page-editable.react-page-editable-mode-layout .react-page-cell-draggable-overlay-handle
.react-page-cell-draggable-overlay-handle-icon::after {
  height: 2px;
  position: absolute;
  content: '';
  transform: translate(-50%, -50%);
  left: 50%;
  transform: translate(-50%, -50%);
  background: white; }

.react-page-editable.react-page-editable-mode-edit .react-page-cell:hover
> div
> div
> div
> .react-page-cell-draggable-overlay, .react-page-editable.react-page-editable-mode-resizing .react-page-cell:hover
> div
> div
> div
> .react-page-cell-draggable-overlay, .react-page-editable.react-page-editable-mode-layout .react-page-cell:hover
> div
> div
> div
> .react-page-cell-draggable-overlay {
  background: rgba(0, 0, 0, 0.26);
  background: var(--minBlack);
  border: 1px solid rgba(255, 255, 255, 0.54);
  border: 1px solid var(--lightWhite);
  background-blend-mode: screen; }

.react-page-editable.react-page-editable-mode-edit .react-page-cell-draggable-overlay.react-page-cell-draggable-leaf, .react-page-editable.react-page-editable-mode-resizing .react-page-cell-draggable-overlay.react-page-cell-draggable-leaf, .react-page-editable.react-page-editable-mode-layout .react-page-cell-draggable-overlay.react-page-cell-draggable-leaf {
  z-index: 3; }

.react-page-editable.react-page-editable-mode-edit .react-page-cell-draggable-inline-left.react-page-cell-draggable-overlay,
.react-page-editable.react-page-editable-mode-edit .react-page-cell-draggable-inline-right.react-page-cell-draggable-overlay,
.react-page-editable.react-page-editable-mode-resizing .react-page-cell-draggable-inline-left.react-page-cell-draggable-overlay,
.react-page-editable.react-page-editable-mode-resizing .react-page-cell-draggable-inline-right.react-page-cell-draggable-overlay,
.react-page-editable.react-page-editable-mode-layout .react-page-cell-draggable-inline-left.react-page-cell-draggable-overlay,
.react-page-editable.react-page-editable-mode-layout .react-page-cell-draggable-inline-right.react-page-cell-draggable-overlay {
  float: left;
  z-index: 4; }

.react-page-editable.react-page-editable-mode-edit .react-page-cell-draggable-is-dragging,
.react-page-editable.react-page-editable-mode-edit .react-page-cell-handle-is-dragging + div > .react-page-cell-droppable > .react-page-cell-draggable,
.react-page-editable.react-page-editable-mode-edit .react-page-cell-handle-is-dragging + div > .react-page-cell-droppable > .react-page-cell-draggable-in-edit,
.react-page-editable.react-page-editable-mode-resizing .react-page-cell-draggable-is-dragging,
.react-page-editable.react-page-editable-mode-resizing .react-page-cell-handle-is-dragging + div > .react-page-cell-droppable > .react-page-cell-draggable,
.react-page-editable.react-page-editable-mode-resizing .react-page-cell-handle-is-dragging + div > .react-page-cell-droppable > .react-page-cell-draggable-in-edit,
.react-page-editable.react-page-editable-mode-layout .react-page-cell-draggable-is-dragging,
.react-page-editable.react-page-editable-mode-layout .react-page-cell-handle-is-dragging + div > .react-page-cell-droppable > .react-page-cell-draggable,
.react-page-editable.react-page-editable-mode-layout .react-page-cell-handle-is-dragging + div > .react-page-cell-droppable > .react-page-cell-draggable-in-edit {
  opacity: 0.4;
  outline: none; }

.react-page-cell-droppable {
  /*position: relative;*/ }

.react-page-cell-droppable-is-over-current:after {
  /* extend dropable area a bit, so that its easier to add as last eleent */
  content: '';
  height: 100px;
  width: 100%;
  position: absolute;
  bottom: -100px; }

.react-page-cell-droppable-is-over-current:before {
  content: ' ';
  display: block;
  position: absolute;
  z-index: 1;
  background: rgba(0, 0, 0, 0.87);
  background: var(--darkBlack); }

.react-page-cell-droppable-is-over-above:before,
.react-page-cell-droppable-is-over-below:before {
  border-left: 0;
  border-right: 0; }

.react-page-cell-droppable-is-over-left-of:before,
.react-page-cell-droppable-is-over-right-of:before {
  border-top: 0;
  border-bottom: 0; }

.react-page-cell-droppable-is-over-above:before {
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  border-left: 0;
  border-right: 0; }

.react-page-cell-droppable-leaf.react-page-cell-droppable-is-over-above:before {
  height: 4px; }

.react-page-cell-droppable-is-over-below:before {
  bottom: 0;
  left: 0;
  right: 0;
  height: 4px; }

.react-page-cell-droppable-leaf.react-page-cell-droppable-is-over-below:before {
  height: 4px; }

.react-page-cell-droppable-is-over-left-of:before {
  top: 0;
  left: 0;
  bottom: 0;
  width: 4px; }

.react-page-cell-droppable-leaf.react-page-cell-droppable-is-over-left-of:before {
  width: 4px; }

.react-page-cell-droppable-is-over-right-of:before {
  top: 0;
  right: 0;
  bottom: 0;
  width: 4px; }

.react-page-cell-droppable-leaf.react-page-cell-droppable-is-over-right-of:before {
  width: 4px; }

.react-page-cell-droppable-is-over-inline-right:before,
.react-page-cell-droppable-is-over-inline-left:before {
  top: 0;
  width: 50%;
  height: 36%;
  max-height: 128px;
  background-color: #e0e0e0;
  background-color: var(--grey300);
  border: 1px solid rgba(0, 0, 0, 0.26);
  border: 1px solid var(--minBlack); }

.react-page-cell-droppable-is-over-inline-left:before {
  left: 0; }

.react-page-cell-droppable-is-over-inline-right:before {
  right: 0; }

.react-page-cell-droppable-not-allowed {
  cursor: not-allowed; }

.react-page-cell-error {
  background-color: red;
  padding: 8px;
  margin: 2px;
  overflow: hidden; }

.react-page-cell-error strong {
  margin: 0 auto; }

.react-page-cell-error code {
  overflow: scroll; }

.react-page-editable .react-page-cell-handle {
  display: none; }

.react-page-editable.react-page-editable-mode-edit .react-page-cell-handle, .react-page-editable.react-page-editable-mode-resizing .react-page-cell-handle, .react-page-editable.react-page-editable-mode-layout .react-page-cell-handle {
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translateX(-50%) translateY(-100%);
  transition: opacity ease 0.4s;
  opacity: 0;
  color: rgba(0, 0, 0, 0.97);
  background: rgba(255, 255, 255, 0.95);
  text-align: center;
  color: rgba(0, 0, 0, 0.97);
  display: inline-block;
  padding: 12px 24px;
  margin: 0 auto;
  border-radius: 12px 12px 0 0;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 1.4;
  letter-spacing: 0.15em;
  box-shadow: 0 -5px 5px rgba(0, 0, 0, 0.22);
  pointer-events: none; }

.react-page-editable.react-page-editable-mode-edit .react-page-cell-handle-drag-enabled, .react-page-editable.react-page-editable-mode-resizing .react-page-cell-handle-drag-enabled, .react-page-editable.react-page-editable-mode-layout .react-page-cell-handle-drag-enabled {
  cursor: move; }

.react-page-editable.react-page-editable-mode-edit .react-page-cell:hover > .react-page-cell-handle,
.react-page-editable.react-page-editable-mode-edit .react-page-cell.react-page-cell-focused > .react-page-cell-handle,
.react-page-editable.react-page-editable-mode-resizing .react-page-cell:hover > .react-page-cell-handle,
.react-page-editable.react-page-editable-mode-resizing .react-page-cell.react-page-cell-focused > .react-page-cell-handle,
.react-page-editable.react-page-editable-mode-layout .react-page-cell:hover > .react-page-cell-handle,
.react-page-editable.react-page-editable-mode-layout .react-page-cell.react-page-cell-focused > .react-page-cell-handle {
  opacity: 1;
  pointer-events: all; }

/* general rules for read only and edit */
.react-page-cell {
  display: flex;
  flex-direction: column;
  box-sizing: border-box; }

.react-page-cell-inline-left {
  float: left; }

.react-page-cell-inline-right {
  float: right; }

.react-page-cell-has-inline-neighbour {
  float: none;
  width: 100%;
  display: block; }

.react-page-cell .react-page-cell-inner {
  height: 100%;
  box-sizing: border-box;
  outline: none; }

/* edit */
.react-page-editable.react-page-editable-mode-edit .react-page-cell, .react-page-editable.react-page-editable-mode-resizing .react-page-cell, .react-page-editable.react-page-editable-mode-layout .react-page-cell {
  position: relative;
  transition: box-shadow 0.3s; }

.react-page-editable.react-page-editable-mode-edit .react-page-cell-is-draft, .react-page-editable.react-page-editable-mode-resizing .react-page-cell-is-draft, .react-page-editable.react-page-editable-mode-layout .react-page-cell-is-draft {
  opacity: 0.3;
  outline: 1px dashed black; }

.react-page-editable.react-page-editable-mode-edit .react-page-cell-bring-to-front, .react-page-editable.react-page-editable-mode-resizing .react-page-cell-bring-to-front, .react-page-editable.react-page-editable-mode-layout .react-page-cell-bring-to-front {
  z-index: 3; }

.react-page-editable.react-page-editable-mode-edit .react-page-cell.react-page-cell-has-plugin:hover, .react-page-editable.react-page-editable-mode-resizing .react-page-cell.react-page-cell-has-plugin:hover, .react-page-editable.react-page-editable-mode-layout .react-page-cell.react-page-cell-has-plugin:hover {
  z-index: 2;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  opacity: 1; }

.react-page-editable.react-page-editable-mode-edit .react-page-cell.react-page-cell-focused.react-page-cell-focused, .react-page-editable.react-page-editable-mode-resizing .react-page-cell.react-page-cell-focused.react-page-cell-focused, .react-page-editable.react-page-editable-mode-layout .react-page-cell.react-page-cell-focused.react-page-cell-focused {
  z-index: 1;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.4);
  opacity: 1; }

.react-page-editable.react-page-editable-mode-insert .react-page-cell {
  cursor: cell; }

.react-page-editable.react-page-editable-mode-insert .react-page-cell:hover {
  z-index: 1;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.4);
  opacity: 1; }

.react-page-controls-mode-toggle-button-inner {
  float: right;
  margin: 8px; }

.react-page-controls-mode-toggle-button-description {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  margin-top: 18px;
  float: right;
  background: transparent;
  color: transparent;
  border: 1px transparent solid;
  padding: 2px 8px;
  text-align: right;
  display: none;
  transition: all 200ms ease;
  white-space: nowrap;
  overflow: hidden; }

.react-page-controls-mode-toggle-button:hover
.react-page-controls-mode-toggle-button-description {
  max-width: 999px;
  background: rgba(0, 0, 0, 0.87);
  background: var(--darkBlack);
  color: #ffffff;
  color: var(--white);
  display: block;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border: 1px solid var(--faintBlack); }

.react-page-controls-mode-toggle-clearfix {
  clear: both; }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0); }
  80% {
    opacity: 1;
    transform: scale(1.05); }
  100% {
    opacity: 1;
    transform: scale(1); } }

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0); }
  80% {
    opacity: 1;
    transform: scale(1.05); }
  100% {
    opacity: 1;
    transform: scale(1); } }

.react-page-controls-mode-toggle-control {
  z-index: 1;
  -webkit-animation: fadeIn 0.8s forwards;
  animation: fadeIn 0.8s forwards;
  opacity: 0; }

.react-page-plugin-drawer-item {
  cursor: pointer;
  z-index: 1; }

.react-page-plugin-drawer-item:hover {
  z-index: 2;
  box-shadow: 0 0 20px #ccc; }

.react-page-controls-trash {
  position: fixed;
  bottom: -64px;
  z-index: 500;
  left: 50%;
  transition: bottom 200ms ease;
  padding: 8px; }

.react-page-controls-trash.react-page-controls-trash-active {
  bottom: 16px; }

.react-page-plugins-content-slate-paragraph-placeholder {
  font-style: italic;
  color: var(--lightBlack); }

.react-page-plugins-content-slate-inline-toolbar {
  position: absolute;
  z-index: 10;
  top: -10000px;
  left: -10000px;
  margin-top: -6px;
  opacity: 0;
  background-color: var(--grey900);
  border-radius: 4px;
  transition: opacity 0.75s; }

.react-page-plugins-content-slate-inline-toolbar--hidden {
  opacity: 0 !important;
  pointer-events: none; }

.react-page-plugins-content-image {
  width: 100%; }

.react-page-plugins-content-image-placeholder {
  position: relative;
  width: 100%;
  text-align: center; }

.react-page-plugins-content-spacer {
  border-bottom: none;
  position: relative; }

.react-page-editable .react-page-plugins-content-spacer {
  outline: 1px dashed var(--minBlack); }

.react-page-editable-mode-preview .react-page-plugins-content-spacer {
  outline: none; }

.react-page-plugins-content-spacer
> .react-resizable
> .react-resizable-handle:before,
.react-page-plugins-content-spacer
> .react-resizable
> .react-resizable-handle:hover:before {
  content: ' ';
  position: absolute;
  text-align: center;
  width: 100%;
  bottom: 0;
  right: 0;
  cursor: n-resize;
  line-height: 12px;
  font-size: 1.5em;
  height: 24px; }

.react-page-plugins-content-divider {
  background-color: #eee;
  width: 100%;
  height: 2px;
  border-color: #eee; }

.react-page-plugins-layout-background {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  padding: 12px;
  position: relative; }

.react-page-plugins-layout-background > .react-page-row {
  position: relative; }

.react-page-plugins-layout-background__backstretch {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

.opportunity-log {
  padding-left: 20px; }
  .opportunity-log .box {
    height: calc(100vh - 231px);
    padding: 0px; }
    .opportunity-log .box .button-container {
      padding: 20px; }
      .opportunity-log .box .button-container button {
        margin-left: auto;
        display: block;
        height: 30px; }
        .opportunity-log .box .button-container button.primary-medium-1 {
          width: 100%;
          height: 50px; }
  .opportunity-log thead th {
    background-color: white !important;
    padding: 11px 16px !important; }
  .opportunity-log thead tr {
    box-shadow: none; }
  .opportunity-log .table-status {
    display: flex;
    align-items: center; }
    .opportunity-log .table-status p {
      margin: 0px; }
    .opportunity-log .table-status div {
      margin-right: 5px;
      width: 14px;
      height: 14px;
      border-radius: 50%; }
    .opportunity-log .table-status.Closed div {
      background-color: #52C41A; }
    .opportunity-log .table-status.Open div {
      background-color: #FFC100; }
    .opportunity-log .table-status.Lost div {
      background-color: #FB4953; }
  .opportunity-log .styled-table tbody tr td:nth-child(2) {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px; }
  .opportunity-log .styled-table tbody tr td:nth-last-child(2) {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px; }
  .opportunity-log .lead-mobile {
    margin: 10px 20px;
    display: flex;
    width: calc(100% - 40px);
    align-items: center;
    border-radius: 8px;
    height: 80px;
    padding: 15px;
    align-items: center; }
    .opportunity-log .lead-mobile:nth-of-type(even) {
      background-color: #F5F5F5; }
    .opportunity-log .lead-mobile div:first-of-type {
      height: 100%; }
    .opportunity-log .lead-mobile div p:first-of-type {
      font-size: 16px;
      color: #505157;
      margin: 0px; }
    .opportunity-log .lead-mobile div p:last-of-type {
      font-size: 14px;
      font-weight: 300;
      margin: 0px; }
    .opportunity-log .lead-mobile div:nth-of-type(2) {
      margin-left: auto; }
    .opportunity-log .lead-mobile > span {
      margin-left: 20px; }
    .opportunity-log .lead-mobile .status {
      display: flex;
      align-items: center; }
      .opportunity-log .lead-mobile .status div {
        height: 14px;
        width: 14px;
        border-radius: 50%;
        margin-right: 0px;
        margin-left: 10px; }
      .opportunity-log .lead-mobile .status.Open div {
        background-color: #FFC100; }
      .opportunity-log .lead-mobile .status.Closed div {
        background-color: #52C41A; }
      .opportunity-log .lead-mobile .status.Lost div {
        background-color: #FB4953; }
    .opportunity-log .lead-mobile .profit {
      text-align: right;
      font-weight: 500; }
      .opportunity-log .lead-mobile .profit span {
        font-weight: 300; }

@media (max-width: 768px) {
  .opportunity-log {
    padding-left: 0px; } }

#signin {
  padding: 30px 93px;
  min-height: 100vh;
  margin: 0px;
  background-repeat: no-repeat !important;
  background-size: contain !important;
  border-radius: 0px !important;
  background-color: white !important;
  background-position: top right !important; }
  #signin > img {
    margin-bottom: 20px; }
  #signin .head img {
    width: 200px;
    margin-top: 40px; }
  @media (max-width: 768px) {
    #signin {
      padding: 20px;
      background-position: bottom !important;
      background-size: contain !important; }
      #signin .head {
        display: flex;
        justify-content: space-between;
        margin-bottom: 40px; }
        #signin .head img {
          width: 142px;
          height: 100%; }
        #signin .head .ant-select {
          font-size: 16px;
          margin-top: 4px; } }

#product-preview {
  width: 500px;
  position: absolute;
  right: -520px; }
  #product-preview > * {
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.08), 0 1px 18px 0 rgba(0, 0, 0, 0.1), 0 3px 5px 0 rgba(0, 0, 0, 0.1);
    border-radius: 8px; }
  #product-preview p {
    margin-bottom: 0px;
    white-space: break-spaces; }
  #product-preview .product-head {
    background-color: #BA4A00;
    display: flex;
    height: 52px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    font-size: 16px;
    color: white;
    padding: 20px; }
    #product-preview .product-head .bold {
      font-weight: 500; }
  #product-preview .previewImgDiv {
    position: relative;
    margin-bottom: 20px;
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.08), 0 1px 18px 0 rgba(0, 0, 0, 0.1), 0 3px 5px 0 rgba(0, 0, 0, 0.1); }
  #product-preview .closeBtn {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    padding: 5px; }
  #product-preview img {
    width: 100%;
    height: auto;
    border-radius: 8px; }
  #product-preview .description-container {
    padding: 20px;
    background-color: white;
    margin-bottom: 20px; }

@media (max-width: 768px) {
  #product-preview {
    width: 100%;
    right: 0px;
    top: 60px;
    padding: 20px; }
    #product-preview .product-head {
      display: block;
      height: unset; } }

.contact-form {
  margin-top: 30px; }
  .contact-form .terms {
    margin-top: 40px;
    font-weight: 300;
    color: #505157;
    text-decoration: underline;
    cursor: pointer; }
  .contact-form .ant-form-item:last-of-type {
    border: 2px solid #BA4A00;
    background-color: #EADBDD;
    border-radius: 4px;
    padding: 20px; }
    .contact-form .ant-form-item:last-of-type .ant-checkbox-wrapper {
      margin-top: 0px; }
    .contact-form .ant-form-item:last-of-type .ant-form-item-label {
      display: none; }
    .contact-form .ant-form-item:last-of-type .ant-form-item-explain {
      margin-bottom: 0px; }

.payment label.bold {
  margin-top: 20px;
  font-size: 16px;
  font-weight: 500;
  color: black; }

.payment label.normal-label {
  color: #505157;
  font-weight: 400;
  margin-top: 30px; }

.payment .explanation {
  margin-top: 60px;
  margin-bottom: 30px; }

.payment .ant-collapse-header {
  font-size: 16px;
  display: table; }
  .payment .ant-collapse-header .anticon-right {
    top: 19px !important; }

.payment .ant-collapse {
  margin-bottom: 40px; }

.payment .ant-collapse .option-header {
  background-color: #F5F5F5 !important; }
  .payment .ant-collapse .option-header.active {
    background-color: #F8E6D0 !important; }

.payment .ant-radio-group {
  width: 100%; }
  .payment .ant-radio-group .ant-radio-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    border: 2px solid #F5F5F5;
    background: #F5F5F5;
    border-radius: 4px;
    margin-bottom: 15px;
    border-radius: 4px;
    padding: 20px 25px;
    box-shadow: 1px 3px 4px rgba(131, 86, 86, 0.15); }
    .payment .ant-radio-group .ant-radio-wrapper .ant-radio-inner {
      width: 20px;
      height: 20px;
      border-color: #BA4A00; }
      .payment .ant-radio-group .ant-radio-wrapper .ant-radio-inner:after {
        top: 4px;
        left: 4px;
        width: 10px;
        height: 10px;
        background-color: #BA4A00; }
    .payment .ant-radio-group .ant-radio-wrapper span:nth-of-type(2) {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: flex-end;
      padding-left: 20px; }
      .payment .ant-radio-group .ant-radio-wrapper span:nth-of-type(2) p {
        margin: 0px;
        font-weight: 300; }
        .payment .ant-radio-group .ant-radio-wrapper span:nth-of-type(2) p.bolder {
          font-weight: 400; }
      .payment .ant-radio-group .ant-radio-wrapper span:nth-of-type(2) div:nth-of-type(2) p {
        text-align: right; }
    .payment .ant-radio-group .ant-radio-wrapper.ant-radio-wrapper-checked {
      background: #EADBDD;
      border: 2px solid #BA4A00; }

.payment .payment-options .option-header {
  color: #1B1B1B;
  cursor: pointer;
  line-height: initial;
  font-size: 14px;
  display: flex;
  padding: 10px 10px 10px 61px;
  align-items: center;
  border-radius: 4px 4px 0px 0px;
  background-color: #F8E6D0;
  border-bottom: none !important;
  box-shadow: 1px 3px 4px rgba(131, 86, 86, 0.15); }
  .payment .payment-options .option-header.active {
    border: 2px solid #BA4A00; }
  .payment .payment-options .option-header p {
    margin-bottom: 0px;
    margin-left: 10px; }

.payment .payment-options .ant-radio-wrapper {
  border-radius: 0px 0px 4px 4px;
  border-top: none !important; }
  .payment .payment-options .ant-radio-wrapper span:nth-of-type(2) {
    display: block; }
    .payment .payment-options .ant-radio-wrapper span:nth-of-type(2) p {
      text-align: left !important;
      white-space: break-spaces; }
      .payment .payment-options .ant-radio-wrapper span:nth-of-type(2) p.bolder {
        margin-top: 20px; }

.payment .summary {
  border-radius: 4px;
  padding: 20px;
  box-shadow: 1px 3px 4px rgba(131, 86, 86, 0.15);
  background-color: #F5F5F5;
  margin-bottom: 10px; }
  .payment .summary p {
    margin-bottom: 0px; }
    .payment .summary p.bolder {
      font-weight: 400; }

.payment .pay-btn {
  width: calc(100% + 40px);
  margin-left: -20px;
  position: relative;
  top: 20px;
  height: 60px;
  color: white; }

.payment .contact-details {
  width: 300px;
  margin: 0 auto; }

@media screen and (max-width: 768px) {
  .payment .ant-radio-group .ant-radio-wrapper span:nth-of-type(2) {
    display: block; }
    .payment .ant-radio-group .ant-radio-wrapper span:nth-of-type(2) p {
      text-align: left !important;
      margin-bottom: 5px;
      white-space: break-spaces; }
  .payment .pay-btn {
    top: unset;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    border-radius: 0px;
    margin-left: 0px; } }

.screen {
  height: 100vh;
  width: 100vw;
  background: #EAEDEE; }
  .screen .payment-result {
    width: 620px;
    margin: 0 auto;
    display: block;
    margin-top: 100px; }
    .screen .payment-result .head {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .screen .payment-result .head img {
        width: 140px; }
      .screen .payment-result .head .ant-select-selector {
        padding-left: 0px !important;
        border: none !important;
        font-size: 16px;
        background-color: transparent !important; }
      .screen .payment-result .head .ant-select-arrow {
        color: #505157;
        top: 48%; }
    .screen .payment-result .container {
      margin-top: 20px;
      background-color: white;
      box-shadow: 1px 3px 4px rgba(131, 86, 86, 0.15);
      padding: 20px 60px; }
      .screen .payment-result .container .icon {
        width: 43px;
        height: 43px;
        border-radius: 50%;
        background: #F5DDD9;
        margin: 0 auto;
        display: block;
        display: flex;
        align-items: center;
        justify-content: center; }
        .screen .payment-result .container .icon i {
          color: #BA4A00; }
      .screen .payment-result .container h1 {
        color: #BA4A00;
        font-weight: 400;
        font-size: 20px;
        text-align: center;
        margin-top: 20px; }
      .screen .payment-result .container p {
        color: #8B8D96;
        font-weight: 300;
        text-align: center; }
        .screen .payment-result .container p.bolder {
          font-weight: 400; }
      .screen .payment-result .container .try-again {
        width: 390px;
        height: 45px;
        color: white;
        margin: 0 auto;
        display: block; }
    .screen .payment-result .contact {
      color: white;
      margin-top: 40px;
      position: unset; }
      .screen .payment-result .contact .row {
        display: flex;
        width: 393px;
        margin: 0 auto; }
        .screen .payment-result .contact .row .phone {
          cursor: pointer;
          background-color: #505157;
          padding: 8px 12px;
          border-radius: 4px;
          width: 268px; }
          .screen .payment-result .contact .row .phone span {
            margin-right: 50px; }
        .screen .payment-result .contact .row .whatsapp {
          cursor: pointer;
          margin-left: 20px;
          border-radius: 4px;
          background-color: #23CD64;
          width: 104px;
          text-align: center; }
          .screen .payment-result .contact .row .whatsapp svg {
            font-size: 24px;
            margin-top: 7px; }
      .screen .payment-result .contact .email {
        cursor: pointer;
        margin: 0 auto;
        background: #1890FF;
        height: 38px;
        padding: 8px 12px;
        text-align: center;
        margin-top: 20px;
        width: 393px;
        border-radius: 4px;
        text-align: center;
        display: block; }
  @media (max-width: 768px) {
    .screen .payment-result {
      width: 100%;
      padding: 20px;
      margin-top: 0px; }
      .screen .payment-result .try-again {
        position: fixed;
        bottom: 0px;
        left: 0px;
        width: 100% !important;
        border-radius: 0px; }
      .screen .payment-result .container {
        padding: 20px !important; }
      .screen .payment-result .contact .email {
        width: 100%; }
      .screen .payment-result .contact .row {
        width: 100%; }
        .screen .payment-result .contact .row .phone {
          width: unset;
          flex: 2; }
        .screen .payment-result .contact .row .whatsapp {
          width: unset;
          flex: 1; } }

.invite-reseller h1 {
  font-weight: 600;
  font-size: 16px; }

.invite-reseller .ant-modal-content {
  border-radius: 20px;
  overflow: hidden; }
  .invite-reseller .ant-modal-content form {
    width: 295px;
    margin: 0 auto; }
  .invite-reseller .ant-modal-content p {
    margin-bottom: 30px; }
  .invite-reseller .ant-modal-content .primary-medium-2 {
    height: 82px;
    position: relative;
    left: -72px;
    bottom: -24px;
    width: 440px;
    font-size: 24px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px; }

.invite-reseller .ant-drawer-body .primary-medium-2 {
  width: 100%;
  height: 50px;
  color: white; }

.invite-reseller .ant-drawer-body p, .invite-reseller .ant-drawer-body h1 {
  padding: 0px 20px; }

.invite-reseller .ant-drawer-body p {
  margin-bottom: 30px; }

.commercial-page {
  background-color: white; }
  .commercial-page header {
    height: 56px;
    display: flex;
    padding: 0px 30px;
    justify-content: space-between;
    align-items: center; }
    .commercial-page header .logo {
      display: flex;
      align-items: center; }
      .commercial-page header .logo p {
        margin: 0px 0px 0px 20px;
        font-weight: 600;
        font-size: 17px;
        color: #BA4A00; }
    .commercial-page header .ant-select-selector {
      border: none !important; }
      .commercial-page header .ant-select-selector .ant-select-selection-item {
        color: #8B8D96;
        text-transform: uppercase;
        font-weight: 600; }
  .commercial-page .content {
    margin: 0px 20px;
    border-radius: 12px 12px 0px 0px;
    background-color: #EAEDEE; }
    .commercial-page .content p {
      white-space: break-spaces; }
    .commercial-page .content .inner-content {
      padding: 40px 20px;
      max-width: 1137px;
      margin: 0 auto; }
      .commercial-page .content .inner-content .ant-col img {
        max-width: 100%;
        height: auto;
        filter: drop-shadow(0px 6px 10px rgba(0, 0, 0, 0.08)) drop-shadow(0px 1px 18px rgba(0, 0, 0, 0.1)) drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.101535));
        border-radius: 8px; }
      .commercial-page .content .inner-content .ant-col h5 {
        color: #505157;
        font-size: 20px;
        margin-top: 10px; }
      .commercial-page .content .inner-content .ant-col h1 {
        color: #BA4A00;
        font-size: 48px;
        font-weight: 600; }
      .commercial-page .content .inner-content .ant-col button {
        height: 60px;
        width: 263px;
        color: white;
        font-size: 24px; }
      .commercial-page .content .inner-content .ant-col .description {
        margin: 20px 0px;
        background: #FFFFFF;
        box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.08), 0px 1px 18px rgba(0, 0, 0, 0.1), 0px 3px 5px rgba(0, 0, 0, 0.101535);
        border-radius: 8px;
        padding: 20px; }
        .commercial-page .content .inner-content .ant-col .description * {
          color: #505157; }
        .commercial-page .content .inner-content .ant-col .description h4 {
          font-size: 18px; }
      .commercial-page .content .inner-content .share-buttons {
        display: table;
        margin-top: 20px; }
        .commercial-page .content .inner-content .share-buttons div {
          color: white;
          border-radius: 4px;
          margin-bottom: 10px;
          padding: 4px 8px;
          cursor: pointer; }
          .commercial-page .content .inner-content .share-buttons div.whatsapp {
            background-color: #23CD64; }
          .commercial-page .content .inner-content .share-buttons div.link {
            background-color: #505157; }
    .commercial-page .content .editor-content {
      filter: drop-shadow(0px 6px 10px rgba(0, 0, 0, 0.08)) drop-shadow(0px 1px 18px rgba(0, 0, 0, 0.1)) drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.101535));
      border-radius: 8px;
      background-color: white;
      overflow: hidden; }

:root {
  --black: #000000;
  --white: #ffffff;
  --transparent: rgba(0, 0, 0, 0);
  --fullBlack: rgba(0, 0, 0, 1);
  --darkBlack: rgba(0, 0, 0, 0.87);
  --lightBlack: rgba(0, 0, 0, 0.54);
  --minBlack: rgba(0, 0, 0, 0.26);
  --faintBlack: rgba(0, 0, 0, 0.12);
  --fullWhite: rgba(255, 255, 255, 1);
  --darkWhite: rgba(255, 255, 255, 0.87);
  --lightWhite: rgba(255, 255, 255, 0.54);
  --minWhite: rgba(255, 255, 255, 0.26);
  --faintWhite: rgba(255, 255, 255, 0.12);
  --grey300: #e0e0e0;
  --grey900: #212121; }

/* based on: https://github.com/kristoferjoseph/flexboxgrid/blob/master/src/css/flexboxgrid.css */
:root {
  --gutter-width: 0rem;
  --outer-margin: 0rem;
  --gutter-compensation: calc((var(--gutter-width) * 0.5) * -1);
  --half-gutter-width: calc((var(--gutter-width) * 0.5));
  --xs-min: 30;
  --sm-min: 48;
  --md-min: 64;
  --lg-min: 75;
  --screen-xs-min: var(--xs-min) em;
  --screen-sm-min: var(--sm-min) em;
  --screen-md-min: var(--md-min) em;
  --screen-lg-min: var(--lg-min) em;
  --container-sm: calc(var(--sm-min) + var(--gutter-width));
  --container-md: calc(var(--md-min) + var(--gutter-width));
  --container-lg: calc(var(--lg-min) + var(--gutter-width)); }

.react-page-editable {
  margin-right: auto;
  margin-left: auto;
  padding-right: 0rem;
  padding-right: var(--outer-margin, 2rem);
  padding-left: 0rem;
  padding-left: var(--outer-margin, 2rem); }

.react-page-row {
  box-sizing: border-box;
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: calc((0rem * 0.5) * -1);
  margin-right: var(--gutter-compensation, -0.5rem);
  margin-left: calc((0rem * 0.5) * -1);
  margin-left: var(--gutter-compensation, -0.5rem);
  position: relative; }

.react-page-cell-xs,
.react-page-cell-xs-1,
.react-page-cell-xs-2,
.react-page-cell-xs-3,
.react-page-cell-xs-4,
.react-page-cell-xs-5,
.react-page-cell-xs-6,
.react-page-cell-xs-7,
.react-page-cell-xs-8,
.react-page-cell-xs-9,
.react-page-cell-xs-10,
.react-page-cell-xs-11,
.react-page-cell-xs-12,
.react-page-cell-xs-offset-0,
.react-page-cell-xs-offset-1,
.react-page-cell-xs-offset-2,
.react-page-cell-xs-offset-3,
.react-page-cell-xs-offset-4,
.react-page-cell-xs-offset-5,
.react-page-cell-xs-offset-6,
.react-page-cell-xs-offset-7,
.react-page-cell-xs-offset-8,
.react-page-cell-xs-offset-9,
.react-page-cell-xs-offset-10,
.react-page-cell-xs-offset-11,
.react-page-cell-xs-offset-12 {
  box-sizing: border-box;
  flex: 0 0 auto;
  padding-right: calc((0rem * 0.5));
  padding-right: var(--half-gutter-width, 0.5rem);
  padding-left: calc((0rem * 0.5));
  padding-left: var(--half-gutter-width, 0.5rem);
  min-width: 0; }

.react-page-cell-xs {
  flex-grow: 1;
  flex-basis: 0;
  width: 100%;
  min-width: 100%; }

.react-page-cell-xs-1 {
  flex-basis: 8.33333333%;
  width: 8.33333333%; }

.react-page-cell-xs-2 {
  flex-basis: 16.66666667%;
  width: 16.66666667%; }

.react-page-cell-xs-3 {
  flex-basis: 25%;
  width: 25%;
  min-width: 25%; }

.react-page-cell-xs-4 {
  flex-basis: 33.33333333%;
  width: 33.33333333%; }

.react-page-cell-xs-5 {
  flex-basis: 41.66666667%;
  width: 41.66666667%; }

.react-page-cell-xs-6 {
  flex-basis: 50%;
  width: 50%;
  min-width: 50%; }

.react-page-cell-xs-7 {
  flex-basis: 58.33333333%;
  width: 58.33333333%; }

.react-page-cell-xs-8 {
  flex-basis: 66.66666667%;
  width: 66.66666667%; }

.react-page-cell-xs-9 {
  flex-basis: 75%;
  width: 75%;
  min-width: 75%; }

.react-page-cell-xs-10 {
  flex-basis: 83.33333333%;
  width: 83.33333333%; }

.react-page-cell-xs-11 {
  flex-basis: 91.66666667%;
  width: 91.66666667%; }

.react-page-cell-xs-12 {
  flex-basis: 100%;
  width: 100%;
  min-width: 100%; }

.react-page-cell-xs-offset-0 {
  margin-left: 0; }

.react-page-cell-xs-offset-1 {
  margin-left: 8.33333333%; }

.react-page-cell-xs-offset-2 {
  margin-left: 16.66666667%; }

.react-page-cell-xs-offset-3 {
  margin-left: 25%; }

.react-page-cell-xs-offset-4 {
  margin-left: 33.33333333%; }

.react-page-cell-xs-offset-5 {
  margin-left: 41.66666667%; }

.react-page-cell-xs-offset-6 {
  margin-left: 50%; }

.react-page-cell-xs-offset-7 {
  margin-left: 58.33333333%; }

.react-page-cell-xs-offset-8 {
  margin-left: 66.66666667%; }

.react-page-cell-xs-offset-9 {
  margin-left: 75%; }

.react-page-cell-xs-offset-10 {
  margin-left: 83.33333333%; }

.react-page-cell-xs-offset-11 {
  margin-left: 91.66666667%; }

[dir="ltr"] .start-xs {
  text-align: left; }

[dir="rtl"] .start-xs {
  text-align: right; }

.start-xs {
  justify-content: flex-start; }

.center-xs {
  justify-content: center;
  text-align: center; }

[dir="ltr"] .end-xs {
  text-align: right; }

[dir="rtl"] .end-xs {
  text-align: left; }

.end-xs {
  justify-content: flex-end; }

.top-xs {
  align-items: flex-start; }

.middle-xs {
  align-items: center; }

.bottom-xs {
  align-items: flex-end; }

.around-xs {
  justify-content: space-around; }

.between-xs {
  justify-content: space-between; }

.first-xs {
  order: -1; }

.last-xs {
  order: 1; }

@media only screen and (min-width: 48em) {
  .react-page-cell-sm,
  .react-page-cell-sm-1,
  .react-page-cell-sm-2,
  .react-page-cell-sm-3,
  .react-page-cell-sm-4,
  .react-page-cell-sm-5,
  .react-page-cell-sm-6,
  .react-page-cell-sm-7,
  .react-page-cell-sm-8,
  .react-page-cell-sm-9,
  .react-page-cell-sm-10,
  .react-page-cell-sm-11,
  .react-page-cell-sm-12,
  .react-page-cell-sm-offset-0,
  .react-page-cell-sm-offset-1,
  .react-page-cell-sm-offset-2,
  .react-page-cell-sm-offset-3,
  .react-page-cell-sm-offset-4,
  .react-page-cell-sm-offset-5,
  .react-page-cell-sm-offset-6,
  .react-page-cell-sm-offset-7,
  .react-page-cell-sm-offset-8,
  .react-page-cell-sm-offset-9,
  .react-page-cell-sm-offset-10,
  .react-page-cell-sm-offset-11,
  .react-page-cell-sm-offset-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: calc((0rem * 0.5));
    padding-right: var(--half-gutter-width, 0.5rem);
    padding-left: calc((0rem * 0.5));
    padding-left: var(--half-gutter-width, 0.5rem);
    min-width: 0; }
  .react-page-cell-sm {
    flex-grow: 1;
    flex-basis: 0;
    width: 100%;
    min-width: 100%; }
  .react-page-cell-sm-1 {
    flex-basis: 8.33333333%;
    width: 8.33333333%; }
  .react-page-cell-sm-2 {
    flex-basis: 16.66666667%;
    width: 16.66666667%; }
  .react-page-cell-sm-3 {
    flex-basis: 25%;
    width: 25%;
    min-width: 25%; }
  .react-page-cell-sm-4 {
    flex-basis: 33.33333333%;
    width: 33.33333333%; }
  .react-page-cell-sm-5 {
    flex-basis: 41.66666667%;
    width: 41.66666667%; }
  .react-page-cell-sm-6 {
    flex-basis: 50%;
    width: 50%;
    min-width: 50%; }
  .react-page-cell-sm-7 {
    flex-basis: 58.33333333%;
    width: 58.33333333%; }
  .react-page-cell-sm-8 {
    flex-basis: 66.66666667%;
    width: 66.66666667%; }
  .react-page-cell-sm-9 {
    flex-basis: 75%;
    width: 75%;
    min-width: 75%; }
  .react-page-cell-sm-10 {
    flex-basis: 83.33333333%;
    width: 83.33333333%; }
  .react-page-cell-sm-11 {
    flex-basis: 91.66666667%;
    width: 91.66666667%; }
  .react-page-cell-sm-12 {
    flex-basis: 100%;
    width: 100%;
    min-width: 100%; }
  .react-page-cell-sm-offset-0 {
    margin-left: 0; }
  .react-page-cell-sm-offset-1 {
    margin-left: 8.33333333%; }
  .react-page-cell-sm-offset-2 {
    margin-left: 16.66666667%; }
  .react-page-cell-sm-offset-3 {
    margin-left: 25%; }
  .react-page-cell-sm-offset-4 {
    margin-left: 33.33333333%; }
  .react-page-cell-sm-offset-5 {
    margin-left: 41.66666667%; }
  .react-page-cell-sm-offset-6 {
    margin-left: 50%; }
  .react-page-cell-sm-offset-7 {
    margin-left: 58.33333333%; }
  .react-page-cell-sm-offset-8 {
    margin-left: 66.66666667%; }
  .react-page-cell-sm-offset-9 {
    margin-left: 75%; }
  .react-page-cell-sm-offset-10 {
    margin-left: 83.33333333%; }
  .react-page-cell-sm-offset-11 {
    margin-left: 91.66666667%; }
  [dir="ltr"] .start-sm {
    text-align: left; }
  [dir="rtl"] .start-sm {
    text-align: right; }
  .start-sm {
    justify-content: flex-start; }
  .center-sm {
    justify-content: center;
    text-align: center; }
  [dir="ltr"] .end-sm {
    text-align: right; }
  [dir="rtl"] .end-sm {
    text-align: left; }
  .end-sm {
    justify-content: flex-end; }
  .top-sm {
    align-items: flex-start; }
  .middle-sm {
    align-items: center; }
  .bottom-sm {
    align-items: flex-end; }
  .around-sm {
    justify-content: space-around; }
  .between-sm {
    justify-content: space-between; }
  .first-sm {
    order: -1; }
  .last-sm {
    order: 1; } }

@media only screen and (min-width: 64em) {
  .react-page-cell-md,
  .react-page-cell-md-1,
  .react-page-cell-md-2,
  .react-page-cell-md-3,
  .react-page-cell-md-4,
  .react-page-cell-md-5,
  .react-page-cell-md-6,
  .react-page-cell-md-7,
  .react-page-cell-md-8,
  .react-page-cell-md-9,
  .react-page-cell-md-10,
  .react-page-cell-md-11,
  .react-page-cell-md-12,
  .react-page-cell-md-offset-0,
  .react-page-cell-md-offset-1,
  .react-page-cell-md-offset-2,
  .react-page-cell-md-offset-3,
  .react-page-cell-md-offset-4,
  .react-page-cell-md-offset-5,
  .react-page-cell-md-offset-6,
  .react-page-cell-md-offset-7,
  .react-page-cell-md-offset-8,
  .react-page-cell-md-offset-9,
  .react-page-cell-md-offset-10,
  .react-page-cell-md-offset-11,
  .react-page-cell-md-offset-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: calc((0rem * 0.5));
    padding-right: var(--half-gutter-width, 0.5rem);
    padding-left: calc((0rem * 0.5));
    padding-left: var(--half-gutter-width, 0.5rem);
    min-width: 0; }
  .react-page-cell-md {
    flex-grow: 1;
    flex-basis: 0;
    width: 100%;
    min-width: 100%; }
  .react-page-cell-md-1 {
    flex-basis: 8.33333333%;
    width: 8.33333333%; }
  .react-page-cell-md-2 {
    flex-basis: 16.66666667%;
    width: 16.66666667%; }
  .react-page-cell-md-3 {
    flex-basis: 25%;
    width: 25%;
    min-width: 25%; }
  .react-page-cell-md-4 {
    flex-basis: 33.33333333%;
    width: 33.33333333%; }
  .react-page-cell-md-5 {
    flex-basis: 41.66666667%;
    width: 41.66666667%; }
  .react-page-cell-md-6 {
    flex-basis: 50%;
    width: 50%;
    min-width: 50%; }
  .react-page-cell-md-7 {
    flex-basis: 58.33333333%;
    width: 58.33333333%; }
  .react-page-cell-md-8 {
    flex-basis: 66.66666667%;
    width: 66.66666667%; }
  .react-page-cell-md-9 {
    flex-basis: 75%;
    width: 75%;
    min-width: 75%; }
  .react-page-cell-md-10 {
    flex-basis: 83.33333333%;
    width: 83.33333333%; }
  .react-page-cell-md-11 {
    flex-basis: 91.66666667%;
    width: 91.66666667%; }
  .react-page-cell-md-12 {
    flex-basis: 100%;
    width: 100%;
    min-width: 100%; }
  .react-page-cell-md-offset-0 {
    margin-left: 0; }
  .react-page-cell-md-offset-1 {
    margin-left: 8.33333333%; }
  .react-page-cell-md-offset-2 {
    margin-left: 16.66666667%; }
  .react-page-cell-md-offset-3 {
    margin-left: 25%; }
  .react-page-cell-md-offset-4 {
    margin-left: 33.33333333%; }
  .react-page-cell-md-offset-5 {
    margin-left: 41.66666667%; }
  .react-page-cell-md-offset-6 {
    margin-left: 50%; }
  .react-page-cell-md-offset-7 {
    margin-left: 58.33333333%; }
  .react-page-cell-md-offset-8 {
    margin-left: 66.66666667%; }
  .react-page-cell-md-offset-9 {
    margin-left: 75%; }
  .react-page-cell-md-offset-10 {
    margin-left: 83.33333333%; }
  .react-page-cell-md-offset-11 {
    margin-left: 91.66666667%; }
  [dir="ltr"] .start-md {
    text-align: left; }
  [dir="rtl"] .start-md {
    text-align: right; }
  .start-md {
    justify-content: flex-start; }
  .center-md {
    justify-content: center;
    text-align: center; }
  [dir="ltr"] .end-md {
    text-align: right; }
  [dir="rtl"] .end-md {
    text-align: left; }
  .end-md {
    justify-content: flex-end; }
  .top-md {
    align-items: flex-start; }
  .middle-md {
    align-items: center; }
  .bottom-md {
    align-items: flex-end; }
  .around-md {
    justify-content: space-around; }
  .between-md {
    justify-content: space-between; }
  .first-md {
    order: -1; }
  .last-md {
    order: 1; } }

@media only screen and (min-width: 75em) {
  .react-page-cell-lg,
  .react-page-cell-lg-1,
  .react-page-cell-lg-2,
  .react-page-cell-lg-3,
  .react-page-cell-lg-4,
  .react-page-cell-lg-5,
  .react-page-cell-lg-6,
  .react-page-cell-lg-7,
  .react-page-cell-lg-8,
  .react-page-cell-lg-9,
  .react-page-cell-lg-10,
  .react-page-cell-lg-11,
  .react-page-cell-lg-12,
  .react-page-cell-lg-offset-0,
  .react-page-cell-lg-offset-1,
  .react-page-cell-lg-offset-2,
  .react-page-cell-lg-offset-3,
  .react-page-cell-lg-offset-4,
  .react-page-cell-lg-offset-5,
  .react-page-cell-lg-offset-6,
  .react-page-cell-lg-offset-7,
  .react-page-cell-lg-offset-8,
  .react-page-cell-lg-offset-9,
  .react-page-cell-lg-offset-10,
  .react-page-cell-lg-offset-11,
  .react-page-cell-lg-offset-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: calc((0rem * 0.5));
    padding-right: var(--half-gutter-width, 0.5rem);
    padding-left: calc((0rem * 0.5));
    padding-left: var(--half-gutter-width, 0.5rem);
    min-width: 0; }
  .react-page-cell-lg {
    flex-grow: 1;
    flex-basis: 0;
    width: 100%;
    min-width: 100%; }
  .react-page-cell-lg-1 {
    flex-basis: 8.33333333%;
    width: 8.33333333%; }
  .react-page-cell-lg-2 {
    flex-basis: 16.66666667%;
    width: 16.66666667%; }
  .react-page-cell-lg-3 {
    flex-basis: 25%;
    width: 25%;
    min-width: 25%; }
  .react-page-cell-lg-4 {
    flex-basis: 33.33333333%;
    width: 33.33333333%; }
  .react-page-cell-lg-5 {
    flex-basis: 41.66666667%;
    width: 41.66666667%; }
  .react-page-cell-lg-6 {
    flex-basis: 50%;
    width: 50%;
    min-width: 50%; }
  .react-page-cell-lg-7 {
    flex-basis: 58.33333333%;
    width: 58.33333333%; }
  .react-page-cell-lg-8 {
    flex-basis: 66.66666667%;
    width: 66.66666667%; }
  .react-page-cell-lg-9 {
    flex-basis: 75%;
    width: 75%;
    min-width: 75%; }
  .react-page-cell-lg-10 {
    flex-basis: 83.33333333%;
    width: 83.33333333%; }
  .react-page-cell-lg-11 {
    flex-basis: 91.66666667%;
    width: 91.66666667%; }
  .react-page-cell-lg-12 {
    flex-basis: 100%;
    width: 100%;
    min-width: 100%; }
  .react-page-cell-lg-offset-0 {
    margin-left: 0; }
  .react-page-cell-lg-offset-1 {
    margin-left: 8.33333333%; }
  .react-page-cell-lg-offset-2 {
    margin-left: 16.66666667%; }
  .react-page-cell-lg-offset-3 {
    margin-left: 25%; }
  .react-page-cell-lg-offset-4 {
    margin-left: 33.33333333%; }
  .react-page-cell-lg-offset-5 {
    margin-left: 41.66666667%; }
  .react-page-cell-lg-offset-6 {
    margin-left: 50%; }
  .react-page-cell-lg-offset-7 {
    margin-left: 58.33333333%; }
  .react-page-cell-lg-offset-8 {
    margin-left: 66.66666667%; }
  .react-page-cell-lg-offset-9 {
    margin-left: 75%; }
  .react-page-cell-lg-offset-10 {
    margin-left: 83.33333333%; }
  .react-page-cell-lg-offset-11 {
    margin-left: 91.66666667%; }
  [dir="ltr"] .start-lg {
    text-align: left; }
  [dir="rtl"] .start-lg {
    text-align: right; }
  .start-lg {
    justify-content: flex-start; }
  .center-lg {
    justify-content: center;
    text-align: center; }
  [dir="ltr"] .end-lg {
    text-align: right; }
  [dir="rtl"] .end-lg {
    text-align: left; }
  .end-lg {
    justify-content: flex-end; }
  .top-lg {
    align-items: flex-start; }
  .middle-lg {
    align-items: center; }
  .bottom-lg {
    align-items: flex-end; }
  .around-lg {
    justify-content: space-around; }
  .between-lg {
    justify-content: space-between; }
  .first-lg {
    order: -1; }
  .last-lg {
    order: 1; } }

.react-page-row-has-floating-children {
  display: block !important; }

.react-page-row-is-hovering-this:before {
  content: ' ';
  position: absolute;
  background: black;
  display: block;
  background: rgba(0, 0, 0, 0.87);
  background: var(--darkBlack);
  z-index: 2; }

.react-page-row-is-hovering-above:before {
  top: 0;
  height: 4px;
  width: 100%; }

.react-page-row-is-hovering-below:before {
  bottom: 0;
  height: 4px;
  width: 100%; }

.react-page-row-is-hovering-left-of:before {
  top: 0;
  left: 0;
  bottom: 0;
  width: 4px; }

.react-page-row-is-hovering-right-of:before {
  top: 0;
  right: 0;
  bottom: 0;
  width: 4px; }

@-webkit-keyframes reactPageCellResizeableAnimate {
  0% {
    background-color: rgba(255, 255, 255, 0.26);
    background-color: var(--minWhite);
    top: 45%;
    bottom: 45%; }
  50% {
    background-color: rgba(255, 255, 255, 0.12);
    background-color: var(--faintWhite);
    top: 0;
    bottom: 0; }
  100% {
    background-color: rgba(255, 255, 255, 0.87);
    background-color: var(--darkWhite);
    top: 45%;
    bottom: 45%; } }

@keyframes reactPageCellResizeableAnimate {
  0% {
    background-color: rgba(255, 255, 255, 0.26);
    background-color: var(--minWhite);
    top: 45%;
    bottom: 45%; }
  50% {
    background-color: rgba(255, 255, 255, 0.12);
    background-color: var(--faintWhite);
    top: 0;
    bottom: 0; }
  100% {
    background-color: rgba(255, 255, 255, 0.87);
    background-color: var(--darkWhite);
    top: 45%;
    bottom: 45%; } }

.react-page-row .resize-handle {
  position: absolute;
  z-index: 6;
  width: 4px;
  top: 0;
  bottom: 0;
  left: -2px;
  cursor: e-resize;
  background-color: rgba(0, 0, 0, 0.54);
  background-color: var(--lightBlack);
  mix-blend-mode: difference; }

.react-page-row .resize-handle:after {
  content: ' ';
  background-color: rgba(255, 255, 255, 0.87);
  background-color: var(--darkWhite);
  /* width: 30px; */
  /* height: 30px; */
  position: absolute;
  /* z-index: 1000; */
  top: 40%;
  bottom: 40%;
  left: 0;
  right: 0;
  transition: all 200ms linear;
  mix-blend-mode: normal;
  -webkit-animation-name: reactPageCellResizeableAnimate;
  animation-name: reactPageCellResizeableAnimate;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s; }

.react-page-row .resize-handle:hover:after,
.react-page-row .resize-handle:active:after {
  background-color: white;
  background-color: var(--fullWhite);
  top: 0;
  bottom: 0; }

.react-page-editable {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  align-items: stretch; }

.react-page-editable .react-page-cell-insert-new {
  cursor: cell;
  flex: 1;
  min-height: 100px;
  transition: all ease 0.4s;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0);
  z-index: 1;
  position: relative; }

.react-page-editable .react-page-cell-insert-new:after {
  content: '+';
  font-size: 52px;
  font-family: monospace;
  line-height: 52px;
  background-color: rgba(0, 0, 0, 0.26);
  background-color: var(--minBlack);
  color: white;
  width: 52px;
  text-align: center;
  border-radius: 50%; }

.react-page-editable .react-page-cell-insert-new:hover,
.react-page-editable .react-page-cell-insert-new.hover {
  background: rgba(0, 0, 0, 0.26);
  background: var(--minBlack);
  border: 1px solid rgba(255, 255, 255, 0.54);
  border: 1px solid var(--lightWhite); }

.react-page-editable.react-page-editable-mode-edit .react-page-cell-draggable, .react-page-editable.react-page-editable-mode-resizing .react-page-cell-draggable, .react-page-editable.react-page-editable-mode-layout .react-page-cell-draggable {
  cursor: move;
  position: relative;
  transition: opacity 200ms ease; }

.react-page-editable.react-page-editable-mode-edit .react-page-cell-draggable-overlay, .react-page-editable.react-page-editable-mode-resizing .react-page-cell-draggable-overlay, .react-page-editable.react-page-editable-mode-layout .react-page-cell-draggable-overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  cursor: move;
  border: 1px solid rgba(0, 0, 0, 0);
  transition: all ease 0.4s; }

.react-page-editable.react-page-editable-mode-edit .react-page-cell-draggable-overlay-handle, .react-page-editable.react-page-editable-mode-resizing .react-page-cell-draggable-overlay-handle, .react-page-editable.react-page-editable-mode-layout .react-page-cell-draggable-overlay-handle {
  position: absolute;
  top: 0;
  right: 0;
  width: 2rem;
  height: 2rem;
  cursor: move;
  background: rgba(0, 0, 0, 0.26);
  background: var(--minBlack);
  content: '';
  z-index: 500; }

.react-page-editable.react-page-editable-mode-edit .react-page-cell-draggable-overlay-handle, .react-page-editable.react-page-editable-mode-resizing .react-page-cell-draggable-overlay-handle, .react-page-editable.react-page-editable-mode-layout .react-page-cell-draggable-overlay-handle {
  opacity: 0;
  transition: opacity 0.3s; }

.react-page-editable.react-page-editable-mode-edit .react-page-cell:hover
> div
> div
> div
> div
> .react-page-cell-draggable-overlay-handle,
.react-page-editable.react-page-editable-mode-edit .react-page-cell.react-page-cell-focused
> div
> div
> div
> div
> .react-page-cell-draggable-overlay-handle,
.react-page-editable.react-page-editable-mode-resizing .react-page-cell:hover
> div
> div
> div
> div
> .react-page-cell-draggable-overlay-handle,
.react-page-editable.react-page-editable-mode-resizing .react-page-cell.react-page-cell-focused
> div
> div
> div
> div
> .react-page-cell-draggable-overlay-handle,
.react-page-editable.react-page-editable-mode-layout .react-page-cell:hover
> div
> div
> div
> div
> .react-page-cell-draggable-overlay-handle,
.react-page-editable.react-page-editable-mode-layout .react-page-cell.react-page-cell-focused
> div
> div
> div
> div
> .react-page-cell-draggable-overlay-handle {
  opacity: 1; }

.react-page-editable.react-page-editable-mode-edit .react-page-cell-draggable-overlay-handle
.react-page-cell-draggable-overlay-handle-icon::before, .react-page-editable.react-page-editable-mode-resizing .react-page-cell-draggable-overlay-handle
.react-page-cell-draggable-overlay-handle-icon::before, .react-page-editable.react-page-editable-mode-layout .react-page-cell-draggable-overlay-handle
.react-page-cell-draggable-overlay-handle-icon::before {
  top: -300%; }

.react-page-editable.react-page-editable-mode-edit .react-page-cell-draggable-overlay-handle
.react-page-cell-draggable-overlay-handle-icon, .react-page-editable.react-page-editable-mode-resizing .react-page-cell-draggable-overlay-handle
.react-page-cell-draggable-overlay-handle-icon, .react-page-editable.react-page-editable-mode-layout .react-page-cell-draggable-overlay-handle
.react-page-cell-draggable-overlay-handle-icon {
  width: 70%;
  top: 50%; }

.react-page-editable.react-page-editable-mode-edit .react-page-cell-draggable-overlay-handle
.react-page-cell-draggable-overlay-handle-icon::after, .react-page-editable.react-page-editable-mode-resizing .react-page-cell-draggable-overlay-handle
.react-page-cell-draggable-overlay-handle-icon::after, .react-page-editable.react-page-editable-mode-layout .react-page-cell-draggable-overlay-handle
.react-page-cell-draggable-overlay-handle-icon::after {
  top: 400%; }

.react-page-editable.react-page-editable-mode-edit .react-page-cell-draggable-overlay-handle
.react-page-cell-draggable-overlay-handle-icon::before,
.react-page-editable.react-page-editable-mode-edit .react-page-cell-draggable-overlay-handle
.react-page-cell-draggable-overlay-handle-icon::after,
.react-page-editable.react-page-editable-mode-resizing .react-page-cell-draggable-overlay-handle
.react-page-cell-draggable-overlay-handle-icon::before,
.react-page-editable.react-page-editable-mode-resizing .react-page-cell-draggable-overlay-handle
.react-page-cell-draggable-overlay-handle-icon::after,
.react-page-editable.react-page-editable-mode-layout .react-page-cell-draggable-overlay-handle
.react-page-cell-draggable-overlay-handle-icon::before,
.react-page-editable.react-page-editable-mode-layout .react-page-cell-draggable-overlay-handle
.react-page-cell-draggable-overlay-handle-icon::after {
  width: 100%; }

.react-page-editable.react-page-editable-mode-edit .react-page-cell-draggable-overlay-handle
.react-page-cell-draggable-overlay-handle-icon,
.react-page-editable.react-page-editable-mode-edit .react-page-cell-draggable-overlay-handle
.react-page-cell-draggable-overlay-handle-icon::before,
.react-page-editable.react-page-editable-mode-edit .react-page-cell-draggable-overlay-handle
.react-page-cell-draggable-overlay-handle-icon::after,
.react-page-editable.react-page-editable-mode-resizing .react-page-cell-draggable-overlay-handle
.react-page-cell-draggable-overlay-handle-icon,
.react-page-editable.react-page-editable-mode-resizing .react-page-cell-draggable-overlay-handle
.react-page-cell-draggable-overlay-handle-icon::before,
.react-page-editable.react-page-editable-mode-resizing .react-page-cell-draggable-overlay-handle
.react-page-cell-draggable-overlay-handle-icon::after,
.react-page-editable.react-page-editable-mode-layout .react-page-cell-draggable-overlay-handle
.react-page-cell-draggable-overlay-handle-icon,
.react-page-editable.react-page-editable-mode-layout .react-page-cell-draggable-overlay-handle
.react-page-cell-draggable-overlay-handle-icon::before,
.react-page-editable.react-page-editable-mode-layout .react-page-cell-draggable-overlay-handle
.react-page-cell-draggable-overlay-handle-icon::after {
  height: 2px;
  position: absolute;
  content: '';
  transform: translate(-50%, -50%);
  left: 50%;
  transform: translate(-50%, -50%);
  background: white; }

.react-page-editable.react-page-editable-mode-edit .react-page-cell:hover
> div
> div
> div
> .react-page-cell-draggable-overlay, .react-page-editable.react-page-editable-mode-resizing .react-page-cell:hover
> div
> div
> div
> .react-page-cell-draggable-overlay, .react-page-editable.react-page-editable-mode-layout .react-page-cell:hover
> div
> div
> div
> .react-page-cell-draggable-overlay {
  background: rgba(0, 0, 0, 0.26);
  background: var(--minBlack);
  border: 1px solid rgba(255, 255, 255, 0.54);
  border: 1px solid var(--lightWhite);
  background-blend-mode: screen; }

.react-page-editable.react-page-editable-mode-edit .react-page-cell-draggable-overlay.react-page-cell-draggable-leaf, .react-page-editable.react-page-editable-mode-resizing .react-page-cell-draggable-overlay.react-page-cell-draggable-leaf, .react-page-editable.react-page-editable-mode-layout .react-page-cell-draggable-overlay.react-page-cell-draggable-leaf {
  z-index: 3; }

.react-page-editable.react-page-editable-mode-edit .react-page-cell-draggable-inline-left.react-page-cell-draggable-overlay,
.react-page-editable.react-page-editable-mode-edit .react-page-cell-draggable-inline-right.react-page-cell-draggable-overlay,
.react-page-editable.react-page-editable-mode-resizing .react-page-cell-draggable-inline-left.react-page-cell-draggable-overlay,
.react-page-editable.react-page-editable-mode-resizing .react-page-cell-draggable-inline-right.react-page-cell-draggable-overlay,
.react-page-editable.react-page-editable-mode-layout .react-page-cell-draggable-inline-left.react-page-cell-draggable-overlay,
.react-page-editable.react-page-editable-mode-layout .react-page-cell-draggable-inline-right.react-page-cell-draggable-overlay {
  float: left;
  z-index: 4; }

.react-page-editable.react-page-editable-mode-edit .react-page-cell-draggable-is-dragging,
.react-page-editable.react-page-editable-mode-edit .react-page-cell-handle-is-dragging + div > .react-page-cell-droppable > .react-page-cell-draggable,
.react-page-editable.react-page-editable-mode-edit .react-page-cell-handle-is-dragging + div > .react-page-cell-droppable > .react-page-cell-draggable-in-edit,
.react-page-editable.react-page-editable-mode-resizing .react-page-cell-draggable-is-dragging,
.react-page-editable.react-page-editable-mode-resizing .react-page-cell-handle-is-dragging + div > .react-page-cell-droppable > .react-page-cell-draggable,
.react-page-editable.react-page-editable-mode-resizing .react-page-cell-handle-is-dragging + div > .react-page-cell-droppable > .react-page-cell-draggable-in-edit,
.react-page-editable.react-page-editable-mode-layout .react-page-cell-draggable-is-dragging,
.react-page-editable.react-page-editable-mode-layout .react-page-cell-handle-is-dragging + div > .react-page-cell-droppable > .react-page-cell-draggable,
.react-page-editable.react-page-editable-mode-layout .react-page-cell-handle-is-dragging + div > .react-page-cell-droppable > .react-page-cell-draggable-in-edit {
  opacity: 0.4;
  outline: none; }

.react-page-cell-droppable {
  /*position: relative;*/ }

.react-page-cell-droppable-is-over-current:after {
  /* extend dropable area a bit, so that its easier to add as last eleent */
  content: '';
  height: 100px;
  width: 100%;
  position: absolute;
  bottom: -100px; }

.react-page-cell-droppable-is-over-current:before {
  content: ' ';
  display: block;
  position: absolute;
  z-index: 1;
  background: rgba(0, 0, 0, 0.87);
  background: var(--darkBlack); }

.react-page-cell-droppable-is-over-above:before,
.react-page-cell-droppable-is-over-below:before {
  border-left: 0;
  border-right: 0; }

.react-page-cell-droppable-is-over-left-of:before,
.react-page-cell-droppable-is-over-right-of:before {
  border-top: 0;
  border-bottom: 0; }

.react-page-cell-droppable-is-over-above:before {
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  border-left: 0;
  border-right: 0; }

.react-page-cell-droppable-leaf.react-page-cell-droppable-is-over-above:before {
  height: 4px; }

.react-page-cell-droppable-is-over-below:before {
  bottom: 0;
  left: 0;
  right: 0;
  height: 4px; }

.react-page-cell-droppable-leaf.react-page-cell-droppable-is-over-below:before {
  height: 4px; }

.react-page-cell-droppable-is-over-left-of:before {
  top: 0;
  left: 0;
  bottom: 0;
  width: 4px; }

.react-page-cell-droppable-leaf.react-page-cell-droppable-is-over-left-of:before {
  width: 4px; }

.react-page-cell-droppable-is-over-right-of:before {
  top: 0;
  right: 0;
  bottom: 0;
  width: 4px; }

.react-page-cell-droppable-leaf.react-page-cell-droppable-is-over-right-of:before {
  width: 4px; }

.react-page-cell-droppable-is-over-inline-right:before,
.react-page-cell-droppable-is-over-inline-left:before {
  top: 0;
  width: 50%;
  height: 36%;
  max-height: 128px;
  background-color: #e0e0e0;
  background-color: var(--grey300);
  border: 1px solid rgba(0, 0, 0, 0.26);
  border: 1px solid var(--minBlack); }

.react-page-cell-droppable-is-over-inline-left:before {
  left: 0; }

.react-page-cell-droppable-is-over-inline-right:before {
  right: 0; }

.react-page-cell-droppable-not-allowed {
  cursor: not-allowed; }

.react-page-cell-error {
  background-color: red;
  padding: 8px;
  margin: 2px;
  overflow: hidden; }

.react-page-cell-error strong {
  margin: 0 auto; }

.react-page-cell-error code {
  overflow: scroll; }

.react-page-editable .react-page-cell-handle {
  display: none; }

.react-page-editable.react-page-editable-mode-edit .react-page-cell-handle, .react-page-editable.react-page-editable-mode-resizing .react-page-cell-handle, .react-page-editable.react-page-editable-mode-layout .react-page-cell-handle {
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translateX(-50%) translateY(-100%);
  transition: opacity ease 0.4s;
  opacity: 0;
  color: rgba(0, 0, 0, 0.97);
  background: rgba(255, 255, 255, 0.95);
  text-align: center;
  color: rgba(0, 0, 0, 0.97);
  display: inline-block;
  padding: 12px 24px;
  margin: 0 auto;
  border-radius: 12px 12px 0 0;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 1.4;
  letter-spacing: 0.15em;
  box-shadow: 0 -5px 5px rgba(0, 0, 0, 0.22);
  pointer-events: none; }

.react-page-editable.react-page-editable-mode-edit .react-page-cell-handle-drag-enabled, .react-page-editable.react-page-editable-mode-resizing .react-page-cell-handle-drag-enabled, .react-page-editable.react-page-editable-mode-layout .react-page-cell-handle-drag-enabled {
  cursor: move; }

.react-page-editable.react-page-editable-mode-edit .react-page-cell:hover > .react-page-cell-handle,
.react-page-editable.react-page-editable-mode-edit .react-page-cell.react-page-cell-focused > .react-page-cell-handle,
.react-page-editable.react-page-editable-mode-resizing .react-page-cell:hover > .react-page-cell-handle,
.react-page-editable.react-page-editable-mode-resizing .react-page-cell.react-page-cell-focused > .react-page-cell-handle,
.react-page-editable.react-page-editable-mode-layout .react-page-cell:hover > .react-page-cell-handle,
.react-page-editable.react-page-editable-mode-layout .react-page-cell.react-page-cell-focused > .react-page-cell-handle {
  opacity: 1;
  pointer-events: all; }

/* general rules for read only and edit */
.react-page-cell {
  display: flex;
  flex-direction: column;
  box-sizing: border-box; }

.react-page-cell-inline-left {
  float: left; }

.react-page-cell-inline-right {
  float: right; }

.react-page-cell-has-inline-neighbour {
  float: none;
  width: 100%;
  display: block; }

.react-page-cell .react-page-cell-inner {
  height: 100%;
  box-sizing: border-box;
  outline: none; }

/* edit */
.react-page-editable.react-page-editable-mode-edit .react-page-cell, .react-page-editable.react-page-editable-mode-resizing .react-page-cell, .react-page-editable.react-page-editable-mode-layout .react-page-cell {
  position: relative;
  transition: box-shadow 0.3s; }

.react-page-editable.react-page-editable-mode-edit .react-page-cell-is-draft, .react-page-editable.react-page-editable-mode-resizing .react-page-cell-is-draft, .react-page-editable.react-page-editable-mode-layout .react-page-cell-is-draft {
  opacity: 0.3;
  outline: 1px dashed black; }

.react-page-editable.react-page-editable-mode-edit .react-page-cell-bring-to-front, .react-page-editable.react-page-editable-mode-resizing .react-page-cell-bring-to-front, .react-page-editable.react-page-editable-mode-layout .react-page-cell-bring-to-front {
  z-index: 3; }

.react-page-editable.react-page-editable-mode-edit .react-page-cell.react-page-cell-has-plugin:hover, .react-page-editable.react-page-editable-mode-resizing .react-page-cell.react-page-cell-has-plugin:hover, .react-page-editable.react-page-editable-mode-layout .react-page-cell.react-page-cell-has-plugin:hover {
  z-index: 2;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  opacity: 1; }

.react-page-editable.react-page-editable-mode-edit .react-page-cell.react-page-cell-focused.react-page-cell-focused, .react-page-editable.react-page-editable-mode-resizing .react-page-cell.react-page-cell-focused.react-page-cell-focused, .react-page-editable.react-page-editable-mode-layout .react-page-cell.react-page-cell-focused.react-page-cell-focused {
  z-index: 1;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.4);
  opacity: 1; }

.react-page-editable.react-page-editable-mode-insert .react-page-cell {
  cursor: cell; }

.react-page-editable.react-page-editable-mode-insert .react-page-cell:hover {
  z-index: 1;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.4);
  opacity: 1; }

.react-page-controls-mode-toggle-button-inner {
  float: right;
  margin: 8px; }

.react-page-controls-mode-toggle-button-description {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  margin-top: 18px;
  float: right;
  background: transparent;
  color: transparent;
  border: 1px transparent solid;
  padding: 2px 8px;
  text-align: right;
  display: none;
  transition: all 200ms ease;
  white-space: nowrap;
  overflow: hidden; }

.react-page-controls-mode-toggle-button:hover
.react-page-controls-mode-toggle-button-description {
  max-width: 999px;
  background: rgba(0, 0, 0, 0.87);
  background: var(--darkBlack);
  color: #ffffff;
  color: var(--white);
  display: block;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border: 1px solid var(--faintBlack); }

.react-page-controls-mode-toggle-clearfix {
  clear: both; }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0); }
  80% {
    opacity: 1;
    transform: scale(1.05); }
  100% {
    opacity: 1;
    transform: scale(1); } }

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0); }
  80% {
    opacity: 1;
    transform: scale(1.05); }
  100% {
    opacity: 1;
    transform: scale(1); } }

.react-page-controls-mode-toggle-control {
  z-index: 1;
  -webkit-animation: fadeIn 0.8s forwards;
  animation: fadeIn 0.8s forwards;
  opacity: 0; }

.react-page-plugin-drawer-item {
  cursor: pointer;
  z-index: 1; }

.react-page-plugin-drawer-item:hover {
  z-index: 2;
  box-shadow: 0 0 20px #ccc; }

.react-page-controls-trash {
  position: fixed;
  bottom: -64px;
  z-index: 500;
  left: 50%;
  transition: bottom 200ms ease;
  padding: 8px; }

.react-page-controls-trash.react-page-controls-trash-active {
  bottom: 16px; }

.react-page-plugins-content-slate-paragraph-placeholder {
  font-style: italic;
  color: var(--lightBlack); }

.react-page-plugins-content-slate-inline-toolbar {
  position: absolute;
  z-index: 10;
  top: -10000px;
  left: -10000px;
  margin-top: -6px;
  opacity: 0;
  background-color: var(--grey900);
  border-radius: 4px;
  transition: opacity 0.75s; }

.react-page-plugins-content-slate-inline-toolbar--hidden {
  opacity: 0 !important;
  pointer-events: none; }

.react-page-plugins-content-image {
  width: 100%; }

.react-page-plugins-content-image-placeholder {
  position: relative;
  width: 100%;
  text-align: center; }

.react-page-plugins-content-spacer {
  border-bottom: none;
  position: relative; }

.react-page-editable .react-page-plugins-content-spacer {
  outline: 1px dashed var(--minBlack); }

.react-page-editable-mode-preview .react-page-plugins-content-spacer {
  outline: none; }

.react-page-plugins-content-spacer
> .react-resizable
> .react-resizable-handle:before,
.react-page-plugins-content-spacer
> .react-resizable
> .react-resizable-handle:hover:before {
  content: ' ';
  position: absolute;
  text-align: center;
  width: 100%;
  bottom: 0;
  right: 0;
  cursor: n-resize;
  line-height: 12px;
  font-size: 1.5em;
  height: 24px; }

.react-page-plugins-content-divider {
  background-color: #eee;
  width: 100%;
  height: 2px;
  border-color: #eee; }

.react-page-plugins-layout-background {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  padding: 12px;
  position: relative; }

.react-page-plugins-layout-background > .react-page-row {
  position: relative; }

.react-page-plugins-layout-background__backstretch {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

.reseller-overview .table-opportunities {
  display: flex; }
  .reseller-overview .table-opportunities div {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500; }
    .reseller-overview .table-opportunities div.total {
      font-weight: normal;
      margin-right: 0px; }
    .reseller-overview .table-opportunities div.open {
      background-color: #F0EBB8;
      color: #CCA324; }
    .reseller-overview .table-opportunities div.closed {
      background-color: #D2E3CA;
      color: #4DAE1E; }
    .reseller-overview .table-opportunities div.lost {
      background-color: #E9CFCF;
      color: #AD1819; }

.reseller-overview .table-btns {
  display: flex; }

.reseller-overview .table-status {
  display: flex;
  align-items: center; }
  .reseller-overview .table-status p {
    margin: 0px; }
  .reseller-overview .table-status div {
    margin-right: 5px;
    width: 14px;
    height: 14px;
    border-radius: 50%; }
  .reseller-overview .table-status.Active div {
    background-color: #52C41A; }
  .reseller-overview .table-status.Inactive div {
    background-color: #FFC100; }
  .reseller-overview .table-status.Pending div {
    background-color: #1890FF; }
  .reseller-overview .table-status.Retired div {
    background-color: #FB4953; }

@media (max-width: 768px) {
  .reseller-overview {
    margin-top: 165px; } }

.reseller-mobile {
  margin: 10px 20px;
  display: flex;
  width: calc(100% - 40px);
  align-items: center;
  border-radius: 8px;
  height: 80px;
  padding: 8px 15px;
  justify-content: space-between; }
  .reseller-mobile:nth-of-type(even) {
    background-color: #F5F5F5; }
  .reseller-mobile p {
    margin-bottom: 0px; }
    .reseller-mobile p.bold {
      font-weight: 500 !important; }
  .reseller-mobile .value {
    margin-left: auto; }
    .reseller-mobile .value p {
      text-align: right; }
      .reseller-mobile .value p span {
        font-weight: 300;
        margin-right: 10px; }
  .reseller-mobile > span {
    margin-left: 10px; }

.invite-modal .ant-modal-body {
  padding: 0px; }

.invite-modal .ant-modal-content {
  overflow: hidden; }

.invite-modal .ant-form > .ant-row {
  padding: 24px 24px 0px 24px; }

.invite-modal img {
  width: 175px;
  margin: 0 auto;
  display: block; }

.invite-modal h5 {
  color: #BA4A00;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  margin: 20px 0px; }

.invite-modal .ant-modal-close-x {
  color: #DADADA;
  font-size: 20px; }

.invite-modal .padding-right {
  padding-right: 10px; }

.invite-modal .padding-left {
  padding-left: 10px; }

.invite-modal .footer {
  padding: 24px;
  width: 100%;
  height: 80px;
  display: flex !important;
  align-items: center;
  justify-content: flex-end;
  background-color: #E6EBEC; }
  .invite-modal .footer button {
    margin-left: 10px; }

@media (max-width: 768px) {
  .invite-modal .ant-modal {
    width: 100% !important;
    margin: 0px;
    max-width: 100%;
    min-height: 100%;
    position: absolute;
    top: 0px;
    padding: 0px; }
    .invite-modal .ant-modal input {
      font-size: 16px; }
    .invite-modal .ant-modal .ant-modal-body {
      width: 100%; }
    .invite-modal .ant-modal .ant-modal-content {
      overflow: hidden;
      min-height: 100%;
      width: 100%; }
      .invite-modal .ant-modal .ant-modal-content img {
        width: 75%; }
      .invite-modal .ant-modal .ant-modal-content h5 {
        font-size: 16px;
        text-align: left; }
      .invite-modal .ant-modal .ant-modal-content .padding-left, .invite-modal .ant-modal .ant-modal-content .padding-right {
        padding: 0px; }
      .invite-modal .ant-modal .ant-modal-content .footer {
        background-color: transparent;
        flex-direction: column;
        height: auto; }
        .invite-modal .ant-modal .ant-modal-content .footer button {
          margin-left: 0px;
          margin-bottom: 20px;
          width: 100%;
          height: 50px;
          font-size: 14px; } }

