#terms {
  padding: 30px 93px;
  min-height: 100vh;
  margin: 0px;
  background-repeat: no-repeat !important;
  background-size: contain !important;
  border-radius: 0px !important;
  background-color: #FCF9F4 !important; }
  #terms > img {
    margin-bottom: 20px; }
  #terms h1 {
    font-size: 20px;
    color: black;
    margin-bottom: 30px; }
  #terms .box {
    box-shadow: 0px 1px 20px #888888;
    width: calc(100% - 196px);
    max-width: 1050px; }
  @media (max-width: 768px) {
    #terms {
      padding: 20px;
      background-position: bottom !important; }
      #terms .box {
        width: 100%;
        margin-bottom: 20px; }
      #terms h1 {
        font-size: 18px; }
      #terms .head {
        display: flex;
        justify-content: space-between;
        margin-bottom: 40px; }
        #terms .head img {
          width: 142px;
          height: 100%; }
        #terms .head .ant-select {
          font-size: 16px;
          margin-top: 4px; } }
