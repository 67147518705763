.header-bar {
  width: 100%;
  background-color: #D7DBDD;
  display: flex;
  border-radius: 10px;
  height: 58px;
  align-items: center;
  padding: 20px;
  margin-bottom: 25px; }
  .header-bar > span {
    display: flex;
    align-items: center; }
  .header-bar .share-container {
    cursor: pointer;
    transition: background-color .5s;
    background-color: #505157;
    height: 32px;
    width: 32px;
    border-radius: 4px;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center; }
    .header-bar .share-container i:before {
      color: white;
      font-size: 16px;
      line-height: unset; }
    .header-bar .share-container:hover {
      background-color: #BA4A00; }
  .header-bar h4 {
    margin-bottom: 0px;
    margin-left: 10px;
    cursor: pointer;
    color: #BA4A00; }
    .header-bar h4.title {
      color: #505157;
      cursor: unset; }
  .header-bar svg {
    color: #BA4A00; }
  .header-bar .right {
    display: flex;
    align-items: center;
    margin-left: auto; }
    .header-bar .right button {
      margin-left: 10px; }
  .header-bar .save i:before {
    font-size: 13px;
    margin-left: 10px; }
  .header-bar .extra-btn {
    color: #8B8D96;
    margin: 0px 10px;
    cursor: pointer;
    font-weight: 500; }
  @media (max-width: 768px) {
    .header-bar {
      background-color: white;
      height: unset;
      padding: 10px;
      box-shadow: 0 4px 8px 0 #00000033;
      border-radius: 0px; }
      .header-bar button {
        height: 40px;
        width: 100%;
        margin: 0px 5px; }
        .header-bar button.elipsis {
          max-width: 40px; }
          .header-bar button.elipsis i:before {
            font-size: 20px;
            position: relative;
            right: 4px !important;
            top: 2px !important; }
          .header-bar button.elipsis .flaticon-delete:before {
            top: 0px;
            right: 3px; }
        .header-bar button.unarchive-mobile-btn {
          width: 40%;
          margin-left: auto; }
      .header-bar .share-container {
        min-width: 40px;
        min-height: 40px;
        margin-left: 5px; } }
  @media (min-width: 768px) {
    .header-bar.account-settings {
      width: calc(100vw - 149px); } }
