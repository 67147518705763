.filters-drawer .ant-drawer-close {
  display: none; }

.filters-drawer .head {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #BFBFBF; }
  .filters-drawer .head p {
    font-size: 16px;
    color: #8B8D96; }

.filters-drawer p.blue {
  color: #398CAF; }

.filters-drawer .clear-all {
  font-size: 16px;
  margin-top: 20px;
  text-align: center; }

.filters-drawer .filter {
  box-shadow: 0 6px 20px 8px #00000026;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 20px; }
  .filters-drawer .filter .ant-checkbox-group {
    display: flex;
    flex-direction: column; }
    .filters-drawer .filter .ant-checkbox-group label {
      background-color: #F5F5F5;
      margin-right: 0px;
      padding: 10px;
      border-radius: 4px;
      margin-bottom: 5px;
      font-size: 14px;
      color: #505157; }
  .filters-drawer .filter .controls {
    margin-top: 5px;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between; }
    .filters-drawer .filter .controls p {
      margin: 0px;
      font-size: 14px; }
      .filters-drawer .filter .controls p:first-of-type {
        color: #505157;
        cursor: pointer; }
      .filters-drawer .filter .controls p:last-of-type {
        color: #8B8D96;
        font-weight: 300; }

.filters-drawer .status-selector label .ant-checkbox span:before {
  content: '';
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  margin-left: 30px; }

.filters-drawer .status-selector label:nth-of-type(1) .ant-checkbox span:before {
  background-color: #52C41A; }

.filters-drawer .status-selector label:nth-of-type(2) .ant-checkbox span:before {
  background-color: #1890FF; }

.filters-drawer .status-selector label:nth-of-type(3) .ant-checkbox span:before {
  background-color: #FFC100; }

.filters-drawer .status-selector label > span:last-of-type {
  margin-left: 30px; }
