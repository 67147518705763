#add-new-note {
  margin: -24px; }
  #add-new-note .header-container {
    height: 82px;
    background-color: #d7dbdd;
    display: flex;
    align-items: center;
    padding-left: 25px;
    padding-right: 25px;
    justify-content: space-between; }
    #add-new-note .header-container .title {
      font-size: 18px;
      font-weight: 600;
      color: #BA4A00; }
    #add-new-note .header-container .icon {
      height: 40px;
      width: 40px;
      border-radius: 10px;
      background: linear-gradient(135deg, #BA4A00 0%, #490708 100%);
      margin-right: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right: 20px; }
    #add-new-note .header-container i:before {
      font-size: 20px;
      color: white; }
  #add-new-note .content {
    padding: 20px;
    height: 87vh;
    background-color: #EAEDEE; }
    #add-new-note .content .top-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 25px; }
    #add-new-note .content .selected {
      color: #1890ff; }
    #add-new-note .content .new-note {
      color: #505157;
      padding: 10px;
      margin-right: 10px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: row; }
    #add-new-note .content .edit-container {
      width: 100%;
      border-radius: 6px;
      background-color: #fcf8f3;
      padding: 20px;
      box-shadow: 0 3px 8px 0 rgba(76, 68, 58, 0.2); }
      #add-new-note .content .edit-container input, #add-new-note .content .edit-container textarea {
        background-color: #ffffff8c;
        border-color: transparent;
        box-shadow: inset 0 0 3px 1px #0000004a; }
      #add-new-note .content .edit-container .head {
        display: flex;
        align-items: center;
        justify-content: space-between; }
        #add-new-note .content .edit-container .head * {
          margin: 0px; }
        #add-new-note .content .edit-container .head h4 {
          color: #AB8E6A;
          font-size: 16px; }
        #add-new-note .content .edit-container .head p {
          color: #0000003d; }
    #add-new-note .content .button-container {
      display: flex;
      flex-direction: column;
      width: 80px;
      margin-left: 20px; }
  #add-new-note .ant-input-affix-wrapper {
    border: none;
    border-radius: 8px;
    background-color: #f5f5f5; }
    #add-new-note .ant-input-affix-wrapper input {
      font-size: 14px;
      color: #8B8D96;
      background-color: #f5f5f5; }
    #add-new-note .ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
      box-shadow: none;
      border: 1px solid #1890FF;
      background-color: #f4f9ff; }
      #add-new-note .ant-input-affix-wrapper.ant-input-affix-wrapper-focused input {
        background-color: transparent; }

@media (max-width: 768px) {
  #add-new-note {
    background-color: #fcf8f3;
    height: 100vh; }
    #add-new-note .header-mobile-container {
      display: flex;
      flex-direction: row;
      background-color: #fcf8f3;
      justify-content: space-between;
      padding: 20px; }
      #add-new-note .header-mobile-container .cancel-text {
        font-size: 16px;
        font-weight: 300;
        color: #8B8D96; }
      #add-new-note .header-mobile-container .save-text {
        font-size: 16px;
        font-weight: 300;
        color: #398CAF; }
    #add-new-note .divider {
      height: 1px;
      background-color: #BFBFBF;
      margin-left: 20px;
      margin-right: 20px; }
    #add-new-note .mobile-content {
      padding: 20px; } }
