.product-details .ant-col-md-16 {
  padding-left: 20px; }

.product-details .ant-row .ant-col-md-12:first-of-type {
  padding-right: 10px; }

.product-details .ant-row .ant-col-md-12:last-of-type {
  padding-left: 10px; }

.product-details textarea {
  font-size: 16px; }

.product-details .agent-selector .ant-form-item {
  max-width: 600px; }

.product-details .separator {
  height: 1px;
  background-color: #F5F5F5;
  margin-left: -20px;
  width: calc(100% + 40px);
  margin-right: -20px;
  margin-bottom: 20px; }

.product-details .product-image .labels {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px; }
  .product-details .product-image .labels p:first-of-type {
    font-size: 14px;
    font-weight: 500;
    margin: 0px;
    color: #505157; }
  .product-details .product-image .labels p:last-of-type {
    font-size: 10px;
    font-weight: 300;
    color: #8B8D96;
    margin: 0px; }

.product-details .product-image .image-container-placeholder {
  width: 100%;
  height: 150px;
  background-color: #DADADA;
  border-radius: 4px;
  border: 10px solid #F5F5F5; }
  .product-details .product-image .image-container-placeholder img {
    object-fit: contain;
    height: 100%;
    margin: 0 auto;
    display: block;
    width: 100%; }

.product-details .product-image .ant-upload {
  width: 100%; }
  .product-details .product-image .ant-upload p {
    color: #8B8D96;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 0px;
    cursor: pointer; }
  .product-details .product-image .ant-upload > div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }

@media (max-width: 768px) {
  .product-details {
    padding-right: 0px; }
    .product-details .ant-upload {
      margin-bottom: 20px; }
    .product-details .product-image .labels p:last-of-type {
      font-size: 12px; } }

@media (max-width: 768px) {
  .section-title {
    display: none; }
  .ant-col-md-16 {
    padding-left: 0px !important; }
  .ant-col-md-12 {
    padding-left: 0px !important; } }

.section-selector-container {
  background-color: #EAEDEE;
  padding: 20px;
  margin-top: -20px; }
