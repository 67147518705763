#edit-user label.title {
  color: #BA4A00;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 10px;
  display: block; }

#edit-user .right {
  padding-left: 20px; }
  #edit-user .right .box {
    min-height: calc(100vh - 250px);
    background-color: transparent; }

#edit-user img {
  position: absolute;
  right: 20px;
  bottom: 20px; }

#edit-user .ant-form > .ant-row > .ant-col {
  padding: 10px; }

@media (max-width: 768px) {
  #edit-user label.title {
    display: none; }
  #edit-user .right {
    padding-left: 0px; }
  #edit-user .view-selector {
    background-color: #EAEDEE;
    padding: 20px;
    margin-top: -20px;
    width: 100%; }
    #edit-user .view-selector .ant-radio-group {
      width: 100%;
      padding: 6px;
      background-color: white;
      border-radius: 8px; }
      #edit-user .view-selector .ant-radio-group > label {
        width: 50%;
        height: 38px;
        padding-top: 4px;
        text-align: center;
        border: none;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
        border-color: transparent;
        border-width: 0px; }
        #edit-user .view-selector .ant-radio-group > label:before {
          display: none; }
        #edit-user .view-selector .ant-radio-group > label.ant-radio-button-wrapper-checked {
          background-color: #BA4A00; }
  #edit-user img {
    position: relative;
    margin: 0 auto;
    display: block;
    margin-top: 40px;
    bottom: 0px;
    right: 0px; } }
