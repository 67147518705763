#change-language {
  display: flex;
  color: #505157;
  margin-bottom: 10px;
  margin-top: 20px; }
  #change-language p {
    margin-right: 15px;
    margin-left: 5px;
    font-size: 16px; }
    #change-language p.lang {
      font-weight: 300;
      cursor: pointer; }
    #change-language p.active {
      font-weight: 500;
      color: #BA4A00;
      padding-bottom: 3px;
      border-bottom: 1px solid #BA4A00;
      margin-top: -3px; }
  @media screen and (max-width: 768px) {
    #change-language {
      margin: 0px;
      display: unset; } }

.select-language-mobile .ant-select-selector {
  background-color: transparent !important;
  color: #505157;
  border: none !important; }

.select-language-mobile .ant-select-arrow {
  color: #505157;
  margin-top: -9px; }

.select-dropdown.language {
  left: unset !important;
  right: 20px !important;
  margin-top: -100px; }
