.invite-modal .ant-modal-body {
  padding: 0px; }

.invite-modal .ant-modal-content {
  overflow: hidden; }

.invite-modal .ant-form > .ant-row {
  padding: 24px 24px 0px 24px; }

.invite-modal img {
  width: 175px;
  margin: 0 auto;
  display: block; }

.invite-modal h5 {
  color: #BA4A00;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  margin: 20px 0px; }

.invite-modal .ant-modal-close-x {
  color: #DADADA;
  font-size: 20px; }

.invite-modal .padding-right {
  padding-right: 10px; }

.invite-modal .padding-left {
  padding-left: 10px; }

.invite-modal .footer {
  padding: 24px;
  width: 100%;
  height: 80px;
  display: flex !important;
  align-items: center;
  justify-content: flex-end;
  background-color: #E6EBEC; }
  .invite-modal .footer button {
    margin-left: 10px; }

@media (max-width: 768px) {
  .invite-modal .ant-modal {
    width: 100% !important;
    margin: 0px;
    max-width: 100%;
    min-height: 100%;
    position: absolute;
    top: 0px;
    padding: 0px; }
    .invite-modal .ant-modal input {
      font-size: 16px; }
    .invite-modal .ant-modal .ant-modal-body {
      width: 100%; }
    .invite-modal .ant-modal .ant-modal-content {
      overflow: hidden;
      min-height: 100%;
      width: 100%; }
      .invite-modal .ant-modal .ant-modal-content img {
        width: 75%; }
      .invite-modal .ant-modal .ant-modal-content h5 {
        font-size: 16px;
        text-align: left; }
      .invite-modal .ant-modal .ant-modal-content .padding-left, .invite-modal .ant-modal .ant-modal-content .padding-right {
        padding: 0px; }
      .invite-modal .ant-modal .ant-modal-content .footer {
        background-color: transparent;
        flex-direction: column;
        height: auto; }
        .invite-modal .ant-modal .ant-modal-content .footer button {
          margin-left: 0px;
          margin-bottom: 20px;
          width: 100%;
          height: 50px;
          font-size: 14px; } }
