.share-modal .ant-modal-close {
  display: none; }

.share-modal .ant-modal-content {
  overflow: hidden; }

.share-modal .share .anticon-check-circle {
  font-size: 44px;
  display: block;
  margin: 20px 0px 20px 0px; }

.share-modal .share h5 {
  color: #BA4A00;
  font-weight: 500;
  font-size: 14px; }

.share-modal .share .create-opportunity {
  margin-top: 20px;
  padding: 15px;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.08), 0 1px 18px 0 rgba(0, 0, 0, 0.1), 0 3px 5px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  border-radius: 8px;
  cursor: pointer; }
  .share-modal .share .create-opportunity p {
    font-weight: 300; }
  .share-modal .share .create-opportunity .text {
    width: 290px; }
  .share-modal .share .create-opportunity .image {
    width: 82px; }
    .share-modal .share .create-opportunity .image img {
      width: 150px; }
    @media (max-width: 768px) {
      .share-modal .share .create-opportunity .image {
        display: none; } }
  .share-modal .share .create-opportunity .link {
    text-decoration: underline;
    color: #1890FF;
    margin-top: -10px;
    margin-bottom: 0px;
    cursor: pointer; }

.share-modal .share .ant-collapse-header {
  background-color: #EAEDEE;
  margin-top: 20px;
  border-radius: 8px 8px 8px 8px !important; }
  .share-modal .share .ant-collapse-header svg {
    transform: rotate(90deg);
    font-size: 14px;
    margin-top: 8px; }
  .share-modal .share .ant-collapse-header p {
    margin-bottom: 0px; }
    .share-modal .share .ant-collapse-header p.small {
      font-size: 12px;
      color: #BA4A00; }

.share-modal .share .ant-collapse-content-box {
  padding: 15px 0px 0px 0px !important; }

.share-modal .share .ant-collapse-content-box div, .share-modal .share .share-btns div {
  height: 50px;
  width: 100%;
  font-size: 14px;
  color: white;
  display: flex;
  margin-bottom: 10px;
  border-radius: 8px;
  align-items: center;
  padding: 15px;
  cursor: pointer; }
  .share-modal .share .ant-collapse-content-box div.whatsapp, .share-modal .share .share-btns div.whatsapp {
    background-color: #23CD64; }
  .share-modal .share .ant-collapse-content-box div.wechat, .share-modal .share .share-btns div.wechat {
    background-color: #78AD2C; }
  .share-modal .share .ant-collapse-content-box div.link, .share-modal .share .share-btns div.link {
    background-color: #505157;
    margin-bottom: 0px; }
  .share-modal .share .ant-collapse-content-box div.only-save, .share-modal .share .share-btns div.only-save {
    background-color: #DADADA;
    color: #505157;
    margin-top: 10px;
    text-align: center;
    font-weight: 500;
    display: block; }
  .share-modal .share .ant-collapse-content-box div svg, .share-modal .share .share-btns div svg {
    font-size: 24px;
    margin-right: 10px; }
  .share-modal .share .ant-collapse-content-box div.cancel, .share-modal .share .share-btns div.cancel {
    background-color: #DADADA;
    color: #505157;
    height: 55px;
    margin-top: 15px;
    text-align: center;
    display: block;
    line-height: 1.8;
    font-weight: 500; }

.share-modal .share .ant-modal-close-x svg {
  color: #DADADA;
  font-size: 20px;
  position: relative;
  top: 10px; }
