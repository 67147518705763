.ant-popover-arrow {
  display: none; }

.ant-popover-inner {
  width: 200px; }

.ant-popover.wide {
  width: 240px; }
  .ant-popover.wide .ant-popover-inner {
    width: 100%; }

.ant-popover-inner-content {
  padding: 15px; }

.popover-content .ant-checkbox-group {
  display: flex;
  flex-direction: column; }
  .popover-content .ant-checkbox-group label {
    background-color: #F5F5F5;
    margin-right: 0px;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 5px;
    font-size: 12px; }
    .popover-content .ant-checkbox-group label.ant-checkbox-wrapper {
      display: flex;
      margin-left: 0px;
      align-items: center; }

.popover-content .controls {
  margin-top: 15px;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between; }
  .popover-content .controls p {
    margin: 0px;
    font-size: 14px;
    cursor: pointer; }
    .popover-content .controls p:first-of-type {
      color: #DADADA; }
    .popover-content .controls p:last-of-type {
      color: #398CAF; }

.popover-content .status .ant-progress-steps-item {
  height: 3px !important;
  border-radius: 3px;
  background: #DADADA; }
  .popover-content .status .ant-progress-steps-item:last-of-type {
    margin-left: 10px; }

.popover-content .status p {
  margin: 0px;
  font-weight: 500;
  font-size: 12px; }

.filter-selector button {
  height: 50px;
  margin-right: 20px;
  font-size: 14px;
  border-color: transparent;
  color: #8C8C8C; }
  .filter-selector button:focus {
    border: 1px solid #505157 !important;
    background-color: #F5F5F5;
    color: #595959; }

.filter-selector.active button {
  display: flex;
  align-items: center;
  border: 2px solid #398CAF;
  background-color: #D9EBF3;
  color: #398CAF; }
  .filter-selector.active button .indicator {
    display: flex;
    align-items: center;
    margin-left: 8px; }
    .filter-selector.active button .indicator div {
      min-width: 20px;
      min-height: 20px;
      font-size: 12px;
      color: white;
      padding-top: 1px;
      padding-left: 1px;
      cursor: pointer; }
      .filter-selector.active button .indicator div:first-of-type {
        background-color: #70ADC6; }
      .filter-selector.active button .indicator div:last-of-type {
        background-color: #398CAF; }

.status-payment {
  display: flex;
  align-items: center; }
  .status-payment p {
    margin: 0px; }
  .status-payment .dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 10px; }
    .status-payment .dot.Cleared {
      background-color: #52C41A; }
    .status-payment .dot.Due {
      background-color: #FFC100; }
    .status-payment .dot.Late {
      background-color: #FB4953; }
    .status-payment .dot.Cancelled {
      background-color: #1B1B1B; }
