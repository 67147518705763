.products-overview > .ant-row {
  margin-left: -20px;
  margin-right: -20px;
  margin-left: 0px;
  margin-right: 0px; }

.products-overview .section-header {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .products-overview .section-header label {
    font-size: 14px;
    font-weight: 600; }
  .products-overview .section-header .view-selector {
    display: flex; }
    .products-overview .section-header .view-selector p {
      margin-left: 20px;
      color: #8B8D96;
      font-weight: 500;
      cursor: pointer; }
      .products-overview .section-header .view-selector p.active {
        cursor: unset;
        color: black;
        border-bottom: 1px solid black; }
  .products-overview .section-header.agent-products {
    margin-top: 40px; }

.products-overview .promoted .ant-table-cell > div {
  color: #BA4A00; }

.products-overview .promoted .discount-rate {
  background-color: #BA4A00;
  font-weight: 600;
  border-radius: 4px;
  padding: 3px 7px;
  color: white;
  margin-right: 10px;
  max-width: 45px; }

.products-overview td .ant-input-affix-wrapper {
  background-color: #F5F5F5;
  height: 40px;
  border-color: transparent; }
  .products-overview td .ant-input-affix-wrapper input {
    background-color: transparent;
    color: #505157; }
  .products-overview td .ant-input-affix-wrapper .ant-input-prefix {
    color: #8B8D96; }
  .products-overview td .ant-input-affix-wrapper.ant-input-affix-wrapper-disabled {
    background-color: #DADADA; }

.products-overview td button {
  height: 28px; }

.products-overview .perpetual-product-name {
  display: flex;
  align-items: center;
  color: #BA4A00; }
  .products-overview .perpetual-product-name img {
    width: auto;
    height: 17px;
    margin-right: 10px; }

.products-overview .product-actions {
  display: flex;
  width: 146px; }
  .products-overview .product-actions > div {
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;
    background-color: #505157; }
    .products-overview .product-actions > div i:before {
      font-size: 16px;
      color: white;
      position: relative;
      top: 2px; }

.products-overview .price span {
  font-size: 12px;
  color: #8B8D96;
  margin-right: 10px; }

.products-overview .product-card {
  padding: 20px;
  position: relative; }
  .products-overview .product-card > div {
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.08), 0 1px 18px 0 rgba(0, 0, 0, 0.1), 0 3px 5px 0 rgba(0, 0, 0, 0.1);
    background-color: white;
    border-radius: 4px;
    overflow: hidden; }
    .products-overview .product-card > div .image {
      padding-top: 70%;
      background-position: center !important;
      background-repeat: no-repeat !important;
      background-size: cover !important; }
    .products-overview .product-card > div .category {
      font-size: 12px;
      color: #8B8D96;
      margin: 10px 0px 0px 10px; }
    .products-overview .product-card > div h5 {
      font-weight: 500;
      color: #BA4A00;
      font-size: 14px;
      margin: 0px 0px 0px 10px; }
    .products-overview .product-card > div .description {
      height: 5vw;
      position: relative;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      overflow: hidden; }
      .products-overview .product-card > div .description .gradient {
        top: 0px;
        left: 0px;
        height: 5vw;
        background: linear-gradient(0deg, white 0%, rgba(255, 255, 255, 0) 33%, rgba(255, 255, 255, 0) 100%);
        width: 100%;
        position: absolute; }
      .products-overview .product-card > div .description p {
        margin: 0px;
        align-self: flex-start;
        color: #505157;
        line-height: 1.5;
        margin-right: 10px; }
      .products-overview .product-card > div .description div:not(.gradient) {
        min-width: 28px;
        min-height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        border-radius: 4px;
        overflow: hidden;
        cursor: pointer;
        background-color: #505157; }
        .products-overview .product-card > div .description div:not(.gradient) i:before {
          font-size: 16px;
          color: white; }
  @media (max-width: 768px) {
    .products-overview .product-card {
      padding-top: 10px; }
      .products-overview .product-card:nth-of-type(odd) {
        padding-right: 10px; }
      .products-overview .product-card:nth-of-type(even) {
        padding-left: 10px; }
      .products-overview .product-card .category {
        font-size: 10px !important; }
      .products-overview .product-card h5 {
        font-size: 12px !important;
        margin-bottom: 5px !important; } }

@media (max-width: 768px) {
  .products-overview {
    margin-top: 161px;
    min-height: calc(100vh - 231px); }
    .products-overview.agent {
      background-color: #EAEDEE; }
    .products-overview.admin {
      background-color: white; } }

.products-overview table input {
  text-align: right; }

.product-preview .ant-popover-inner-content > div img {
  width: 173px; }

.product-mobile {
  margin: 10px 20px;
  display: flex;
  width: calc(100% - 40px);
  align-items: center;
  border-radius: 8px;
  height: 80px;
  padding: 8px 15px;
  justify-content: space-between; }
  .product-mobile:nth-of-type(even) {
    background-color: #F5F5F5; }
  .product-mobile.promoted .bold {
    color: #BA4A00; }
  .product-mobile p {
    margin-bottom: 0px; }
    .product-mobile p.bold {
      font-weight: 500 !important; }
  .product-mobile .value {
    margin-left: auto; }
    .product-mobile .value p {
      text-align: right; }
      .product-mobile .value p span {
        font-weight: 300;
        margin-right: 10px; }
  .product-mobile > span {
    margin-left: 10px; }
  .product-mobile .product-actions {
    justify-content: flex-end; }
    .product-mobile .product-actions > div {
      margin-right: 0px;
      margin-left: 10px; }
