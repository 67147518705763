.basic-header {
  height: 56px;
  background-color: white;
  display: flex;
  align-items: center;
  padding: 0px 50px;
  position: fixed;
  width: 100%;
  z-index: 10; }
  .basic-header > img {
    margin-right: 30px; }
  .basic-header h1 {
    margin-bottom: 0px;
    color: #BA4A00;
    font-size: 17px;
    font-family: Noto Serif SC;
    font-weight: 600; }
  .basic-header button {
    background-color: #E8E8E8;
    border: 1px solid #E8E8E8;
    height: 31px;
    color: black;
    font-size: 14px;
    padding-left: 7px;
    display: flex;
    align-items: center;
    padding-right: 7px;
    margin: 0px 20px; }
    .basic-header button img {
      position: absolute;
      top: -5px;
      right: 24px; }
    .basic-header button p {
      margin-bottom: 0px;
      margin-right: 48px; }
    .basic-header button:hover {
      border-color: #BA4A00;
      background-color: white;
      color: black; }
  .basic-header > p {
    margin-bottom: 0px;
    color: #0094FF;
    text-decoration: underline;
    cursor: pointer; }
  .basic-header > .ant-select {
    margin-left: auto;
    margin-right: 20px; }
    .basic-header > .ant-select .ant-select-selector {
      border: none;
      color: #8B8D96;
      font-weight: 500;
      text-transform: uppercase; }
      .basic-header > .ant-select .ant-select-selector svg {
        position: relative;
        bottom: 2px;
        font-size: 13px; }
  .basic-header .header-menu {
    height: 56px;
    display: flex;
    align-items: center; }
    .basic-header .header-menu svg {
      margin-left: 5px; }
    .basic-header .header-menu.open svg {
      color: #BA4A00; }
  @media (max-width: 992px) {
    .basic-header img {
      margin-left: 10px; }
    .basic-header * {
      font-size: 12px !important; }
    .basic-header h1 {
      white-space: nowrap; }
    .basic-header > p {
      width: 139px;
      line-height: 17px; } }
  @media (max-width: 768px) {
    .basic-header {
      height: 70px;
      padding: 0px 18px; }
      .basic-header > h1, .basic-header > p, .basic-header > .ant-select, .basic-header .header-menu svg {
        display: none; }
      .basic-header > img {
        margin-right: 0px; }
      .basic-header .header-menu {
        margin-left: auto; } }

.profile-menu .ant-popover-inner {
  width: unset; }

.profile-menu .ant-popover-inner-content {
  width: 221px;
  padding: 10px; }
  .profile-menu .ant-popover-inner-content div label {
    font-size: 12px;
    color: #505157;
    font-weight: 500;
    margin-bottom: 0px;
    margin-top: 20px; }
  .profile-menu .ant-popover-inner-content div > div {
    width: 100%;
    height: 36px;
    padding: 7px 10px;
    border-radius: 4px;
    margin-bottom: 5px;
    cursor: pointer;
    background-color: #F5F5F5;
    color: black;
    transition: all 0.3s; }
    .profile-menu .ant-popover-inner-content div > div:hover {
      background-color: #e2e2e2; }
    .profile-menu .ant-popover-inner-content div > div i {
      margin-right: 8px;
      position: relative;
      top: 2px; }
    .profile-menu .ant-popover-inner-content div > div.getpaid {
      background-color: #BA4A00;
      color: white; }
    .profile-menu .ant-popover-inner-content div > div.whatsapp {
      background-color: #23CD64;
      color: white; }
      .profile-menu .ant-popover-inner-content div > div.whatsapp:hover {
        background-color: #19b555; }
    .profile-menu .ant-popover-inner-content div > div.phone {
      background-color: #505157;
      margin-bottom: 0px;
      color: white; }
      .profile-menu .ant-popover-inner-content div > div.phone:hover {
        background-color: #27282b; }
    .profile-menu .ant-popover-inner-content div > div.logout {
      margin-top: 20px; }
  .profile-menu .ant-popover-inner-content div svg {
    font-size: 16px;
    margin-right: 5px; }

.menu-drawer label {
  margin-top: 20px;
  margin-bottom: 5px; }

.menu-drawer .whatsapp {
  background-color: #23CD64;
  color: white; }
  .menu-drawer .whatsapp svg {
    margin-right: 10px; }

.menu-drawer .phone {
  background-color: #505157;
  color: white; }
  .menu-drawer .phone svg {
    margin-right: 10px; }

.menu-drawer .signout {
  margin-top: 30px; }
