#dashboard .dashboard-section-title {
  font-size: 14px !important;
  font-weight: normal;
  color: #BA4A00;
  margin-bottom: 20px; }

#dashboard .no-data {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }
  #dashboard .no-data img {
    margin: 0 auto;
    display: block; }
  #dashboard .no-data p {
    text-align: center;
    margin-top: 10px; }

#dashboard .number {
  border-radius: 6px;
  padding: 15px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around; }
  #dashboard .number h4 {
    font-size: 12px;
    margin-bottom: 0px; }
  #dashboard .number p {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0px; }
  #dashboard .number.primary {
    background-color: #BA4A00; }
    #dashboard .number.primary * {
      color: white; }
  #dashboard .number.secondary {
    background-color: #ba4a0021; }
    #dashboard .number.secondary * {
      color: #8B8D96; }
  #dashboard .number.gray {
    background-color: #F3F4F4; }
    #dashboard .number.gray * {
      color: #8B8D96; }
  #dashboard .number.bigger h4 {
    font-size: 14px;
    margin-bottom: 10px; }

#dashboard .padding {
  padding: 10px; }

#dashboard .user-filter-header {
  display: flex;
  align-items: center;
  margin: 10px 0px; }
  #dashboard .user-filter-header p {
    margin-bottom: 0px;
    margin-right: 20px;
    line-height: 3.5; }
  #dashboard .user-filter-header .ant-select {
    width: 400px;
    border-radius: 8px;
    border: 2px solid white; }
    #dashboard .user-filter-header .ant-select .ant-select-selector {
      border: none; }
    #dashboard .user-filter-header .ant-select svg {
      position: relative;
      bottom: 7px;
      right: 4px;
      font-size: 20px; }
    #dashboard .user-filter-header .ant-select.active {
      border: 2px solid #398CAF;
      background-color: #D9EBF3;
      color: #398CAF; }
      #dashboard .user-filter-header .ant-select.active .ant-select-selector {
        background-color: #D9EBF3; }
      #dashboard .user-filter-header .ant-select.active .ant-select-arrow {
        display: none; }
      #dashboard .user-filter-header .ant-select.active .ant-select-clear {
        opacity: 1; }
      #dashboard .user-filter-header .ant-select.active .ant-select-clear {
        background-color: #398CAF;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2px;
        top: 42% !important; }
        #dashboard .user-filter-header .ant-select.active .ant-select-clear svg {
          bottom: 0px;
          right: 0px;
          font-size: 10px;
          color: white; }
    #dashboard .user-filter-header .ant-select.inactive .ant-select-clear {
      opacity: 0 !important; }

#dashboard .chart-container {
  border-radius: 10px;
  box-shadow: 0px 10px 20px rgba(112, 144, 176, 0.1);
  background-color: white;
  padding: 20px 20px 40px 20px;
  height: 100%;
  position: relative;
  min-height: 350px; }
  #dashboard .chart-container > p, #dashboard .chart-container .ant-col-xs-24 > p {
    font-weight: 400;
    font-size: 18px; }
  #dashboard .chart-container .ant-col-xs-24 > p {
    margin-bottom: 0px;
    margin-top: -10px; }
  #dashboard .chart-container .recharts-cartesian-axis-line, #dashboard .chart-container .recharts-cartesian-axis-tick-line {
    display: none; }
  #dashboard .chart-container .recharts-default-legend {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: -27%;
    bottom: 0px; }
    #dashboard .chart-container .recharts-default-legend li {
      margin-bottom: 10px; }
  #dashboard .chart-container .recharts-surface {
    margin-left: -30px; }
  #dashboard .chart-container .recharts-legend-item-text {
    color: #8B8D96 !important; }
  #dashboard .chart-container .recharts-yAxis .recharts-cartesian-axis-ticks .recharts-layer:first-of-type {
    display: none; }
  #dashboard .chart-container .recharts-cartesian-axis-ticks .recharts-layer {
    color: #8B8D96; }
  #dashboard .chart-container .recharts-tooltip-wrapper * {
    color: #8B8D96;
    border-radius: 6px; }
  #dashboard .chart-container .controls {
    position: absolute;
    right: 20px;
    bottom: 20px; }
    #dashboard .chart-container .controls .anticon-left {
      margin-right: 20px; }
  #dashboard .chart-container .sub-title {
    color: #8B8D96;
    margin-top: 30px;
    font-size: 14px; }
  #dashboard .chart-container .bar {
    font-size: 14px;
    color: white;
    background-color: #BA4A00;
    border-radius: 25px;
    padding: 10px 20px; }
  #dashboard .chart-container .numbers .ant-col {
    padding: 10px; }
    #dashboard .chart-container .numbers .ant-col .notice {
      font-size: 12px;
      color: #8B8D96;
      margin-bottom: 0px; }
  #dashboard .chart-container.opportunities-amount {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 20px !important; }
    #dashboard .chart-container.opportunities-amount > p:first-of-type {
      margin-bottom: 0px; }
    #dashboard .chart-container.opportunities-amount.nodata {
      justify-content: unset; }
  #dashboard .chart-container .recharts-responsive-container {
    position: relative;
    top: 20px; }
  #dashboard .chart-container .title {
    font-size: 14px;
    color: #8B8D96; }
  #dashboard .chart-container.leaderboard {
    overflow-x: auto; }
    #dashboard .chart-container.leaderboard thead th {
      position: sticky !important;
      top: 0px;
      background-color: white;
      z-index: 10;
      font-weight: 400 !important;
      box-shadow: 19px 10px 11px 2px #88888824; }
      #dashboard .chart-container.leaderboard thead th:first-of-type {
        box-shadow: 7px 10px 18px 8px #88888824; }
    #dashboard .chart-container.leaderboard .controls {
      bottom: 30px; }
    #dashboard .chart-container.leaderboard .number {
      height: auto !important; }
    #dashboard .chart-container.leaderboard .ant-pagination {
      display: none; }
    #dashboard .chart-container.leaderboard .ant-table-content * {
      font-size: 12px !important; }
    #dashboard .chart-container.leaderboard .ant-table-content th, #dashboard .chart-container.leaderboard .ant-table-content td, #dashboard .chart-container.leaderboard .ant-table-content .ant-table-column-sorters > span:first-of-type {
      color: #8B8D96 !important; }
    #dashboard .chart-container.leaderboard .ant-table-content .ant-table-cell {
      border-bottom: none !important; }
    #dashboard .chart-container.leaderboard .ant-table-content .ant-table-column-sort {
      background: white; }
    #dashboard .chart-container.leaderboard .ant-table-content .ant-table-tbody > tr.ant-table-row:hover > td {
      background: #fafafa !important; }
    #dashboard .chart-container.leaderboard .inline {
      display: flex; }
      #dashboard .chart-container.leaderboard .inline .numbers {
        width: 20%;
        padding-left: 20px;
        margin: 0 auto; }
        #dashboard .chart-container.leaderboard .inline .numbers .number {
          border-radius: 6px;
          margin-bottom: 25px;
          padding: 10px; }
          #dashboard .chart-container.leaderboard .inline .numbers .number h4 {
            font-size: 12px;
            font-weight: normal;
            margin: 0px; }
          #dashboard .chart-container.leaderboard .inline .numbers .number p {
            font-size: 19px;
            font-weight: 600;
            margin: 0px; }
          #dashboard .chart-container.leaderboard .inline .numbers .number.primary {
            background-color: #ba4a0021; }
            #dashboard .chart-container.leaderboard .inline .numbers .number.primary h4, #dashboard .chart-container.leaderboard .inline .numbers .number.primary p {
              color: #8B8D96; }
          #dashboard .chart-container.leaderboard .inline .numbers .number.secondary {
            background-color: #BA4A00; }
            #dashboard .chart-container.leaderboard .inline .numbers .number.secondary h4, #dashboard .chart-container.leaderboard .inline .numbers .number.secondary p {
              color: white; }
  #dashboard .chart-container.agents-overview .ant-col {
    padding: 10px; }
  #dashboard .chart-container.income-chart .recharts-surface {
    margin-left: 0px !important; }
  #dashboard .chart-container.income-chart.admin .legend-item-2, #dashboard .chart-container.income-chart.admin .legend-item-3 {
    display: none !important; }
  #dashboard .chart-container.income-chart.admin .recharts-tooltip-item-list li:nth-of-type(3), #dashboard .chart-container.income-chart.admin .recharts-tooltip-item-list li:nth-of-type(4) {
    display: none !important; }
  #dashboard .chart-container.income-chart.agent-reseller .legend-item-1 {
    display: none !important; }
  #dashboard .chart-container.income-chart.agent-reseller li:nth-of-type(2) {
    display: none !important; }
  #dashboard .chart-container.promotions {
    height: auto; }
    #dashboard .chart-container.promotions .promotion-product {
      padding: 15px;
      border-radius: 4px; }
      #dashboard .chart-container.promotions .promotion-product .discount-rate {
        background-color: #BA4A00;
        font-weight: 600;
        border-radius: 4px;
        padding: 3px 7px;
        color: white;
        margin-right: 10px;
        max-width: 45px; }
      #dashboard .chart-container.promotions .promotion-product .icon {
        background-color: #505157;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px;
        max-height: 32px;
        max-width: 32px;
        margin-left: auto;
        cursor: pointer; }
        #dashboard .chart-container.promotions .promotion-product .icon i {
          color: #F0F0F0; }
          #dashboard .chart-container.promotions .promotion-product .icon i:before {
            position: relative;
            top: 2px; }
      #dashboard .chart-container.promotions .promotion-product p {
        margin-bottom: 0px; }
        #dashboard .chart-container.promotions .promotion-product p span {
          margin-right: 10px; }
      #dashboard .chart-container.promotions .promotion-product .name {
        font-weight: 300;
        color: #8B8D96; }
      #dashboard .chart-container.promotions .promotion-product .original-price {
        color: #8B8D96;
        text-decoration: line-through;
        font-weight: 300; }
      #dashboard .chart-container.promotions .promotion-product .price {
        color: #BA4A00; }
      #dashboard .chart-container.promotions .promotion-product:nth-of-type(odd) {
        background-color: #F0F0F0; }

@media (max-width: 768px) {
  #dashboard {
    background-color: #EAEDEE !important;
    padding: 15px;
    overflow: hidden; }
    #dashboard .agent-dashboard-sales {
      flex-direction: column-reverse; }
    #dashboard .recharts-responsive-container {
      top: 0px !important; }
    #dashboard .right-section .filters {
      flex-wrap: wrap;
      justify-content: unset !important; }
      #dashboard .right-section .filters .ant-btn {
        margin-bottom: 20px; }
    #dashboard .recharts-responsive-container {
      width: 95% !important; }
    #dashboard .recharts-default-legend {
      right: -10% !important;
      top: -175px; }
    #dashboard .income-chart .recharts-default-legend {
      display: block;
      top: -200px !important; }
    #dashboard .controls {
      width: calc(100% - 40px);
      display: flex;
      justify-content: space-between;
      bottom: 25px !important; }
    #dashboard .chart-container .recharts-wrapper {
      top: 20px; }
    #dashboard .chart-container.leaderboard {
      position: relative;
      margin-bottom: 20px; }
      #dashboard .chart-container.leaderboard .inline {
        width: 100%;
        display: block; }
      #dashboard .chart-container.leaderboard .controls {
        justify-content: flex-end !important;
        top: 25px; }
    #dashboard .chart-container .numbers {
      display: flex; }
      #dashboard .chart-container .numbers > div:nth-child(odd) {
        padding-left: 0px; }
      #dashboard .chart-container .numbers > div:nth-child(even) {
        padding-right: 0px; }
      #dashboard .chart-container .numbers p {
        margin-bottom: 0px; }
    #dashboard .user-filter-header {
      padding: 0px 10px; }
      #dashboard .user-filter-header .ant-select {
        width: 50vw; }
      #dashboard .user-filter-header p {
        line-height: 1.5; }
    #dashboard .agent-opportunities-container {
      order: 2; } }
