.account-modal h1 {
  font-size: 18px;
  font-weight: 600; }

.account-modal .ant-modal-content {
  overflow: hidden;
  border-radius: 10px; }

.account-modal p {
  font-size: 14px;
  color: #8C8C8C;
  font-weight: 300;
  margin-top: -15px; }

.account-modal .buttons {
  display: flex;
  padding: 25px;
  background: #E6EBEC;
  margin-left: -24px;
  margin-bottom: -24px;
  width: calc(100% + 48px); }
  .account-modal .buttons button {
    margin-left: auto; }

.account-modal label {
  margin-bottom: 0px; }

.account-modal .password-box {
  position: relative; }
  .account-modal .password-box span {
    position: absolute;
    right: 15px;
    color: #398CAF;
    font-weight: 300;
    cursor: pointer;
    top: 36px; }
    @media (max-width: 768px) {
      .account-modal .password-box span {
        top: 42px; } }

.account-modal .success .anticon-check-circle {
  margin: 0 auto;
  display: block;
  margin-bottom: 20px; }
  .account-modal .success .anticon-check-circle svg {
    color: #89C763;
    font-size: 100px; }

.account-modal .success p {
  font-weight: 300;
  font-size: 16px;
  color: #8C8C8C;
  text-align: center;
  margin-top: 20px; }

.account-modal .success button {
  height: 50px;
  border-radius: 8px;
  background: #DADADA;
  color: black;
  width: 100%; }

.account-modal .bank {
  padding-right: 10px; }

.account-modal .account {
  padding-left: 10px; }
