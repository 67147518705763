.earnings-info {
  height: 88px;
  padding: 13px 15px;
  border-radius: 10px;
  width: 298px;
  position: relative; }
  .earnings-info label {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 0px; }
  .earnings-info p {
    font-family: Poppins;
    font-weight: 700;
    font-size: 26px;
    margin-bottom: 0px; }
    .earnings-info p span {
      font-size: 18px; }
    .earnings-info p.placeholder {
      font-size: 20px;
      margin-top: 5px;
      opacity: .5; }
  .earnings-info.month {
    background-color: #BA4A00; }
    .earnings-info.month label {
      color: #E0C7A8; }
    .earnings-info.month p {
      color: white; }
      .earnings-info.month p.placeholder {
        color: white; }
  .earnings-info.future {
    background-color: white; }
    .earnings-info.future label {
      color: #BA4A00; }
    .earnings-info.future p {
      color: black; }
      .earnings-info.future p.placeholder {
        color: black; }
  .earnings-info img {
    position: absolute;
    filter: drop-shadow(2px 2px 8px rgba(148, 23, 46, 0.4));
    right: 10px;
    top: 15px;
    cursor: pointer; }
  @media (max-width: 768px) {
    .earnings-info {
      width: 100%;
      margin-bottom: 15px; } }
