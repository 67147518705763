.leads-table .status {
  display: flex;
  align-items: center; }
  .leads-table .status p {
    margin: 0px; }
  .leads-table .status div {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    margin-right: 10px; }
  .leads-table .status.Open div, .leads-table .status.Payment div {
    background-color: #FFC100; }
  .leads-table .status.Closed div {
    background-color: #52C41A; }
  .leads-table .status.Lost div {
    background-color: #FB4953; }

.leads-table table .secondary-medium-2 {
  height: 30px; }

@media (max-width: 768px) {
  .leads-table {
    margin-top: 170px; } }

.value-col span {
  color: #505157;
  margin-right: 10px; }

.lead-mobile {
  margin: 10px 20px;
  display: flex;
  width: calc(100% - 40px);
  align-items: center;
  border-radius: 8px;
  height: 80px;
  padding: 8px 15px;
  align-items: center; }
  .lead-mobile:nth-of-type(even) {
    background-color: #F5F5F5; }
  .lead-mobile div:first-of-type {
    height: 100%; }
  .lead-mobile div p:first-of-type {
    font-size: 16px;
    color: #505157;
    margin: 0px; }
  .lead-mobile div p:last-of-type {
    font-size: 14px;
    font-weight: 300;
    margin: 0px; }
  .lead-mobile div:nth-of-type(2) {
    margin-left: auto; }
  .lead-mobile > span {
    margin-left: 20px; }
  .lead-mobile .status {
    display: flex; }
    .lead-mobile .status div {
      height: 14px;
      width: 14px;
      border-radius: 50%;
      margin-right: 0px;
      margin-left: 10px; }
    .lead-mobile .status.Open div {
      background-color: #FFC100; }
    .lead-mobile .status.Closed div {
      background-color: #52C41A; }
    .lead-mobile .status.Lost div {
      background-color: #FB4953; }
  .lead-mobile .profit {
    text-align: right;
    font-weight: 500; }
    .lead-mobile .profit span {
      font-weight: 300; }
