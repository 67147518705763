#add-lead {
  overflow-x: hidden; }
  #add-lead label.title {
    color: #BA4A00;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 10px;
    display: block; }
  #add-lead .divider {
    margin-top: 10px;
    margin-left: -20px;
    width: 110%;
    height: 1px;
    background-color: #EAEAEA; }
  #add-lead > .ant-form > .ant-row > .ant-col {
    padding-right: 20px !important; }

@media (max-width: 768px) {
  input {
    font-size: 16px !important; }
  #add-lead {
    overflow-x: initial; }
    #add-lead .ant-form .ant-row .ant-col {
      padding-right: 0px !important; }
    #add-lead .top-container {
      margin-top: -20px;
      display: block;
      padding: 20px;
      margin-bottom: 10px;
      background-color: #EAEAEA;
      flex-direction: column; }
    #add-lead .status {
      padding: 15px;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border-radius: 8px;
      margin-bottom: 20px;
      cursor: pointer; }
      #add-lead .status .title {
        font-size: 14px;
        color: #595959;
        width: 100%;
        display: flex;
        align-items: center; }
        #add-lead .status .title div {
          width: 14px;
          height: 14px;
          border-radius: 50%;
          margin-right: 10px;
          margin-left: 10px; }
        #add-lead .status .title.Open div {
          background-color: #FFC100; }
        #add-lead .status .title.Closed div {
          background-color: #52C41A; }
        #add-lead .status .title.Lost div {
          background-color: #FB4953; }
      #add-lead .status .ant-progress-steps-item {
        height: 3px !important;
        border-radius: 3px;
        background: #dadada; }
        #add-lead .status .ant-progress-steps-item:last-of-type {
          margin-left: 10px; }
      #add-lead .status p {
        margin: 0px;
        font-weight: 500;
        font-size: 12px; }
      #add-lead .status.lost .ant-progress-steps-item:last-of-type {
        background-color: #fb4953; }
    #add-lead .inline {
      display: flex;
      width: 100%;
      margin-bottom: 20px; }
      #add-lead .inline:last-of-type {
        margin-bottom: 0px; }
      #add-lead .inline button {
        height: 50px;
        min-width: 50px; }
      #add-lead .inline .ant-radio-group {
        width: 100%;
        padding: 6px;
        background-color: white;
        border-radius: 8px;
        display: flex; }
        #add-lead .inline .ant-radio-group > label {
          flex: 1;
          height: 38px;
          padding-top: 4px;
          text-align: center;
          border: none;
          border-top-right-radius: 6px;
          border-top-left-radius: 6px;
          border-bottom-right-radius: 6px;
          border-bottom-left-radius: 6px;
          border-color: transparent;
          border-width: 0px; }
          #add-lead .inline .ant-radio-group > label:before {
            display: none; }
          #add-lead .inline .ant-radio-group > label.ant-radio-button-wrapper-checked {
            background-color: #BA4A00; } }
