.products {
  margin-top: 20px;
  background-color: white;
  border-radius: 10px 10px 0px 0px;
  height: calc(100vh - 301px); }
  .products .head {
    padding: 20px;
    border-bottom: 1px solid #EAEAEA; }
    .products .head button {
      height: 28px;
      margin-left: auto;
      display: block; }
  .products .body {
    padding: 20px; }
    .products .body .ant-table-wrapper {
      overflow-y: scroll;
      max-height: calc(100vh - 390px); }
    .products .body .ant-table-column-sorters-with-tooltip, .products .body .remove-product, .products .body th, .products .body td {
      background-color: white !important; }
    .products .body .ant-table-cell, .products .body th, .products .body tr {
      border: none; }
    .products .body .price span {
      font-size: 12px;
      color: #8B8D96;
      margin-right: 10px; }
    .products .body .discount-rate span {
      background-color: #BA4A00;
      font-weight: 600;
      border-radius: 4px;
      padding: 3px 10px;
      color: white;
      margin-right: 10px; }
    .products .body .discount-price .price, .products .body .discount-price .price * {
      color: #BA4A00; }
  @media screen and (max-width: 768px) {
    .products {
      padding: 0px 10px; }
      .products .primary-medium-1 {
        margin: 20px auto;
        display: block;
        height: 50px;
        width: 100%; }
      .products .product-mobile {
        border-radius: 4px;
        padding: 20px 15px; }
        .products .product-mobile:nth-of-type(odd) {
          background-color: #F5F5F5; }
        .products .product-mobile .name {
          font-weight: 400;
          margin-bottom: 2px; }
        .products .product-mobile .price {
          margin-bottom: 0px;
          font-weight: 300; }
        .products .product-mobile .discount-rate {
          background-color: #BA4A00;
          font-weight: 600;
          border-radius: 4px;
          padding: 3px 10px;
          color: white;
          margin-right: 10px; }
        .products .product-mobile .discounted-price {
          margin-bottom: 0px;
          color: #BA4A00; } }
