.filters-bar-leads {
  display: flex;
  align-items: center;
  margin-bottom: 30px; }
  .filters-bar-leads .ant-input-affix-wrapper {
    max-width: 390px;
    margin-right: 20px;
    border: none;
    border-radius: 8px; }
    .filters-bar-leads .ant-input-affix-wrapper input {
      height: 42px;
      font-size: 16px;
      color: #8B8D96; }
    .filters-bar-leads .ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
      box-shadow: none;
      border: 1px solid #1890FF;
      background-color: #F4F9FF; }
      .filters-bar-leads .ant-input-affix-wrapper.ant-input-affix-wrapper-focused input {
        background-color: transparent; }
  .filters-bar-leads .archived {
    margin-bottom: 0px;
    color: #8B8D96;
    margin-left: auto; }
  .filters-bar-leads button {
    margin-left: auto; }
  .filters-bar-leads .clear {
    font-weight: 500;
    color: #398CAF;
    white-space: nowrap;
    margin-bottom: 0px;
    margin-right: 20px;
    cursor: pointer; }

@media (max-width: 768px) {
  .filters-bar-leads {
    display: block;
    padding: 20px;
    margin-bottom: 10px;
    background-color: #EAEDEE;
    position: fixed;
    width: 100%;
    z-index: 1; }
    .filters-bar-leads .inline {
      display: flex;
      width: 100%;
      margin-bottom: 20px; }
      .filters-bar-leads .inline:last-of-type {
        margin-bottom: 0px; }
      .filters-bar-leads .inline button {
        height: 50px;
        min-width: 50px; }
        .filters-bar-leads .inline button svg {
          font-size: 20px; }
      .filters-bar-leads .inline .filters {
        min-width: 50px;
        min-height: 50px;
        background-color: white;
        border-radius: 8px;
        margin-left: 20px;
        display: flex;
        align-items: center;
        justify-content: center; }
        .filters-bar-leads .inline .filters i:before {
          font-size: 20px;
          color: #505157; } }

.ant-checkbox-wrapper .status {
  display: flex;
  align-items: center;
  font-size: 14px; }
  .ant-checkbox-wrapper .status p {
    font-size: 14px !important; }
  .ant-checkbox-wrapper .status .dot {
    min-height: 10px;
    min-width: 10px;
    margin-right: 5px;
    border-radius: 50%; }
    .ant-checkbox-wrapper .status .dot.Open {
      background-color: #FFC100; }
    .ant-checkbox-wrapper .status .dot.Payment {
      background-color: #8B8D96; }
    .ant-checkbox-wrapper .status .dot.Closed {
      background-color: #52C41A; }
    .ant-checkbox-wrapper .status .dot.Lost {
      background-color: #FB4953; }
